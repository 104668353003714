import React, { useState } from 'react'
import classes from 'classnames'
import { Icon, Icons } from 'ui-kit'
import './message-form.scss'

interface Props {
  icon: React.FunctionComponent,
  title: string
  children: any,
  isExpanded?: boolean
  onDelete?: () => void
}

const MessagesFromDateBlock = function (props: Props) {
  const { icon, title, onDelete, children } = props
  const [ isExpanded, setExpanded ] = useState(props.isExpanded || false)

  return <div className='message-form-date-block'>
    <div className='message-form-date-header' onClick={() => setExpanded(!isExpanded)}>
      <Icon type={icon} />
      <span className='-title'> { title }</span>
      { onDelete
        ? <button
          type='button'
          className='-delete'
          onClick={(e) => {
            e.stopPropagation()
            onDelete?.()
          }}
        >
          <Icon type={ Icons.Close }/>
        </button>
        : <button
          type='button'
          className={ classes(isExpanded && '-expanded') }
        >
          <Icon type={ Icons.ArrowRight }/>
        </button>
      }
    </div>

    <div className={classes('message-form-date-content', isExpanded && '-expanded')}>
      { children }
    </div>
  </div>
}

export default MessagesFromDateBlock
