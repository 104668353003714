import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAccessToken } from '@megapolis/react-auth'

const methods = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH']

const getParams = function (other: any[]) {
  const method = other.find(x => typeof x === 'string' && methods.includes(x.toUpperCase()))
  const query = other.find(x => typeof x === 'string' && !methods.includes(x.toUpperCase()))
  const params = other.find(x => typeof x !== 'string')
  return { method, query, params: JSON.stringify(params)}
}

export const useFetch = function<T>(
  url: string,
  ...other: any[]
): [
  T | null | undefined,
  string,
  () => void
] {
  const token = useAccessToken()
  const [ data, setData ] = useState<null | undefined | T>(undefined)
  const [ error, setError ] = useState('')
  const [ xhr ] = useState(new XMLHttpRequest())
  const [ method, query, params ] = useMemo(function () {
    if (!url)  {
      return []
    }
    const { method, query, params } = getParams(other)
    return [ method, query, params ]
  }, [ other, url ])

  useEffect(function () {
    let timerId: number;
    if (data === undefined) {
      if (token && url && xhr) {
        timerId = window.setTimeout(() => {
          xhr.open(method || 'GET', `${url}${query ? `?${query}` : ''}`, true)
          xhr.setRequestHeader('Accept', 'application/json')
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)
          xhr.setRequestHeader('Content-Type', 'application/json')
          xhr.send(params)
        }, 50)
      }
    }

    return function () {
      clearTimeout(timerId)
    }
  }, [data, token, url, query, params, method, xhr])

  useEffect(function () {
    return function () {
      setData(undefined)
      setError('')
    }
  }, [ url, query, params ])

  const handleReload = useCallback(function () {
    setData(undefined)
    setError('')
  }, [])

  useEffect(function () {
    let mount = true
    xhr.onreadystatechange = async function() {
      if (!mount) { return }
      if (xhr.readyState !== 4) { return }

      if (xhr.status !== 200) {
        setError(`${xhr.status}: ${xhr.responseText}`)
        setData(null)
      } else {
        setData(JSON.parse(xhr.response))
      }
    }

    return function () {
      mount = false
    }
  }, [ xhr ])

  return [ data, error, handleReload ]
}

export default useFetch
