import React, { useEffect, useMemo, useState } from 'react'
import { FeatureCollection } from 'geojson'
import { Icons } from 'ui-kit'
import { Suggester } from 'ui-kit/controls'
import { AppIcons } from 'consts'
import { IGeoZone } from 'models/IGeoZone'
import { useFetch } from '../../ui-kit/hooks'
import { getQueryParams } from '../../ui-kit/helpers'
import { IGeoAddress } from 'models/IGeoAddress'

interface Props {
  onChange: (v: FeatureCollection | null) => void
  mode: 'boards' | 'geozones'
  disabled: boolean
  zones: IGeoZone[] | null | undefined
}

const MessagesBoardsSearch = function (props: Props) {
  const { onChange, mode, zones, disabled } = props
  const [ value, setValue ] = useState('')

  const [ addresses ] = useFetch<IGeoAddress[]>(value && mode === 'boards'
    ? `https://api.megapolis-it.ru/geocode/api/address${getQueryParams({ address: value, limit: 10 })}`
    : '')

  const zonesList = useMemo(function () {
    return zones
      ? zones.map(z => ({
        label: z.value || '',
        value: z.id,
        coordinates: z.geo?.shapes?.map(x => x.coordinates) || ''
      }))
      : zones
  }, [ zones ])

  const addressesList = useMemo(function () {
    return addresses
      ? addresses.map(a => ({
        label: a.address,
        value: a.id,
        lat: a.lat,
        lng: a.lng
      }))
      : addresses
  }, [ addresses ])

  const handleZoneSelect = function (data: any) {
    onChange({
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        id: 1,
        geometry: {
          type: 'MultiPolygon',
          coordinates: [data.coordinates]
        },
      }]
    } as FeatureCollection)
  }

  const handleAddressSelect = function (data: any) {
    const { label, lng, lat} = data
    setValue(label)

    onChange({
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        id: 1,
        geometry: {
          type: 'Point',
          coordinates: [ lng, lat ]
        },
      }]
    } as FeatureCollection)
  }

  useEffect(function () {
    if (!value) {
      onChange(null)
    }
  }, [ value, onChange ])

  useEffect(function () {
    setValue('')
  }, [ mode ])

  return mode === 'boards'
    ? <Suggester
      fixed
      filtered
      size={30}
      icon={Icons.Map}
      value={value}
      onChange={(e: any) => setValue(e.target?.value || '')}
      onSelect={handleAddressSelect}
      list={addressesList}
      placeholder='Поиск по адресу'
      disabled={disabled}
    />
    : <Suggester
      fixed
      size={30}
      icon={AppIcons.ZoneSearch}
      placeholder='Поиск геозоны'
      value={value}
      onChange={(e: any) => setValue(e.target?.value || '')}
      disabled={disabled}
      onSelect={handleZoneSelect}
      list={zonesList}
    />
}

export default MessagesBoardsSearch
