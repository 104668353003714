import React, { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import classes from 'classnames'
import { Icon } from '../components'
import { Icons } from '../consts'
import { ReduxState, RouteParams } from '../types'
import { setSorting } from '../reducers/settings'
import { ITableColumn } from '.'

interface Props {
  columns: ITableColumn[]
  widths: number[]
  minWidth: number
  style?: React.CSSProperties
}

const TableHead = React.forwardRef(function (props: Props, ref: React.Ref<any>) {
  const { columns, widths, minWidth, style } = props
  const { page }: RouteParams = useParams()
  const dispatch = useDispatch()
  const sort = useSelector(({ settings }: ReduxState) => settings.sort[page], shallowEqual)
  
  useEffect(function () {
    if (!sort?.key) {
      const defaultKey = columns.filter(x => x.key && x.label)[0]?.key
      defaultKey && dispatch(setSorting(page, defaultKey))
    }
  }, [ sort, columns, dispatch, page ])
  
  const handleClick = useCallback(function (column: ITableColumn) {
    if (column.label && column.key) {
      dispatch(setSorting(page, column.key))
    }
  }, [ page, dispatch ])
  
  return <div 
    className='table-head' 
    style={style}
  >
    <div
      className={'table-head-row'}
      style={{ minWidth }}
    >
      { columns.map((column, index) => {
        const isSortable = column.key && column.label
        const isActive = column.key && sort?.key === column.key
        
        return <span
          key={ index }
          className='table-head-cell'
          style={ { width: widths[index] } }
        >
          <button
            type='button'
            className={ classes(
              'table-head-button',
              isSortable && '-clickable',
              isActive && '-active',
              sort?.descending ? '-descending' : '-ascending'
            ) }
            onClick={() => handleClick(column)}
          >
            <span>{ column.label || ' ' }</span>
            { isSortable && <Icon type={ Icons.ArrowRight }/> }
          </button>
        </span>
      }) }
    </div>
  </div>
})

export default TableHead