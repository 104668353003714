import { getQueryParams } from 'ui-kit/helpers'
import { API_URL } from 'consts'
import { TSummary, TValue } from 'ui-kit/types'
import { IContent, IContentResult } from 'models/IContent'
import { IBoard } from 'models/IBoard'
import { ITag } from 'models/ITag'

/**
 * Получает саммари по контенту
 * @param { string } token
 */
export const fetchSummary = async function (token: string) {
  const response = await fetch(`${ API_URL }/Content/summary`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return await response.json() as TSummary
  } else {
    throw await response.json()
  }
}

/**
 * Возвращает список сообщений постранично
 * @param { string } token
 * @param { Object } filters - фильтры
 * @param { number } filters.page - номер страницы
 * @param { number } filters.count - количество записей на страницу
 * @param { string } filters.search - поисковый запрос
 * @param { number[] } filters.formats - формат табло
 * @param { [boolean] } filters.removed - с архивными сообщениями
 * @param { [boolean] } filters.my - сообщения пользователя
 * @param { string[] } filters.tags - тег
 */
export const fetchContents = async function (
  token: string,
  filters?: { page?: number, count?: number } & { [key: string]: TValue[] }
) {
  const params = {
    ...filters,
    tags: (filters?.tags as unknown as ITag[])?.map((x) => x.id),
    removed: undefined,
    my: undefined,
    showRemoved: filters?.removed,
    createdByMe: filters?.my
  }

  const response = await fetch(`${ API_URL }/Content${ getQueryParams(params) }`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return await response.json() as IContentResult
  } else {
    throw await response.json()
  }
}

/**
 * Возвращает картинку/видео фрейма
 * @param {string} token
 * @param {number} frameId - идентификатор фрейма
 */
export const fetchContentFrame = async function (token: string, frameId: number) {
  const response = await fetch(`${ API_URL }/Content/frame/${ frameId }/data`, {
    method: 'GET',
    headers: {
      Accept: 'application/octet-stream',
      Authorization: `Bearer ${token}`,
    }
  })

  if (response.status === 200) {
    return URL.createObjectURL(await response.blob())
  } else {
    throw await response.json()
  }
}

export const fetchContent = async function (token: string, id: number) {
  const response = await fetch(`${ API_URL }/Content/${ id }`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return await response.json() as IContent
  } else {
    throw await response.json()
  }
}

/**
 * Возвращает табло, к которым привязан контент
 * @param {string} token
 * @param {number} id - идентификатор контента
 * @returns {IBoard[]} - список табло
 */
export const fetchContentBoards = async function (token: string, id: number) {
  const response = await fetch(`${ API_URL }/Content/${ id }/board`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return await response.json() as IBoard[]
  } else {
    throw await response.json()
  }
}

/**
 * Сохраняет данные сообщения
 * @param {string} token — токен авторизации
 * @param {IContent} data - данные
 * @returns {IContent} - сохраненное сообщения
 */
export const saveContent = async function (token: string, data: IContent) {
  const response = await fetch(
    `${ API_URL }/Content${data.id ? `/${data.id}` : ''}`,
    {
      method: data.id ? 'PUT' : 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ token }`
      },
      body: JSON.stringify(data)
    }
  )

  if (response.status === 200) {
    return await response.json() as IContent
  } else {
    throw await response.json()
  }
}

/**
 * Архивирует контент
 * @param {string} token — токен авторизации
 * @param {number} id - идентификатор контента
 * @returns {boolean}
 */
export const archiveContent = async function (token: string, id: number) {
  const response = await fetch(
    `${ API_URL }/Content/${id}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ token }`
      },
    }
  )

  if (response.status === 204) {
    return true
  } else {
    throw await response.json()
  }
}

/**
 * Разархивировать контент
 * @param {string} token — токен авторизации
 * @param {number} id - идентификатор контента
 * @returns {boolean}
 */
export const extractContent = async function (token: string, id: number) {
  const response = await fetch(
    `${ API_URL }/Content/${id}/restore`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ token }`
      },
    }
  )

  if (response.status === 204) {
    return true
  } else {
    throw await response.json()
  }
}

/**
 * Назначает табло на контент реплейсом
 * @param {string} token
 * @param {string} contentId - идентификатор контента
 * @param {number[]} ids - массив идентификторов табло
 * @returns { boolean }
 */
export const assignBoards = async  function (token: string, contentId: number, ids: number[]) {
  const response = await fetch(
    `${ API_URL }/Content/${contentId}/board`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ token }`
      },
      body: JSON.stringify(ids)
    }
  )

  if (response.status === 200) {
    return true
  } else {
    throw await response.json()
  }
}

/**
 * Получает историю по контенту
 * @param {string} token
 * @param {string} contentId - идентификатор контента
 * @returns { boolean }
 */
export const fetchContentLog = async function (
  token: string,
  contentId: number,
) {
  const response = await fetch(
    `${ API_URL }/Content/history?contentId=${contentId}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ token }`
      }
    }
  )

  if (response.status === 200) {
    return await response.json()
  } else {
    throw await response.json()
  }
}

/**
 * Публикует/снимает с публикации контент
 * @param { string } token
 * @param { number } contentId - идентификатор контента
 * @param { boolean } publish - флаг
 * @returns { boolean }
 */
export const publishContent = async function (token: string, contentId: number, publish: boolean) {
  const response = await fetch(
    `${ API_URL }/Content/${contentId}/publish`,
    {
      method: publish ? 'PUT' : 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${ token }`
      }
    }
  )

  if (response.status === 204) {
    return true
  } else {
    throw await response.json()
  }
}
