import { loadDatum } from 'ui-kit/reducers/datum'
import { loadDetails } from 'ui-kit/reducers/details'
import { Page } from 'consts'
import * as logsApi from 'services/logs'

export const loadLogsData = function (batch?: number) {
  return loadDatum(Page.Logs, logsApi.fetchLogs, batch)
}

export const loadLogDetails = function (id: number) {
  return loadDetails(id, Page.Logs, logsApi.fetchLog)
}
