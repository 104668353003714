import React from 'react'
import { LayoutArea } from 'ui-kit'
import Form from 'ui-kit/form'
import MessageFormComponent from './MessageForm'

const MessageForm = (props: { edit: boolean }) => {
  return <LayoutArea>
    <Form>
      <MessageFormComponent {...props} />
    </Form>
  </LayoutArea>
}

export default MessageForm
