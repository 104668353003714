import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "classnames";
import format from "date-fns/format";
import TimePicker from "./TimePicker";
import { Checkbox } from "../index";
import { getLocalDate } from "../../helpers";
import DayPicker, { Modifier } from "react-day-picker";
import "./calendar.scss";

// FIXME: Make proper peroid date|time selection

interface Props {
  title?: string;
  from?: Date | string;
  to?: Date | string;
  endOfDay?: boolean;
  withTime?: boolean | ((value: boolean) => void);
  startDate: Date | undefined;
  endDate?: Date | undefined;
  onChange: (
    startDate: Date | number | undefined,
    endDate?: Date | number | undefined
  ) => void;
  period?: boolean;
  weekDays?: number[] | null;
  displayMonth?: Date;
  activeDate?: "start" | "end";
  style?: React.CSSProperties;
}

export const locales = {
  locale: "ru",
  weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  weekdaysLong: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
  months: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  firstDayOfWeek: 1,
};

export const isWithTime = function (
  startDate: Date | undefined,
  endDate: Date | undefined
) {
  return Boolean(
    (startDate &&
      (startDate.getHours() !== 0 || startDate.getMinutes() !== 0)) ||
      (endDate && (endDate.getHours() !== 23 || endDate.getMinutes() !== 59))
  );
};

const Calendar = function (props: Props) {
  const {
    from,
    to,
    endOfDay,
    startDate,
    endDate,
    onChange,
    period,
    weekDays,
    title,
    displayMonth,
    activeDate,
    style,
  } = props;

  const [withTime, setWithTime] = useState(isWithTime(startDate, endDate));
  const [time, setTime] = useState(["", ""]);

  const onToggleTime = useMemo(
    function () {
      return typeof props.withTime === "function" ? props.withTime : undefined;
    },
    [props.withTime]
  );

  useEffect(
    function () {
      onToggleTime?.(withTime);
    },
    [withTime, onToggleTime]
  );

  useEffect(
    function () {
      setTime([
        startDate ? format(startDate, "HH:mm") : "00:00",
        endDate ? format(endDate, "HH:mm") : "23:59",
      ]);
    },
    [startDate, endDate]
  );

  const getDateTime = useCallback(
    function (date: Date, end?: boolean) {
      return setDateTime(date, withTime ? time[end ? 1 : 0] : null, end);
    },
    [withTime, time]
  );

  const disabledDays = useMemo(
    function (): Modifier[] | undefined {
      let days: Modifier[] = [];
      if (from || to) {
        days.push({
          before: getLocalDate(from),
          after: getLocalDate(to),
        } as Modifier);
      }

      if (weekDays && weekDays.length < 7) {
        days.push({
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6].filter(
            (x) => !weekDays.includes(x)
          ),
        });
      }

      return days.length > 0 ? days : undefined;
    },
    [from, to, weekDays]
  );

  const handleChange = function (
    startDate: Date | undefined,
    endDate?: Date | undefined
  ) {
    onChange(startDate, endDate);
  };

  const handleDaySelect = function (day: Date) {
    if (period) {
      const start = startDate;
      const isStartDate =
        !start ||
        Boolean(endDate) ||
        day.setHours(0, 0, 0, 0) < start.setHours(0, 0, 0, 0);
      handleChange(
        isStartDate ? getDateTime(day, false) : startDate,
        isStartDate ? undefined : getDateTime(day, true)
      );
    } else {
      const nextDate = endOfDay
        ? day.setHours(23, 59, 59, 999)
        : day.setHours(0, 0, 0, 0);
      const isDeselect =
        activeDate === "end"
          ? endDate &&
            (endOfDay
              ? endDate.setHours(23, 59, 59, 999)
              : endDate.setHours(0, 0, 0, 0)) === nextDate
          : startDate &&
            (endOfDay
              ? startDate.setHours(23, 59, 59, 999)
              : startDate.setHours(0, 0, 0, 0)) === nextDate;

      handleChange(
        !isDeselect
          ? getDateTime(new Date(nextDate), activeDate === "end")
          : undefined
      );
    }
  };

  const dateValues = useMemo(
    function () {
      if (startDate && endDate) {
        return [startDate, { from: startDate, to: endDate }];
      }

      return period
        ? startDate
          ? [startDate]
          : undefined
        : startDate || endDate
        ? [startDate || endDate]
        : undefined;
    },
    [startDate, endDate, period]
  );

  return (
    <div className="calendar" style={style}>
      {title && <div className="calendar-title">{title}</div>}
      {/* @ts-ignore */}
      <DayPicker
        {...locales}
        className={classes(period && "Selectable")}
        selectedDays={dateValues}
        month={displayMonth || startDate}
        onDayClick={handleDaySelect}
        disabledDays={disabledDays}
      />
      {props.withTime && (
        <div className="calendar-time">
          {/* @ts-ignore */}
          <Checkbox value={withTime} onChange={(v) => setWithTime(Boolean(v))}>
            Время
          </Checkbox>
          {/* @ts-ignore */}
          <TimePicker
            small
            value={time[0]}
            onChange={(e) => {
              setTime([e.currentTarget.value, time[1]]);
              if (activeDate === "end" ? endDate : startDate) {
                handleChange(
                  activeDate === "end"
                    ? setDateTime(endDate, e.currentTarget.value, true)
                    : setDateTime(startDate, e.currentTarget.value, false)
                );
              }
            }}
            hidden={!withTime}
            disabled={!withTime || (!period && activeDate === "end")}
          />

          {/* @ts-ignore */}
          <TimePicker
            small
            value={time[1]}
            onChange={(e) => {
              setTime([time[0], e.currentTarget.value]);
              if (activeDate === "end" ? endDate : startDate) {
                handleChange(
                  activeDate === "end"
                    ? setDateTime(endDate, e.currentTarget.value, true)
                    : setDateTime(startDate, e.currentTarget.value, false)
                );
              }
            }}
            hidden={!withTime}
            disabled={!withTime || (!period && activeDate === "start")}
          />
        </div>
      )}
    </div>
  );
};

export default Calendar;

const setDateTime = function (
  date: Date | undefined,
  time: string | null,
  end?: boolean
) {
  if (!date) {
    return undefined;
  }

  const res = new Date(date);

  return new Date(
    time
      ? res.setHours(
          +time.split(":")[0],
          +time.split(":")[1],
          end ? 59 : 0,
          end ? 999 : 0
        )
      : end
      ? res.setHours(23, 59, 59, 999)
      : res.setHours(0, 0, 0, 0)
  );
};
