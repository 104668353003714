import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classes from "classnames";
import { Button, Icon, Icons, Image, List, Loader, Popup } from "ui-kit";
import Scroller, { ScrollPosition } from "ui-kit/components/Scroller";
import {
  getDatesRange,
  getFormattedSchedule,
} from "components/Messages/Messages.helpers";
import { BoardFormat, BoardType, Photo, AppIcons } from "consts";
import { boardFormatList, boardTypeList } from "settings";
import { ISchedule } from "models/ISchedule";
import "ui-kit/table/table.scss";
import "components/Messages/message.scss";

export type FrameImage = {
  id: number;
  type: Photo;
  style: React.CSSProperties;
};

interface Props {
  id: number;
  title: string | React.ReactElement;
  formats?: BoardFormat[] | null;
  isPublished?: boolean;
  types?: BoardType[] | null;
  schedule?: ISchedule[] | null;
  boardCount?: string | React.ReactElement;
  images: FrameImage[];
  widths: number[];
}

const scrollerProps = {
  style: { minHeight: 149 },
  offset: { left: 20, right: 20 },
};

const MessagesRow = function (props: Props) {
  const {
    id,
    title,
    formats,
    types,
    isPublished,
    schedule,
    boardCount,
    images,
    widths,
  } = props;

  const imagesRef = useRef<HTMLSpanElement | null>(null);
  const [rightArrow, setRightArrow] = useState(false);
  const [leftArrow, setLeftArrow] = useState(false);
  const scrollParamsRef = useRef<null | { [key: string]: any }>(null);

  const onScroll = useCallback(
    function (p: ScrollPosition) {
      if (scrollParamsRef.current) {
        const { direction, end } = scrollParamsRef.current;
        if (direction > 0) {
          if (end === p.limitLeft) setRightArrow(false);
          setLeftArrow((p.scrollLeft || 0) > 0);
          if (p.scrollLeft === p.limitLeft) scrollParamsRef.current = null;
        }
        if (direction < 0) {
          if (end === 0) setLeftArrow(false);
          setRightArrow((p.scrollLeft || 0) < (p.limitLeft || 0));
          if (p.scrollLeft === 0) scrollParamsRef.current = null;
        }
      } else {
        setLeftArrow((p.scrollLeft || 0) > 0);
        setRightArrow((p.scrollLeft || 0) < (p.limitLeft || 0));
      }
    },
    [scrollParamsRef]
  );

  const inertialScroll = function () {
    if (scrollParamsRef.current) {
      const { end, element, direction } = scrollParamsRef.current;
      if (Math.abs(end - element.scrollLeft) > 1) {
        element.scrollLeft +=
          Math.min(30, Math.abs(end - element.scrollLeft) / 2) * direction;
        window.requestAnimationFrame(inertialScroll);
      } else {
        element.scrollLeft = end;
        scrollParamsRef.current = null;
      }
    }
  };

  const handleScroll = function (e: any, direction: -1 | 1) {
    e.stopPropagation();
    const scrollEl = imagesRef.current?.parentElement;
    if (scrollEl) {
      scrollParamsRef.current = {
        direction,
        element: scrollEl,
        end:
          direction === 1
            ? Math.min(
                scrollEl.scrollLeft + widths[0],
                scrollEl.scrollWidth - scrollEl.offsetWidth + 15
              )
            : Math.max(0, scrollEl.scrollLeft - widths[0]),
      };

      inertialScroll();
    }
  };

  useEffect(
    function () {
      if (imagesRef.current) {
        const scrollEl = imagesRef.current.parentElement;
        if (!scrollEl?.scrollLeft) {
          setRightArrow(imagesRef.current.offsetWidth > widths[0]);
        }
      } else {
        setRightArrow(false);
      }
    },
    [imagesRef, widths]
  );

  const handleMouseEnter = useCallback(
    function () {
      const scrollEl = imagesRef.current?.parentElement;
      if (scrollEl) {
        const scrollEvent = new Event("scroll");
        scrollEl?.dispatchEvent(scrollEvent);
      }
    },
    [imagesRef]
  );

  return (
    <span className="message-block" onMouseEnter={handleMouseEnter}>
      {id ? (
        <>
          <span className="message-header">
            {title && <span className="message-title">{title}</span>}
            {boardCount !== undefined && (
              <span className="message-board-count">{boardCount}</span>
            )}
            {schedule !== undefined && (
              <MessagesRowDates
                isCollapsed={widths[0] < 680}
                schedule={schedule}
              />
            )}
            {isPublished !== undefined && (
              <span className="message-icons">
                <Icon fill type={AppIcons.Published(isPublished)} />
              </span>
            )}
            {types !== undefined && (
              <MessagesRowTypes isCollapsed={widths[0] < 580} types={types} />
            )}
            {formats !== undefined && (
              <MessagesRowFormats
                isCollapsed={widths[0] < 580}
                formats={formats}
              />
            )}
          </span>
          {images?.length ? (
            <>
              <div className="message-images-wrapper">
                <Scroller {...scrollerProps} scrollTo={onScroll}>
                  <span className="message-images" ref={imagesRef}>
                    {images.map((p) => (
                      <Image key={p.id} {...p} cover />
                    ))}
                  </span>
                </Scroller>
              </div>
              <Button
                small
                blur
                className={classes(
                  "message-images-arrow -left",
                  leftArrow && "-visible"
                )}
                icon={Icons.ArrowLeft}
                onClick={(e) => handleScroll(e, -1)}
              />
              <Button
                small
                blur
                className={classes(
                  "message-images-arrow -right",
                  rightArrow && "-visible"
                )}
                icon={Icons.ArrowRight}
                onClick={(e) => handleScroll(e, 1)}
              />
            </>
          ) : null}
        </>
      ) : (
        <>
          <span className="message-header">
            <span className="message-title">
              <Loader />
            </span>
          </span>
          <div className="message-images-wrapper">
            <span className="message-images">
              <Image id={0} style={{ height: 120, width: 120 }} />
            </span>
          </div>
        </>
      )}
    </span>
  );
};

const MessagesRowDates = function (props: {
  schedule: ISchedule[] | null;
  isCollapsed: boolean;
}) {
  const { schedule, isCollapsed } = props;

  const scheduleList = useMemo(
    function () {
      if (!schedule) {
        return [
          {
            label: getFormattedSchedule(null).period,
            icon: Icons.Calendar,
          },
        ];
      }

      return schedule.map((s, index) => {
        const { period, weekDays, time } = getFormattedSchedule(s);

        return {
          label: period,
          description: [weekDays, time].filter((x) => x).join(" / "),
          icon: Icons.Calendar,
        };
      });
    },
    [schedule]
  );

  return isCollapsed ? (
    <span className="message-button">
      {/* @ts-ignore */}
      <Popup
        style={{ right: 5 }}
        className="message-dates-popup"
        trigger={Button}
        triggerProps={({ onClick, isActive }: any) => ({
          onClick,
          fill: isActive,
          icon: Icons.Calendar,
          clear: true,
        })}
      >
        {/* @ts-ignore */}
        <List list={scheduleList} />
      </Popup>
    </span>
  ) : (
    <span className="message-dates">
      {/* @ts-ignore */}
      <Icon type={Icons.Calendar} />
      {getDatesRange(schedule)}
    </span>
  );
};

const MessagesRowFormats = function (props: {
  formats: BoardFormat[] | null;
  isCollapsed: boolean;
}) {
  const { formats, isCollapsed } = props;

  const formatsList = useMemo(
    function () {
      if (!formats) {
        return [];
      }

      return boardFormatList.filter((x) => formats.includes(x.value));
    },
    [formats]
  );

  if (!formats?.length) {
    return null;
  }

  return isCollapsed && formats.length > 1 ? (
    <span className="message-button">
      {/* @ts-ignore */}
      <Popup
        style={{ right: 5 }}
        className="message-dates-popup"
        trigger={Button}
        triggerProps={({ onClick, isActive }: any) => ({
          onClick,
          fill: isActive,
          icon: AppIcons.BoardFormat(),
          clear: true,
        })}
      >
        {/* @ts-ignore */}
        <List list={formatsList} />
      </Popup>
    </span>
  ) : (
    <span className="message-icons">
      {formats.map((f) => (
        //@ts-ignore
        <Icon type={AppIcons.BoardFormat(f)} key={f} />
      ))}
    </span>
  );
};

const MessagesRowTypes = function (props: {
  types: BoardType[] | null;
  isCollapsed: boolean;
}) {
  const { types, isCollapsed } = props;

  const typesList = useMemo(
    function () {
      if (!types) {
        return [];
      }

      return boardTypeList.filter((x) => types.includes(x.value));
    },
    [types]
  );

  if (!types?.length) {
    return null;
  }

  return isCollapsed && types.length > 1 ? (
    <span className="message-button">
      {/* @ts-ignore */}
      <Popup
        style={{ right: 5 }}
        className="message-dates-popup"
        trigger={Button}
        triggerProps={({ onClick, isActive }: any) => ({
          onClick,
          fill: isActive,
          icon: AppIcons.BoardType(),
          clear: true,
        })}
      >
        {/* @ts-ignore */}
        <List list={typesList} />
      </Popup>
    </span>
  ) : (
    <span className="message-icons">
      {types.map((f) => (
        //@ts-ignore
        <Icon type={AppIcons.BoardType(f)} key={f} />
      ))}
    </span>
  );
};

export default MessagesRow;
