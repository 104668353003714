import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { SectionTitle, Value, Tabs, Icons } from "ui-kit";
import { Button } from "ui-kit/controls";
import { showFilters } from "ui-kit/reducers/layout";
import { FilterSearch } from "ui-kit/filter";
import { createPathURL } from "ui-kit/helpers";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { Page } from "consts";

interface Props {
  width: number;
}

const MessagesHeader = function (props: Props) {
  const match: RouteMatch = useRouteMatch();
  const hasFilters = useSelector(
    ({ layout }: ReduxState) => layout.showFilters[Page.Messages]
  );
  const total = useSelector(
    ({ datum }: ReduxState) => datum[Page.Messages].total
  );
  const isArchive = useSelector(
    ({ settings }: ReduxState) => settings.filters[Page.Messages]?.removed?.[0]
  );
  const dispatch = useDispatch();

  return (
    <>
      <Button
        icon={Icons.Filter}
        onClick={() => dispatch(showFilters(Page.Messages))}
        active={hasFilters}
      />
      <SectionTitle
        label={
          <span>
            <span>Найдено: </span>
            <Value value={total} />
          </span>
        }
      >
        {isArchive ? "Архив сообщений" : "Список сообщений"}
      </SectionTitle>

      <Button
        fill
        icon={Icons.Plus}
        href={`${match?.url}/create`}
        label={props.width > 840 ? "Добавить сообщение" : undefined}
      />

      <FilterSearch
        placeholder={isArchive ? "Поиск по архиву" : "Поиск сообщений"}
      />

      <Tabs>
        <Button
          href={createPathURL(match?.path, { ...match?.params, mode: "list" })}
          replace
          clear
          icon={Icons.List}
        />
        <Button
          href={createPathURL(match?.path, { ...match?.params, mode: "tiles" })}
          replace
          clear
          icon={Icons.Tiles}
        />
      </Tabs>
    </>
  );
};

export default MessagesHeader;
