import * as PIXI from 'pixi.js'
import { PixelateFilter } from '@pixi/filter-pixelate'
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import { TwistFilter } from '@pixi/filter-twist'
import { applyEffects } from './applyEffects'
import { TPreviewSource } from '../usePreviewFrames'
import { FrameKind, FrameTransition } from 'consts'

export const initFrames = function (
  position: number,
  renderers: (TPreviewSource | undefined)[],
  pixi: PIXI.Application
) {
  const [ previous, current ] = renderers
  pixi.stage.filters = []

  if (previous) {
    previous.sprite.alpha = 1
  }

  if (current) {
    current.sprite.alpha = 1

    switch (current.effect) {
      case FrameTransition.Blinds: {
        current.sprite.children.length > 1 && current.sprite.removeChildren(1)
        const mask = new PIXI.Graphics()
        current.sprite.addChild(mask)
        mask.lineStyle(0)
        mask.beginFill(0x8bc5ff, 1)
        mask.drawRect(0, 0, pixi.view.width, pixi.view.height)
        mask.endFill()
        current.sprite.children[0].mask = mask
        break
      }

      case FrameTransition.Pixelate: {
        const filter = new PixelateFilter(1 / devicePixelRatio)
        pixi.stage.filters = [filter as unknown as PIXI.Filter]
        break
      }

      case FrameTransition.RadialBlur: {
        const filter = new KawaseBlurFilter(0, 10, true)
        pixi.stage.filters = [filter as unknown as PIXI.Filter]
        break
      }

      case FrameTransition.Swirl: {
        const filter = new TwistFilter({
          radius: Math.max(pixi.view.width, pixi.view.height),
          angle: 0
        })
        filter.offset.set(pixi.view.width / 2, pixi.view.height / 2)
        pixi.stage.filters = [filter as unknown as PIXI.Filter]
        break
      }

    }

    if (current.kind === FrameKind.Video) {
      (current.source as HTMLVideoElement).currentTime = position / 1000
    }

    if (pixi.stage.children.length > 1) pixi.stage.removeChildren(0, 1)
    pixi.stage.addChild(current.sprite)

    if (position >= 0 && position < current.time.transition + 100) {
      applyEffects(position, renderers, pixi)
    }
  }
}
