import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import classes from "classnames";
import { List, IndexRange, OverscanIndexRange } from "react-virtualized";
import { AppLoader, AppLoadingError, AppDataEmpty } from "../app";
import Tile from "./Tile";
import { sectionContext } from "../section";
import { createPathURL } from "../helpers";
import { useDidUpdate } from "../hooks";
import { setRedirect } from "../reducers/layout";
import { BATCH_SIZE } from "../consts";
import { RouteMatch, TileData } from "../types";
import "./tiles.scss";

interface Props {
  onLoad: (batch?: number) => void;
  items: (TileData | undefined)[] | undefined | null;
  total: number | undefined | null;
}

const Tiles = function (props: Props) {
  const { onLoad, total, items } = props;
  const match: RouteMatch = useRouteMatch();
  const listRef = useRef<List | null>(null);
  const dispatch = useDispatch();
  const { scrollTo, onScroll, style } = useContext(sectionContext);
  const { width, height } = style || {};

  const [rowMapper, rowHeight] = useMemo(
    function () {
      const count = Math.round((width || 0) / 290) || 1;
      let mapper = [];
      for (let i = 0; i < count; i++) {
        mapper.push(i);
      }

      const rowHeight = ((width || 0) / count - 40) * 0.75 + 135;
      return [mapper, rowHeight];
    },
    [width]
  );

  const rowIndex = useMemo(
    function () {
      return items
        ? items.findIndex(
            (x: any) => x && x.id === Number(match?.params?.id || 0)
          )
        : null;
    },
    [items, match]
  );

  useDidUpdate(
    function ([prevRowIndex, prevMatch]: any) {
      if (
        (rowIndex !== null && prevRowIndex === null) ||
        prevMatch?.params.id !== match?.params?.id
      ) {
        if (match?.params?.id && rowIndex) {
          const start = Math.floor(rowIndex / rowMapper.length) * rowHeight;
          scrollTo?.({ scrollTop: [start, start + rowHeight] });
        }
      }
    },
    [rowIndex, match, scrollTo, rowHeight]
  );

  useLayoutEffect(
    function () {
      if (rowIndex !== null) {
        if (!match?.params?.id && items && items[0] && items[0].id) {
          dispatch(
            setRedirect(
              createPathURL(match?.path, { ...match?.params, id: items[0].id })
            )
          );
        }
      } else {
        scrollTo?.({ scrollTop: 0 });
      }
    },
    [dispatch, scrollTo, rowIndex, rowMapper, rowHeight, match, items]
  );

  const handleScrollTo = useCallback(
    function (params) {
      if (Array.isArray(params.scrollTop) || Array.isArray(params.scrollLeft))
        return;
      listRef.current?.Grid?.handleScrollEvent(params);
    },
    [listRef]
  );

  useLayoutEffect(
    function () {
      onScroll?.(handleScrollTo);

      return function () {
        onScroll?.(handleScrollTo);
      };
    },
    [onScroll, handleScrollTo]
  );

  const handleFetch = function (params: IndexRange & OverscanIndexRange) {
    if (items) {
      const { overscanStartIndex, overscanStopIndex } = params;
      let batches = [];
      let i = overscanStartIndex;
      while (i <= overscanStopIndex * rowMapper.length) {
        if (items[i] === undefined) {
          const batch = Math.floor(i / (BATCH_SIZE as number)) + 1;
          batches.push(batch);
          i = batch * (BATCH_SIZE as number);
        } else {
          i++;
        }
      }

      batches.forEach((batch) => onLoad(batch));
    }
  };

  const handleNavigate = function (e: any, id?: number) {
    if (e.target.tagName !== "A") {
      const url = createPathURL(match?.path, { ...match?.params, id: id });
      dispatch(setRedirect(url));
    }
  };

  if (!items) {
    return items === null ? (
      //@ts-ignore
      <AppLoadingError onClick={() => onLoad()} />
    ) : (
      //@ts-ignore
      <AppLoader />
    );
  }

  if (!total) {
    //@ts-ignore
    return <AppDataEmpty />;
  }

  return (
    <div className="tiles">
      {/* @ts-ignore */}
      <List
        ref={listRef}
        rowCount={Math.ceil(total / rowMapper.length)}
        rowHeight={rowHeight}
        width={width || 0}
        height={height || 0}
        onRowsRendered={handleFetch}
        overscanRowCount={3}
        style={{
          overflowX: "visible",
          overflowY: "visible",
          paddingTop: 144,
        }}
        rowRenderer={(props) => (
          <div className="tiles-row" key={props.index} style={props.style}>
            {rowMapper.map((i) => {
              const index = props.index * rowMapper.length + i;
              const item = items[index];
              const { id } = item || {};
              return (
                <span
                  className={classes(
                    "tiles-item",
                    id === Number(match?.params?.id || 0) && "-active",
                    index >= total && "-disabled"
                  )}
                  key={i}
                  style={{ maxWidth: `${100 / rowMapper.length}%` }}
                  onClick={(e) => handleNavigate(e, id)}
                >
                  {/* @ts-ignore */}
                  {index < total && <Tile {...item} />}
                </span>
              );
            })}
          </div>
        )}
      />
    </div>
  );
};

export default Tiles;
