import React, { ChangeEvent } from 'react'
import Textbox, { TextboxProps } from '../Textbox/Textbox'

interface NumberProps extends TextboxProps {
  toFixed?: number
  step?: number
  max?: number,
  min?: number
}

const Number = React.forwardRef(function (props: NumberProps, ref: any) {
  const { onChange, onKeyDown, toFixed, step, max, min, ...other } = props

  const handleChange = function (e: any) {
    if (!e.currentTarget.value) {
      onChange?.(e)
    } else {
      const val = +e.currentTarget.value
      if (isNaN(val)) return
      if ((min && val < min) || (max && val > max)) return
      if (toFixed && val.toString() > val.toFixed(toFixed)) return
      if (e.currentTarget.value.substr(0, 1) !== '0' || val < 1) {
        onChange?.(e)
      } else {
        e.currentTarget.value = e.currentTarget.value.substr(1)
        onChange?.({ currentTarget: e.currentTarget } as ChangeEvent<HTMLInputElement>)
      }
    }
  }

  const handleKeyDown = function (e: any) {
    onKeyDown?.(e)
    const diff = e.shiftKey ? (step || 1) * 10 : (step || 1)

    const val = +(props.value || 0)
    if (isNaN(val)) return

    if (e.key === 'ArrowUp') {
      e.preventDefault()
      e.currentTarget.value = +(max !== undefined ? Math.min(val + diff, max) : val + diff).toFixed(toFixed || 0)
      onChange?.({ currentTarget: e.currentTarget } as ChangeEvent<HTMLInputElement>)
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      e.currentTarget.value = +(min !== undefined ? Math.max(val - diff, min) : val - diff).toFixed(toFixed || 0)
      onChange?.({ currentTarget: e.currentTarget } as ChangeEvent<HTMLInputElement>)
    }
  }

  return <Textbox
    ref={ref}
    {...other }
    onChange={handleChange}
    onKeyDown={handleKeyDown}
  />
})

export default Number
