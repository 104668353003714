import { DetailsState, ReduxState } from '../types'

const initialState: DetailsState = {}

const SET_DETAILS = 'details/SET_DETAILS'
const CLEAR_DETAILS = 'details/CLEAR_DETAILS'
const SET_APPENDIX = 'details/SET_APPENDIX'

interface SetDetailsAction {
  type: typeof SET_DETAILS,
  page: string
  referrer?: number,
  data: any | null | undefined
  time?: number
}

interface SetAppendixAction {
  type: typeof SET_APPENDIX
  page: string
  referrer?: number,
  key: string
  appendix: any | null | undefined
  time?: number
}

interface ClearDetailsAction {
  type: typeof CLEAR_DETAILS,
  page: string
}

type DetailsActions =
  SetDetailsAction |
  SetAppendixAction |
  ClearDetailsAction

const detailsReducer = function (state = initialState, action: DetailsActions) {
  switch (action.type) {
    case SET_DETAILS:
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          data: action.data,
          referrer: action.referrer,
          time: {
            ...state[action.page].time,
            data: action.time
          }
        }
      }

    case SET_APPENDIX:
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          [action.key]: action.appendix,
          referrer: action.referrer,
          time: {
            ...state[action.page].time,
            [action.key]: action.time
          }
        }
      }

    case CLEAR_DETAILS:
      return {
        ...state,
        [action.page]: {
          time: undefined
        }
      }

    default:
      return state
  }
}

export default detailsReducer;

export const clearDetails = function (page: string) {
  return {
    type: CLEAR_DETAILS,
    page
  }
}

export const setDetails = function (
  page: string,
  data: any,
  time?: number,
  referrer?: number
) {
  return {
    type: SET_DETAILS,
    page,
    referrer,
    time,
    data
  }
}

export const setAppendix = function (
  page: string,
  key: string,
  appendix: any,
  time?: number,
  referrer?: number
) {
  return {
    type: SET_APPENDIX,
    page,
    referrer,
    time,
    key,
    appendix
  }
}

export const loadDetails = function (
  id: number, page: string, endpoint: Function
) {
  return async function (dispatch: any, getState: () => ReduxState) {
    const referrer = getState().details[page]?.referrer
    const details = getState().details[page]?.data

    if (referrer && id !== referrer) {
      dispatch(clearDetails(page))
    }

    const token = getState().user.token
    if (token && id && endpoint) {
      const time = Date.now()
      dispatch(setDetails(page, details?.id === id ? details : undefined, time, id))

      try {
        const data = await endpoint(token, id)
        time === getState().details[page]?.time?.data && (
          dispatch(setDetails(page, data))
        )
      } catch {
        time === getState().details[page]?.time?.data && (
          dispatch(setDetails(page, null))
        )
      }
    }
  }
}

export const loadAppendix = function (
  id: number, key: string, page: string, endpoint: Function
) {
  return async function (dispatch: any, getState: () => ReduxState) {
    const token = getState().user.token

    if (token && id && endpoint) {
      const time = Date.now()
      dispatch(setAppendix(page, key, undefined, time, id))

      try {
        const data = await endpoint(token, id)
        time === getState().details[page]?.time?.[key] && (
          dispatch(setAppendix(page, key, data))
        )
      } catch {
        time === getState().details[page]?.time?.[key] && (
          dispatch(setAppendix(page, key, null))
        )
      }
    }
  }
}
