import React, { useCallback, useEffect, useState } from 'react'
import useEventListener from '@use-it/event-listener'

type TOffset = { top: number, bottom: number }

const useModalSize = function (
  headerRef: React.RefObject<HTMLDivElement | null> | null,
  footerRef: React.RefObject<HTMLDivElement | null> | null
): TOffset {
  const [ offset, setOffset ] = useState<TOffset>({ top: 0, bottom: 0 })

  useEffect(function () {
    setOffset({
      top: headerRef?.current?.offsetHeight || 0,
      bottom: footerRef?.current?.offsetHeight || 0
    })
  }, [ headerRef, footerRef ])

  const handleResize = useCallback(function () {
    setOffset({
      top: headerRef?.current?.offsetHeight || 0,
      bottom: footerRef?.current?.offsetHeight || 0
    })
  }, [ headerRef, footerRef])
  
  useEventListener('resize', handleResize, window)
  useEventListener('mouseup', handleResize, window)

  return offset
}

export default useModalSize