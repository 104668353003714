import sortBy from "lodash/sortBy";
import {
  deleteDatumItem,
  loadDatum,
  setDatumAppendix,
} from "ui-kit/reducers/datum";
import { loadSummary, setPrompt, setRedirect } from ".";
import { loadPhoto } from "ui-kit/reducers/photos";
import { loadAppendix, loadDetails, setDetails } from "ui-kit/reducers/details";
import { Page, Photo } from "consts";
import { IGeoZone } from "models/IGeoZone";
import { Dispatch } from "react";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { IGeoGroup } from "models/IGeoGroup";
import * as geoZonesApi from "services/geozones";
import { createPathURL } from "../ui-kit/helpers";

export const loadGeoZonesData = function (batch?: number) {
  return loadDatum(Page.Geozones, geoZonesApi.fetchGeoZones, batch);
};

export const loadGeoGroups = function () {
  return loadSummary(Page.Geozones, geoZonesApi.fetchGeoGroups);
};

export const loadGeoZoneDetails = function (id: number) {
  return loadDetails(id, Page.Geozones, geoZonesApi.fetchGeoZone);
};

export const loadGeoZoneBoards = function (id: number) {
  return loadAppendix(
    id,
    "boards",
    Page.Geozones,
    geoZonesApi.fetchGeoZoneBoards
  );
};

export const loadGeoZoneContent = function (id: number) {
  return loadAppendix(
    id,
    "content",
    Page.Geozones,
    geoZonesApi.fetchGeoZoneContent
  );
};

export const saveGeoZone = function (data: IGeoZone, match: RouteMatch) {
  return async function (dispatch: Dispatch<any>, getState: () => ReduxState) {
    if (!data) {
      return;
    }

    dispatch(
      setPrompt({
        message: "Сохранение геозоны...",
        loader: true,
      })
    );

    const token = getState().user.token;
    if (!token) {
      return;
    }
    const geoGroupId = data.geoGroupId;
    delete data.geoGroupId;
    try {
      const res = await geoZonesApi.saveGeoZone(token, data, geoGroupId);
      dispatch(setPrompt());

      if (res.id) {
        dispatch(loadGeoZonesData());
        dispatch(
          setDatumAppendix(Page.Geozones, "selectedGroupId", +(geoGroupId || 0))
        );
        dispatch(setDetails(Page.Geozones, res));
        dispatch(loadPhoto(res.id, Photo.GeoZonePreview, { realTime: true }));
        match &&
          dispatch(
            setRedirect(
              createPathURL("/:page/:mode/:id/:tab?", {
                ...match?.params,
                id: res.id,
              }),
              true
            )
          );
      }
      if (match && res.id) {
        dispatch(
          setRedirect(
            createPathURL("/:page/:mode/:id/:tab?", {
              ...match?.params,
              id: res.id,
            }),
            true
          )
        );
      } else {
        dispatch(setRedirect(`/${Page.Geozones}`, true));
      }
    } catch (e) {
      dispatch(
        setPrompt({
          message: e.error || "При сохранении геозоны произошла ошибка.",
        })
      );
    }
  };
};

export const deleteGeozone = function (id: number, match: RouteMatch) {
  return setPrompt({
    message: "Вы уверены, что хотите удалить геозону?",
    buttons: [
      {
        label: "Удалить",
        default: true,
        danger: true,
        action: async function (
          dispatch: Dispatch<any>,
          getState: () => ReduxState
        ) {
          dispatch(
            setPrompt({
              message: "Удаление геозоны...",
              loader: true,
            })
          );

          const token = getState().user.token;
          if (!token) {
            return;
          }
          try {
            await geoZonesApi.deleteGeoZone(token, id);
            dispatch(deleteDatumItem(Page.Geozones, id, match));
            dispatch(setPrompt());
          } catch (e) {
            dispatch(
              setPrompt({
                message: e?.error || "При удалении геозоны произошла ошибка.",
              })
            );
          }
        },
      },
    ],
  });
};

export const saveGeoGroup = function (group: IGeoGroup, parentId?: number) {
  return async function (dispatch: Dispatch<any>, getState: () => ReduxState) {
    const token = getState().user.token;
    if (!token) {
      return;
    }
    try {
      const res = await geoZonesApi.saveGeoGroup(token, group, parentId);
      const groups: IGeoGroup[] = getState().datum[Page.Geozones].summary || [];

      if (group.id) {
        /** Update existing group */
        const mapper = function (
          items: IGeoGroup[] | null
        ): IGeoGroup[] | null {
          if (!items) return items;
          return items.find((x) => x.id === res.id)
            ? sortBy(
                items.map((x) => (x.id === res.id ? res : x)),
                "name"
              )
            : items.map((x) => ({ ...x, geoGroups: mapper(x.geoGroups) }));
        };
        dispatch(setDatumAppendix(Page.Geozones, "summary", mapper(groups)));
      } else {
        /** Add new group */
        const mapper = function (
          items: IGeoGroup[] | null
        ): IGeoGroup[] | null {
          if (!items) return items;
          return !parentId
            ? sortBy([...items, res], "name")
            : items.map((g) => ({
                ...g,
                geoGroups:
                  g.id === parentId
                    ? sortBy([...(g.geoGroups || []), res], "name")
                    : mapper(g.geoGroups),
              }));
        };
        dispatch(setDatumAppendix(Page.Geozones, "summary", mapper(groups)));
      }

      dispatch(setDatumAppendix(Page.Geozones, "selectedGroupId", res.id));
    } catch (e) {
      dispatch(
        setPrompt({
          message:
            e?.error || group.id
              ? "Не удалось изменить геогруппу"
              : "Не удалось создать геогруппу.",
        })
      );
    }
  };
};

export const deleteGeoGroup = function (group: IGeoGroup) {
  return async function (dispatch: Dispatch<any>, getState: () => ReduxState) {
    const token = getState().user.token;
    if (!token) {
      return;
    }
    try {
      await geoZonesApi.deleteGeoGroup(token, group);
      const groups: IGeoGroup[] = getState().datum[Page.Geozones].summary || [];
      let selectedId: number | undefined = groups[0].id;
      const id = group.id;

      const filterer = function (
        items: IGeoGroup[] | null,
        parentId?: number
      ): IGeoGroup[] | null {
        if (!items) {
          return items;
        }

        return items
          .filter((x, i) => {
            if (x.id === id) {
              selectedId = items[i + 1]?.id || items[i - 1]?.id || parentId;
            }
            return x.id !== id;
          })
          .map((x) => ({ ...x, geoGroups: filterer(x.geoGroups, x.id) }));
      };
      const summary = filterer(groups);
      dispatch(setDatumAppendix(Page.Geozones, "summary", summary));
      dispatch(setDatumAppendix(Page.Geozones, "selectedGroupId", selectedId));
    } catch (e) {
      dispatch(
        setPrompt({
          message: e?.error || "При удалении геогруппы произошла ошибка.",
        })
      );
    }
  };
};
