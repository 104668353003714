export const getQueryParams = function (params?: { [key: string]: any }) {
  const items = Object.keys(params || {}).map(function (key: string) {
    return !params?.[key] && params?.[key] !== false
      ? null
      : `${ key }=${ Array.isArray(params[key])
        ? params[key].map((x: any) => encodeURIComponent(x)).join(',')
        : encodeURIComponent(params[key])
      }`
  }).filter(x => x !== null)


  if (items.length === 0) {
    return ''
  }

  return '?' + items.join('&')
}
