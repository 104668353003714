import { arrify, isEmpty, matchTypes } from './index'

export type TItem = string | number | { ['string']: any } | Array<TItem>
export type TKeys = Array<string>
export type TOptions = {
  exclude: Array<string> | string,
  narrow: Array<string> | string
}
/**
 * Function to find if any of elements in item match query
 * @param {string} query - needle
 * @param {any} item - stack
 */
export const searchItem = function (query: string, item: any): boolean {
  if (isEmpty(item)) {
    return false
  }

  if (matchTypes(item, 'string', 'number')) {
    const stack = String(item).toLowerCase()
    const needle = query.toLowerCase()

    if (stack.indexOf(needle) === 0) return true

    const bits: Array<string> = stack.replace(/[!@#$%^&*)(}{+=._-]/g, ' ').trim().split(' ')
    for (let i = 0; i < bits.length; i++) {
      if (bits[i].indexOf(needle) === 0) return true
    }

    return false
  }

  if (matchTypes(item, 'array')) {
    for (let i = 0; i < item.length; i++) {
      if (searchItem(query, item[i])) {
        return true
      }
    }

    return false
  }

  if (matchTypes(item, 'object')) {
    const keys = Object.keys(item)

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]

      if (searchItem(query, item[key])) {
        return true
      }
    }
  }

  return false
}

const filterItem = function (
  query: string,
  item: any,
  search: TKeys,
  { exclude, narrow }: TOptions
): Array<TItem> | Array<Array<TItem>> {
  if (isEmpty(item)) {
    return []
  }

  if (matchTypes(item, 'string', 'number')) {
    return searchItem(query, item)
      ? [ item ]
      : []
  }

  if (matchTypes(item, 'object')) {
    const keys = Object.keys(item)
    const filtered = { ...item }
    let matches = false

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]

      if (
        Array.isArray(item[key]) &&
        (narrow.length > 0 ? narrow.indexOf(key) > -1 : exclude.indexOf(key) === -1)
      ) {
        /** Sub-list */
        filtered[key] = filterArray(query, item[key], search, {
          exclude,
          narrow
        })
        matches = matches || filtered[key].length > 0
      } else {

        if (search.length === 0 || search.indexOf(key) > -1) {
          if (searchItem(query, item[key])) {
            /** Item matches query. No need to filter children. */
            return [ item ]
          }
        }
      }
    }

    return matches ? [ filtered ] : []
  }

  if (matchTypes(item, 'array')) {
    const filtered = filterArray(query, item, search, { exclude, narrow })
    if (filtered && filtered.length > 0) {
      return [ filtered ]
    }
  }

  return []
}

export const filterArray = function (
  query: string,
  list?: Array<any> | null | undefined,
  search: TKeys | string = [],
  { exclude, narrow }: TOptions = { exclude: [], narrow: [] }
): Array<any> | null {
  if (!Array.isArray(list)) return null

  if (isEmpty(query) || !matchTypes(query, 'string', 'number')) {
    return list
  }

  let filtered: Array<any> = []
  for (let i = 0; i < list.length; i++) {
    filtered = [
      ...filtered,
      ...filterItem(
        String(query),
        list[i],
        arrify(search),
        { exclude: arrify(exclude), narrow: arrify(narrow) }
      )
    ]
  }

  return filtered
}
