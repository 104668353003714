import React from 'react'

interface Props {
  label?: string | Object
  children: any
}

const SectionTitle = function (props: Props) {
  const { label, children } = props

  return <div className='section-title'>
    <h1>{ children }</h1>
    { label && <span className='section-title-label'>
      { label }
    </span> }
  </div>
}

export default SectionTitle
