import { LngLatLike } from 'mapbox-gl'
export { Icons } from './assets'

export enum Colors {
  Green = '#6CC700',
  Red = '#FD3D00',
  Blue = '#5784FF'
}

export enum DaData {
  Party = 'party',
  Address = 'address'
}

export enum FilterType {
  List = 'list',
  Group = 'group',
  Date = 'date',
  Suggestions = 'suggestions',
  Component = 'component'
}

export const TIME_ZONE = window.config?.REACT_APP_TIME_ZONE || process.env.REACT_APP_TIME_ZONE || '+03:00'
export const BATCH_SIZE = window.config?.REACT_APP_BATCH_SIZE || process.env.REACT_APP_BATCH_SIZE || 100
export const AUTH_URL = window.config?.REACT_APP_AUTH_URL || process.env.REACT_APP_AUTH_URL || ''
export const CITY = (
  (window.config?.REACT_APP_CITY || process.env.REACT_APP_CITY || 'moscow').toString().toLowerCase()
)
export const MAP_STYLE_URL = window.config?.REACT_APP_MAP_STYLE_URL || process.env.REACT_APP_MAP_STYLE_URL || 'https://maps.megapolis-it.ru/styles/megapolis-light/style.json'
export const MAP_TOKEN = window.config?.REACT_APP_MAP_TOKEN || process.env.REACT_APP_MAP_TOKEN || 'pk.eyJ1Ijoiam9obmVlZWV5IiwiYSI6ImNrMXJ2ZDJ4ZDAyNWwzaG4zbnRzbjJhYWUifQ.qMSqsALUEAVZPkrU5SU-HQ'
export const MAP_CENTER = JSON.parse(
  window.config?.REACT_APP_MAP_CENTER || process.env.REACT_APP_MAP_CENTER || '[37.621465, 55.752615]'
) as LngLatLike
export const MAP_BOUNDS = JSON.parse(
  window.config?.REACT_APP_MAP_BOUNDS || process.env.REACT_APP_MAP_BOUNDS || '[[36.135101,54.475352],[38.875893,57.023326]]'
) as [LngLatLike, LngLatLike]

export const DADATA_TOKEN = window.config?.REACT_APP_DADATA_TOKEN || process.env.REACT_APP_DADATA_TOKEN
export const DADATA_AREA = JSON.parse(
  window.config?.REACT_APP_DADATA_AREA || process.env.REACT_APP_DADATA_AREA || '[{"kladr_id":"77"},{"kladr_id":"50"}]'
)
