import { SettingsState, TValue } from '../types'

export const settingsState: SettingsState = {
  filters: {},
  sort: {}
}

const SET_FILTER = 'settings/SET_FILTER'
const CLEAR_FILTERS = 'settings/CLEAR_FILTER'
const SET_SORTING = 'settings/SET_SORTING'

interface SetFilterAction {
  type: typeof SET_FILTER
  page: string
  key: string
  value: TValue[] | undefined
}

interface ClearFiltersAction {
  type: typeof CLEAR_FILTERS
  page: string
}

interface SetSortingAction {
  type: typeof SET_SORTING
  page: string
  key: string
}

type SettingsActions =
  SetFilterAction |
  ClearFiltersAction |
  SetSortingAction

const settingsReducer = function (state = settingsState, action: SettingsActions) {
  switch (action.type) {
    case SET_FILTER:
      if (action.value === state.filters[action.page]?.[action.key]) {
        return state
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.page]:{
            ...state.filters[action.page],
            [action.key]: action.value
          }
        }
      }

    case CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.page]: state.filters[action.page]?.search
            ? { search: state.filters[action.page].search }
            : undefined
        }
      }

    case SET_SORTING:
      return {
        ...state,
        sort: {
          ...state.sort,
          [action.page]: {
            key: action.key,
            descending: action.key === state.sort[action.page]?.key
              ? !state.sort[action.page]?.descending
              : Boolean(state.sort[action.page]?.descending)
          }
        }
      }

    default:
      return state
  }
}

export default settingsReducer;

export const setFilter = function (page: string, key: string, value: any[] | undefined): SetFilterAction {
  return {
    type: SET_FILTER,
    key,
    page,
    value
  }
}

export const clearFilters = function (page: string): ClearFiltersAction {
  return {
    type: CLEAR_FILTERS,
    page,
  }
}

export const setSorting = function(page: string, key: string): SetSortingAction {
  return {
    type: SET_SORTING,
    page,
    key
  }
}
