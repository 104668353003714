import React, {
  useCallback, useEffect,
  useRef,
  useState
} from 'react'
import { AutoSizer, Size } from 'react-virtualized'
import classes from 'classnames'
import Scroller, { ScrollPosition } from '../components/Scroller'
import { sectionContext } from '.'
import { toggleArray } from '../helpers'
import './section.scss'

interface Props {
  className?: string
  header?: any
  headerProps?: any
  footer?: any
  children: any
}

interface BlockProps extends Props {
  size: Size
}

const getHeaderScrolled = function (p: ScrollPosition | null) {
  if (!p?.scrollTop) return false
  const top = Array.isArray(p.scrollTop)
    ? p.scrollTop[0]
    : p.scrollTop

  return Boolean(top)
}

const Section = function (props: Props) {
  const { className, children } = props
  return <div className={ classes('section', className) }>
    <AutoSizer>
      { (size) =>
        <SectionBlock {...props} size={size}>
          { children }
        </SectionBlock>
      }
    </AutoSizer>
  </div>
}

const SectionBlock = function (props: BlockProps) {
  const {
    header: Header,
    footer: Footer,
    headerProps,
    children,
    size
  } = props
  const headerRef = useRef<HTMLDivElement | null>(null)
  const footerRef = useRef<HTMLDivElement | null>(null)
  const { Provider } = sectionContext
  const scrollListeners = useRef<((p: ScrollPosition) => void)[]>([])
  const [ offset, setOffset ] = useState({ top: 0, bottom: 0})
  const [ scrollPosition, setScrollPosition ] = useState<ScrollPosition | null>(null)
  const [ isScrolled, setScrolled ] = useState(false)

  const handleSubscribe = useCallback(function (callback: (p: ScrollPosition) => void) {
    scrollListeners.current = toggleArray(scrollListeners.current, callback)
  }, [ scrollListeners ])

  const dispatchScroll = useCallback(function (position: ScrollPosition) {
    setScrolled(getHeaderScrolled(position))
    for (let i = 0; i < scrollListeners.current.length; i++) {
      scrollListeners.current[i](position)
    }
  }, [ scrollListeners ])

  useEffect(function () {
    setOffset({
      top: headerRef.current?.offsetHeight || 0,
      bottom: footerRef.current?.offsetHeight || 0
    })
  }, [ Header, Footer, headerProps, headerRef, footerRef, children, size ])

  useEffect(function () {
    setScrolled(getHeaderScrolled(scrollPosition))
  }, [ scrollPosition ])

  return <Provider value={{
    style: size,
    offset: offset,
    onScroll: handleSubscribe,
    scrollTo: setScrollPosition
  }}>
    { props.header ? (
      <div
        ref={headerRef}
        className='section-header'
        style={{ width: size.width }}
      >
        <div className='section-header-block'>
          <Header {...headerProps} width={size.width} />
        </div>
        <div className={classes('section-header-bg', isScrolled && '-scrolled')} />
      </div>
    ) : null }

    <div className='section-body'>
      {
        (!props.header || headerRef.current) &&
        (!props.footer || footerRef.current) &&
        <Scroller
          className='section-content'
          style={size}
          padding={{
            paddingTop: offset.top || 16,
            paddingBottom: offset.bottom || 16,
          }}
          scrollTop={scrollPosition?.scrollTop }
          scrollLeft={scrollPosition?.scrollLeft }
          offset={offset}
          scrollTo={dispatchScroll}
        >
          {children}
        </Scroller>
      }
    </div>
    { props.footer ? (
      <div ref={footerRef} className='section-footer'>
        <Footer />
      </div>
    ) : null }
  </Provider>
}

export default Section
