import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Props } from "./svgForm.types";

const defaultTitlePlaceholder = "Введите заголовок";
const defaultTextPlaceholder = "Введите текст";

export const SvgForm = (props: Props) => {
  const { onChange } = useMemo(() => props, [props]);

  const [title, setTitle] = useState(props.title);
  const [text, setText] = useState(props.text);

  const titleInputRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLDivElement>(null);

  const styleContainer: any = {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column" as "column",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#ffffff",
  };

  const titleContainer = {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "22%",
    backgroundColor: "#4169e2",
  };

  const textContainer = {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "78%",
    backgroundColor: "#000000",
  };

  const messageTitle = {
    width: "100%",
    outline: "none",
    background: "transparent",
    textAlign: "center" as "center",
    fontSize: "24px",
    lineHeight: "28px",
  };

  const messageText = {
    width: "100%",
    outline: "none",
    background: "transparent",
    textAlign: "center" as "center",
    fontSize: "32px",
    lineHeight: "38px",
  };

  const follower = useCallback((id: string, key: string) => {
    const div = document.getElementById(id);
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === "characterData") {
          if (key === "SVGTitle") {
            setTitle(() => div?.innerHTML);
          } else {
            setText(() => div?.innerHTML);
          }
        }
      });
    });

    div &&
      observer.observe(div, {
        characterData: true,
        subtree: true,
      });
    return { observer };
  }, []);

  useEffect(() => {
    const titleElement = titleInputRef.current;
    const textElement = textInputRef.current;

    const registerFocusListener = (
      element: HTMLDivElement,
      placeholder: string
    ) => {
      element.addEventListener("focus", () => {
        if (element.innerHTML === placeholder) {
          element.innerHTML = "";
        }
      });
    };

    const registerBlurListener = (
      element: HTMLDivElement,
      placeholder: string
    ) => {
      element.addEventListener("blur", () => {
        if (element.innerHTML === "") {
          element.innerHTML = placeholder;
        }
      });
    };

    registerFocusListener(
      titleElement!,
      props.title || defaultTitlePlaceholder
    );
    registerFocusListener(textElement!, props.text || defaultTextPlaceholder);

    registerBlurListener(titleElement!, defaultTitlePlaceholder);
    registerBlurListener(textElement!, defaultTextPlaceholder);
  }, [props.text, props.title]);

  useEffect(() => {
    const followTitle = follower("message-title", "SVGTitle");
    const followText = follower("message-text", "SVGText");
    return () => {
      followTitle.observer.disconnect();
      followText.observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange?.({
      SVGText: text || "",
      SVGTitle: title || "",
    });
  }, [onChange, text, title]);

  return (
    <svg
      id="message-screen"
      xmlns="http://www.w3.org/2000/svg"
      width={`${props.width}px`}
      height={`${props.height}px`}
    >
      <foreignObject x="0" y="0" width="100%" height="100%">
        <div style={styleContainer} id="container">
          <div style={titleContainer} id="title-container">
            <div
              id="message-title"
              contentEditable="true"
              style={messageTitle}
              ref={titleInputRef}
              suppressContentEditableWarning={true}
            >
              {props.title || defaultTitlePlaceholder}
            </div>
          </div>
          <div style={textContainer} id="text-container">
            <div
              id="message-text"
              style={messageText}
              ref={textInputRef}
              contentEditable="true"
              suppressContentEditableWarning={true}
            >
              {props.text || defaultTextPlaceholder}
            </div>
          </div>
        </div>
      </foreignObject>
    </svg>
  );
};
