import React, { useEffect, useRef, useState } from 'react'
import classes from 'classnames'
import { shallowEqual } from 'react-redux'
import Scroller from '../../components/Scroller/Scroller'
import { TKeys, TSuggestion } from '../plugins/use-controls/types'
import { arrify } from '../plugins/use-controls/helpers'
import './suggestions.scss'

type TItem = {
  disabled?: boolean
} & TSuggestion

interface ListProps {
  items: TItem[]
  value: any
  keys?: TKeys
  small?: boolean
  className?: string
}

const Suggestions = function (props: ListProps) {
  const { small, className } = props
  const [items, setItems] = useState(props.items)
  const [style, setStyle] = useState<undefined | React.CSSProperties>(undefined)
  const listRef = useRef<null | HTMLDivElement>(null)
  const [ keys ] = useState({
    ...props.keys,
    value: props.keys?.value
      ? arrify(props.keys.value)
      : ['value', 'id'],
    display: props.keys?.display
      ? arrify(props.keys.display)
      : ['label', 'name']
  })

  useEffect(
    function () {
      if (props.items.length === 0) {
        items.length !== 0 && window.setTimeout(setItems, 200, props.items)
      } else {
        !shallowEqual(props.items, items) && setItems(props.items)
      }
    },
    [props.items, items]
  )

  useEffect(function () {
    if (listRef.current) {
      const rect = listRef.current?.getBoundingClientRect()
      setStyle({
        height: Math.min(310, (listRef.current?.offsetHeight || 0) + ( small ? 27 : 35 )),
        maxHeight: window.innerHeight - rect.top + 30
      })
    }
  }, [ listRef, items, small ])

  return (
    <Scroller className='suggestions-wrapper' style={style} offset={{ top: 35 }}>
      <div className={classes('suggestions', className)} role="list" ref={listRef}>
        {props.items.map(function (item, i) {
          //@ts-ignore
          const value = typeof item === 'object' ? item[keys.value.find(x => item[x])] : item
          //@ts-ignore
          const label = typeof item === 'object' ? item[keys.display.find(x => item[x])] : item

          return (
            <div
              role="button"
              key={ label }
              className={classes(value.toString() === props.value?.toString() && '-selected')}
              aria-disabled={item.disabled ? true : undefined}
            >
              {label}
            </div>
          )
        })}
      </div>
    </Scroller>
  )
}

export default Suggestions
