import React, { useMemo } from 'react'
import { Value } from 'ui-kit'
import { getFrameWidth } from 'components/Messages/Messages.helpers'
import { BoardFormat, Photo } from 'consts'
import { IBoardLog } from 'models/IBoardLog'

export const useTableData = function (
  data: IBoardLog[] | null | undefined,
  format: BoardFormat | undefined
) {
  return useMemo(function () {
    if (!format) return format
    const width = getFrameWidth(format, 120)

    return data
      ? data
        .filter(item => item?.frames?.length)
        .map(item => ({
          id: item.id,
          title: <Value value={item.name} />,
          images: (item.frames || []).map(f => ({
            id: f.id,
            type: Photo.Frame,
            isVideo: f.kind === 4,
            style: {
              height: 120,
              width
            }
          }))
        }))
      : data
  }, [ data, format ])
}
