import React, { useEffect, useMemo, useState } from 'react'
import classes from 'classnames'
import { addDays } from 'date-fns'
import { Calendar, TimePicker } from 'ui-kit/controls'
import { Icons } from 'ui-kit'
import MessagesFormDateBlock from './MessageFormDateBlock'
import { validateTime } from './MessageForm.helpers'
import { getISODate, getLocalDate } from 'ui-kit/helpers'
import { locales } from 'ui-kit/controls/DatePicker/Calendar'
import { ISchedule } from 'models/ISchedule'
import './message-form.scss'
import 'ui-kit/controls/DatePicker/calendar.scss'

interface Props {
  value: ISchedule
  onChange: (value: ISchedule) => void,
}

const MessageFormDate = function (props: Props) {
  const { value, onChange } = props
  const [ timeError, setTimeError ] = useState<false | string>('')

  const handleTimeChange = function (start: string, stop: string) {
    onChange({
      ...value,
      time: { start, stop }
    })
  }

  const weekdayAvailability = useMemo(function () {
    const { startDate, endDate } = value
    let day = getLocalDate(startDate)
    let weekdays = []
    const end = getLocalDate(endDate)
    if (day && end) {
      while (day < end) {
        weekdays.push(day.getDay())
        day = addDays(day, 1)

        if (weekdays.length === 7) {
          return null
        }
      }

      return weekdays
    }

    return null
  }, [ value ])

  useEffect(function () {
    setTimeError(validateTime(value.time?.start || '', value.time?.stop || ''))
  }, [ value])

  return <div className='message-form-date'>
    <MessagesFormDateBlock
      icon={Icons.Calendar}
      title='Календарь'
      isExpanded={Boolean(value.startDate || value.endDate)}
      onDelete={value?.startDate || value?.endDate
        ? () => onChange({ ...value, startDate: null, endDate: null })
        : undefined
      }
    >
      <Calendar
        period
        startDate={getLocalDate(value?.startDate)}
        endDate={getLocalDate(value?.endDate)}
        from={new Date()}
        onChange={(startDate, endDate) => onChange({
          ...value,
          startDate: startDate ? getISODate(startDate) : undefined,
          endDate: endDate ? getISODate(endDate) : undefined
        })}
        weekDays={value.weekDays}
      />
    </MessagesFormDateBlock>
    <MessagesFormDateBlock
      icon={Icons.Time}
      title='Время'
      isExpanded={Boolean(value.time)}
      onDelete={value?.time
        ? () => onChange({ ...value, time: null })
        : undefined
      }
    >
      <div className='message-form-date-time'>
        <TimePicker
          value={value.time?.start || ''}
          onChange={e => handleTimeChange(e.currentTarget.value, value.time?.stop || '')}
          error={timeError}
        />
        <TimePicker
          value={value.time?.stop || ''}
          onChange={e => handleTimeChange(value.time?.start || '', e.currentTarget.value)}
          error={timeError}
        />
      </div>
      { timeError && <div className='-error'>
        {timeError === 'invalid' && 'Неверный интервал времени'}
        {timeError === 'equal' && 'Время начала и конца не должны совпадать'}
      </div> }
    </MessagesFormDateBlock>
    <MessagesFormDateBlock
      icon={Icons.CalendarAlt}
      title='Дни недели'
      isExpanded={Boolean(value.weekDays)}
      onDelete={value?.weekDays
        ? () => onChange({ ...value, weekDays: null })
        : undefined
      }
    >
      <div className='calendar message-form-date-weekdays'>
        <div className='DayPicker-Week'>
          {[1,2,3,4,5,6,0].map(day => (
            <span
              key={day}
              className={classes('DayPicker-Day', value?.weekDays?.includes(day) && 'DayPicker-Day--selected')}
              aria-disabled={Boolean(weekdayAvailability && !weekdayAvailability.includes(day))}
              aria-selected={Boolean(value?.weekDays?.includes(day))}
              onClick={() => {
                const weekDays = value?.weekDays?.includes(day)
                  ? value.weekDays.filter(x => x !== day)
                  : [...(value.weekDays || []), day]

                onChange({
                  ...value,
                  weekDays: weekDays.length > 0 ? weekDays : null
                })
              }}
            >
              {locales.weekdaysShort[day]}
            </span>
          ))}
        </div>
      </div>
    </MessagesFormDateBlock>
  </div>
}

export default MessageFormDate
