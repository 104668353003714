import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Icons, SectionTitle, Value } from 'ui-kit'
import { showFilters } from 'ui-kit/reducers/layout'
import { Page } from 'consts'
import { ReduxState } from 'ui-kit/types'
import { FilterSearch } from '../../ui-kit/filter'

const LogsHeader = function () {
  const dispatch = useDispatch()
  const total = useSelector(({ datum }: ReduxState) => datum[Page.Logs].total)
  const hasFilters = useSelector(({ layout }: ReduxState) => layout.showFilters[Page.Logs])

  return <>
    <Button
      icon={ Icons.Filter }
      onClick={ () => dispatch(showFilters(Page.Logs)) }
      active={ hasFilters }
    />
    <SectionTitle
      label={
        <span>
          <span>Найдено: </span>
          <Value value={ total }/>
        </span>
      }
    >
      Логи по работе с контентом
    </SectionTitle>
    <FilterSearch />
  </>
}

export default LogsHeader
