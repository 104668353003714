import React, { useCallback, useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { formContext } from '.'

const Form = function (props: React.PropsWithChildren<any>) {
  const [ state, setState ] = useState<{[key: string]: any}>({})
  const [ errors, setErrors ] = useState<{[key: string]: boolean} | null>(null)
  const [ disabled, setDisabled ] = useState(true)
  const [ isChanged, setChanged ] = useState(false)
  const { Provider } = formContext
  const [ validate, setValidate ] = useState<Function | null>(null)

  const handleChange = useCallback(function (name: string, e: any) {
    let change = false

    setState(state => {
      const value = typeof e === 'function'
        ? e(state[name])
        : e?.currentTarget ? e.currentTarget.value : e

      if (isEqual(value, state?.[name])) {
        return state
      } else {
        change = true
        return {
          ...state,
          [name]: value
        }
      }
    })

    setChanged(c => c || change)
  }, [])

  const handleInit = useCallback(function (
    state: {[key: string]: any} | undefined | null,
    callback?: Function | null
  ) {
    setState(state || {})
    setValidate(() => (callback || null))
  }, [])

  useEffect(function () {
    const errors = validate?.(state)
    setErrors(errors || null)
    setDisabled(errors ? Object.values(errors).some(x => x) : false)
  }, [ state, validate ])

  return <Provider value={{
    state,
    onChange: handleChange,
    onInit: handleInit,
    isChanged,
    errors,
    disabled
  }}>
    {props.children}
  </Provider>
}

export default Form
