import { TPreviewSource } from '../usePreviewFrames'

export const setVideoFrame = function (
  position: number,
  source: TPreviewSource
) {
  const currentPosition = position - source.time.start
  const video = source.source as HTMLVideoElement
  video.paused && video.play()
  video.currentTime = currentPosition / 1000
  video.addEventListener('seeked', function () {
    !video.paused && video.pause()
  }, { once: true })
}
