import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button, Textbox } from "ui-kit/controls";
import { SectionTitle, Icons } from "ui-kit";
import { FormControl } from "ui-kit/form";

interface Props {
  id: number | undefined;
  disabled: boolean;
  onSubmit: () => void;
  onExit: (url: string) => void;
}
const MessageFormHeader = function (props: Props) {
  const { onSubmit, onExit, disabled } = props;
  const match = useRouteMatch();

  const url = useMemo(
    function () {
      return match?.url?.substr(
        0,
        Math.max(
          match?.url?.lastIndexOf("/edit"),
          match?.url?.lastIndexOf("/create")
        )
      );
    },
    [match]
  );

  return (
    <>
      <SectionTitle>
        <FormControl
          name="name"
          headline
          placeholder="Введите наименование сообщения"
          component={Textbox}
          style={{ maxWidth: 900 }}
        />
      </SectionTitle>
      <Button
        fill
        icon={Icons.Save}
        disabled={disabled}
        onClick={onSubmit}
        label="Сохранить"
      />
      <Button exact icon={Icons.Exit} onClick={() => onExit?.(url)} />
    </>
  );
};

export default MessageFormHeader;
