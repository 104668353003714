import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  AppMessage,
  Section,
  Value,
  Datum,
  Icon,
  ImageSlider,
  List,
  Icons,
} from "ui-kit";
import { Button } from "ui-kit/controls";
import MessagesCardHeader from "./MessagesCardHeader";
import MessagesCardLog from "components/Messages/MessagesCardLog";
import { getFormattedSchedule, useFrames, useTags } from "./Messages.helpers";
import {
  createPathURL,
  filterObject,
  getFormattedDate,
  isBitmaskContain,
} from "ui-kit/helpers";
import { loadContentDetails, loadContentBoards, loadContentLog } from "actions";
import { AppIcons, Page, LogAction, logActions, tagsColor } from "consts";
import { IBoard } from "models/IBoard";
import { IFrameSet } from "models/IFrameSet";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { ITag } from "models/ITag";
import { ILog } from "models/ILog";
import { boardTypeList } from "settings";

const MessagesCard = function () {
  const match: RouteMatch = useRouteMatch();
  const { id, page } = match?.params || { id: "", page: "" };
  const data = useSelector(
    ({ details }: ReduxState) => details[Page.Messages].data,
    shallowEqual
  );
  const boards = useSelector(
    ({ details }: ReduxState) => details[Page.Messages].boards,
    shallowEqual
  );
  const log: ILog[] = useSelector(
    ({ details }: ReduxState) => details[Page.Messages].log?.items,
    shallowEqual
  );
  const dispatch = useDispatch();

  const frames = useFrames(data);
  const tags = useTags(data);
  const [hoverPoint, setHoverPoint] = useState<number | undefined>();

  useEffect(
    function () {
      if (id) {
        dispatch(loadContentDetails(+id));
        dispatch(loadContentBoards(+id));
        dispatch(loadContentLog(+id));
      }
    },
    [dispatch, id, page]
  );

  const filterLog = useCallback(function (query: string, item: ILog) {
    return filterObject(query, item, {
      userDisplayName: true,
      action: (v: LogAction) => logActions[v].label,
      timestamp: (date: string) => getFormattedDate(date, false).toString(),
    });
  }, []);

  const filterBoards = useCallback(function (query: string, item: IBoard) {
    return filterObject(query, item, {
      num: true,
      address: true,
    });
  }, []);

  if (!id) {
    return (
      <Section>
        <AppMessage>Выберите сообщение</AppMessage>
      </Section>
    );
  }

  return (
    // @ts-ignore
    <Section header={MessagesCardHeader} headerProps={{ selected: hoverPoint }}>
      {!match?.params?.tab && (
        <>
          {/* @ts-ignore */}
          <ImageSlider
            images={frames}
            placeholder={data ? "Нет активных сообщений" : undefined}
          />

          {/* @ts-ignore */}
          <Datum sizes={[24, "unset", 24, 24, 24, 24, 24, 24, 24, 24, 24]}>
            {data ? (
              [
                [
                  //@ts-ignore
                  <Icon fill type={AppIcons.Published(data.isPublished)} />,
                  //@ts-ignore
                  <Value
                    value={
                      data.isPublished ? "Опубликовано" : "Не опубликовано"
                    }
                  />,
                  ...boardTypeList
                    .map((b) => b.value)
                    .filter((t) => isBitmaskContain(t + 1, data.usage))
                    .map((t) => <Icon key={t} type={AppIcons.BoardType(t)} />),
                  ...(data.frameSets || []).map((fS: IFrameSet) => (
                    // @ts-ignore
                    <Icon
                      key={fS.format}
                      type={AppIcons.BoardFormat(fS.format)}
                    />
                  )),
                ],
              ]
            ) : (
              //@ts-ignore
              <Value value={data} />
            )}
          </Datum>
          {/* @ts-ignore */}
          <Datum
            label="Дата размещения"
            sizes={[24, "unset", "unset"]}
            items={data ? data.schedule || [{}] : data}
            renderer={(s) => {
              const { period, weekDays, time } = getFormattedSchedule(s);
              return [
                //@ts-ignore
                <Icon type={Icons.Calendar} />,
                //@ts-ignore
                <Value value={period} />,
                //@ts-ignore
                <Value value={[weekDays, time].filter((x) => x).join(" / ")} />,
              ];
            }}
          />

          {/* @ts-ignore */}
          <List
            label="Теги"
            list={
              data
                ? tags.map((tag: ITag) => ({
                    icon: tag.isGeo ? AppIcons.Geozone : Icons.Tag,
                    iconColor: tagsColor[tag.color],
                    label: tag.value,
                  }))
                : data
            }
          />
        </>
      )}

      {match?.params?.tab === "boards" && (
        <>
          {/* @ts-ignore */}
          <Datum
            label={
              <>
                <span>Табло, привязанные к сообщению</span>
                {/* @ts-ignore */}
                <Button
                  small
                  label="Редактировать"
                  icon={AppIcons.Infoboard}
                  href={`${createPathURL(match?.path, {
                    ...match?.params,
                    tab: undefined,
                  })}/add-boards`}
                />
              </>
            }
            rowProps={(board) => ({
              onMouseEnter: () => setHoverPoint(board?.id),
              onMouseLeave: () => setHoverPoint(undefined),
            })}
            sizes={[60, "unset", 24, 24]}
            classNames={["-strong", null, null, null]}
            filter={filterBoards}
            items={boards}
            renderer={(board: IBoard) => [
              //@ts-ignore
              <Value
                href={`/${Page.Infoboards}/list/${board.id}`}
                value={`№${board.num}`}
              />,
              //@ts-ignore
              <Value value={board.address} />,
              //@ts-ignore
              <Icon type={AppIcons.BoardType(board.type)} />,
              //@ts-ignore
              <Icon type={AppIcons.BoardFormat(board.format)} />,
            ]}
          />
        </>
      )}
      {match?.params?.tab === "log" && (
        <>
          {/* @ts-ignore */}
          <Datum
            filter={filterLog}
            items={log}
            renderer={(item: ILog, row: number) => [
              //@ts-ignore
              <MessagesCardLog key={row} {...item} />,
            ]}
          />
        </>
      )}
    </Section>
  );
};

export default MessagesCard;
