import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "classnames";
import { Loader } from "..";
import { Button } from "../../controls";
import { loadPhoto } from "../../reducers/photos";
import { Icons } from "../../consts";
import { IImage, ReduxState } from "../../types";
import "./image.scss";
import ImageVideo from "./ImageVideo";

const Image = React.forwardRef(function (
  props: IImage,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    id,
    type,
    onClick,
    className,
    style,
    placeholder,
    cover,
    isVideo,
    params,
    src,
    onUpdate,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const image = useSelector(({ photos }: ReduxState) =>
    src === undefined ? (id && type ? photos[type]?.[+id] : undefined) : src
  );
  const [stateImage, setStateImage] = useState<string | null>();

  useEffect(
    function () {
      setStateImage((stateImage) => (image === undefined ? stateImage : image));
    },
    [image]
  );

  useEffect(
    function () {
      return function () {
        setStateImage(undefined);
      };
    },
    [dispatch, id, type]
  );

  useEffect(
    function () {
      if (src === undefined && id && type) {
        dispatch(loadPhoto(+id, type, params));
      }
    },
    [dispatch, id, type, src, params]
  );

  useEffect(
    function () {
      setLoading(image === undefined);
    },
    [image]
  );

  const handleUpdate = function (e: any) {
    e.preventDefault();

    if (onUpdate) {
      onUpdate();
    } else {
      id &&
        type &&
        dispatch(loadPhoto(+id, type, { ...params, realTime: true }));
    }
  };

  const imageStyle = useMemo(
    function () {
      if (style?.height !== undefined) {
        return { padding: 0, ...style };
      }

      return style;
    },
    [style]
  );

  return (
    <div className={classes("image", className)} style={imageStyle} ref={ref}>
      {isVideo ? (
        <ImageVideo cover={cover} src={stateImage} onClick={onClick} />
      ) : (
        <span
          onClick={image ? onClick : undefined}
          className={classes(
            "image-view",
            !stateImage && "-hidden",
            onClick && "-clickable",
            cover && "-cover"
          )}
          style={{
            backgroundImage: stateImage ? `url(${stateImage})` : "none",
          }}
        />
      )}

      {((params?.realTime !== undefined && id && type) || onUpdate) && image && (
        <span className="image-update">
          <Button
            small
            blur
            disabled={!image && !src}
            icon={Icons.Refresh}
            onClick={handleUpdate}
          />
        </span>
      )}
      {isLoading && <Loader className={classes(stateImage && "-semi")} />}
      {image === null && ((id && type) || onUpdate) && (
        <span className="image-error">
          {`Не удалось загрузить ${isVideo ? "видео" : "изображение"}.`}
          <br />
          <Button clear icon={Icons.Refresh} onClick={handleUpdate} />
        </span>
      )}
      {image === null && (!id || !type) && placeholder && (
        <span className="image-placeholder">{placeholder}</span>
      )}
    </div>
  );
});

export default Image;
