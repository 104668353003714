import React, { useEffect, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Form, { FormControl, FormSection, useForm } from "ui-kit/form";
import { Select, Textarea, Textbox } from "ui-kit/controls";
import {
  Button,
  Icons,
  Section,
  SectionTitle,
  Value,
  Space,
  Image,
} from "ui-kit";
import { createPathURL } from "ui-kit/helpers";
import { useMapSnapshot } from "ui-kit/map";
import { saveGeoZone, loadGeoZoneDetails } from "actions/geozones";
import { Page } from "consts";
import { FeatureCollection, Position } from "geojson";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { IGeoZone } from "models/IGeoZone";
import { IGeoGroup } from "models/IGeoGroup";
import { IListItem } from "ui-kit/components/List/ListItem";
import "./geozones.scss";

interface Props {
  zones: Position[][] | null | undefined;
  form: "edit" | "create";
}

const validateGeoZone = function (data: IGeoZone) {
  return {
    value: !data?.value,
    geo: !data?.geo?.shapes?.length || !data?.geo?.base64Preview,
  };
};

const GeozonesForm = function (props: Props) {
  const { zones, form } = props;
  const dispatch = useDispatch();
  const match: RouteMatch = useRouteMatch();
  const { id } = match?.params || { id: "" };
  const data = useSelector(
    ({ details }: ReduxState) =>
      details[Page.Geozones].data as IGeoZone | undefined | null,
    shallowEqual
  );
  const geoGroups = useSelector(
    ({ datum }: ReduxState) => datum[Page.Geozones].summary,
    shallowEqual
  );
  const selectedGroupId = useSelector(
    ({ datum }: ReduxState) => datum[Page.Geozones].selectedGroupId
  );

  const geoGroupsList = useMemo(
    function () {
      if (!geoGroups) return geoGroups;
      let res: IListItem[] = [];
      const nameFiller = function (name: string, depth: number) {
        let n = name;
        for (let i = 0; i < depth; i++) n = " " + n;
        return n;
      };
      const mapper = function (item: IGeoGroup, depth = 0) {
        res.push({
          label: nameFiller(item.name, depth),
          value: item.id,
        });

        item.geoGroups?.forEach((x) => mapper(x, depth + 1));
      };

      geoGroups.forEach(mapper);

      return res;
    },
    [geoGroups]
  );

  useEffect(
    function () {
      if (form === "edit" && id) {
        dispatch(loadGeoZoneDetails(+id));
      }
    },
    [dispatch, id, form]
  );

  const initialState = useMemo(
    function () {
      return form === "edit"
        ? { ...data, geoGroupId: selectedGroupId }
        : { geoGroupId: selectedGroupId };
    },
    [form, data, selectedGroupId]
  );
  const { onSubmit, onChange, onExit, disabled } = useForm(
    saveGeoZone,
    validateGeoZone,
    initialState
  );

  useEffect(
    function () {
      onChange?.("geo", (geo: any) => {
        return zones && zones.length > 0
          ? {
              ...geo,
              shapes: zones.map((x) => ({ coordinates: x })),
            }
          : undefined;
      });
    },
    [onChange, zones, form, data]
  );

  const previewGeoJSON = useMemo(
    function () {
      if (!zones || zones.length === 0) {
        return null;
      }

      return {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            id: 1,
            geometry: {
              type: "MultiPolygon",
              coordinates: zones.map((c: Position[]) => [c || []] || []),
            },
            properties: {
              fill: "#5784FF",
            },
          },
        ],
      } as FeatureCollection;
    },
    [zones]
  );

  const [width, height, padding] = useMemo(function () {
    const pr = window.devicePixelRatio;
    return [800 / pr, 600 / pr, [20]];
  }, []);

  const [image, onImageUpdate] = useMapSnapshot(
    previewGeoJSON,
    width,
    height,
    padding
  );

  useEffect(
    function () {
      onChange?.("geo", (geo: any) => ({
        ...geo,
        base64Preview: image
          ? image.substr(image.indexOf("base64,") + 7)
          : image,
      }));
    },
    [image, onChange]
  );

  return (
    <Section
      header={() => (
        <>
          <Button
            fill
            icon={Icons.Save}
            onClick={onSubmit}
            disabled={disabled || !selectedGroupId}
          />
          <Space />
          <Button
            exact
            icon={Icons.Exit}
            onClick={() =>
              onExit(
                createPathURL("/:page/:mode/:id?/:tab?", {
                  ...match?.params,
                  modal: undefined,
                })
              )
            }
          />
          <hr />
          <SectionTitle>
            <Value
              value={
                form === "create"
                  ? "Создание геозоны"
                  : "Редактирование геозоны"
              }
            />
          </SectionTitle>
        </>
      )}
    >
      <FormSection>
        <FormControl
          label="Наименование геозоны"
          placeholder="Введите наименование"
          name="value"
          component={Textbox}
          disabled={form === "edit" && !data}
        />
        <FormControl
          label="Описание"
          placeholder="Введите описание"
          name="description"
          component={Textarea}
          disabled={form === "edit" && !data}
        />
        <FormControl
          label="Геогруппа"
          placeholder="Выберите геогруппу"
          name="geoGroupId"
          component={Select}
          list={geoGroupsList}
          disabled={form === "edit" && !data}
        />
      </FormSection>

      <FormSection>
        <FormControl
          label="Превью"
          component={() => (
            <>
              <div className="geozones-form-screenshot">
                <Image
                  src={form === "edit" && !data ? null : image}
                  placeholder="Добавьте геозону"
                  onUpdate={onImageUpdate}
                />
              </div>
            </>
          )}
        />
      </FormSection>
    </Section>
  );
};

const GeozonesFormWrapper = function (props: Props) {
  return (
    <Form>
      <GeozonesForm {...props} />
    </Form>
  );
};

export default GeozonesFormWrapper;
