import React  from 'react'
import { Loader } from '../components'
import './table.scss'

interface Props {
  id?: number
  datum?: (string | Object | React.ReactElement)[]
  widths: number[]
}

const TableRow = function (props: Props) {
  const { datum, widths, ...item } = props

  return  datum
    ? datum.map((data, index) => (
      <span
        key={ index }
        className='table-cell'
        style={ { width: widths[index] } }
      >
        { typeof data === 'function' ? data(item) : data }
      </span>
    ))
    : widths.map((width, index) => {
      return <span
        key={ index }
        className='table-placeholder'
        style={ { width } }
      >
        { width > 50 && <Loader/> }
      </span>
    })
}

export default TableRow
