import { ReactComponent as Archive } from './trash.svg'
import { ReactComponent as ArrowLeft } from './arrow_left.svg'
import { ReactComponent as ArrowRight } from './arrow_right.svg'
import { ReactComponent as Calendar } from './calendar.svg'
import { ReactComponent as CalendarAlt } from './calendar_alt.svg'
import { ReactComponent as Check } from './check.svg'
import { ReactComponent as Clip } from './clip.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Copy } from "./copy.svg"
import { ReactComponent as Delete } from './delete.svg'
import { ReactComponent as Edit } from './edit.svg'
import { ReactComponent as Error } from './error.svg'
import { ReactComponent as Exit } from './exit.svg'
import { ReactComponent as Export } from './export.svg'
import { ReactComponent as Filter } from './filter.svg'
import { ReactComponent as Folder } from './folder.svg'
import { ReactComponent as FolderGroup } from './folder_group.svg'
import { ReactComponent as FolderNew } from './folder_new.svg'
import { ReactComponent as FolderOpen } from './folder_open.svg'
import { ReactComponent as Fullscreen } from './fullscreen.svg'
import { ReactComponent as List } from './list.svg'
import { ReactComponent as Map } from './map.svg'
import { ReactComponent as Menu } from './menu.svg'
import { ReactComponent as Minus } from './minus.svg'
import { ReactComponent as Pause } from './pause.svg'
import { ReactComponent as Play } from './play.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as Refresh } from './refresh.svg'
import { ReactComponent as Save } from './save.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Settings } from './settings.svg'
import { ReactComponent as Share } from './share.svg'
import { ReactComponent as Switch } from './switch.svg'
import { ReactComponent as Tag } from './tag.svg'
import { ReactComponent as Tiles } from './tiles.svg'
import { ReactComponent as Time } from './time.svg'
import { ReactComponent as User } from './user_groups.svg'
import { ReactComponent as LogoHomeButton } from './logo_home_button.svg';

export const Icons = {
  Archive,
  ArrowLeft,
  ArrowRight,
  Calendar,
  CalendarAlt,
  Check,
  Clip,
  Close,
  Copy,
  Delete,
  Edit,
  Error,
  Exit,
  Export,
  Filter,
  Folder,
  FolderGroup,
  FolderNew,
  FolderOpen,
  Fullscreen,
  List,
  Map,
  Menu,
  Minus,
  Pause,
  Play,
  Plus,
  Refresh,
  Save,
  Search,
  Settings,
  Share,
  Switch,
  Tag,
  Tiles,
  Time,
  User,
  LogoHomeButton
}
