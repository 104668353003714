import React, { useContext } from 'react'
import { Button } from '../controls'
import { sectionContext } from '../section'
import { ScrollerOffset } from '../components/Scroller'
import './app.scss'

export const AppMessage = function (props: { offset?: ScrollerOffset, children: any }) {
  const context = useContext(sectionContext)
  const offset = props.offset || context.offset

  return <div
    className='app-loader'
    style={{
      top: (offset?.top || 0) * 0.7,
      bottom: offset?.bottom || 0,
      left: offset?.left || 0,
      right: offset?.right || 0
    }}
  >
    <div className='app-message'>
      { Array.isArray(props.children)
        ? props.children.map(function (child, index) {
          return child ? <p key={index}>{child}</p> : null
        })
        : props.children
      }
    </div>
  </div>
}

export const AppDataEmpty = function (
  props: { children?: any, offset?: ScrollerOffset }
) {
  return <AppMessage offset={props.offset}>
    { props.children || 'Ничего не найдено.' }
  </AppMessage>
}

export const AppLoadingError = function (
  props: {
    onClick?: () => void
    children?: any
    offset?: ScrollerOffset
    error?: string
  }
) {
  return <AppMessage
    offset={props.offset}
    children={[
      props.children || 'При загрузке данных произошла ошибка.',
      !props.children && <small>{props.error}</small>,
      props.onClick && <Button onClick={props.onClick} white>
        Попробовать ещё раз
      </Button>
    ]}
  />
}
