import React, { InputHTMLAttributes } from 'react'
import classes from 'classnames'
import { Icon } from '../../components'
import './textbox.scss'

export interface TextboxProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.FunctionComponent
  button?: any
  small?: boolean
  center?: boolean
  headline?: boolean
  type?: string
}

const Textbox = React.forwardRef(function (props: TextboxProps, ref: any) {
  const { style, icon, className, button, children, size, small, headline, center, ...other } = props

  return <label
    className={classes(
      'textbox',
      className,
      !size && '-wide',
      small && '-small',
      center && '-center',
      headline && '-headline'
    )}
    style={{
      maxWidth: size ? size * 8.4 : 'unset',
      minWidth: size ? size * 8.4 : 'unset',
      ...style
    }}
  >
    <input
      ref={ref}
      type='text'
      autoComplete='off'
      {...other}
      className='textbox-control'
    />
    {icon && <Icon type={icon} />}
    {button && <span
      {...button }
      onMouseDown={e => e.preventDefault()}
      className={classes('textbox-button', button.disabled && '-disabled')}
    />}
    <span className='textbox-appearance' />
  </label>
})

export default Textbox
