import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Value, Tabs, SectionTitle, Icons } from "ui-kit";
import { Button } from "ui-kit/controls";
import { Page } from "consts";
import { showFilters } from "ui-kit/reducers/layout";
import { FilterSearch } from "ui-kit/filter";
import { createPathURL } from "ui-kit/helpers";
import { ReduxState, RouteMatch } from "ui-kit/types";

const InfoboardsHeader = function () {
  const match: RouteMatch = useRouteMatch();
  const hasFilters = useSelector(
    ({ layout }: ReduxState) => layout.showFilters[Page.Infoboards]
  );
  const total = useSelector(
    ({ datum }: ReduxState) => datum[Page.Infoboards].total
  );
  const dispatch = useDispatch();

  return (
    <>
      <Button
        icon={Icons.Filter}
        onClick={() => dispatch(showFilters(Page.Infoboards))}
        active={hasFilters}
      />
      <SectionTitle
        label={
          <span>
            <span>Найдено: </span>
            <Value value={total} />
          </span>
        }
      >
        Информационное табло
      </SectionTitle>

      <FilterSearch />

      <Tabs>
        <Button
          href={createPathURL(match?.path, { ...match?.params, mode: "map" })}
          replace
          clear
          icon={Icons.Map}
        />
        <Button
          href={createPathURL(match?.path, { ...match?.params, mode: "list" })}
          replace
          clear
          icon={Icons.List}
        />
        <Button
          href={createPathURL(match?.path, { ...match?.params, mode: "tiles" })}
          replace
          clear
          icon={Icons.Tiles}
        />
      </Tabs>
    </>
  );
};

export default InfoboardsHeader;
