import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IndexRange, List, OverscanIndexRange } from "react-virtualized";
import { useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from "classnames";
import { AppDataEmpty, AppLoader, AppLoadingError } from "../app";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import { useDidUpdate } from "../hooks";
import { sectionContext } from "../section";
import { createPathURL } from "../helpers";
import { setRedirect } from "../reducers/layout";
import { BATCH_SIZE } from "../consts";
import { RouteMatch, TableData } from "../types";
import { ITableColumn } from ".";
import { ScrollPosition } from "../components/Scroller";
import "./table.scss";

interface Props {
  width?: number;
  height?: number;
  onLoad: (batch?: number) => void;
  scrollTo?: (value: ScrollPosition) => void;
  rowHeight?: number;
  rowRenderer?: any;
  items: (TableData | undefined)[] | undefined | null;
  total: number | undefined | null;
  columns: ITableColumn[];
  syncScroll?: (callback: (params: any) => void) => void;
}

const Table = function (props: Props) {
  const { onLoad, rowRenderer: RowRenderer, items, total, columns } = props;
  const [widths, setWidths] = useState<number[]>([]);
  const [minWidth, setMinWidth] = useState(0);
  const match: RouteMatch = useRouteMatch();
  const rowHeight = props.rowHeight || 48;
  const Row = useMemo(
    function () {
      return RowRenderer || TableRow;
    },
    [RowRenderer]
  );
  const listRef = useRef<List | null>(null);
  const headRef = useRef<HTMLDivElement | null>(null);
  const { scrollTo, onScroll, style } = useContext(sectionContext);
  const { width, height } = style || {};
  const dispatch = useDispatch();

  useDidUpdate(
    function ([prevItems, prevMatch]: any) {
      if ((items && !prevItems) || prevMatch?.params.id !== match?.params?.id) {
        if (match?.params?.id && items) {
          const start =
            items.findIndex(
              (x: any) => x && x.id === Number(match?.params?.id || 0)
            ) * rowHeight;
          scrollTo?.({ scrollTop: [start, start + rowHeight] });
        }
      }
    },
    [items, match, scrollTo, rowHeight]
  );

  useLayoutEffect(
    function () {
      if (items) {
        if (!match?.params?.id && items[0] && items[0].id) {
          dispatch(
            setRedirect(
              createPathURL(match?.path, { ...match?.params, id: items[0].id })
            )
          );
        }
      } else {
        scrollTo?.({ scrollTop: 0 });
      }
    },
    [dispatch, scrollTo, items, match]
  );

  useLayoutEffect(
    function () {
      if (width) {
        let minWidth = 0;
        let resizableSum = 0;
        columns.forEach((x) => {
          minWidth += x.width;
          resizableSum += x.resizable ? x.width : 0;
        });

        if (minWidth < width - 40) {
          const sizePool = width - 40 - minWidth;
          const ratios = columns.map((x) =>
            x.resizable ? x.width / resizableSum : 0
          );
          setWidths(columns.map((x, i) => x.width + sizePool * ratios[i]));
        } else {
          setWidths(columns.map((x) => x.width));
        }

        setMinWidth(minWidth);
      }
    },
    [width, columns]
  );

  const labels = useMemo(
    function () {
      const labels = columns.map((x) => x.label);

      return labels.some((x) => x) ? labels : null;
    },
    [columns]
  );

  const calculatedHeight = useMemo(
    function () {
      return (
        (height || 0) +
        ((total || 0) * rowHeight > (height || 0) ? rowHeight * 2 : 0)
      );
    },
    [rowHeight, total, height]
  );

  const handleScrollTo = useCallback(
    function (params) {
      if (Array.isArray(params.scrollTop) || Array.isArray(params.scrollLeft))
        return;
      listRef.current?.Grid?.handleScrollEvent(params);
      headRef.current && (headRef.current.scrollLeft = params.scrollLeft || 0);
    },
    [listRef, headRef]
  );

  useLayoutEffect(
    function () {
      onScroll?.(handleScrollTo);

      return function () {
        onScroll?.(handleScrollTo);
      };
    },
    [onScroll, handleScrollTo]
  );

  const handleFetch = function (params: IndexRange & OverscanIndexRange) {
    if (items) {
      const { overscanStartIndex, overscanStopIndex } = params;
      let batches = [];
      let i = overscanStartIndex;
      while (i <= overscanStopIndex - 3) {
        if (items[i] === undefined) {
          const batch = Math.floor(i / (BATCH_SIZE as number)) + 1;
          batches.push(batch);
          i = (batch + 1) * (BATCH_SIZE as number);
        } else {
          i++;
        }
      }

      batches.forEach((batch) => onLoad(batch));
    }
  };

  const handleNavigate = function (e: any, id?: number) {
    if (e.target.tagName !== "A") {
      const url = createPathURL(match?.path, { ...match?.params, id: id });
      dispatch(setRedirect(url));
    }
  };

  if (!items) {
    return items === null ? (
      //@ts-ignore
      <AppLoadingError onClick={() => onLoad()} />
    ) : (
      //@ts-ignore
      <AppLoader />
    );
  }

  if (!total) {
    return <AppDataEmpty />;
  }

  return (
    <>
      {labels && (
        //@ts-ignore
        <TableHeader
          {...{ widths, minWidth, columns }}
          style={{
            width: Math.max(minWidth + 40, width || 0),
          }}
        />
      )}
      <div className="table" style={{ marginTop: -rowHeight * 3 }}>
        {/* @ts-ignore */}
        <List
          ref={listRef}
          rowCount={(total || 0) + 3}
          rowHeight={rowHeight}
          width={Math.max(minWidth + 40, width || 0)}
          height={calculatedHeight}
          onRowsRendered={handleFetch}
          style={{
            overflowX: "visible",
            overflowY: "visible",
          }}
          rowRenderer={(props) => {
            if (props.index > 2) {
              /** Добавляем 3 пустых ряда в начало, чтобы при скроллинге
               * они не пропадали за полупрозрачной подложкой */
              const item = items[props.index - 3];
              const { id } = item || {};

              return (
                <span
                  key={props.index}
                  className={classes(
                    "table-row",
                    match?.params?.id === String(id) && "-selected",
                    !id && "-disabled"
                  )}
                  onClick={(e) => handleNavigate(e, id)}
                  style={{
                    ...props.style,
                    minWidth,
                    width: "100%",
                    height: rowHeight + 1,
                  }}
                >
                  {/* @ts-ignore */}
                  <Row {...item} widths={widths} />
                </span>
              );
            } else {
              return null;
            }
          }}
        />
      </div>
    </>
  );
};

export default Table;
