import React from 'react'
import { ScrollerOffset, ScrollPosition } from '../components/Scroller'
import { Size } from 'react-virtualized'
export { default } from './Section'
export { default as SectionTitle } from './SectionTitle'

export const sectionContext = React.createContext<{
  onScroll?: (callback: (position: ScrollPosition) => void) => void
  scrollTo?: (position: ScrollPosition) => void
  offset?: ScrollerOffset
  style?: Size
}>({})

