import React from 'react'
import { Button } from '../controls'
import { Icons } from '../consts'
import './map.scss'

interface Props {
  fullscreen: boolean,
  onToggle: (value: boolean) => void
}

const MapFullscreen = function (props: Props) {
  const { fullscreen, onToggle } = props 
  
  return <span className='map-fullscreen'>
    <Button 
      clear 
      icon={fullscreen ? Icons.Close : Icons.Fullscreen}
      onClick={() => onToggle(!fullscreen)}
    />
  </span>
}

export default MapFullscreen