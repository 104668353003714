import React, { useCallback, useMemo, useState } from "react";
import { IFilterSuggestions, ReduxState, RouteParams } from "../types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { List } from "../components";
import { Suggester } from "../controls";
import { setFilter } from "../reducers/settings";
import { Icons } from "../consts";
import { useFetch } from "../hooks";

const FilterSuggester = function (props: IFilterSuggestions) {
  const { label, name, endpointURL, search, icon, renderer } = props;
  const dispatch = useDispatch();
  const page = (useParams() as RouteParams).page;
  const [query, setQuery] = useState("");
  const filtered: { value: string; label: string }[] = useSelector(
    ({ settings }: ReduxState) => settings.filters[page]?.[name],
    shallowEqual
  );
  const [fetched] = useFetch<{ [key: string]: string }>(
    query ? `${endpointURL}?search=${query}` : ""
  );

  const handleDispatchValue = useCallback(
    function (selected: { value: string; label: string }[] | undefined) {
      dispatch(setFilter(page, name, selected?.length ? selected : undefined));
    },
    [dispatch, name, page]
  );

  const handleDelete = useCallback(
    function (value: any) {
      handleDispatchValue((filtered || []).filter((x) => x.value !== value));
    },
    [handleDispatchValue, filtered]
  );

  const handleAddItem = useCallback(
    function (item: any) {
      setTimeout(() => setQuery(""));
      handleDispatchValue([...(filtered || []), item]);
    },
    [handleDispatchValue, filtered]
  );

  const suggestions = useMemo(
    function () {
      if (!fetched) {
        return fetched;
      }

      return renderer?.(fetched, [filtered || [], false]) || [];
    },
    [fetched, filtered, renderer]
  );

  const list = useMemo(
    function () {
      if (!filtered) {
        return [{}];
      }

      return filtered.map((x) => ({
        ...x,
        icon,
        onRemove: () => handleDelete(x.value),
      }));
    },
    [filtered, icon, handleDelete]
  );

  return (
    <>
      {/* @ts-ignore */}
      <List
        label={label}
        search={
          //@ts-ignore
          <Suggester
            filtered
            className="-filter"
            placeholder={
              fetched === null
                ? "Ошибка при загрузке"
                : search?.placeholder || ""
            }
            icon={Icons.Search}
            value={query}
            onChange={(e: any) => setQuery(e.currentTarget.value)}
            onSelect={handleAddItem}
            list={suggestions}
            disabled={fetched === null}
          />
        }
        list={list}
      />
    </>
  );
};

export default FilterSuggester;
