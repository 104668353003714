import React, { useCallback, useContext } from 'react'
import classes from 'classnames'
import { formContext } from '.'
import './form.scss'

interface Props {
  label?: string
  name?: string
  hint?: string | React.ReactElement
  component?: any
  inline?: boolean
  [key: string]: any
  children?: any
  error?: string
}

const FormControl = React.forwardRef(function (props: Props, ref: React.Ref<any>) {
  const { label, name, inline, component: Component, children, error, hint, ...other } = props
  const { state, onChange, errors } = useContext(formContext)

  const handleChange = useCallback(function (e: any) {
    if (name && onChange) {
      onChange(name, e)
    }
  }, [name, onChange])

  const errorText = (error || (name && errors?.[name]))

  return <span className={classes('form-control', inline && '-inline')}>
    {label && <span className='form-label'>{label}</span>}
    <span className='form-input'>
      { Component
        ? <Component
          onChange={ handleChange }
          name={ name }
          value={ name && (state[name] === undefined ? '' : state[name] )}
          { ...other }
          aria-invalid={Boolean(errorText && typeof errorText === 'string') || undefined}
          ref={ ref }
        />
        : children
      }
    </span>
    { (errorText && typeof errorText === 'string') && <span className='form-error'>
      {errorText}
    </span> }
    { hint && <span className='form-hint'>{ hint }</span> }
  </span>
})

export default FormControl
