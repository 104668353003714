import React, { useContext } from 'react'
import { Loader } from '../components'
import { sectionContext } from '../section'
import { ScrollerOffset } from '../components/Scroller'
import './app.scss'

const AppLoader = function (props: { offset?: ScrollerOffset }) {
  const context = useContext(sectionContext)
  const offset = props.offset || context.offset

  return <div
    className='app-loader'
    style={{
      top: offset?.top || 0,
      bottom: offset?.bottom || 0,
      left: offset?.left || 0,
      right: offset?.right || 0
    }}
  >
    <h1><Loader /></h1>
  </div>
}

export default AppLoader
