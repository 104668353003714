import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useEventListener from '@use-it/event-listener'
import classes from 'classnames'
import { Icon, Icons } from 'ui-kit'
import './infoboard-log.scss'

interface Props {
  value: [number, number | undefined]
  onChange: (value: [number, number | undefined]) => void
}

type TDrag = {
  mode: 'time' | 'range' | 'start' | 'end'
  start: number,
  step: number,
  left: number,
  range: number
}

const getTime = function (minutes: number) {
  return `${Math.floor(minutes / 60)}:${('0'+(minutes % 60)).substr(-2)}`
}

const InfoboardLogTimeline = function (props: Props) {
  const { value, onChange } = props
  const [ time, setTime ] = useState(value)
  const timelineRef = useRef<HTMLDivElement | null>(null)
  const [ drag, setDrag ] = useState<null | TDrag>(null)

  const isRange = useMemo(function () {
    return time[1] !== undefined && time[0] !== time[1]
  }, [ time ])

  useEffect(function () {
    setTime(value)
  }, [ value ])

  const handleMouseDown = function (e: any) {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect()
      const step = (timelineRef.current.offsetWidth - 20) / 144 // deciminute
      document.body.style.cursor = 'ew-resize'
      setDrag({
        mode: 'time',
        start: e.pageX,
        step,
        left: rect.left + 10,
        range: 0
      })

      setTime([
        Math.max(0, Math.min(1440, Math.round((e.pageX - rect.left - 10) / step) * 10)),
        undefined
      ])
    }

  }

  const handleRangeMouseDown = function (e: any) {
    e.stopPropagation()
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect()
      const step = (timelineRef.current.offsetWidth - 20) / 144 // deciminute
      document.body.style.cursor = 'grabbing'
      setDrag({
        mode: 'range',
        start: e.pageX - e.currentTarget.getBoundingClientRect().left,
        step,
        left: rect.left + 10,
        range: (time[1] || 1440) - time[0]
      })
    }
  }

  const handleDragMouseDown = function (e: any, type: 'start' | 'end' = 'start') {
    e.stopPropagation()
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect()
      const step = (timelineRef.current.offsetWidth - 20) / 144 // deciminute
      document.body.style.cursor = 'ew-resize'
      setDrag({
        mode: type,
        start: e.pageX,
        step,
        left: rect.left + 10,
        range: 0
      })
    }
  }

  const handleMouseMove = useCallback(function (e: any) {
    if (drag?.mode === 'time') {
      const start = Math.max(0, Math.min(1440, Math.round((drag.start - drag.left) / drag.step) * 10))
      const end = Math.abs(e.pageX - drag.start) > drag.step
        ? Math.max(0, Math.min(1440, Math.round((e.pageX - drag.left) / drag.step) * 10))
        : undefined
      if (end === undefined) {
        setTime([start, undefined ])
      } else {
        setTime([ Math.min(start, end), Math.max(start, end)])
      }
    }

    if (drag?.mode === 'range') {
      const value = Math.round((e.pageX - drag.start - drag.left) / drag.step) * 10
      const start = Math.min(Math.max(0, value), 1440 - drag.range)
      setTime([ start, start + drag.range])
    }

    if (drag?.mode === 'start') {
      const start = Math.max(0, Math.min(1440, Math.round((e.pageX - drag.left) / drag.step) * 10))
      let mode: 'start' | 'end' = 'start'
      setTime(t => {
        if (t[1] !== undefined) {
          if (start > t[1]) {
            mode = 'end'
            return [ t[1], start ]
          } else {
            return [start, t[1]]
          }
        } else {
          return [ start, t[1] ]
        }
      })

      if (mode !== 'start') {
        setDrag(d => d ? { ...d, mode } : d)
      }
    }

    if (drag?.mode === 'end') {
      const end = Math.max(0, Math.min(1440, Math.round((e.pageX - drag.left) / drag.step) * 10))
      let mode: 'start' | 'end' = 'end'

      setTime(t => {
        if (end < t[0]) {
          mode = 'start'
          return [ end, t[0] ]
        } else {
          return [ t[0], end ]
        }
      })

      if (mode !== 'end') {
        setDrag(d => d ? { ...d, mode } : d)
      }
    }
  }, [ drag ])

  const handleMouseUp = useCallback(function (e: any) {
    document.body.style.cursor = 'default'
    setDrag(null)
    onChange(time)
  }, [ time, onChange ])

  useEventListener('mousemove', handleMouseMove, drag ? document : null)
  useEventListener('mouseup', handleMouseUp, drag ? document : null)

  return <div
    className={classes('infoboard-log-timeline', drag && '-dragging')}
  >
    <div
      className='-wrapper'
      ref={timelineRef}
      onMouseDown={handleMouseDown}
    >
      {([...new Array(23), 0]).map((x, i) => {
        return <span className='-hour' data-hour={i+1} key={i}>
          <span className='-minutes' />
        </span>
      })}
      { isRange
        ? <span
          className='-range'
          data-value={`${getTime(time[0])} — ${getTime(time[1] || 1440)}`}
          onMouseDown={handleRangeMouseDown}
          style={{
            left: `${time[0] / 14.4}%`,
            right: `${100 - (time[1] || 0) / 14.4}%`
          }}
        >
          <span
            className='-drag'
            onMouseDown={handleDragMouseDown}
          />
          <span
            className='-drag -end'
            onMouseDown={e => handleDragMouseDown(e, 'end')}
          />
          <Icon
            type={Icons.Close}
            className='-clear'
            onMouseDown={(e) => {
              e.stopPropagation()
              onChange([time[0], undefined])
            }}
          />
        </span>
        : <span
          className='-time'
          data-value={getTime(time[0])}
          style={{ left: `${time[0] / 14.4}%`}}
        >
          <span
            className='-drag'
            onMouseDown={handleDragMouseDown}
          />
        </span>
      }
    </div>
  </div>
}

export default InfoboardLogTimeline
