import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  AppMessage,
  Datum,
  Icon,
  ImageSlider,
  Section,
  SectionTitle,
  Status,
  Value,
} from "ui-kit";
import { LogAction, logActions, logBoardActions, Page, AppIcons } from "consts";
import { loadLogDetails } from "actions";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { useFrames } from "components/Messages/Messages.helpers";
import { IContent } from "models/IContent";
import { IBoard } from "models/IBoard";
import { filterObject, isBitmaskContain } from "ui-kit/helpers";
import { boardTypeList } from "settings";
import { IFrameSet } from "models/IFrameSet";
import { ILog } from "models/ILog";
import { AppLoader } from "../../ui-kit/app";

const LogsCard = function () {
  const dispatch = useDispatch();
  const match: RouteMatch = useRouteMatch();
  const { id, page } = match?.params || { id: "", page: "" };
  const data: ILog = useSelector(
    ({ details }: ReduxState) => details[Page.Logs].data,
    shallowEqual
  );

  useEffect(
    function () {
      if (id) {
        dispatch(loadLogDetails(+id));
      }
    },
    [dispatch, id, page]
  );

  const rawData = data ? data.data : data;

  if (!id) {
    return (
      <Section>
        <AppMessage>Выберите запись</AppMessage>
      </Section>
    );
  }

  return (
    <Section
      header={() => (
        <>
          <SectionTitle
            label={
              <Status {...logActions[data?.action]?.modifier}>
                {data ? logActions[data.action]?.label : data}
              </Status>
            }
          >
            <span>Запись</span>
            {` `}
            <Value value={data ? `№${data.id}` : data} />
          </SectionTitle>
        </>
      )}
    >
      {data ? (
        <>
          {data.action !== LogAction.BoardsUpdate &&
            data.action !== LogAction.Delete && (
              <LogsCardEdit data={rawData as IContent} />
            )}
          {data?.action === LogAction.BoardsUpdate && (
            <LogsCardBoards data={data.boards} />
          )}
        </>
      ) : (
        <AppLoader />
      )}
    </Section>
  );
};

const LogsCardEdit = function (props: { data: IContent }) {
  const { data } = props;
  const frames = useFrames(data);

  return (
    <>
      <ImageSlider
        images={frames}
        placeholder={data ? "Нет активных сообщений" : undefined}
      />
      <Datum sizes={[24, "unset", 24, 24, 24, 24, 24, 24, 24, 24, 24]}>
        {data
          ? [
              [
                <Icon fill type={AppIcons.Published(data.isPublished)} />,
                <Value
                  value={data.isPublished ? "Опубликовано" : "Не опубликовано"}
                />,
                ...boardTypeList
                  .map((b) => b.value)
                  .filter((t) => isBitmaskContain(t + 1, data.usage))
                  .map((t) => <Icon key={t} type={AppIcons.BoardType(t)} />),
                ...(data.frameSets || []).map(
                  (fS: IFrameSet, index: number) => (
                    <Icon key={index} type={AppIcons.BoardFormat(fS.format)} />
                  )
                ),
              ],
            ]
          : data}
      </Datum>
    </>
  );
};

const LogsCardBoards = function (props: { data: IBoard[] | null }) {
  const { data } = props;

  const filterBoards = useCallback(function (query: string, item: IBoard) {
    return filterObject(query, item, {
      num: true,
      address: true,
    });
  }, []);

  return (
    <Datum
      label="Табло, привязанные к сообщению"
      sizes={[60, "unset", 24, 24]}
      classNames={["-strong", null, null, null]}
      filter={filterBoards}
      items={data}
      rowProps={(board: IBoard) =>
        board.editAction !== undefined && logBoardActions[board.editAction]
      }
      renderer={(board: IBoard) => [
        <Value
          href={`/${Page.Infoboards}/list/${board.id}`}
          value={`№${board.num}`}
        />,
        <Value value={board.address} />,
        <Icon type={AppIcons.BoardType(board.type)} />,
        <Icon type={AppIcons.BoardFormat(board.format)} />,
      ]}
    />
  );
};

export default LogsCard;
