import { PhotosState, ReduxState } from '../types'

const SET_IMAGE = 'photos/SET_IMAGE'
const initialState: PhotosState = {}

interface SetImageAction {
  type: typeof SET_IMAGE,
  image: string | null | undefined,
  key: string
  id: number
}

type PhotosActions = SetImageAction

const photosReducer = function (state = initialState, action: PhotosActions) {
  switch (action.type) {
    case SET_IMAGE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          [action.id]: action.image
        }
      }
    default:
      return state
  }
}

export default photosReducer

export const loadPhoto = function (
  id: number,
  key: string,
  params?: { [key: string]: any } & { realTime?: boolean }
  ) {
  return async function (dispatch: any, getState: () => ReduxState) {
    const image = getState().photos[key]?.[id]
    const token = getState().user.token
    const endpoint = getState().photos[key]?.endpoint

    if (image !== undefined && !params?.realTime) {
      return
    }

    if (token && id && endpoint) {
      dispatch({ type: SET_IMAGE, key, id, image: undefined })

      try {
        const image = await endpoint(token, id, params)
        dispatch({ type: SET_IMAGE, key, id, image })
        return
      } catch {
        dispatch({ type: SET_IMAGE, key, id, image: null })
      }
    }
  }
}
