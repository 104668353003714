import useEventListener from '@use-it/event-listener'
import { useCallback } from 'react'

export { default as Button } from './Button/Button'
export { default as DatePicker } from './DatePicker/DatePicker'
export { default as Calendar } from './DatePicker/Calendar'
export { default as TimePicker } from './DatePicker/TimePicker'
export { default as Textbox } from './Textbox/Textbox'
export { default as Number } from './Number/Number'
export { default as Textarea } from './Textarea/Textarea'
export { default as Select } from './Select/Select'
export { default as Suggester } from './Suggester/Suggester'
export { default as DadataSuggester } from './Suggester/DadataSuggester'
export { default as LicensePlateTextbox } from './LicensePlateTextbox/LicensePlateTextbox'
export { default as InputPopup } from './InputPopup/InputPopup'
export { default as Suggestions } from './Suggestions/Suggestions'
export { default as Checkbox } from './Checkbox/Checkbox'
export { default as Radio } from './Checkbox/Radio'

export const useOutsideClick = function (isOpened: boolean, setOpened: any, ...ignoreRefs: React.RefObject<HTMLElement | null | undefined>[]) {
  const handleMouseDown = useCallback(function (e: any) {
    const element = e.target
    let parent = element.parentElement

    while (parent) {
      for (let i = 0; i < ignoreRefs.length; i++) {
        if (ignoreRefs[i].current === parent) return
      }
      parent = parent.parentElement
    }

    setOpened && setOpened(false)
  }, [ ignoreRefs, setOpened ])

  useEventListener('mousedown', handleMouseDown, isOpened ? document : null)
}
