import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { SortableContainer } from "react-sortable-hoc";
import { AppIcons, BoardFormat, formats, FrameKind } from "consts";
import Scroller from "ui-kit/components/Scroller";
import { Button } from "ui-kit/controls";
import { Badge, Popup, Icons } from "ui-kit";
import MessagesFormFrame from "./MessageFormFrame";
import MessagePreview from "components/MessagePreview";
import { getFrameContent } from "./MessageForm.helpers";
import { setModal, setPrompt } from "actions";
import { IFrameSet } from "models/IFrameSet";
import "components/Messages/message.scss";
import "./message-form.scss";

interface Props {
  format: BoardFormat;
  frameSet: IFrameSet | undefined | null;
  onChange: any;
}

const MessageFormFrameSet = function (props: Props) {
  const { format, frameSet, onChange } = props;
  const dispatch = useDispatch();

  const handleSortEnd = useCallback(
    function ({ oldIndex, newIndex }) {
      if (frameSet?.frames) {
        if (newIndex > oldIndex) {
          onChange([
            ...frameSet.frames.slice(0, oldIndex),
            ...frameSet.frames.slice(oldIndex + 1, newIndex + 1),
            frameSet.frames[oldIndex],
            ...frameSet.frames.slice(newIndex + 1),
          ]);
        } else if (newIndex < oldIndex) {
          onChange([
            ...frameSet.frames.slice(0, newIndex),
            frameSet.frames[oldIndex],
            ...frameSet.frames.slice(newIndex, oldIndex),
            ...frameSet.frames.slice(oldIndex + 1),
          ]);
        }
      }
    },
    [frameSet, onChange]
  );

  const handlePreviewClick = function () {
    dispatch(
      setModal({
        component: MessagePreview,
        format: format,
        frames: frameSet?.frames || [],
      })
    );
  };

  const handleAddFrame = async function (kind: FrameKind) {
    try {
      const data = await getFrameContent(dispatch, kind, undefined, format);
      onChange([
        ...(frameSet?.frames || []),
        {
          kind,
          frameSetId: frameSet?.id,
          playInterval: 3000,
          transitionInterval: 500,
          transition: 0,
          ...data,
        },
      ]);
    } catch (error) {
      error && dispatch(setPrompt(error));
    }
  };

  const addFrameList = useMemo(function () {
    return [
      {
        label: "Изображение",
        value: FrameKind.StaticImage,
        icon: AppIcons.FrameType(FrameKind.StaticImage),
      },
      {
        label: "Видео",
        value: FrameKind.Video,
        icon: AppIcons.FrameType(FrameKind.Video),
      },
      {
        label: "Динамическая ссылка",
        value: FrameKind.UrlSource,
        icon: AppIcons.FrameType(FrameKind.UrlSource),
      },
      {
        label: "Текстовое сообщение",
        value: FrameKind.MessageEditor,
        icon: AppIcons.FrameType(FrameKind.MessageEditor),
      },
    ];
  }, []);

  return (
    <div className="message-form-item">
      <div className="-header">
        <Button
          accent
          className="-expand"
          icon={Icons.Play}
          disabled={!frameSet?.frames || frameSet.frames?.length === 0}
          onClick={handlePreviewClick}
        />
        <div className="-title">
          Формат {formats[Math.min(format, 5)]}
          <Badge disabled={!frameSet?.frames?.length}>
            {frameSet?.frames?.length || 0}
          </Badge>
        </div>
        <Popup
          items={addFrameList}
          onClick={handleAddFrame}
          triggerProps={({ isActive, onClick }: any) => ({
            clear: true,
            accent: true,
            active: isActive,
            onClick: onClick,
            small: true,
            children: "Добавить кадр",
          })}
          trigger={Button}
        />
      </div>
      <div className="-body">
        {frameSet?.frames?.length ? (
          <Scroller style={{ height: 188, marginBottom: -10 }}>
            {/* @ts-ignore */}
            <SortableFrames
              onSortEnd={handleSortEnd}
              useDragHandle
              axis="x"
              lockAxis="x"
            >
              {frameSet.frames.map((value, index) => (
                // @ts-ignore
                <MessagesFormFrame
                  key={index}
                  value={value}
                  format={format}
                  index={index}
                  onChange={(name: string | string[], value: any) => {
                    if (frameSet.frames) {
                      const n = typeof name === "string" ? [name] : name;
                      const v = typeof name === "string" ? [value] : value;
                      let update = { ...frameSet.frames[index] } as any;
                      n.forEach((x, i) => {
                        update[x] = v[i];
                      });
                      onChange([
                        ...frameSet.frames.slice(0, index),
                        update,
                        ...frameSet.frames.slice(index + 1),
                      ]);
                    }
                  }}
                  onDelete={() => {
                    if (frameSet.frames) {
                      onChange([
                        ...frameSet.frames.slice(0, index),
                        ...frameSet.frames.slice(index + 1),
                      ]);
                    }
                  }}
                />
              ))}
            </SortableFrames>
          </Scroller>
        ) : null}
      </div>
    </div>
  );
};

export default MessageFormFrameSet;

const SortableFrames = SortableContainer(function (props: any) {
  return <div className="message-form-images">{props.children}</div>;
});
