export { useTouchOnChange } from './useTouchOnChange'
export { strictEqual } from './strictEqual'
export { hasTouchEvents } from './hasTouchEvents'
export { filterArray } from './filterArray'
export { flatArray } from './flatArray'
export { matchTypes } from './matchTypes'
export { isFetchable } from './isFetchable'
export {
  moveSuggestionFocus,
  setSuggestionFocus,
  getFocusedIndex,
  focusElement
} from './focusSuggestion'
export { getSuggestionData, isSuggestionDisabled } from './getSuggestion'
export { dispatchValue } from './dispatchValue'
export { getStyleProperty } from './getStyleProperty'

export const isEmpty = function (value: any): boolean {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    (typeof value === 'number' && isNaN(value))
  )
}

export const arrify = function (value: any): Array<any> {
  if (!value) {
    return []
  }
  return Array.isArray(value) ? value : [ value ]
}

