import React from 'react'
import { NavLink} from "react-router-dom";
import { Status } from 'ui-kit'
import { getFormattedDate} from 'ui-kit/helpers'
import { ILog } from 'models/ILog'
import { logActions } from 'consts'

const MessagesCardLog = function (props: ILog) {
  const { userDisplayName, timestamp, action, id } = props

  return <NavLink className='message-log' to={`/logs/${id}`}>
    <span className='-time'>
      { getFormattedDate(timestamp)}
    </span>
    <span className='-action'>
      <Status {...logActions[action]?.modifier}>
          {logActions[action]?.label}
      </Status>
    </span>
    <span className='-name'>
      { userDisplayName }
    </span>
  </NavLink>
}

export default MessagesCardLog
