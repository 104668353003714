import React, { ChangeEvent } from 'react'

export const dispatchValue = function (
  value: string,
  inputRef: React.RefObject<HTMLInputElement>,
  handler: (e: ChangeEvent | Event) => void
): void {
  if (!inputRef.current || !handler) return
  
  inputRef.current.value = value
  inputRef.current.addEventListener('change', handler, { once: true })
  let inputEvent = new Event('change', { bubbles: true })
  inputRef.current.dispatchEvent(inputEvent)
}
