import { CirclePaint, Map, SymbolLayout, SymbolPaint } from 'mapbox-gl'
import { FeatureCollection, Point, MultiPoint, Polygon, MultiPolygon, Position } from 'geojson'
type FitBounds = [ [ number, number ], [ number, number ] ]

const defaultSymbolLayout: SymbolLayout = {
  'icon-image': { type: 'identity', property: 'icon' },
  'text-field': [ 'get', 'label' ],
  'text-size': { stops: [ [ 10, 6 ], [ 12, 18 ] ], type: 'exponential' },
  'icon-size': { stops: [ [ 10, 0.125 ], [ 12, 0.5 ] ], type: 'exponential' },
  'icon-allow-overlap': true
}

const defaultCirclePaint: CirclePaint = {
  'circle-radius': {
    base: 3,
    stops: [
      [ 6, 3 ],
      [ 12, 15 ]
    ]
  },
  'circle-stroke-width': 2,
  'circle-stroke-color': '#FFFFFF',
  'circle-color': { type: 'identity', property: 'color' }
}

const defaultSymbolPaint: SymbolPaint = {
  'text-color': '#FFFFFF',
  'icon-opacity': { stops: [ [ 10, 0 ], [ 10.00001, 1 ] ], type: 'exponential' },
}

const defaultSelectedPaint: CirclePaint = {
  'circle-radius': {
    base: 5,
    stops: [
      [ 9, 5 ],
      [ 10, 8 ],
      [ 11, 13 ],
      [ 12, 24 ]
    ],
    type: 'exponential'
  },
  'circle-stroke-width': 2,
  'circle-stroke-color': 'rgba(87,132,255, 0.8)',
  'circle-color': 'rgba(87,132,255,0.8)'
}

export const addPointLayers = function (
  map: Map,
  index: number,
  props?: {
    paint?: { circle?: CirclePaint, symbol?: SymbolPaint, selected?: CirclePaint }
    layout?: { symbol?: SymbolLayout }
  } | null,
  editMode?: boolean,
  selected = false
) {
  const layerPrefix = !selected ? 'layer' : 'selected_layer'
  const sourcePrefix = !selected ? 'data' : 'selected'

  if (selected) {
    map.addLayer({
      id: `${ layerPrefix }${ index }_area`,
      source: `${ sourcePrefix }${ index }`,
      type: 'circle',
      paint: props?.paint?.selected || defaultSelectedPaint
    })
  }

  map.addLayer({
    id: `${ layerPrefix }${ index }`,
    source: `${ sourcePrefix }${ index }`,
    type: 'circle',
    paint: props?.paint?.circle || defaultCirclePaint
  })

  map.addLayer({
    id: `${ layerPrefix }${index}_icons`,
    source: `${ sourcePrefix }${index}`,
    type: 'symbol',
    layout: props?.layout?.symbol || defaultSymbolLayout,
    paint: props?.paint?.symbol || defaultSymbolPaint
  })
}

export const addPolygonLayers = function (map: Map, index: number, editMode?: boolean, selected = false) {
  const layerPrefix = !selected ? 'layer' : 'selected_layer'
  const sourcePrefix = !selected ? 'data' : 'selected'

  map.addLayer({
    id: `${ layerPrefix }${ index }`,
    source: `${ sourcePrefix }${ index }`,
    type: 'fill',
    paint: {
      'fill-color': editMode ? '#828DA2' : { type: 'identity', property: 'fill' },
      'fill-opacity': 0.3
    }
  })

  map.addLayer({
    id: `${ layerPrefix }${ index }_line`,
    source: `${ sourcePrefix }${ index }`,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': editMode ? '#828DA2' : { type: 'identity', property: 'fill' },
      'line-width': editMode ? 1 : selected ? 2 : 1,
      'line-opacity': editMode ? 0.5 : 1
    }
  })
}

export const addLineLayers = function (map: Map, index: number, editMode?: boolean, selected = false) {
  const layerPrefix = !selected ? 'layer' : 'selected_layer'
  const sourcePrefix = !selected ? 'data' : 'selected'

  map.addLayer({
    id: `${ layerPrefix }${ index }_line`,
    source: `${ sourcePrefix }${ index }`,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': editMode ? '#828DA2' : { type: 'identity', property: 'color' },
      'line-width': { type: 'identity', property: 'strokeWidth' },
      'line-opacity': editMode ? 0.5 : 1
    }
  })
}

export const getFitBounds = function (
  mapData: ({
    type: string | null, data: FeatureCollection | null | undefined
  })[]
) {
  if (mapData.some(x => !x.data)) {
    return undefined
  }

  if (
    mapData.filter(x => x.data && x.data.features.length > 0).length === 0
  ) {
    return undefined
  }

  let response: FitBounds = [
    [ 90, 90 ],
    [ -90, -90 ]
  ]

  const updateCoordinates = function (coordinates?: Position) {
    if (!coordinates || !coordinates[0] || !coordinates[1]) {
      return
    }

    response[0][0] = Math.min(coordinates[0] - 0.005, response[0][0])
    response[1][0] = Math.max(coordinates[0] + 0.005, response[1][0])
    response[0][1] = Math.min(coordinates[1] - 0.005, response[0][1])
    response[1][1] = Math.max(coordinates[1] + 0.005, response[1][1])
  }

  if (mapData.length === 1) {
    const json = mapData[0]
    if (json.type === 'Point' && json.data && json.data.features.length === 1) {
      const f = json.data.features[0]
      const c = (f.geometry as Point).coordinates
      response = [
        [ c[0] - 0.015, c[1] - 0.015 ],
        [ c[0] + 0.015, c[1] + 0.015 ]
      ]
      return response
    }
  }

  mapData.forEach(function (json) {
    if (json.data && json.data.features.length > 0) {
      if (json.type === 'Point') {
        json.data.features.forEach(function (f) {
          updateCoordinates((f.geometry as Point).coordinates)
        })
      } else if (json.type === 'Multipoint') {
        json.data.features.forEach(function (f) {
          (f.geometry as MultiPoint).coordinates.forEach(x => updateCoordinates(x))
        })
      } else if (json.type === 'Polygon') {
        json.data.features.forEach(function (f) {
          (f.geometry as Polygon).coordinates.forEach(p => {
            p.forEach(c => updateCoordinates(c))
          })
        })
      } else if (json.type === 'MultiPolygon') {
        json.data.features.forEach(function (f) {
          (f.geometry as MultiPolygon).coordinates.forEach(p => {
            p.forEach(c => c.forEach(x => updateCoordinates(x)))
          })
        })
      }
    }
  })

  return response
}
