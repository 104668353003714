import React from 'react'
import classes from 'classnames'
import './timepicker.scss'

interface Props {
  value: string,
  onChange: (e: any) => void
  hidden?: boolean,
  disabled?: boolean
  small?: boolean
  error?: string | boolean
  className?: string
  style?: React.CSSProperties
}

const TimePicker = function (props: Props) {
  const {
    value,
    onChange,
    hidden,
    disabled,
    small,
    error,
    className,
    style
  } = props

  return <span
    className={ classes(
      'timepicker',
      hidden && '-hidden',
      small && '-small'
    ) }
  >
    <input
      className={classes(
        'timepicker',
        className,
        error && '-error',
        !value && '-placeholder'
      )}
      style={style}
      type={'time'}
      value={value || ''}
      onChange={onChange}
      disabled={disabled}
    />
  </span>
}

export default TimePicker
