import React, { useEffect, useRef, useState } from 'react'
import { IImage } from '../../types'
import classes from 'classnames'

const ImageVideo = function (props: IImage) {
  const { src, onClick, cover } = props
  const blockRef = useRef<HTMLDivElement | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [ isPlaying, setPlaying ] = useState(false)
  const [ position, setPosition ] = useState(0)
  const [ style, setStyle ] = useState<React.CSSProperties | undefined>()

  useEffect(function () {
    let videoEl: HTMLVideoElement;

    if (cover && src && blockRef.current) {
      videoEl = document.createElement('video')
      videoEl.style.position = 'fixed'
      videoEl.style.opacity = '0'
      videoEl.src = src
      document.body.appendChild(videoEl)
      videoEl.addEventListener('loadeddata', function () {
        const ratio = videoEl.offsetWidth / videoEl.offsetHeight
        videoEl?.parentElement?.removeChild(videoEl)

        if (blockRef.current) {
          const coverRatio = blockRef.current.offsetWidth / blockRef.current.offsetHeight
          if (coverRatio < ratio) {
            setStyle({
              height: blockRef.current.offsetHeight,
              width: blockRef.current.offsetHeight * ratio,
              marginLeft: (coverRatio - ratio) / 2 * blockRef.current.offsetHeight
            })
          } else {
            setStyle({
              width: blockRef.current.offsetWidth,
              height: blockRef.current.offsetWidth / ratio,
              marginTop: (ratio - coverRatio) / 2 * blockRef.current.offsetHeight
            })
          }
        }
      })
    }

    return function () {
      videoEl?.parentElement?.removeChild(videoEl)
    }
  }, [ cover, src, blockRef ])

  useEffect(function () {
    if (videoRef.current) {
      if (isPlaying && videoRef.current.paused) {
        videoRef.current.play()
      }

      if (!isPlaying && !videoRef.current.paused) {
        videoRef.current.pause()
      }
    }
  }, [ isPlaying, videoRef ])

  useEffect(function () {
    const el = videoRef.current

    if (!isPlaying && el?.duration) {
      el.currentTime = el.duration * position
    }

  }, [ position, isPlaying, videoRef, src ])

  const handleClick = function (e: any) {
    onClick?.(e)

    setPlaying(playing => !playing)
  }

  const handleMouseMove = function (e: any) {
    if (videoRef.current) {
      const { left, width } = videoRef.current.getBoundingClientRect()
      setPosition((e.pageX - left) / width)
    }
  }

  return <div
    ref={blockRef}
    onClick={src ? handleClick : undefined}
    onMouseMove={src ? handleMouseMove : undefined}
    className={classes(
      'image-view -video',
      !src && '-hidden',
      onClick && '-clickable',
    )}
  >
    <video
      ref={videoRef}
      muted
      style={style}
      src={src || 'none'}
    />
  </div>
}

export default ImageVideo
