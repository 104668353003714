import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Route, useRouteMatch } from "react-router-dom";
import classes from "classnames";
import Form from "../form";
import { AppRoutes, ReduxState } from "../types";
import "./modal.scss";

const Modal = function (props: { routes: AppRoutes }) {
  const { modals } = props.routes;
  const {
    // @ts-ignore
    params: { modal: modalName },
  } = useRouteMatch({ path: "*/@:modal" }) || { params: {} };
  const stateModal = useSelector(
    ({ layout }: ReduxState) => layout.modal,
    shallowEqual
  );
  const [isVisible, setVisibility] = useState(false);
  const [selected, setSelected] = useState<
    { path: string; component: any } | undefined
  >();

  useEffect(
    function () {
      if (stateModal?.component) {
        setSelected({ path: "*", component: stateModal.component });
        setTimeout(setVisibility, 0, true);
      } else if (modalName && modals) {
        const selected = modals.find(function (item) {
          const modalPath = item.path.substr(item.path.lastIndexOf("@") + 1);
          return modalName && modalPath === modalName;
        });

        if (selected) {
          setSelected({
            path: `${selected.path.substr(
              0,
              selected.path.lastIndexOf("@")
            )}:modal`,
            component: selected.component,
          });
          setTimeout(setVisibility, 0, true);
        }
      } else {
        setTimeout(setVisibility, 0, false);
        setTimeout(setSelected, 200);
      }
    },
    [modalName, modals, stateModal]
  );

  const [path, Component] = useMemo(
    function () {
      if (selected) {
        return [selected.path, selected.component];
      } else {
        return ["", () => null];
      }
    },
    [selected]
  );

  if (!selected) {
    return null;
  }

  return (
    <div className={classes("modal", isVisible && "-visible")}>
      {/* @ts-ignore */}
      <Form>
        <div className="modal-window">
          {/* @ts-ignore */}
          <Route path={path}>
            {/* @ts-ignore */}
            <Component />
          </Route>
        </div>
      </Form>
    </div>
  );
};

export default Modal;
