export const toggleArray = function (collection: any[] | undefined, item: any, value?: boolean) {
  if (value !== false && !collection?.includes(item)) {
    return [
      ...(collection || []),
      item
    ]
  }
  
  return (collection || []).filter(x => x !== item)
}
