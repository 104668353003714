import React, { useCallback } from 'react'
import MessageFormDates from './MessageFormDates'
import MessageFormTags from './MessageFormTags'
import MessageFormBoards from './MessageFormBoards'
import { ITag } from 'models/ITag'
import './message-form.scss'

interface Props {
  state: { [key: string]: any },
  onChange?: (name: string, value: any) => void
}

const MessageFormSettings = function (props: Props) {
  const { state, onChange } = props

  const handleTagsChange = useCallback(function (value: ITag[]) {
    onChange?.('tags', value)
  }, [ onChange ])

  return <div className='message-form-settings'>
    <MessageFormBoards
      value={state?.usage}
      onChange={(value: number) => onChange?.('usage', value)}
    />

    <MessageFormDates
      value={state?.schedule}
      onChange={(value) => onChange?.('schedule', value)}
    />

    <MessageFormTags
      value={state?.tags || []}
      onChange={handleTagsChange}
    />
  </div>
}

export default MessageFormSettings
