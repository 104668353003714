import {
  BoardFormat as Format,
  BoardType as Type,
  Cities,
  FrameKind
} from "consts";
import { CITY } from "ui-kit";

import { ReactComponent as BoardFormat } from "./board_format.svg";
import { ReactComponent as BoardFormatOneOne } from "./board_format_11.svg";
import { ReactComponent as BoardFormatTwoThree } from "./board_format_23.svg";
import { ReactComponent as BoardFormatFourThree } from "./board_format_43.svg";
import { ReactComponent as BoardFormatFiveOne } from "./board_format_15.svg";
import { ReactComponent as BoardFormatThreeHalfOne } from "./board_format_36_1.svg";
import { ReactComponent as BoardFormatUnknown } from "./board_format_unknown.svg";
import { ReactComponent as BoardType } from "./board_type.svg";
import { ReactComponent as BoardTypeCommon } from "./tab_second.svg";
import { ReactComponent as BoardTypeVehicle } from "./sidebar_mobile.svg";
import { ReactComponent as BoardTypeVehicleSaratov } from "./board_type_vehicle_saratov.svg";
import { ReactComponent as BoardTypeTruck } from "./trailer.svg";
import { ReactComponent as BoardTypeRoadSign } from "./sidebar_mck.svg";
import { ReactComponent as Effect } from "./effect.svg";
import { ReactComponent as FrameKindImage } from "./frame_image.svg";
import { ReactComponent as FrameKindLink } from "./frame_link.svg";
import { ReactComponent as FrameKindText } from "./frame_text.svg";
import { ReactComponent as FrameKindVideo } from "./frame_video.svg";
import { ReactComponent as Geozone } from "./freepolygon.svg";
import { ReactComponent as Infoboard } from "./bubble_lines.svg";
import { ReactComponent as Message } from "./tableau_second.svg";
import { ReactComponent as NonPublished } from "./danger.svg";
import { ReactComponent as Published } from "./success_circle.svg";
import { ReactComponent as Waiting } from "./waiting.svg";
import { ReactComponent as ZoneAdd } from "./zone_add.svg";
import { ReactComponent as ZoneSearch } from "./zone_search.svg";
import { ReactComponent as ZoneSelect } from "./zone_select.svg";

export const AppIcons = {
  BoardFormat: (format?: Format) => {
    switch (format) {
      case Format.OneOne: return BoardFormatOneOne
      case Format.TwoThree: return BoardFormatTwoThree
      case Format.FourThree: return BoardFormatFourThree
      case Format.FiveOne: return BoardFormatFiveOne
      case Format.ThreeHalfOne: return BoardFormatThreeHalfOne
      case Format.Unknown: return BoardFormatUnknown
      default: return BoardFormat
    }
  },
  BoardType: (type?: Type) => {
    switch (type) {
      case Type.Common: return BoardTypeCommon
      case Type.Vehicle: return CITY === Cities.Saratov
        ? BoardTypeVehicleSaratov
        : BoardTypeVehicle
      case Type.Truck: return BoardTypeTruck
      case Type.RoadSign: return BoardTypeRoadSign
      default: return BoardType
    }
  },
  Effect,
  FrameType: (kind: FrameKind) => {
    switch (kind) {
      case FrameKind.StaticImage: return FrameKindImage
      case FrameKind.UrlSource: return FrameKindLink
      case FrameKind.Video: return FrameKindVideo
      case FrameKind.MessageEditor: return FrameKindText
    }
  },
  Geozone,
  Infoboard,
  Message,
  Published: (value?: boolean) => value
    ? Published
    : NonPublished,
  Waiting,
  ZoneAdd,
  ZoneSearch,
  ZoneSelect
}
