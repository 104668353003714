import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import classes from "classnames";
import { UserComponent } from "@megapolis/react-auth";
import { Icons } from "ui-kit/assets";
import Menu from "../Menu";
import { AppRoutes } from "../../types";
import "./navigation.scss";

const Navigation = function (props: { routes: AppRoutes }) {
  const [isActive, setActive] = useState(false);
  const timerRef = useRef(0);

  return (
    <div
      className={classes("navigation", isActive && "-active")}
      onMouseMove={() => {
        if (!timerRef.current) {
          timerRef.current = window.setTimeout(() => {
            setActive(true);
          }, 700);
        }
      }}
      onMouseLeave={() => {
        clearTimeout(timerRef.current);
        timerRef.current = 0;
        setActive(false);
      }}
      onClick={() => {
        clearTimeout(timerRef.current);
        timerRef.current = 0;
        setActive(false);
      }}
    >
      <NavLink to="/" className="navigation-logo">
        {props.routes?.nav?.image ? (
          <img src={props.routes.nav.image} alt="" />
        ) : (
          <Icons.LogoHomeButton />
        )}
      </NavLink>

      <div className="navigation-menu">
        <Menu routes={props.routes} />
      </div>

      <div className="navigation-user">
        <UserComponent size={36} />
      </div>
    </div>
  );
};

export default Navigation;
