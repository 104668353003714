import React from "react";
import Application from "ui-kit/app";
import {
  datumState,
  detailsState,
  layoutState,
  photosState,
  routes,
} from "settings";

const App = function () {
  return (
    //@ts-ignore
    <Application
      clientId="vms-web"
      scope="openid boardservice.api"
      state={{
        datum: datumState,
        details: detailsState,
        layout: layoutState,
        photos: photosState,
      }}
      routes={routes}
    />
  );
};

export default App;
