import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LayoutArea } from 'ui-kit'
import { AppLoader } from 'ui-kit/app'
import Section from 'ui-kit/section'
import Table from 'ui-kit/table'
import LogsHeader from './LogsHeader'
import LogsCard from './LogsCard'
import Filter from 'ui-kit/filter'
import { useTableData } from './Logs.helpers'
import { useDataLoading } from 'ui-kit/hooks'
import { loadLogsData } from 'actions'
import { Page } from 'consts'
import { filterLists, tableColumns } from 'settings'
import { ReduxState } from 'ui-kit/types'
import { setDatumAppendix } from '../../ui-kit/reducers/datum'

const Logs = function () {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [ logs, total ] = useDataLoading(loadLogsData)
  const hasFilters = useSelector(({ layout }: ReduxState) => layout.showFilters[Page.Logs])
  const tableData = useTableData(logs)

  useEffect(function () {
    /** Set mock summary to prevent preloaders */
    dispatch(setDatumAppendix(Page.Logs, 'summary', { types: { 0: ' ', 1: ' ', 2: ' '} }))
  }, [ dispatch ])

  return <>
    <LayoutArea size={ 280 } collapsed={ !hasFilters } className='-pale'>
      <Filter list={filterLists[Page.Logs]} total={total}/>
    </LayoutArea>
    <LayoutArea>
      <Section
        header={ LogsHeader }
      >
        <Table
          total={ total }
          items={ tableData }
          onLoad={ (batch) => dispatch(loadLogsData(batch)) }
          columns={tableColumns[Page.Logs]}
        />
      </Section>
    </LayoutArea>
    <LayoutArea size={ 424 }>
      { logs || id ? <LogsCard /> : <AppLoader /> }
    </LayoutArea>
  </>
}

export default Logs
