import React, { useLayoutEffect, useRef, useState } from "react";
import classes from "classnames";
import ScrollBars from "react-custom-scrollbars";
import { ScrollerOffset, ScrollPosition } from ".";
import "./scroller.scss";

interface Props {
  children: any;
  maxHeight?: number;
  style?: React.CSSProperties;
  scrollTop?: number | [number, number];
  scrollLeft?: number | [number, number];
  scrollTo?: (p: ScrollPosition) => void;
  offset?: ScrollerOffset;
  padding?: React.CSSProperties;
  className?: string;
}

const calculatePosition = function (
  next: undefined | number | [number, number],
  current: number,
  size: number
) {
  if (next === undefined) return undefined;
  if (typeof next === "number") return next;

  const [start, stop] = next;
  /** Not visible. Scroll to center */
  if (stop < current || start > current + size) {
    return start + (stop - start) / 2 - size / 2;
  } else if (start < current) {
    return start;
  } else if (stop > current + size) {
    return stop - size;
  }

  return undefined;
};

const Scroller = function (props: Props) {
  const {
    children,
    maxHeight,
    scrollTop,
    scrollLeft,
    scrollTo,
    padding,
    className,
    style,
    offset,
  } = props;
  const scrollRef = useRef<ScrollBars | null>(null);
  const [hasHorizontal, setHorizontal] = useState(false);
  const [hasVertical, setVertical] = useState(false);
  const offsetHeight = (offset?.top || 0) + (offset?.bottom || 0);
  const offsetWidth = (offset?.left || 0) + (offset?.right || 0);

  useLayoutEffect(
    function () {
      if (scrollRef.current) {
        const el = scrollRef.current;
        setHorizontal(el.getScrollWidth() > el.getClientWidth());
        setVertical(el.getScrollHeight() > el.getClientWidth());
      }
    },
    [scrollRef, children]
  );

  useLayoutEffect(
    function () {
      const el = scrollRef.current;
      if (el) {
        const top = calculatePosition(
          scrollTop,
          el.getScrollTop(),
          el.getClientHeight() - offsetHeight
        );
        const left = calculatePosition(
          scrollLeft,
          el.getScrollLeft(),
          el.getClientWidth() - offsetWidth
        );
        top !== undefined && el.scrollTop(top);
        left !== undefined && el.scrollLeft(left);
      }
    },
    [scrollTop, scrollLeft, scrollRef, offsetHeight, offsetWidth]
  );

  const handleScroll = function (e: any) {
    if (scrollRef.current) {
      const el = scrollRef.current;
      setHorizontal(el.getScrollWidth() > el.getClientWidth());
      setVertical(el.getScrollHeight() > el.getClientWidth());

      const { scrollTop, scrollLeft } = e.target;
      scrollTo?.({
        scrollTop,
        scrollLeft,
        limitLeft: el.getScrollWidth() - el.getClientWidth(),
        limitTop: el.getScrollHeight() - el.getClientWidth(),
      });
    }
  };

  const Thumb = function (props: any) {
    return <span className="scroller-thumb" {...props} />;
  };

  const VerticalTrack = function (p: any) {
    return (
      <span
        onClick={(e) => e.preventDefault()}
        className="scroller-track -vertical"
        style={{
          ...p.style,
          top: 1 + (offset?.top || 0),
          bottom: (hasHorizontal ? 5 : 1) + (offset?.bottom || 0),
        }}
      />
    );
  };

  const HorizonalTrack = function (p: any) {
    return (
      <span
        onClick={(e) => e.preventDefault()}
        className={classes(
          "scroller-track",
          "-horizontal",
          !hasHorizontal && "-hidden"
        )}
        style={{
          ...p.style,
          left: offset?.left || 0,
          right: (hasVertical ? 5 : 1) + (offset?.right || 0),
          bottom: 1 + (offset?.bottom || 0),
        }}
      />
    );
  };

  const ScrollView = function (p: any) {
    return (
      <span
        className={classes("scroller", className)}
        {...p}
        style={{ ...p.style, ...padding }}
      />
    );
  };
  //@ts-ignore
  return (
    //@ts-ignore
    <ScrollBars
      ref={scrollRef}
      renderThumbVertical={Thumb}
      renderThumbHorizontal={Thumb}
      renderTrackVertical={VerticalTrack}
      renderTrackHorizontal={HorizonalTrack}
      renderView={ScrollView}
      autoHide
      autoHeight={!style}
      autoHeightMax={maxHeight}
      autoHideTimeout={700}
      autoHideDuration={200}
      onScroll={handleScroll}
      style={style}
      universal
    >
      {children}
    </ScrollBars>
  );
};

export default Scroller;
