import React, { useEffect, useRef, useState } from 'react'
import classes from 'classnames'
import MapFullscreen from './MapFullscreen'
import MapDataHandler from './MapDataHandler'
import MapView, { MapViewProps } from './MapView'
import './map.scss'

interface Props extends MapViewProps {
  onLoad?: () => void
}

const MapPreview = function (props: Props) {
  const { onLoad, ...other } = props
  const [ fullscreen, setFullscreen ] = useState(false)
  const [ size, setSize ] = useState<{ width?: number, height?: number }>({})
  const blockRef = useRef<HTMLDivElement | null>(null)

  useEffect(function () {
    if (blockRef.current) {
      setSize({
        width: blockRef.current.offsetWidth,
        height: blockRef.current.offsetHeight
      })

      if (fullscreen) {
        document.body.classList.add('-fullscreen')
      } else {
        document.body.classList.remove('-fullscreen')
      }
    }
  }, [ blockRef, fullscreen ])

  return <div
    className={ classes(
      'map-preview',
      props.hidden && '-hidden',
      fullscreen && '-fullscreen'
    ) }
    ref={ blockRef }
  >
    <MapDataHandler onLoad={onLoad} data={props.data} offset={{}} />
    <MapFullscreen fullscreen={ fullscreen } onToggle={ setFullscreen }/>
    <MapView
      { ...other }
      locked={props.locked && !fullscreen}
      width={ size.width }
      height={ size.height }
    />
  </div>
}

export default MapPreview
