import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LayoutArea, Section } from "ui-kit";
import Filter from "ui-kit/filter";
import Table from "ui-kit/table";
import Tiles from "ui-kit/tiles";
import {
  MessagesRow,
  MessagesHeader,
  MessagesCard,
  useTableData,
  useTilesData,
} from ".";
import MessagesBoards from "./MessagesBoards";
import { useDataLoading } from "ui-kit/hooks";
import { loadContentData, loadContentSummary } from "actions";
import { filterLists, tableColumns } from "settings";
import { Page } from "consts";
import { ReduxState } from "ui-kit/types";
import { AppLoader } from "../../ui-kit/app";

const Messages = function () {
  const dispatch = useDispatch();
  const { mode, id, form } = useParams();
  const [messages, total, summary] = useDataLoading(loadContentData);
  const hasFilters = useSelector(
    ({ layout }: ReduxState) => layout.showFilters[Page.Messages]
  );
  const search = useSelector(
    ({ settings }: ReduxState) => settings.filters[Page.Messages]?.search?.[0]
  );
  const tableData = useTableData(messages, search);
  const tilesData = useTilesData(messages, search);

  useEffect(
    function () {
      if (messages === undefined) {
        dispatch(loadContentSummary());
      }
    },
    [dispatch, messages]
  );

  if (form === "add-boards") {
    return <MessagesBoards />;
  }

  return (
    <>
      <LayoutArea size={280} collapsed={!hasFilters} className="-pale">
        <Filter
          list={filterLists[Page.Messages]}
          total={summary && summary.total}
        />
      </LayoutArea>
      <LayoutArea>
        <Section header={MessagesHeader}>
          {mode === "list" && (
            <Table
              rowHeight={184}
              onLoad={(batch) => dispatch(loadContentData(batch))}
              rowRenderer={MessagesRow}
              total={total}
              items={tableData}
              columns={tableColumns[Page.Messages]}
            />
          )}
          {mode === "tiles" && (
            <Tiles
              total={total}
              items={tilesData}
              onLoad={(batch) => dispatch(loadContentData(batch))}
            />
          )}
        </Section>
      </LayoutArea>
      <LayoutArea size={424}>
        {messages || id ? <MessagesCard /> : <AppLoader />}
      </LayoutArea>
    </>
  );
};

export default Messages;
