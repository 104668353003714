import React, { useMemo } from 'react'
import orderBy from 'lodash/orderBy'
import { Badge, Datum, Icon, Icons, Value } from 'ui-kit'
import { getFormattedDate, isBitmaskContain } from 'ui-kit/helpers'
import { locales } from 'ui-kit/controls/DatePicker/Calendar'
import { boardTypeList } from 'settings'
import { BoardFormat, Photo, AppIcons } from 'consts'
import { FrameImage } from 'components/Messages/MessagesRow'
import { IContent } from 'models/IContent'
import { IImage } from 'ui-kit/types'
import { ISchedule } from 'models/ISchedule'
import { ITag } from 'models/ITag'

export const getFrameWidth = function (format: BoardFormat, height: number): number {
  switch (format) {
    case BoardFormat.FourThree:
      return height / 3 * 4
    case BoardFormat.TwoThree:
      return height / 3 * 2
    case BoardFormat.FiveOne:
      return height * 5.2545
    case BoardFormat.ThreeHalfOne:
      return height * 3.6
    default:
      return height
  }
}

export const useTilesData = function (
  data: IContent[] | null | undefined,
  search = ''
) {
  return useMemo(function () {
    return data
      ? data.map(item => {
        const images = item
          ? getImageFrames([item]).map(i => ({...i, contain: true, style: { paddingTop: '75%' } }))
          : []

        return item ? {
          id: item.id,
          title: <>
            <Value value={item.name} highlight={search} />
            { images.length && <em>({images.length})</em> }
          </>,
          images,
          children: <>
            <Datum sizes={['unset', 24, 24, 24, 24, 24, 24, 24, 24, 24, 24]}>
              {[[
                <>
                  <Badge disabled={ item.bindedBoardsCount === 0 }>{ item.bindedBoardsCount }</Badge>
                  <span>Табло</span>
                </>,
                <Icon fill type={AppIcons.Published(item.isPublished)}/>,
                ...boardTypeList
                  .map(b => b.value)
                  .filter(t => isBitmaskContain(t + 1, item.usage))
                  .map(t => <Icon key={t} type={ AppIcons.BoardType(t) } />),
                ...(item.frameSets || []).map(fS => (
                  <Icon
                    type={ AppIcons.BoardFormat(fS.format) }
                    key={ fS.format }
                  />
                ))
              ]]}
            </Datum>
            <Datum sizes={[20,'unset']}>
              {[[
                <Icon type={Icons.Calendar} />,
                <Value value={getDatesRange(item.schedule)} />
              ]]}
            </Datum>
          </>
        } : item
      })
      : data
  }, [ data, search ])
}

export const useTableData = function (
  data: IContent[] | null | undefined,
  search = ''
) {
  return useMemo(function () {
    return data
      ? data.map(item => {
        let framesCount = 0
        let images: FrameImage[] = []
        if (item) {
          item.frameSets?.forEach(fS => {
            framesCount = Math.max(fS.frames?.length || 0, framesCount)
            images = [
              ...images,
              ...(fS.frames || []).map(f => ({
                id: f.id,
                type: Photo.Frame,
                isVideo: f.kind === 4,
                style: {
                  height: 120,
                  width: getFrameWidth(fS.format, 120)
                }
              }))
            ]
          })
        }

        return item
          ? {
            id: item.id,
            title: <>
              <Value value={item.name} highlight={search} />
              { framesCount && <em>({ framesCount })</em> }
            </>,
            schedule: item.schedule || null,
            boardCount: <>
              <Badge
                disabled={ item.bindedBoardsCount === 0 }>{ item.bindedBoardsCount }
              </Badge>
              <span>Табло</span>
            </>,
            types: boardTypeList
              .map(b => b.value)
              .filter(t => isBitmaskContain(t + 1, item.usage)),
            isPublished: item.isPublished,
            formats: item.frameSets?.map(fS => fS.format) || null,
            images
          }
          : item
      })
      : data
  }, [ data, search ])
}

const getImageFrames = function (data: IContent[], format?: null | BoardFormat) {
  let frames: IImage[] = []
  data.forEach(c => {
    c.frameSets?.filter(fS => fS.format === format || format === undefined).forEach(fS => {
      frames = [
        ...frames,
        ...(fS.frames?.filter(f => !f.isRemoved) || []).map(f => ({
          id: f.id,
          type: Photo.Frame,
          isVideo: f.kind === 4,
        }))
      ]
    })
  })

  return frames
}

export const useFrames = function (
  content: IContent | IContent[] | null | undefined,
  format?: null | BoardFormat,
) {
  return useMemo(function () {
    if (content) {
      const data: IContent[] = Array.isArray(content) ? content : [content]
      return getImageFrames(data, format)
    } else {
      return content
    }
  }, [ format, content ])
}

export const useTags = function (
  content: IContent | IContent[] | null | undefined
): ITag[] {
  return useMemo(function () {
    let tags: ITag[] = []
    if (content) {
      const data: IContent[] = Array.isArray(content) ? content : [content]
      data.forEach(message => {
        message.tags?.forEach(tag => {
          if (!tags.includes(tag)) {
            tags.push(tag)
          }
        })
      })
    }

    return tags
  }, [ content ])
}

export const getDatesRange = function (schedule: ISchedule[] | null) {
  if (!schedule) {
    return 'Весь период'
  }

  const startDate = orderBy(schedule, ['startDate'], 'asc').filter(x => x.startDate)[0]?.startDate
  const endDate = orderBy(schedule, ['endDate'], 'desc').filter(x => x.endDate)[0]?.endDate

  if (!startDate || !endDate) {
    return 'Весь период'
  }

  return `${getFormattedDate(startDate, false) || '...'} — ${getFormattedDate(endDate, false) || '...'}`
}

export const getFormattedSchedule = function (schedule: ISchedule | null) {
  const period = !schedule?.startDate && !schedule?.endDate
    ? 'Весь период'
    : `${schedule.startDate ? getFormattedDate(schedule.startDate, false) : '...'} — ${schedule.endDate ? getFormattedDate(schedule.endDate, false) : '...'}`

  const weekDays = schedule?.weekDays?.length === 7
    ? ''
    : [1,2,3,4,5,6,0]
      .filter((x) => schedule?.weekDays?.includes(x))
      .map(x => locales.weekdaysShort[x])
      .join(', ')

  const time: string = schedule?.time?.start && schedule.time?.stop && schedule.time.start !== schedule.time.stop
    ? `${schedule.time?.start.substr(0, 5)} — ${schedule.time?.stop.substr(0, 5)}`
    : ''

  return { period, weekDays, time }
}
