import React, { InputHTMLAttributes } from 'react'
import classes from 'classnames'
import './textarea.scss'

interface TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef(function (props: TextareaProps, ref: any) {
  const { className, size, style, ...other } = props

  return <textarea
    ref={ref}
    rows={3}
    {...other}
    className={classes('textarea', className, !size && '-wide')}
    style={{
      maxWidth: size ? size * 8.4 : 'unset',
      minWidth: size ? size * 8.4 : 'unset',
      ...style
    }}
  />
})

export default Textarea
