import { TInputProps, TSelectOptions, TSelectProps, TSuggestion } from './types'

import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  dispatchValue,
  filterArray,
  flatArray,
  getFocusedIndex,
  getSuggestionData,
  hasTouchEvents,
  matchTypes,
  moveSuggestionFocus,
  setSuggestionFocus,
  strictEqual
} from './helpers'
import useSuggestions from './shared/useSuggestions'

const Select = function (
  inputRef: React.RefObject<HTMLInputElement>,
  listRef: React.RefObject<HTMLElement>,
  props: TSelectProps,
  options: TSelectOptions = {}
): [ TInputProps, Array<TSuggestion>, boolean ] {
  const {
    onChange, onBlur, onKeyDown, onKeyPress, onMouseUp,
    list, value, defaultValue, ...other
  } = props

  const [ input, setInput ] = useState('')
  /** Use Object { value: string } to force useEffect update: */
  const [ query, setQuery ] = useState({ value: '' })
  const [ isOpened, setOpened ] = useState(false)
  const [ isActive, setActive ] = useState(false)
  const [ placeholder, setPlaceholder ] = useState(props.placeholder)
  const [ suggestions, setSuggestions ] = useState<{ map: Array<TSuggestion>, flat: Array<TSuggestion>}>({ map: [], flat: [] })
  const selectedRef = useRef<TSuggestion>('')
  const focusedRef = useRef<HTMLElement | null>(null)
  const isMobile = hasTouchEvents()

  const handleSetSuggestions = useCallback(function (next: Array<TSuggestion> = []) {
    if (!strictEqual(suggestions.map, next)) {
      const keys = options.keys || {}
      setSuggestions({
        map: next,
        flat: flatArray(
          next,
          keys.value,
          {
            narrow: keys.list || [],
            exclude: keys.value && (keys.list === keys.value) ? [] : keys.value || []
          }
        ) || []
      })
    }
  }, [ suggestions, options.keys])


  const handleSetLabel = useCallback(function () {
    const selected = selectedRef.current !== undefined ? selectedRef.current : ''
    const types = { display: [ 'string', 'number' ] }

    if (matchTypes(selected, 'string', 'number', 'boolean')) {
      setInput(String(selected))

      if (onChange && selected !== input) {
        const items = list

        if (Array.isArray(items)) {
          for (let i = 0; i < items.length; i++) {
            const [ val, lbl ] = getSuggestionData(items[i], options.keys, types)
            if (selected.toString() === val.toString()) {
              setInput(lbl)
            }
          }
        }
      }
    } else {
      setInput(getSuggestionData(selected, options.keys, types)[1])
    }
  }, [ selectedRef, list, options.keys, input, onChange ])

  useEffect(function () {
    if (list === undefined || (list !== null && list.length > 0)) {
      const nextValue = value === undefined ? defaultValue : value
      const [ selectedValue ] = getSuggestionData(selectedRef.current, options.keys)

      if (nextValue !== selectedValue) {
        selectedRef.current = list?.find(x => getSuggestionData(x, options.keys)?.[0] === nextValue) || (nextValue !== undefined ? nextValue : '')
        handleSetLabel()
      }
    }
  }, [ value, defaultValue, handleSetLabel, list, options.keys ])

  useEffect(function () {
    !isActive && setQuery({ value: input })
  }, [ input, isActive ])

  useEffect(function () {
    return function () {
      handleSetSuggestions()
    }
  }, [ list, handleSetSuggestions ])

  useEffect(function () {
    if (list && onChange && (value || defaultValue)) {
      const val = (value || defaultValue || '').toString()

      if (!list.find(x => getSuggestionData(x, options.keys)[0].toString() === val)) {
        setQuery({ value: ''})
        setInput('')
        dispatchValue('', inputRef, onChange)
        selectedRef.current = ''
        setOpened(false)
        setActive(false)

        setTimeout(() => {
          setOpened(false)
          setActive(false)
        }, 1)

      }
    }
  }, [ list, value, defaultValue, onChange, options, inputRef])

  useEffect(function () {
    if (isActive) {
      const keys = options.keys || {}

      handleSetSuggestions(
        filterArray(
          query.value,
          list,
          keys.search,
          {
            narrow: keys.list || [],
            exclude: keys.value && (keys.list === keys.value) ? [] : keys.value || []
          }
        ) || []
      )
      setOpened(true)
    } else {
      setOpened(false)
    }
  }, [ query, isActive, handleSetSuggestions, list, options.keys ])

  useEffect(function () {
    if (!isActive) {
      handleSetSuggestions()
      setQuery({ value: input })
      setOpened(false)
    }
  }, [ isActive, input, handleSetSuggestions ])

  useEffect(function () {
    const selected = getSuggestionData(selectedRef.current, options.keys)[0]
    let index = (list || []).findIndex(x => getSuggestionData(x, options.keys)[0].toString() === selected)
    focusedRef.current = setSuggestionFocus(index, listRef, true)
  }, [ suggestions, query, list, selectedRef, listRef, options.keys, isOpened ])

  const handleSelectSuggestion = function (index: number) {
    selectedRef.current = suggestions.flat[index]
    const [ val, lbl ] = getSuggestionData(
      selectedRef.current,
      options.keys,
      { display: [ 'string', 'number' ] }
    )

    if (onChange) {
      matchTypes(val, 'string', 'number', 'boolean')
        ? dispatchValue(val, inputRef, onChange)
        // @ts-ignore
        : onChange(String(val))
      setInput(lbl)
    } else {
      setInput(val)
    }

    setOpened(false)
  }

  /**
   * NATIVE EVENTS
   */
  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    const val = e.currentTarget.value

    if (e.isTrusted) {
      setQuery({ value: val })
      isMobile && setActive(true)
    }

    setInput(val)
  }

  const handleBlur = function (e: FocusEvent | Event) {
    onBlur && onBlur(e)

    handleSetLabel()
    setActive(false)
  }

  const handleKeyDown = function (e: KeyboardEvent) {
    onKeyDown && onKeyDown(e)

    if (
      (e.key === 'ArrowUp' || e.key === 'ArrowDown') &&
      suggestions.flat.length > 0
    ) {
      e.preventDefault()

      if (!isOpened) {
        /** Select textbox text on opening for user be able to replace value */
        inputRef.current && inputRef.current.select()
        setOpened(true)
      } else {
        focusedRef.current = moveSuggestionFocus(e.key === 'ArrowUp' ? -1 : 1, listRef, true)
      }
    } else if (e.key === 'Escape') {
      e.preventDefault()
      setOpened(false)
    }
  }

  const handleMouseUp = function (e: MouseEvent) {
    onMouseUp && onMouseUp(e)

    if (isActive && !isOpened) {
      inputRef.current && inputRef.current.select()
      setOpened(true)
    }

    if (!isOpened) {
      setPlaceholder(input)
      setQuery({value: ''})
      setInput('')
    } else {
      setOpened(false)
      handleSetLabel()
    }

    setActive(true)
  }

  const handleKeyPress = function (e: KeyboardEvent) {
    onKeyPress && onKeyPress(e)

    if (e.key === 'Enter') {
      e.preventDefault()

      if (isOpened) {
        /**
         * focusedindex > 0: user press Enter with selected
         * suggestion. In this case parent component doesn't
         * receive handleKeyPress event
         */
        const index = getFocusedIndex(listRef)

        if (index > -1) {
          handleSelectSuggestion(index)
        }
      } else {
        if (!isActive) {
          setPlaceholder(input)
          setQuery({value: ''})
          setInput('')
        }
        /**
         * Select textbox text on opening for user
         * be able to replace value
         */
        inputRef.current && inputRef.current.select()
        setOpened(true)
      }
    }

    setTimeout(() => !isMobile && setActive(true), 1)
  }

  useSuggestions(inputRef, (isOpened && listRef) || { current: null }, focusedRef, handleSelectSuggestion)

  return [
    {
      ...other,
      readOnly: other.readOnly || isMobile,
      disabled: other.disabled || !list,
      value: input || '',
      placeholder: !list
        ? list === null ? 'Ошибка' : 'Загрузка...'
        : isActive && placeholder ? placeholder : props.placeholder,
      onChange: handleChange,
      onMouseUp: handleMouseUp,
      ...(!isMobile
          ? {
            onBlur: handleBlur,
            onKeyDown: handleKeyDown,
            onKeyPress: handleKeyPress,
          }
          : {
            onBlur: isOpened ? onBlur : handleBlur,
            onKeyDown,
            onKeyPress
          }
      )
    },
    isMobile ? [] : suggestions.map,
    isMobile ? false : isOpened
  ]
}

export default Select
