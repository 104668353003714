import React, { useCallback, useContext, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import classes from "classnames";
import MapView, { MapViewProps } from "./MapView";
import MapDataHandler from "./MapDataHandler";
import { sectionContext } from "../section";
import { createPathURL } from "../helpers";
import { setRedirect } from "../reducers/layout";
import { RouteMatch } from "../types";
import "./map.scss";

interface Props extends MapViewProps {
  className?: string;
  hidden?: boolean;
  onLoad?: () => void;
}

const Map = function (props: Props) {
  const { className, onLoad, onClick, selected, ...other } = props;
  const match: RouteMatch = useRouteMatch();
  const id = Number(match?.params?.id || 0);
  const dispatch = useDispatch();
  const blockRef = useRef<HTMLDivElement | null>(null);
  const { style, offset, scrollTo, onScroll } = useContext(sectionContext);
  const { width, height } = style || {};

  const handleEmulateScroll = useCallback(
    function () {
      if (!props.hidden) {
        scrollTo?.({ scrollTop: 10 });
      }
    },
    [scrollTo, props]
  );

  useLayoutEffect(
    function () {
      onScroll?.(handleEmulateScroll);

      return function () {
        onScroll?.(handleEmulateScroll);
      };
    },
    [onScroll, handleEmulateScroll]
  );

  useLayoutEffect(
    function () {
      handleEmulateScroll();
    },
    [handleEmulateScroll]
  );

  const handleElementClick = useCallback(
    function (e: any) {
      if (onClick) {
        onClick(e);
      } else {
        dispatch(
          setRedirect(
            createPathURL(match?.path, {
              ...match?.params,
              id: e.features[0]?.id,
            }),
            true
          )
        );
      }
    },
    [dispatch, match, onClick]
  );

  return (
    <div
      className={classes("map", className, props.hidden && "-hidden")}
      style={{ width, height }}
      ref={blockRef}
    >
      <MapDataHandler onLoad={onLoad} data={props.data} />
      <MapView
        width={width}
        height={height}
        onClick={handleElementClick}
        selected={selected || id}
        offset={offset}
        {...other}
      />
    </div>
  );
};

export default Map;
