import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { Icon } from "../components";
import { Textbox } from "../controls";
import { setFilter } from "../reducers/settings";
import { Icons } from "../consts";
import { ReduxState, RouteParams } from "../types";

const FilterSearch = function (props: { placeholder?: string }) {
  const page = (useParams() as RouteParams).page;
  const search = useSelector(
    ({ settings }: ReduxState) => settings.filters[page]?.search?.[0]
  );
  const [query, setQuery] = useState<string>("");
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((value: string | undefined) => {
      if (value !== search) {
        dispatch(setFilter(page, "search", value ? [value] : undefined));
      }
    }, 300),
    [dispatch, search, page]
  );

  const handleChange = useCallback(function (e) {
    setQuery(e.currentTarget.value);
  }, []);

  useEffect(
    function () {
      handleUpdate(query);
    },
    [query, handleUpdate]
  );

  return (
    <Textbox
      value={(query || "").toString()}
      onChange={handleChange}
      icon={Icons.Search}
      placeholder={props.placeholder || "Поиск"}
      button={{
        onClick: () => {
          dispatch(setFilter(page, "search", undefined));
          setQuery("");
        },
        children: <Icon type={Icons.Close} />,
        disabled: !query,
      }}
      size={30}
    />
  );
};

export default FilterSearch;
