import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { setRedirect } from "../reducers/layout";
import { clearDatum } from "../reducers/datum";
import { createPathURL } from "../helpers";
import { ReduxState, RouteMatch } from "../types";

/**
 * Хук для загрузки и фильтрации данных.
 * @param { function } loadAction
 * @param { boolean } serverFiltering - флаг фильтрации данных на сервере
 */
const useDataLoading = function (
  loadAction: (batch?: number) => any,
  serverFiltering = true
) {
  const match: RouteMatch = useRouteMatch();
  const { page } = match?.params || { page: "" };
  const filters = useSelector(
    ({ settings }: ReduxState) => settings.filters[page],
    shallowEqual
  );
  const sort = useSelector(
    ({ settings }: ReduxState) => settings.sort[page],
    shallowEqual
  );
  const [_filters, setFilters] = useState(filters);
  const items = useSelector(
    ({ datum }: ReduxState) => datum[page]?.items,
    shallowEqual
  );
  const summary = useSelector(
    ({ datum }: ReduxState) => datum[page]?.summary,
    shallowEqual
  );
  const total = useSelector(({ datum }: ReduxState) => datum[page]?.total);
  const dispatch = useDispatch();

  useEffect(
    function () {
      if (_filters !== filters) {
        match?.params?.id &&
          dispatch(
            setRedirect(
              createPathURL(match?.path, {
                ...match?.params,
                id: undefined,
              })
            )
          );
        dispatch(clearDatum(match?.params?.page || ""));
        setFilters(filters);
      }
    },
    [_filters, filters, dispatch, match]
  );

  useEffect(
    function () {
      serverFiltering && dispatch(loadAction());
    },
    [dispatch, _filters, sort, serverFiltering, loadAction]
  );

  useEffect(
    function () {
      !serverFiltering && dispatch(loadAction());
    },
    [dispatch, loadAction, serverFiltering]
  );

  return [items, total, summary];
};

export default useDataLoading;
