import React from 'react'

interface Props {
  children: any
}

const Layout = function (props: Props) {
  return <div className='layout'>
    { props.children }
  </div>
}

export default Layout
