import React from 'react'
import classes from 'classnames'
import Icon from '../Icon/Icon'
import { Icons } from '../../consts'
import { Value } from '..'
import './status.scss'

interface StatusProps {
  success?: boolean,
  danger?: boolean,
  accent?: boolean,
  soft?: boolean
  children?: any
}

const Status = function (props: StatusProps) {
  const { success, danger, accent, soft } = props

  return <span
    className={classes(
      'status',
      success && '-success',
      danger && '-danger',
      accent && '-accent',
      soft && '-soft'
    )}
  >
    <Icon type={Icons.Tag} />
    <Value value={props.children} />
  </span>
}

export default Status
