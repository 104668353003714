import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import classes from 'classnames'
import { Button, Icon, Icons, SectionTitle, Value } from 'ui-kit'
import { Textbox } from 'ui-kit/controls'
import { FormControl } from 'ui-kit/form'
import { setPrompt, deleteGeoGroup, saveGeoGroup } from 'actions'
import { IGeoGroup } from 'models/IGeoGroup'

interface Props {
  selected?: IGeoGroup
  total?: number
  rootLength: number | undefined
  depth: number
  parentId: number
  query: string
  setQuery: (q: string) => void
}

const GeozonesGroupsHeader = function (props: Props) {
  const { selected, total, rootLength, depth, parentId, query, setQuery } = props
  const [ isSearchActive, setSearchActive ] = useState(false)
  const dispatch = useDispatch()

  /** id === undefined ? создание группы : редактирование */
  const handleSaveGroup = function (group?: IGeoGroup, parentId?: number) {
    dispatch(setPrompt({
      initialState: group,
      component: ({ value, onChange }: any) => <>
        <FormControl
          label={parentId ? 'Название подгруппы' : 'Название геогруппы' }
          value={value?.name || ''}
          onChange={(e: any) => onChange({...value, name: e.currentTarget.value})}
          placeholder='Введите название'
          component={Textbox}
          tabIndex={0}
          size={40}
        />
      </>,
      buttons: [{
        default: true,
        fill: true,
        label: group?.id ? 'Сохранить' : 'Создать',
        props: (g: IGeoGroup) => ({ disabled: !g?.name }),
        callback: (g: IGeoGroup) => {
          g?.name && dispatch(saveGeoGroup(g, parentId))
        }
      }]
    }))
  }

  const handleDeleteGroup = function () {
    selected && dispatch(setPrompt({
      message: 'Вы уверены, что хотите удалить выбранную\nгеогруппу и все группы, входящие в неё?',
      buttons: [{
        fill: true,
        danger: true,
        label: 'Удалить',
        action: deleteGeoGroup(selected)
      }]
    }))
  }

  return <>
    <SectionTitle
      label={<span>
        <span>Всего объектов: </span>
        <Value value={total} />
      </span>}
    >
      Группы
    </SectionTitle>
    <hr/>
    <div className='section-header-buttons'>
      <Button
        icon={Icons.FolderNew}
        onClick={() => handleSaveGroup(undefined, parentId)}
        disabled={total === undefined}
      />
      <Button
        icon={Icons.FolderGroup}
        onClick={() => handleSaveGroup(undefined, selected?.id)}
        disabled={!selected || depth >= 2}
      />
      <Button
        icon={Icons.Edit}
        onClick={() => handleSaveGroup(selected)}
        disabled={!selected}
      />
      <Button
        icon={Icons.Delete}
        onClick={handleDeleteGroup}
        disabled={!selected || !rootLength || (rootLength < 2 && depth === 0)}
      />
      <div className={classes('geozones-group-search', isSearchActive && '-expanded')}>
        <Textbox
          icon={Icons.Search}
          onFocus={() => setSearchActive(true)}
          onBlur={() => setSearchActive(false)}
          placeholder='Поиск по геозонам'
          value={query}
          onChange={e => setQuery(e.currentTarget.value)}
          disabled={!total}
          button={{
            onClick: () => {
              setQuery('')
            },
            children: <Icon type={Icons.Close} />,
            disabled: !query
          }}
        />
      </div>
    </div>
  </>
}

export default GeozonesGroupsHeader
