import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { AutoSizer } from "react-virtualized";
import classes from "classnames";
import Scroller from "../components/Scroller";
import { Button } from "../controls";
import { Icon } from "../components";
import { createPathURL } from "../helpers";
import { setModal, setPrompt, setRedirect } from "../reducers/layout";
import { formContext } from "../form";
import { Icons } from "../consts";
import "./modal.scss";

export const ModalHeader = React.forwardRef(function (
  props: React.PropsWithChildren<any>,
  ref: React.Ref<HTMLDivElement>
) {
  const { children, onClose } = props;
  return (
    <div className={classes("modal-header", onClose && "-close")} ref={ref}>
      {children}

      {onClose && (
        <button className="modal-close" onClick={onClose}>
          <Icon type={Icons.Close} />
        </button>
      )}
    </div>
  );
});

export const ModalFooter = React.forwardRef(function (
  props: React.PropsWithChildren<any>,
  ref: React.Ref<HTMLDivElement>
) {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { isChanged } = useContext(formContext);

  const handleCancelClick = function () {
    if (isChanged) {
      dispatch(
        setPrompt({
          message: "Данные не будут сохранены. Продолжить?",
          buttons: [
            {
              label: "Продолжить",
              danger: true,
              default: true,
              action: [
                setRedirect(
                  createPathURL(match?.path, {
                    ...match?.params,
                    modal: undefined,
                  }),
                  true
                ),
                setModal(),
              ],
            },
          ],
        })
      );
    } else {
      dispatch(
        setRedirect(
          createPathURL(match?.path, { ...match?.params, modal: undefined })
        )
      );
      dispatch(setModal());
    }
  };

  return (
    <div className="modal-footer" ref={ref}>
      {props.children}
      <Button onClick={handleCancelClick}>Отмена</Button>
    </div>
  );
});

export const ModalBody = React.forwardRef(function (
  props: {
    offset: { top: number; bottom: number };
    width?: number | string;
    height?: number | string;
    children: any;
    title?: string;
    stretch?: boolean;
  },
  ref: any
) {
  const { children, width, offset, title, stretch } = props;
  const [height, setHeight] = useState();
  const bodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(
    function () {
      setHeight(
        stretch
          ? "100%"
          : props.height ||
              ref?.current?.offsetHeight ||
              bodyRef.current?.offsetHeight ||
              undefined
      );
    },
    [ref, bodyRef, children, props.height, stretch]
  );

  return (
    <div className="modal-area">
      <Scroller
        offset={{
          top: offset.top,
          bottom: offset.bottom,
        }}
        style={{
          width: width || "100%",
          height: height ? (height || 0) + 1 : "100%",
        }}
      >
        <div
          tabIndex={0}
          className="modal-body"
          ref={ref || bodyRef}
          style={{
            paddingTop: offset.top || 16,
            paddingBottom: offset.bottom || 16,
            minHeight: stretch ? height : "200px",
          }}
        >
          {title && <div className="modal-title">{title}</div>}
          {children}
        </div>
      </Scroller>
    </div>
  );
});

export const ModalAside = function (props: React.PropsWithChildren<any>) {
  return (
    <div className="modal-aside-wrapper">
      <AutoSizer>
        {({ width, height }) => (
          <Scroller style={{ width, height }}>
            <div className="modal-aside">{props.children}</div>
          </Scroller>
        )}
      </AutoSizer>
    </div>
  );
};

export { default } from "./Modal";
export { default as useModalSize } from "./useModalSize";
export { default as useModalData } from "./useModalData";
