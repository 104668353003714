import { API_URL } from 'consts'
import { IGeoZone, IGeoZoneResult } from 'models/IGeoZone'
import { IBoard } from 'models/IBoard'
import { IContent } from 'models/IContent'
import { IGeoGroup } from 'models/IGeoGroup'
import { TSummary, TValue } from '../ui-kit/types'
import { getQueryParams } from '../ui-kit/helpers'

/**
 * Получает список геозон
 * @param {string } token
 * @param { Object } filters - фильтры
 * @param { number[] } filters.groupId - идентификаторы группы
 */
export const fetchGeoZones = async function (token: string, filters?: { [key: string]: TValue[] }) {
  const response = await fetch(`${ API_URL }/Tag/geo${getQueryParams(filters)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return await response.json() as IGeoZoneResult
  } else {
    throw await response.json()
  }
}

/**
 * Получает геогруппы
 * @param { string } token
 */
export const fetchGeoGroups = async function (token: string) {
  const response = await fetch(`${ API_URL }/Tag/geoGroup`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return await response.json() as TSummary
  } else {
    throw await response.json()
  }
}

/**
 * Создает/редактирует геозону
 * @param {string} token
 * @param {IGeoZone} data - данные геозоны
 * @param {number} geoGroupId - id родительской геозоны
 */
export const saveGeoZone = async function (token: string, data: IGeoZone, geoGroupId?: number) {
  const response = await fetch(`${ API_URL }/Tag${data.id ? `/${data.id}` : ''}/geo${getQueryParams({ geoGroupId })}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ token }`
    },
    body: JSON.stringify(data)
  })

  if (response.status === 200) {
    try {
      return await response.json() as IGeoZone
    } catch {
      return data
    }
  } else {
    throw await response.json()
  }
}

/**
 * Получает информацию по табло
 * @param {string} token
 * @param {number } id - идентификатор табло
 */
export const fetchGeoZone = async function (token: string, id: number) {
  const response = await fetch(`${API_URL}/Tag/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 200) {
    return await response.json() as IGeoZone
  } else {
    throw await response.json()
  }
}

/**
 * Удаляет геозону
 * @param {string} token
 * @param {number } id - идентификатор табло
 */
export const deleteGeoZone = async function (token: string, id: number) {
  const response = await fetch(`${API_URL}/Tag/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 200) {
    return true
  } else {
    throw await response.json()
  }
}

/** Получает список табло геозоны */
export const fetchGeoZoneBoards = async function (token: string, id: number) {
  const response = await fetch(`${API_URL}/Tag/geo/${id}/board`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 200) {
    return await response.json() as IBoard[]
  } else {
    throw await response.json()
  }
}

/** Получает список контента геозоны */
export const fetchGeoZoneContent = async function (token: string, id: number) {
  const response = await fetch(`${API_URL}/Tag/geo/${id}/content`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 200) {
    return await response.json() as IContent[]
  } else {
    throw await response.json()
  }
}

/** Создает|обновляет геогруппу */
export const saveGeoGroup = async function (token: string, group: IGeoGroup, parentId?: number) {
  const response = await fetch(`${API_URL}/Tag/geoGroup/${group.id || ''}${getQueryParams({ parentId })}`, {
    method: group.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(group)
  })

  if (!group.id && response.status === 200) {
    return await response.json() as IGeoGroup
  } else if (group.id && response.status === 204) {
    return group
  } else {
    throw await response.json()
  }
}

/** Удаляет геогруппу */
export const deleteGeoGroup = async function (token: string, group: IGeoGroup) {
  const response = await fetch(`${API_URL}/Tag/geoGroup/${group.id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 204 || response.status === 200) {
    return true
  } else {
    throw await response.json()
  }
}

/**
 * Возвращает превью геозоны
 * @param {string} token
 * @param {number} zoneId - идентификатор геозоны
 */
export const fetchGeoZonePreview = async function (token: string, zoneId: number) {
  const response = await fetch(`${ API_URL }/Tag/geo/${ zoneId }/preview`, {
    method: 'GET',
    headers: {
      Accept: 'application/octet-stream',
      Authorization: `Bearer ${token}`,
    }
  })

  if (response.status === 200) {
    return URL.createObjectURL(await response.blob())
  } else {
    throw Error()
  }
}
