import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button } from '../controls'
import { clearFilters } from '../reducers/settings'
import { ReduxState, RouteParams } from '../types'

const FilterFooter = function () {
  const dispatch = useDispatch()
  const page = (useParams() as RouteParams).page
  const filters = useSelector(({ settings }: ReduxState) =>
    settings.filters[page], shallowEqual)

  const isDisabled = useMemo(function () {
    const { search, ...others } = filters || {}
    return !Object.values(others).some(x => Array.isArray(x) && x.length > 0)
  }, [ filters ])

  return <Button
    wide
    white
    onClick={() => dispatch(clearFilters(page))}
    disabled={isDisabled}
  >
    Сбросить фильтр
  </Button>
}

export default FilterFooter
