import {
  API_URL,
  BoardFormat,
  BoardType,
  Cities,
  Page,
  Photo,
  AppIcons,
  LogAction,
} from "consts";
import { fetchBoardImage } from "services/boards";
import { fetchContentFrame } from "services/content";
import { fetchGeoZonePreview } from "services/geozones";
import Messages from "components/Messages";
import MessageForm from "components/MessageForm";
import Infoboards from "components/Infoboards";
import Geozones from "components/Geozones";
import Logs from "components/Logs";
import TagsFilter from "components/Tags/TagsFilter";
import MessagesBoards from "components/Messages/MessagesBoards";
import { CITY, Colors, Icons } from "ui-kit";
import { FilterType } from "ui-kit/consts";
import {
  AppRoutes,
  DatumState,
  DetailsState,
  IFilter,
  ITableSettings,
  PhotosState,
} from "ui-kit/types";
import { IListItem } from "ui-kit/components/List/ListItem";

export const datumState: DatumState = {
  [Page.Messages]: {
    items: undefined,
  },
  [Page.Infoboards]: {
    items: undefined,
  },
  [Page.Geozones]: {
    items: undefined,
  },
  [Page.Logs]: {
    items: undefined,
  },
};

export const detailsState: DetailsState = {
  [Page.Infoboards]: {
    data: undefined,
    content: undefined,
  },
  [Page.Messages]: {
    data: undefined,
    boards: undefined,
  },
  [Page.Geozones]: {
    data: undefined,
  },
  [Page.Logs]: {
    data: undefined,
  },
};

export const photosState: PhotosState = {
  [Photo.BoardCamera]: {
    endpoint: fetchBoardImage,
  },
  [Photo.Frame]: {
    endpoint: fetchContentFrame,
  },
  [Photo.GeoZonePreview]: {
    endpoint: fetchGeoZonePreview,
  },
};

export const layoutState = {
  showFilters: {
    [Page.Infoboards]: true,
    [Page.Messages]: true,
    [Page.Geozones]: true,
    [Page.Logs]: true,
  },
};

export const routes: AppRoutes = {
  nav: {
    image: CITY === Cities.Saratov ? require("assets/saratov_logo.svg") : null,
  },
  pages: [
    {
      url: `/${Page.Messages}`,
      icon: AppIcons.Message,
      title: "Сообщения",
      path: "/:page/:mode/:id?/:tab?",
      component: Messages,
      redirect: `/${Page.Messages}/list`,
      routes: [
        {
          path: "/:page/:mode/:id?/:tab?/create",
          component: MessageForm,
        },
        {
          path: "/:page/:mode/:id?/:tab?/edit",
          component: MessageForm,
          props: { edit: true },
        },
        {
          path: "/:page/:mode/:id/:tab?/add-boards/:ftab?",
          component: MessagesBoards,
        },
      ],
    },
    {
      url: `/${Page.Infoboards}`,
      icon: AppIcons.Infoboard,
      title: "Табло",
      path: "/:page/:mode/:id?/:tab?",
      component: Infoboards,
      redirect: `/${Page.Infoboards}/list`,
    },
    {
      url: `/${Page.Geozones}`,
      icon: AppIcons.Geozone,
      title: "Геозоны",
      path: [
        "/:page/:mode/:id?/:tab?/create",
        "/:page/:mode/:id?/:tab?/edit",
        "/:page/:mode/:id?/:tab?",
      ],
      component: Geozones,
      redirect: `/${Page.Geozones}/list`,
    },
    {
      url: `/${Page.Logs}`,
      icon: Icons.User,
      title: "Логи",
      path: ["/:page/:id?/:tab?"],
      component: Logs,
      redirect: `/${Page.Logs}`,
    },
  ],
  redirect: `/${Page.Messages}`,
};

export const boardTypeList =
  CITY === Cities.Saratov
    ? [
        {
          value: BoardType.Common,
          label: "Стационарное",
          icon: AppIcons.BoardType(BoardType.Common),
        },
        {
          value: BoardType.Vehicle,
          label: "Знак Переменной Информации",
          icon: AppIcons.BoardType(BoardType.Vehicle),
        },
      ]
    : [
        {
          value: BoardType.Common,
          label: "Стационарное",
          icon: AppIcons.BoardType(BoardType.Common),
        },
        {
          value: BoardType.Vehicle,
          label: "Мобильное",
          icon: AppIcons.BoardType(BoardType.Vehicle),
        },
        {
          value: BoardType.Truck,
          label: "Прицеп",
          icon: AppIcons.BoardType(BoardType.Truck),
        },
        {
          value: BoardType.RoadSign,
          label: "УДЗ",
          icon: AppIcons.BoardType(BoardType.RoadSign),
        },
      ];

export const boardFormatList = [
  {
    value: BoardFormat.OneOne,
    label: "Формат табло 1:1",
    icon: AppIcons.BoardFormat(BoardFormat.OneOne),
  },
  {
    value: BoardFormat.TwoThree,
    label: "Формат табло 2:3",
    icon: AppIcons.BoardFormat(BoardFormat.TwoThree),
  },
  {
    value: BoardFormat.FourThree,
    label: "Формат табло 4:3",
    icon: AppIcons.BoardFormat(BoardFormat.FourThree),
  },
  {
    value: BoardFormat.FiveOne,
    label: "Формат табло 5:1",
    icon: AppIcons.BoardFormat(BoardFormat.FiveOne),
  },
  {
    value: BoardFormat.ThreeHalfOne,
    label: "Формат табло 3.6:1",
    icon: AppIcons.BoardFormat(BoardFormat.ThreeHalfOne),
  },
  {
    value: BoardFormat.Unknown,
    label: "Неизвестный формат",
    icon: AppIcons.BoardFormat(BoardFormat.Unknown),
    hideMissing: true,
  },
];

export const suggesterRenderer = (
  s: { [key: string]: string },
  keys?: [any[], boolean]
): IListItem[] => {
  return Object.keys(s)
    .filter((id) => !keys || keys[0].some((x) => x.value === id) === keys[1])
    .map((key) => ({ value: key, label: s[key] }));
};

export const filterLists: {
  [key in Page]: IFilter[];
} = {
  [Page.Infoboards]: [
    {
      label: "Статус",
      name: "status",
      single: true,
      list: [
        { value: 0, label: "Работает", icon: Icons.Tag, iconColor: "#6CC700" },
        {
          value: 1,
          label: "Не работает",
          icon: Icons.Tag,
          iconColor: "#FD3D00",
        },
      ],
    },
    {
      label: "Тип",
      name: "types",
      list: boardTypeList,
    },
    {
      label: "Формат",
      name: "formats",
      list: boardFormatList,
    },
    {
      label: "Теги",
      name: "tags",
      type: FilterType.Component,
      component: TagsFilter,
    },
  ],
  [Page.Messages]: [
    {
      label: "Статус",
      name: "publication",
      single: true,
      list: [
        { value: 1, label: "Опубликовано", icon: AppIcons.Published(true) },
        { value: 0, label: "Не опубликовано", icon: AppIcons.Published(false) },
      ],
    },
    {
      label: "Тип",
      name: "types",
      list: boardTypeList,
    },
    {
      label: "Формат",
      name: "formats",
      list: boardFormatList,
    },
    {
      type: FilterType.Group,
      label: "Разное",
      list: [
        { value: "my", label: "Созданные мной", icon: Icons.User },
        { value: "removed", label: "В архиве", icon: Icons.Archive },
      ],
    },
    {
      label: "Теги",
      name: "tags",
      type: FilterType.Component,
      component: TagsFilter,
    },
  ],
  [Page.Geozones]: [],
  [Page.Logs]: [
    {
      label: "Дата",
      name: "timestamp",
      type: FilterType.Date,
    },
    {
      label: "Тип",
      name: "types",
      list: boardTypeList,
    },
    {
      label: "Пользователи",
      name: "userId",
      type: FilterType.Suggestions,
      search: { placeholder: "Имя пользователя" },
      endpointURL: `${API_URL}/user/suggestions`,
      renderer: suggesterRenderer,
      icon: Icons.User,
    },
    {
      label: "Табло",
      name: "boardId",
      type: FilterType.Suggestions,
      search: { placeholder: "Номер табло" },
      endpointURL: `${API_URL}/board/suggestions`,
      renderer: suggesterRenderer,
      icon: AppIcons.BoardType(BoardType.Common),
    },
    {
      label: "Контент",
      name: "contentId",
      type: FilterType.Suggestions,
      search: { placeholder: "Сообщение" },
      endpointURL: `${API_URL}/content/suggestions`,
      renderer: suggesterRenderer,
      icon: AppIcons.BoardFormat(BoardFormat.Unknown),
    },
    {
      label: "Статус",
      name: "action",
      list: [
        {
          value: LogAction.Create,
          label: "Создание",
          icon: Icons.Tag,
          iconColor: Colors.Green,
        },
        {
          value: LogAction.Update,
          label: "Редактирование",
          icon: Icons.Tag,
          iconColor: Colors.Blue,
        },
        {
          value: LogAction.Delete,
          label: "Удаление",
          icon: Icons.Tag,
          iconColor: Colors.Red,
        },
        {
          value: LogAction.Publish,
          label: "Публикация",
          icon: Icons.Tag,
          iconColor: Colors.Green,
        },
        {
          value: LogAction.Unpublish,
          label: "Снятие с публикаци",
          icon: Icons.Tag,
          iconColor: Colors.Red,
        },
        {
          value: LogAction.BoardsUpdate,
          label: "Изменение табло",
          icon: Icons.Tag,
          iconColor: Colors.Blue,
        },
      ],
    },
  ],
};

export const tableColumns: ITableSettings = {
  [Page.Infoboards]: [
    { width: 35 },
    { width: 90 },
    { width: 120 },
    { width: 200, resizable: true },
    { width: 90 },
  ],
  [Page.Messages]: [{ width: 250, resizable: true }],
  [Page.Geozones]: [
    { width: 60 },
    { width: 250, resizable: true },
    { width: 150, resizable: true },
    { width: 80 },
  ],
  [Page.Logs]: [
    { width: 130 },
    { width: 210, resizable: true },
    { width: 220 },
    { width: 100 },
    { width: 360, resizable: true },
  ],
};
