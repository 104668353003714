import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Section  from 'ui-kit/section'
import { AppLoader } from 'ui-kit/app'
import { Button } from 'ui-kit/controls'
import GeozonesGroup from './GeozonesGroup'
import GeozonesGroupsHeader from './GeozonesGroupsHeader'
import { toggleArray } from 'ui-kit/helpers'
import { clearFilters, setFilter } from 'ui-kit/reducers/settings'
import { setDatumAppendix } from 'ui-kit/reducers/datum'
import { Page } from 'consts'
import { ReduxState, RouteParams } from 'ui-kit/types'
import { IGeoGroup } from 'models/IGeoGroup'

const GeozonesGroups = function () {
  const dispatch = useDispatch()
  const page = (useParams() as RouteParams).page as Page
  const filters = useSelector(({ settings }: ReduxState) =>
    settings.filters[Page.Geozones]?.groupId, shallowEqual)
  const selectedGroupId: number = useSelector(( { datum }: ReduxState) =>
    datum[Page.Geozones].selectedGroupId)
  const summary: IGeoGroup[] | null | undefined = useSelector(({ datum }: ReduxState) =>
    datum[Page.Geozones].summary, shallowEqual)
  const [ selected, setSelected ] = useState<undefined | IGeoGroup>()
  const [ selectedParams, setSelectedParams ] = useState({ depth: 0, parentId: 0 })
  const [ query, setQuery ] = useState('')

  const total = useMemo(function () {
    let res = 0
    if (!summary) { return summary}

    const mapper = function (items: IGeoGroup[] | null) {
      if (!items) return
      res += items.length

      items.forEach(x => mapper(x.geoGroups))
    }

    mapper(summary)
    return res
  }, [ summary ])

  const groupsList = useMemo(function () {
    if (!query) return summary

    const filterer = function (
      items: IGeoGroup[] | null | undefined
    ): IGeoGroup[] | null | undefined {
      if (!items) return items
      let res: IGeoGroup[] = []
      for (let i = 0; i < items.length; i++) {
        if (
          items[i].name.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          filterer(items[i].geoGroups)?.length
        ) {
          res.push({
            ...items[i],
            geoGroups: filterer(items[i].geoGroups) || null
          })
        }
      }

      return res
    }

    return filterer(summary)
  }, [ summary, query ])

  useEffect(function () {
    if (summary?.length) {
      const finder = function (
        items: IGeoGroup[],
        depth = 0,
        parentId = 0
      ): [IGeoGroup | undefined, number, number]  {
        for (let i = 0; i < items.length; i++) {
          if (items[i].id === selectedGroupId) return [ items[i], depth, parentId ]
          const [ r, d, p ] = finder(items[i].geoGroups || [], depth + 1, items[i].id)
          if (r) return [ r, d, p ]
        }
        return [ undefined, depth, 0 ]
      }

      const [ item, depth, parentId ] = finder(summary)
      if (selectedGroupId && item) {
        setSelected(item)
        setSelectedParams({ depth, parentId})
      } else {
        dispatch(setDatumAppendix(Page.Geozones, 'selectedGroupId', summary[0].id))
      }
    } else {
      setSelected(undefined)
      setSelectedParams({ depth: 0, parentId: 0})
    }
  }, [ dispatch, selectedGroupId, summary ])

  const handleCheck = useCallback(function (id: number, value: boolean) {
    const payload = toggleArray(filters || [], id, value)
    dispatch(setFilter(Page.Geozones, 'groupId', payload.length ? payload : undefined))
  }, [ filters, dispatch])

  const handleClick = useCallback(function (id: number) {
    dispatch(setDatumAppendix(Page.Geozones, 'selectedGroupId', id))
  }, [ dispatch ])

  return <Section
    header={ GeozonesGroupsHeader }
    headerProps={{
      selected,
      total,
      rootLength: summary?.length,
      query,
      setQuery,
      ...selectedParams
    }}
    footer={ () => (
      <Button
        wide
        white
        onClick={() => dispatch(clearFilters(page))}
        disabled={!Object.values(filters || {}).some(x => x !== undefined)}
      >
        Сбросить выбранные группы
      </Button>
    ) }
  >
    {groupsList
      ? <div className='list-items'>
        { groupsList.map(group => (
          <GeozonesGroup
            { ...group }
            key={ group.id }
            selected={ selected }
            filters={ filters }
            depth={0}
            onCheck={ handleCheck }
            onClick={ handleClick }
            query={ query }
          />
        )) }
      </div>
      : groupsList !== null && <AppLoader />
    }
  </Section>
}

export default GeozonesGroups
