import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import useEventListener from "@use-it/event-listener";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import {
  ModalBody,
  ModalHeader,
  useModalData,
  useModalSize,
} from "ui-kit/modal";
import {
  Value,
  Button,
  Icons,
  SectionTitle,
  List,
  Tabs,
  AppMessage,
} from "ui-kit";
import { AppLoader } from "ui-kit/app";
import { Textbox } from "ui-kit/controls";
import TagsEditForm from "./TagsEditForm";
import { useForm } from "ui-kit/form";
import { createPathURL } from "ui-kit/helpers";
import {
  loadTags,
  setModal,
  setPrompt,
  setRedirect,
  saveTag,
  deleteTag,
} from "actions";
import { AppIcons, tagsColor } from "consts";
import { ITag } from "models/ITag";
import { ReduxState, RouteMatch } from "ui-kit/types";
import "./tags.scss";

const calculateWidth = function () {
  return Math.max(440, Math.min(1020, window.innerWidth - 280 - 100));
};

type TagsData = {
  value: ITag[];
  onSubmit: (value: ITag[]) => void;
};

const Tags = function () {
  const props = useModalData<TagsData>(
    ({ layout }: ReduxState) => layout.modal
  );
  const { value, onSubmit } = props || {};

  const match: RouteMatch = useRouteMatch();
  const dispatch = useDispatch();
  const headerMainRef = useRef<HTMLDivElement | null>(null);
  const headerAsideRef = useRef<HTMLDivElement | null>(null);
  const offsetMain = useModalSize(headerMainRef, null);
  const offsetAside = useModalSize(headerAsideRef, null);
  const tags = useSelector(
    ({ datum }: ReduxState) => datum.tags?.items,
    shallowEqual
  );
  const [query, setQuery] = useState("");
  const [width, setWidth] = useState(calculateWidth());

  const { onExit, state, onChange } = useForm(
    undefined,
    null,
    useMemo(
      function () {
        return { value };
      },
      [value]
    )
  );

  useEffect(
    function () {
      !tags && dispatch(loadTags());
    },
    [dispatch, tags]
  );

  useEffect(
    function () {
      if (!onSubmit && match?.params?.modal === "@tags") {
        onExit?.();
      }
    },
    [onSubmit, onExit, match]
  );

  const handleSetWidth = useCallback(function () {
    setWidth(calculateWidth());
  }, []);

  const handleExit = function () {
    onExit(undefined, setModal());
  };

  const handleSubmit = function () {
    onSubmit?.(state?.value);
    dispatch(
      setRedirect(
        createPathURL(match?.path, { ...match?.params, modal: undefined }),
        true
      )
    );
    dispatch(setModal());
  };

  const handleEditTag = useCallback(
    function (tag?: ITag) {
      dispatch(
        setPrompt({
          initialState: tag || {
            color: Math.floor(Math.random() * tagsColor.length),
          },
          component: TagsEditForm,
          buttons: [
            {
              props: (s: ITag) => ({ disabled: !s.value || isEqual(s, tag) }),
              default: true,
              fill: true,
              label: tag?.id ? "Cохранить" : "Создать",
              callback: (s: ITag) => {
                dispatch(saveTag(s));
              },
            },
          ],
        })
      );
    },
    [dispatch]
  );

  const handleDeleteTag = useCallback(
    function (tag: ITag) {
      dispatch(
        setPrompt({
          message: `Вы уверены, что хотите удалить тег «${tag.value}»?`,
          buttons: [
            {
              danger: true,
              label: "Удалить",
              action: deleteTag(tag.id),
            },
          ],
        })
      );
    },
    [dispatch]
  );

  useEventListener("resize", handleSetWidth, window);

  const sortedTags = useMemo(
    function () {
      if (!tags) return tags;

      const sorted = sortBy(tags, "value");

      /** Move digital tags at the end of the list */
      const letterIndex = sorted.findIndex((x) => x.value.charCodeAt(0) >= 97);
      return [
        ...sorted.slice(letterIndex),
        ...sorted.slice(0, letterIndex - 1),
      ];
    },
    [tags]
  );

  const tagsList = useMemo(
    function () {
      if (!sortedTags) return sortedTags;

      const q = query.toLowerCase();
      return sortedTags
        .filter((tag) => tag.value.toLowerCase().indexOf(q) > -1)
        .map((tag) => ({
          label: tag.value,
          value: tag.id,
          icon: tag.isGeo ? AppIcons.Geozone : Icons.Tag,
          iconColor: tagsColor[tag.color],
          controls: (
            <div className="tags-controls">
              <Tabs blur>
                <Button
                  clear
                  small
                  icon={Icons.Edit}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleEditTag(tag);
                  }}
                />
                <Button
                  clear
                  small
                  icon={Icons.Delete}
                  danger
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleDeleteTag(tag);
                  }}
                />
              </Tabs>
            </div>
          ),
        }));
    },
    [query, sortedTags, handleEditTag, handleDeleteTag]
  );

  const handleDelete = useCallback(
    function (id: number) {
      onChange?.("value", (value?: ITag[]) =>
        value?.filter((x) => x.id !== id)
      );
    },
    [onChange]
  );

  const handleChangeTags = useCallback(
    function (ids: number[] = []) {
      onChange?.("value", (value?: ITag[]) => {
        const valueIds = value?.map((x) => x.id) || [];
        const newIds = ids.filter((x) => !valueIds.includes(x));
        const newTags = tags ? tags.filter((x) => newIds.includes(x.id)) : [];

        return [
          ...(value || []).filter((x) => ids.includes(x.id)),
          ...(newTags || []),
        ];
      });
    },
    [tags, onChange]
  );

  const valueList = useMemo(
    function () {
      if (!state?.value) return [];

      return state.value.map((tag: ITag) => ({
        label: tag.value,
        value: tag.id,
        icon: tag.isGeo ? AppIcons.Geozone : Icons.Tag,
        iconColor: tagsColor[tag.color],
        onRemove: () => handleDelete(tag.id),
      }));
    },
    [state, handleDelete]
  );

  return (
    <>
      <div className="modal-column">
        {/* @ts-ignore */}
        <ModalHeader ref={headerMainRef}>
          {/* @ts-ignore */}
          <SectionTitle
            label={<Value value={tags ? `Найдено: ${tags.length}` : tags} />}
          >
            Список тегов
          </SectionTitle>
          {/* @ts-ignore */}
          <Button
            fill
            label="Добавить тег"
            icon={Icons.Plus}
            onClick={() => handleEditTag()}
            disabled={!tagsList}
          />
          {/* @ts-ignore */}
          <Textbox
            icon={Icons.Search}
            placeholder="Поиск по тегам"
            size={30}
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
          />
        </ModalHeader>
        {/* @ts-ignore */}
        <ModalBody offset={offsetMain} width={width}>
          <div className="tags">
            {tagsList ? (
              tagsList.length > 0 ? (
                //@ts-ignore
                <List
                  inline={220}
                  list={tagsList}
                  value={(state?.value || []).map((x: ITag) => x.id)}
                  onChange={handleChangeTags}
                />
              ) : (
                //@ts-ignore
                <AppMessage>Ничего не найдено.</AppMessage>
              )
            ) : (
              //@ts-ignore
              <AppLoader />
            )}
          </div>
        </ModalBody>
      </div>
      <div className="modal-column">
        {/* @ts-ignore */}
        <ModalHeader ref={headerAsideRef} onClose={handleExit}>
          {/* @ts-ignore */}
          <Button icon={Icons.Save} onClick={handleSubmit} />
        </ModalHeader>
        {/* @ts-ignore */}
        <ModalBody offset={offsetAside} width={280}>
          {/* @ts-ignore */}
          <List label="Выбранные теги" list={valueList} />
        </ModalBody>
      </div>
    </>
  );
};

export default Tags;
