import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as PIXI from 'pixi.js'
import { useDispatch } from 'react-redux'
import classes from 'classnames'
import useEventListener from '@use-it/event-listener'
import {
  ModalBody,
  ModalHeader,
  useModalData,
  useModalSize
} from 'ui-kit/modal'
import { Button, Image, SectionTitle, Icons } from 'ui-kit'
import { useAnimationFrame } from 'ui-kit/hooks'
import usePreviewFrames, { TPreviewSource } from './usePreviewFrames'
import { setModal } from 'actions'
import { applyEffects, setVideoFrame, initFrames } from './helpers'
import { BoardFormat, FrameKind } from 'consts'
import { IFrame } from 'models/IFrame'
import { ReduxState } from 'ui-kit/types'
import './message-preview.scss'

type ModalData = {
  frames: IFrame[],
  format: BoardFormat
}

const MessagePreview = function () {
  const modalData = useModalData<ModalData>(({ layout }: ReduxState) => layout.modal)
  const dispatch = useDispatch()
  const headerRef = useRef<HTMLDivElement | null>(null)
  const offset = useModalSize(headerRef, null)
  const [ maxHeight, setMaxHeight ] = useState(Math.max(Math.min(400, window.innerHeight - 200), 110))
  const { frames, format } = modalData || {}
  const positionRef = useRef(0)
  const timerRef = useRef(0)
  const progressRef = useRef<HTMLSpanElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const switchRef = useRef(-1)
  const framesRef = useRef<(TPreviewSource | undefined)[]>([])
  const [ pixi, setPixi ] = useState<PIXI.Application | null>(null)
  const [ isPlaying, setPlaying ] = useState(false)
  const [ isLocked, setLocking ] = useState(false)
  const [ isButtonVisible, setButtonVisibility ] = useState(true)

  const style = useMemo(function () {
    const h = maxHeight

    switch (format) {
      case BoardFormat.TwoThree:
        return { width: h / 3 * 2, height: h }
      case BoardFormat.FourThree:
        return { width: h / 3 * 4, height: h }
      case BoardFormat.FiveOne:
        const width = Math.min(window.innerWidth - 100, h / 2 * 5)
        return { width, height: width / 5 }
      case BoardFormat.ThreeHalfOne:
        return { width: h * 3.6, height: h }
      default:
        return { width: h, height: h }
    }
  }, [ format, maxHeight ])

  const [ sources, total ] = usePreviewFrames(frames, style)

  const setProgress = useCallback(function (position: number) {
    positionRef.current = position
    if (progressRef.current) {
      progressRef.current.style.width = `${positionRef.current / total * 100}%`
    }
  }, [ progressRef, positionRef, total ])

  /****************************************************************************
   * PIXI initialisation
   ****************************************************************************/
  useEffect(function () {
    if (pixi) {
      wrapperRef.current?.appendChild(pixi.view)
    }

    return function () {
      if (pixi) {
        pixi.view.parentElement?.removeChild(pixi.view)
        pixi.destroy()
      }
    }
  }, [ wrapperRef, pixi ])

  useEffect(function () {
    if (wrapperRef.current) {
      setPixi(new PIXI.Application({
        ...style,
        resizeTo: wrapperRef.current,
        antialias: true
      }))
    }

    return function () {
      setPixi(null)
    }
  }, [ wrapperRef, style ])

  /****************************************************************************
   * Manual sequence frame preview
   ****************************************************************************/
  const handlePreviewFrame = useCallback(function (position: number) {
    if (sources && pixi) {
      let prev = -1
      let next = 0
      for (let i = 0; i < sources.length; i++) {
        if (sources[i].time.start < position) next = i
      }
      for (let i = 0; i < sources.length; i++) {
        if (sources[i].time.start < sources[next].time.start) prev = i
      }

      sources[prev] && pixi.stage.addChild(sources[prev].sprite)
      framesRef.current = [sources[prev], sources[next]]
      framesRef.current.forEach(function (r) {
        if (r) {
          r.kind === FrameKind.Video && (
            setTimeout(setVideoFrame, 10, position, r)
          )
        }
      })
      initFrames(position - (framesRef.current[1]?.time.start || 0), framesRef.current, pixi)
      setProgress(position)
    }
  }, [ sources, pixi, setProgress ])

  const handleMouseDown = function (e: any) {
    if (!sources) return

    const { left, width } = e.currentTarget.getBoundingClientRect()
    setLocking(true)

    const setPosition = function (e: MouseEvent) {
      handlePreviewFrame(Math.max(0, Math.min(width, e.pageX - left)) / width * total)
    }

    document.addEventListener('mousemove', setPosition)
    document.addEventListener('mouseup', function () {
      setLocking(false)
      document.removeEventListener('mousemove', setPosition)
    }, { once: true })

    setPosition(e)
  }

  /****************************************************************************
   * Initial first frame render
   ****************************************************************************/
  useEffect(function () {
    if (sources?.length) {
      handlePreviewFrame(positionRef.current)
    }
  }, [ pixi, sources, handlePreviewFrame, positionRef ])

  /****************************************************************************
   * Playing frame sequence
   ****************************************************************************/
  const getSwitchTime = useCallback(function () {
    if (sources) {
      let index = sources.findIndex(x => x.time.start > positionRef.current)
      if (index === -1) { index = sources.length - 1 }
      return (sources[index]?.time.start || 0)
    }

    return 0
  }, [ sources, positionRef ])

  const getFrame = useCallback(function () {
    if (sources) {
      let index = sources.findIndex(x => x.time.start > positionRef.current) - 1
      if (index === -2) { index = sources.length - 1 }
      return sources[index]
    }

    return undefined
  }, [ sources, positionRef ])

  const switchFrames = useCallback(function () {
    if (pixi) {
      switchRef.current = getSwitchTime()
      const frame = getFrame()
      framesRef.current = [framesRef.current[1], frame]
      initFrames(
        positionRef.current - (frame?.time.start || 0),
        framesRef.current,
        pixi
      )
    }

  }, [ getSwitchTime, getFrame, positionRef, pixi ])

  const handleRequestFrame = useCallback(function (delta: number) {
    if (total && sources) {
      const position = positionRef.current + delta

      if (position < total) {
        setProgress(position)
        if (switchRef.current < total) {
          if (position >= switchRef.current) switchFrames()
        } else {
          if (position < switchRef.current % total) {
            switchRef.current = switchRef.current % total
          }
        }
      } else {
        setProgress(position % total)
        switchFrames()
      }

      if (pixi) {
        const position = positionRef.current - (framesRef.current[1]?.time.start || 0)
        if (position >= 0 && position < (framesRef.current[1]?.time.transition || 0) + 100) {
          applyEffects(position, framesRef.current, pixi)
        }
      }
    }
  }, [ positionRef, total, sources, setProgress, switchRef, switchFrames, pixi ])

  useEffect(function () {
    if (isPlaying && !isLocked) {
      switchRef.current = getSwitchTime()
    }
  }, [ isPlaying, isLocked, getSwitchTime ])

  useEffect(function () {
    if (sources) {
      sources.forEach((s, i) => {
        if (s?.kind === FrameKind.Video) {
          if (isPlaying && !isLocked) {
            (s.source as HTMLVideoElement)?.play()
          } else {
            (s.source as HTMLVideoElement)?.pause()
          }
        }
      })
    }
  }, [ isPlaying, isLocked, sources, getSwitchTime ])

  useAnimationFrame(isPlaying && !isLocked ? handleRequestFrame : null)

  /****************************************************************************
   * Handlers to show/hide play button
   ****************************************************************************/
  useEffect(function () {
    if (isPlaying) {
      timerRef.current = window.setTimeout(function () {
        setButtonVisibility(false)
      }, 100)
    } else {
      clearTimeout(timerRef.current)
      setButtonVisibility(true)
    }
  }, [ isPlaying ])

  const handleMouseMove = useCallback(function () {
    if (isPlaying) {
      clearTimeout(timerRef.current)
      setButtonVisibility(true)
      timerRef.current = window.setTimeout(function () {
        setButtonVisibility(false)
      }, 1500)
    }
  }, [ timerRef, isPlaying ])

  useEventListener('mousemove', handleMouseMove, pixi?.view || null)

  /***/

  const handleExit = function () {
    dispatch(setModal())
  }

  useEventListener('resize', useCallback(function () {
    setMaxHeight(Math.max(Math.min(400, window.innerHeight - 200), 110))
  }, []), window)

  return <>
    <ModalHeader ref={headerRef} onClose={handleExit}>
      <SectionTitle>
        Просмотр сообщения
      </SectionTitle>
    </ModalHeader>
    <ModalBody
      offset={offset}
      width={Math.max(500, style.width + 40)}
    >
      <div className='message-preview-wrapper' style={style} ref={wrapperRef}>
        <Image src={undefined} id={0} style={style} className={classes(sources && '-hidden')}/>
        { sources && <Button
          className={classes(!isButtonVisible && '-hidden')}
          icon={isPlaying ? Icons.Pause : Icons.Play}
          onClick={() => setPlaying(isPlaying => !isPlaying)}
        />}
      </div>
      <div
        className={classes('message-preview-progress', !sources && '-loading')}
        onMouseDown={handleMouseDown}
      >
        <span ref={progressRef} />
      </div>
    </ModalBody>
  </>
}

export default MessagePreview
