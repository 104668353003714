import { getQueryParams } from 'ui-kit/helpers'
import { API_URL, BoardType, IMAGE_API_URL } from 'consts'
import { IBoard, IBoardResult } from 'models/IBoard'
import { TSummary, TValue } from 'ui-kit/types'
import { IContent } from 'models/IContent'
import { ITag } from 'models/ITag'

/**
 * Получает саммари по табло
 * @param { string } token
 */
export const fetchSummary = async function (token: string) {
  const response = await fetch(`${ API_URL }/Board/summary`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return await response.json() as TSummary
  } else {
    throw await response.json()
  }
}

/**
 * Получает список табло
 * @param { string } token
 * @param { Object } filters - фильтры
 * @param { string } filters.search - поисковый запрос
 * @param { number } filters.formats - формат табло
 * @param { number } filters.status - статус табло (0 - работает, 1 - не работае)
 * @param { number } filters.types - тип табло
 * @param { string } filters.tags - тег
 */
export const fetchBoards = async function (token: string, filters?: { [key: string]: TValue[] }) {
  const params = {
    ...filters,
    tags: (filters?.tags as unknown as ITag[])?.map((x) => x.id)
  }

  const response = await fetch(`${ API_URL }/Board${ getQueryParams(params) }`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return await response.json() as IBoardResult
  } else {
    throw await response.json()
  }
}

/**
 * Получает информацию по табло
 * @param {string} token
 * @param {number } id - идентификатор табло
 */
export const fetchBoard = async function (token: string, id: number) {
  const response = await fetch(`${API_URL}/Board/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 200) {
    return await response.json() as IBoard
  } else {
    throw await response.json()
  }
}

/**
 * Возвращает фото с камеры табло
 * @param {string} token
 * @param {number} id - идентификатор табло
 * @param {object} params - параметры
 * @param {boolean} params.realTime - признак фото в реальном времени
 * @param {BoardType} params.type - тип табло
 */
export const fetchBoardImage = async function (
  token: string,
  id: number,
  params?: { realTime: boolean, type: BoardType }
) {
  const { realTime, type } = params || {}
  const response = await fetch(`${ type === 1 || type === 2 ? API_URL : IMAGE_API_URL }/Board/${id}/cameraimage${realTime ? '?realTime=true' : ''}`, {
    method: 'GET',
    headers: {
      Accept: 'application/octet-stream',
      Authorization: `Bearer ${token}`,
    }
  })

  if (response.status === 200) {
    return URL.createObjectURL(await response.blob())
  } else {
    throw await response.json()
  }
}

/**
 * Возращает контент для табло
 * @param {string} token
 * @param {number} id - идентификатор табло
 */
export const fetchBoardContent = async function (token: string, id: number) {
  const response = await fetch(`${API_URL}/Board/${id}/content`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 200) {
    return await response.json() as IContent[]
  } else {
    throw await response.json()
  }
}

export const updateBoardSoftware = async function (token: string, id: number) {
  const response = await fetch(`${API_URL}/Board/${id}/software/update`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 200) {
    return await response.json() as IContent[]
  } else {
    throw await response.json()
  }
}
