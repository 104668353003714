import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Map } from 'mapbox-gl'
import { MAP_STYLE_URL, MAP_TOKEN } from '../consts'
import { FeatureCollection } from 'geojson'
import {
  addLineLayers,
  addPointLayers,
  addPolygonLayers,
  getFitBounds
} from './MapView.helpers'

const useMapSnapshot = function (
  data: (FeatureCollection | FeatureCollection[]) | null | undefined,
  width: number,
  height: number,
  padding?: number[]
): [
  string | null | undefined,
  (() => void) | undefined
] {
  const mapRef = useRef<HTMLDivElement | null>(null)
  const [ map, setMap ] = useState<Map | null>(null)
  const [ image, setImage ] = useState<string | null | undefined>(null)

  useEffect(function () {
    mapRef.current = document.createElement('div')
    mapRef.current.style.width = `${width}px`
    mapRef.current.style.height = `${height}px`
    mapRef.current.style.position = 'fixed'
    mapRef.current.style.zIndex = '-1'
    mapRef.current.style.visibility = 'hidden'
    document.body.prepend(mapRef.current)

    const map = new Map({
      container: mapRef.current,
      style: MAP_STYLE_URL,
      accessToken: MAP_TOKEN,
      dragRotate: false,
      preserveDrawingBuffer: true
    })

    map.on('load', () => {
      if (mapRef.current) {
        setMap(map)
      }
    })

    return function () {
      mapRef.current && document.body.removeChild(mapRef.current)
      mapRef.current = null
      setMap(null)
    }
  }, [ width, height ])

  /** Convert data to Feature collection */
  const mapData = useMemo(function () {
    const combined = Array.isArray(data) ? data : [ data ]

    return combined
      .map(data => ({
        data,
        type: (data && data.features[0]?.geometry.type) || null
      }))
  }, [ data ])

  /** Render data layers */
  useEffect(function () {
    if (!map) return

    mapData.forEach((json, index) => {
      if (json.data) {
        map.addSource(`data${ index }`, {
          type: 'geojson',
          data: json.data
        })

        if (json.type === 'Point' || json.type === 'MultiPoint') {
          addPointLayers(map, index)
        } else if (json.type === 'Polygon' || json.type === 'MultiPolygon') {
          addPolygonLayers(map, index)
        } else if (json.type === 'LineString' || json.type === 'MultiLineString') {
          addLineLayers(map, index)
        }
      }
    })

    return function () {
      if (map) {
        mapData.forEach((json, index) => {
          if (map.getSource(`data${ index }`)) {
            map.getLayer(`layer${ index }`) && map.removeLayer(`layer${ index }`)
            map.getLayer(`layer${ index }_line`) && map.removeLayer(`layer${ index }_line`)
            map.getLayer(`layer${ index }_icons`) && map.removeLayer(`layer${ index }_icons`)
            map.removeSource(`data${ index }`)
          }
        })
      }
    }
  }, [ map, mapData ])

  /** Set map boundaries */
  useEffect(function () {
    if (map && mapData) {
      // Не меняем границы, пока что-то из данных загружается
      if (mapData.some(l => l.data === undefined)) {
        return
      }

      const fitBounds = getFitBounds(mapData)

      if (fitBounds) {
        map.fitBounds(fitBounds, {
          padding: {
            top: padding?.[0] || 0,
            bottom: padding?.[2] || padding?.[0] || 0,
            left: padding?.[3] || padding?.[1] || padding?.[0] || 0,
            right: padding?.[1] || padding?.[0] || 0
          },
          animate: false
        })
      }
    }
  }, [ map, mapData, padding ])

  useEffect(function () {
    if (map) {
      if (mapData?.every(x => x.data)) {
        setImage(undefined)
        setTimeout(() => {
          setImage(map.getCanvas().toDataURL())
        }, 1000)
      } else {
        setImage(null)
      }
    }
  }, [ map, mapData ])

  const handleUpdate = useCallback(function () {
    if (map) {
      setImage(undefined)
      setTimeout(function () {
        setImage(map.getCanvas().toDataURL())
      }, 300)
    }
  }, [ map ])

  return [ image, map && image ? handleUpdate : undefined ]
}

export default useMapSnapshot
