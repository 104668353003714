import { MouseEvent } from 'react'
import useEventListener from '@use-it/event-listener'
import { focusElement } from '../helpers'
import { hasTouchEvents } from '../helpers'

const useSuggestions = function (
  inputRef: any,
  listRef: any,
  focusedRef: any,
  onSelect: (i: number, e: MouseEvent | Event) => void,
) {

  const handleMouseDown = function (e: Event) {
    // isFocused.current = true
    e.preventDefault()
    inputRef.current && inputRef.current.focus()
  }

  const handleMouseMove = function (e: any) {
    const currentEl = e.target?.closest('[role="button"]')

    if (currentEl !== focusedRef.current) {
      const items = listRef.current?.querySelectorAll('[role="button"]') || []
      for (let i = 0; i < items.length; i++) {
        if (currentEl === items[i]) {
          focusedRef.current = focusElement(i, listRef)
          return
        }
      }
    }
  }

  const handleMouseUp = function (e: any) {
    if (e && e.button !== 0) return

    const currentEl = e.target.closest('[role="button"]')
    const suggestionsEl = listRef.current?.querySelectorAll('[role="button"]') || []
    for (let i = 0; i < suggestionsEl.length; i++) {
      if (currentEl === suggestionsEl[i]) {
        onSelect(i, e)
        return
      }
    }
  }

  const handleMouseLeave = function () {
    if (listRef.current) {
      focusedRef.current = focusElement(-1, listRef)
    }
  }

  useEventListener('mousedown', handleMouseDown, !hasTouchEvents() ? listRef.current : null)
  useEventListener('mousemove', handleMouseMove, !hasTouchEvents() ? listRef.current : null)
  useEventListener('mouseleave', handleMouseLeave, !hasTouchEvents() ? listRef.current : null)
  useEventListener('mouseup', handleMouseUp, !hasTouchEvents() ? listRef.current : null)
}

export default useSuggestions
