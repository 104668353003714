export const isBitmaskContain = function (
  needle: number,
  stack: number | undefined | null
) {
  if (!needle || !stack) {
    return false
  }

  return Boolean(Math.pow(2, needle - 1) & stack)
}
