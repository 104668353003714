import React from 'react'
import { Map } from 'mapbox-gl'
import { Button, SectionTitle, Tabs } from 'ui-kit'
import MessagesBoardsSearch from './MessagesBoardsSearch'
import { AppIcons } from 'consts'
import { IBoard } from 'models/IBoard'
import { IGeoZone } from 'models/IGeoZone'

interface Props {
  map: Map
  mode: 'boards' | 'geozones'
  setMode: any
  setEditMode: any
  isDrawing: boolean,
  onAddPolygon: () => void
  boards: IBoard[] | null | undefined
  zones: IGeoZone[] | null | undefined
  setFocused: any
}

const MessagesBoardsHeader = function (props: Props) {
  const {
    map,
    mode, setMode,
    setEditMode,
    isDrawing, onAddPolygon,
    boards, zones,
    setFocused
  } = props

  const handleDrawZone = function () {
    setEditMode(true)
    onAddPolygon()
  }

  return <>
    <SectionTitle>
      Добавить табло в сообщение
    </SectionTitle>
    <MessagesBoardsSearch
      mode={ mode }
      onChange={ setFocused }
      disabled={ !map || !boards }
      zones={zones}
    />
    <Button
      icon={ AppIcons.ZoneSelect }
      onClick={ !isDrawing ? handleDrawZone : () => setEditMode(false) }
      active={ isDrawing }
      disabled={ !map || !boards }
    />
    <Tabs>
      <Button
        clear
        icon={ AppIcons.Infoboard }
        active={ mode === 'boards' }
        onClick={ () => setMode('boards') }
        disabled={ !map || !boards }
      />
      <Button
        clear
        icon={ AppIcons.Geozone }
        active={ mode === 'geozones' }
        onClick={ () => setMode('geozones') }
        disabled={ !map || !boards }
      />
    </Tabs>
  </>
}

export default MessagesBoardsHeader
