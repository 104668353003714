import { useRef, useEffect, useCallback, useLayoutEffect } from 'react'

const useAnimationFrame = function (callback: null | ((value: number) => void)) {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<undefined | number>()
  const previousTimeRef = useRef<undefined | number>()
  const callbackRef = useRef<any>(callback)

  useLayoutEffect(function () {
    callbackRef.current = callback
  }, [ callback ])

  const animate = useCallback(function (time: number) {
    if (callbackRef.current && previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callbackRef.current(deltaTime)
    }
    previousTimeRef.current = time;
    callbackRef.current && (requestRef.current = requestAnimationFrame(animate));
  }, [ callbackRef, previousTimeRef, requestRef ])

  useEffect(() => {
    if (callback) {
      requestRef.current = requestAnimationFrame(animate);
    } else {
      previousTimeRef.current = undefined
    }
    return function () {
      requestRef.current && cancelAnimationFrame(requestRef.current);
    }
  }, [ requestRef, animate, callback ])
}

export default useAnimationFrame
