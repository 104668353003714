import React, { useMemo } from 'react'
import { Value, Datum, Icon, Icons, Status } from 'ui-kit'
import { getFormattedDate } from 'ui-kit/helpers'
import { Photo, AppIcons, statusColors, statuses, Cities, Page } from 'consts'
import { CITY } from 'ui-kit/consts'
import { IBoard } from 'models/IBoard'
import { FeatureCollection } from 'geojson'
import { ReduxState, TableData, TileData } from 'ui-kit/types'
import { useSelector } from 'react-redux'

const getMonitoringUrl = function (item: IBoard, year: number) {
  return item.type === 0
    ? `https://warehouse.megapolis-it.ru/heatmap/board/${item.id}/${year}`
    : `https://warehouse.megapolis-it.ru/heatmap/boardmobile/${item.id}/${year}`
}

export const useTilesData = function (
  data: IBoard[] | null | undefined
): TileData[] | null | undefined {
  const search = useSelector(({ settings }: ReduxState) => settings.filters[Page.Infoboards]?.search?.[0])

  return useMemo(function () {
    const year = (new Date()).getFullYear()

    return data
      ? data.map(item => {
        return item ? {
          id: item.id,
          title: <span>Табло №{ <Value value={item.num} highlight={search}/> }</span>,
          imageHeader: {
            to: getMonitoringUrl(item, year),
            children: <>
              { item.onlineDate ? getFormattedDate(item.onlineDate) : 'Нет связи'}
              <Icon type={ Icons.Calendar }/>
            </>
          },
          images: [ {
            id: item.id,
            type: Photo.BoardCamera,
            params: { realTime: false }
          } ],
          children: <>
            <Datum sizes={['unset', 24, 24]}>
              {[[
                <Status soft success={item.status === 0} danger={item.status === 1}>
                  <Value value={ statuses[item.status] }/>
                </Status>,
                <Icon type={ AppIcons.BoardType(item.type) } />,
                <Icon type={ AppIcons.BoardFormat(item.format) } />
              ]]}
            </Datum>
            <span className='tiles-text'>
              <Value value={ item.address } highlight={ search } />
            </span>
          </>
        } : item
      })
      : data
  }, [ data, search ])
}

export const useTableData = function (
  data: IBoard[] | null | undefined
): TableData[] | null | undefined {
  const search = useSelector(({ settings }: ReduxState) => settings.filters[Page.Infoboards]?.search?.[0])

  return useMemo(function () {
    return data
      ? data.map(item => item
        ? {
          id: item.id,
          datum: [
            <span className='table-icons'>
              <Icon
                type={ Icons.Tag }
                color={ statusColors[item.status] }
              />
            </span>,
            <span className='table-icons'>
              <Icon type={ AppIcons.BoardType(item.type) } />
              <Icon type={ AppIcons.BoardFormat(item.format) } />
            </span>,
            <span className='table-accent'>
              №{ <Value value={item.num} highlight={search}/> }
            </span>,
            <span className='table-text'>
              <Value value={ item.address } highlight={ search } />
            </span>,
            ''
          ]
        } : {}
      ) : data
  }, [ data, search ])
}

export const useMapData = function (
  data: IBoard[] | null | undefined
): [
  FeatureCollection | null | undefined,
  {[key: string]: string}
] {
  return useMemo(function () {
    return data
      ? [
        {
          type: 'FeatureCollection',
          features: data.map(item => {
            return {
              type: 'Feature',
              id: item.id,
              geometry: {
                type: 'Point',
                coordinates: [ item.lng, item.lat ]
              },
              properties: {
                icon: `board_type_${item.type}_${item.status}`,
                color: statusColors[item.status],
                status: item.status,
                address: item.address,
                num: item.num
              }
            }
          })
        }, {
          'board_type_0_0': CITY === Cities.Saratov ? require(`assets/map/saratov/board_type_0_0.png`) : require(`assets/map/moscow/board_type_0_0.png`),
          'board_type_0_1': CITY === Cities.Saratov ? require(`assets/map/saratov/board_type_0_1.png`) : require(`assets/map/moscow/board_type_0_1.png`),
          'board_type_1_0': CITY === Cities.Saratov ? require(`assets/map/saratov/board_type_1_0.png`) : require(`assets/map/moscow/board_type_1_0.png`),
          'board_type_1_1': CITY === Cities.Saratov ? require(`assets/map/saratov/board_type_1_1.png`) : require(`assets/map/moscow/board_type_1_1.png`),
          'board_type_2_0': CITY === Cities.Saratov ? require(`assets/map/saratov/board_type_2_0.png`) : require(`assets/map/moscow/board_type_2_0.png`),
          'board_type_2_1': CITY === Cities.Saratov ? require(`assets/map/saratov/board_type_2_1.png`) : require(`assets/map/moscow/board_type_2_1.png`),
          'board_type_3_0': CITY === Cities.Saratov ? require(`assets/map/saratov/board_type_3_0.png`) : require(`assets/map/moscow/board_type_3_0.png`),
          'board_type_3_1': CITY === Cities.Saratov ? require(`assets/map/saratov/board_type_3_1.png`) : require(`assets/map/moscow/board_type_3_1.png`),
        }
      ]
      : [ data, {} ]
  }, [data ])
}
