import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useAccessToken } from "@megapolis/react-auth";
import { Button } from "ui-kit/controls";
import InfoboardLog from "components/InfoboardLog";
import {
  AppMessage,
  Datum,
  Icon,
  Icons,
  Image,
  ImageSlider,
  List,
  Section,
  SectionTitle,
  Status,
  Tabs,
  Value,
} from "ui-kit";
import {
  getDatesRange,
  useFrames,
  useTags,
} from "components/Messages/Messages.helpers";
import { createPathURL, filterObject } from "ui-kit/helpers";
import {
  formats,
  Page,
  Photo,
  statuses,
  tagsColor,
  AppIcons,
  types,
} from "consts";
import { loadBoardContent, loadBoardDetails, setModal } from "actions";
import { updateBoardSoftware } from "services/boards";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { IContent } from "models/IContent";
import { ITag } from "models/ITag";

const InfoboardsCard = function () {
  const match: RouteMatch = useRouteMatch();
  const { id, page } = match?.params || { id: "", page: "" };
  const data = useSelector(
    ({ details }: ReduxState) => details[Page.Infoboards].data,
    shallowEqual
  );
  const content = useSelector(
    ({ details }: ReduxState) => details[Page.Infoboards].content,
    shallowEqual
  );
  const dispatch = useDispatch();

  const [isUpdateDisabled, setUpdateDisabled] = useState(false);
  const token = useAccessToken();
  const mountRef = useRef(true);
  const frames = useFrames(content, data ? data.format : null);
  const tags = useTags(content);

  useEffect(
    function () {
      setUpdateDisabled(false);
      mountRef.current = true;
      if (id) {
        dispatch(loadBoardDetails(+id));
        dispatch(loadBoardContent(+id));
      }

      return () => {
        mountRef.current = false;
      };
    },
    [dispatch, id, page]
  );

  const handleShowContentLog = function () {
    dispatch(
      setModal({
        component: InfoboardLog,
        data,
        match,
      })
    );
  };

  const imageParams = useMemo(
    function () {
      return {
        realTime: false,
        type: data?.type,
      };
    },
    [data]
  );

  const filterContent = useCallback(function (query: string, item: IContent) {
    return filterObject(query, item, {
      name: true,
    });
  }, []);

  const handleUpdate = useCallback(async () => {
    if (!token) return;
    setUpdateDisabled(true);
    try {
      await updateBoardSoftware(token, +id);
      mountRef.current && setUpdateDisabled(false);
    } catch {
      mountRef.current && setUpdateDisabled(false);
    }
  }, [mountRef, token, id]);

  const [isCopied, setCopied] = useState(false);
  const handleCopyClipboard = useCallback(() => {
    navigator.clipboard.writeText(data?.controllerAddress || "");
    setCopied(true);

    setTimeout(() => {
      mountRef.current && setCopied(false);
    }, 1000);
  }, [data, mountRef]);

  if (!id) {
    return (
      <Section>
        <AppMessage>Выберите табло</AppMessage>
      </Section>
    );
  }

  return (
    // @ts-ignore
    <Section
      header={() => (
        <>
          {/* @ts-ignore */}
          <Button icon={Icons.Edit} onClick={console.log} disabled />
          {/* @ts-ignore */}
          <Button danger icon={Icons.Delete} onClick={console.log} disabled />
          <hr />
          {/* @ts-ignore */}
          <SectionTitle>
            {/* @ts-ignore */}
            <Value value={data ? `Табло №${data.num}` : data} />
          </SectionTitle>
          <div>
            {/* @ts-ignore */}
            <Button
              clear
              accent
              disabled={!data}
              icon={AppIcons.Waiting}
              onClick={handleShowContentLog}
            />
            {/* @ts-ignore */}
            <Button
              clear
              accent
              disabled={!data}
              icon={Icons.Calendar}
              onClick={console.log}
            />
          </div>
          <hr />
          {/* @ts-ignore */}
          <Tabs wide>
            {/* @ts-ignore */}
            <Button
              large
              clear
              exact
              replace
              href={createPathURL(match?.path, {
                ...match?.params,
                tab: "",
              })}
            >
              Основное
            </Button>
            {/* @ts-ignore */}
            <Button
              large
              clear
              exact
              replace
              href={createPathURL(match?.path, {
                ...match?.params,
                tab: "content",
              })}
            >
              Контент
            </Button>
          </Tabs>
        </>
      )}
    >
      {!match?.params?.tab && (
        <>
          {/* @ts-ignore */}
          <Image id={data?.id} type={Photo.BoardCamera} params={imageParams} />
          {/* @ts-ignore */}
          <Datum sizes={["unset", 24, 24]}>
            {data ? (
              [
                [
                  // @ts-ignore
                  <Status
                    soft
                    success={data.status === 0}
                    danger={data.status === 1}
                  >
                    {/* @ts-ignore */}
                    <Value value={statuses[data.status]} />
                  </Status>,
                  // @ts-ignore
                  <Icon type={AppIcons.BoardType(data.type)} />,
                  // @ts-ignore
                  <Icon type={AppIcons.BoardFormat(data.format)} />,
                ],
              ]
            ) : (
              // @ts-ignore
              <Value value={data} />
            )}
          </Datum>

          {/* @ts-ignore */}
          <Datum
            label="Информация"
            sizes={[120, "unset"]}
            classNames={["-label", "-right"]}
          >
            {[
              // @ts-ignore
              [<Value value="Адрес" />, <Value value={data && data.address} />],
              [
                // @ts-ignore
                <Value value="Тип" />,
                // @ts-ignore
                <Value value={data && types[data.type]} />,
              ],
              [
                // @ts-ignore
                <Value value="Формат" />,
                // @ts-ignore
                <Value value={data && formats[Math.min(5, data.format)]} />,
              ],
              [
                // @ts-ignore
                <Value value="Дата онлайн" />,
                // @ts-ignore
                <Value dateTime value={data && data.onlineDate} />,
              ],
              [
                // @ts-ignore
                <Value value="IP адрес" />,
                <>
                  {/* @ts-ignore */}
                  <Value
                    value={
                      isCopied ? "Скопировано" : data && data.controllerAddress
                    }
                  />
                  {/* @ts-ignore */}
                  <Button
                    icon={Icons.Copy}
                    clear
                    accent
                    disabled={!data?.controllerAddress}
                    onClick={handleCopyClipboard}
                  />
                </>,
              ],
              [
                // @ts-ignore
                <Value value="Версия прошивки" />,
                <>
                  {/* @ts-ignore */}
                  <Value value={data && data.appVersion} />
                  {/* @ts-ignore */}
                  <Button
                    icon={Icons.Refresh}
                    clear
                    accent
                    disabled={isUpdateDisabled}
                    onClick={handleUpdate}
                  />
                </>,
              ],
            ]}
          </Datum>
          {/* @ts-ignore */}
          <List
            label="Теги"
            list={
              data
                ? (data.tags || []).map((tag: ITag) => ({
                    icon: tag.isGeo ? AppIcons.Geozone : Icons.Tag,
                    iconColor: tagsColor[tag.color],
                    label: tag.value,
                  }))
                : data
            }
          />
        </>
      )}

      {match?.params?.tab === "content" && (
        <>
          {/* @ts-ignore */}
          <ImageSlider
            images={frames}
            placeholder={
              content && content.length
                ? "Нет подходящих форматов"
                : "Нет активных сообщений"
            }
          />
          {/* @ts-ignore */}
          <Datum
            label={"Сообщения"}
            filter={filterContent}
            classNames={[null, "-right", ""]}
            sizes={["unset", "unset", 22]}
            items={
              content
                ? content.filter((x: IContent) => x.isActive && !x.isRemoved)
                : content
            }
            renderer={(item: IContent) => [
              //@ts-ignore
              <Value
                value={item.name}
                href={`/${Page.Messages}/list/${item.id}`}
              />,
              //@ts-ignore
              <Value value={getDatesRange(item.schedule)} />,
              //@ts-ignore
              <Icon type={Icons.Calendar} />,
            ]}
          />
          {/* @ts-ignore */}
          <List
            label="Теги"
            list={
              data
                ? (tags || []).map((tag: ITag) => ({
                    icon: tag.isGeo ? AppIcons.Geozone : Icons.Tag,
                    iconColor: tagsColor[tag.color],
                    label: tag.value,
                  }))
                : data
            }
          />
        </>
      )}
    </Section>
  );
};

export default InfoboardsCard;
