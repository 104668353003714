import React, { useMemo, useState } from "react";
import { Icons } from "ui-kit";
import { Button } from "ui-kit/controls";
import Popup from "ui-kit/components/Popup";
import ListItem from "ui-kit/components/List/ListItem";
import MessageFormDate from "./MessageFormDate";
import { getFormattedSchedule } from "components/Messages/Messages.helpers";
import { ISchedule } from "models/ISchedule";
import "ui-kit/components/List/list.scss";

interface Props {
  value: ISchedule[] | null;
  onChange: (value: ISchedule[]) => void;
}

const MessageFormDates = function (props: Props) {
  const { value, onChange } = props;
  const [opened, setOpened] = useState<number>(-1);

  const handleDelete = function (index: number) {
    onChange([
      ...(value || []).slice(0, index),
      ...(value || []).slice(index + 1),
    ]);
  };

  const handleAddPeriod = function () {
    const filtered = (value || []).filter((x) => Object.keys(x).length > 0);
    onChange([...filtered, {}]);
    setOpened(filtered.length);
  };

  const handleChange = function (index: number, schedule: ISchedule) {
    onChange([
      ...(value || []).slice(0, index),
      schedule,
      ...(value || []).slice(index + 1),
    ]);
  };

  const scheduleList = useMemo(
    function () {
      if (!value) {
        return [];
      }

      return value.map((s, index) => {
        const { period, weekDays, time } = getFormattedSchedule(s);

        return {
          label: period,
          description: [weekDays, time].filter((x) => x).join(" / "),
          icon: Icons.Calendar,
        };
      });
    },
    [value]
  );

  return (
    <div className="message-form-list -dates">
      <div className="list-label">Даты</div>
      <div className="list-items">
        {scheduleList.length > 0 ? (
          scheduleList.map((item, index) => {
            return (
              <div key={index}>
                {/* @ts-ignore */}
                <ListItem
                  {...item}
                  selected={index === opened}
                  onRemove={
                    Object.keys(value?.[index] || {}).length > 0
                      ? () => handleDelete(index)
                      : undefined
                  }
                  onClick={() => setOpened((o) => (o === index ? -1 : index))}
                />
                {/* @ts-ignore */}
                {opened === index && (
                  <Popup
                    isOpened={true}
                    onToggle={(isOpened) => {
                      if (!isOpened) {
                        setOpened(-1);
                        onChange?.(
                          (value || []).filter((x) => Object.keys(x).length > 0)
                        );
                      }
                    }}
                  >
                    {/* @ts-ignore */}
                    <MessageFormDate
                      value={value?.[index] as ISchedule}
                      onChange={(value) => handleChange(index, value)}
                    />
                  </Popup>
                )}
              </div>
            );
          })
        ) : (
          //@ts-ignore
          <ListItem placeholder label={"Весь период"} icon={Icons.Calendar} />
        )}
      </div>
      {/* @ts-ignore */}
      <Button clear accent small onClick={handleAddPeriod}>
        Добавить время публикации
      </Button>
    </div>
  );
};

export default MessageFormDates;
