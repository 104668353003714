import * as PIXI from 'pixi.js'
import { PixelateFilter } from '@pixi/filter-pixelate'
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import { TwistFilter } from '@pixi/filter-twist'
import { easeInOut, FrameTransition } from 'consts'
import { TPreviewSource } from '../usePreviewFrames'


export const applyEffects = function (
  position: number,
  renderers: (TPreviewSource | undefined)[],
  pixi: PIXI.Application
) {
  const [ previous, current ] = renderers

  if (previous) {
    previous.sprite.alpha = 1
  }

  if (current) {
    const percent = easeInOut(current.time.transition
      ? Math.min(1, position / current.time.transition)
      : 1)

    switch (current.effect) {
      case FrameTransition.Blinds:
      case FrameTransition.SlideBottom:
      case FrameTransition.SlideLeft:
      case FrameTransition.SlideRight:
      case FrameTransition.SlideTop:
      case FrameTransition.Wave:
        current.sprite.alpha = 1
        break

      case FrameTransition.Pixelate:
      case FrameTransition.RadialBlur:
      case FrameTransition.Swirl:
      case FrameTransition.Ripple:
        current.sprite.alpha = Math.min(1, Math.max(0, percent - 0.4) / 0.2)
        break

      default:
        current.sprite.alpha = percent
    }

    switch (current.effect) {
      case FrameTransition.Blinds: {
        const mask = current.sprite.children[1] as PIXI.Graphics | undefined
        if (mask) {
          mask.clear()
          mask.beginFill(0xFFFFFF)
          for (let i = 0; i < 10; i++) {
            mask.drawRect(i * (pixi.view.width / 10), 0, (pixi.view.width / 10) * percent, pixi.view.height)
          }
          mask.endFill()
        }
        break
      }

      case FrameTransition.Pixelate: {
        const filter = pixi.stage.filters?.[0]
        const value = (1 - Math.abs(percent - 0.5) / 0.5) * 15
        if (filter) {
          (filter as unknown as PixelateFilter).size = 1 / devicePixelRatio + value
        }

        break
      }

      case FrameTransition.RadialBlur: {
        const filter = pixi.stage.filters?.[0]
        if (filter) {
          (filter as unknown as KawaseBlurFilter).blur = (1 - Math.abs(percent - 0.5) / 0.5) * 20
        }
        break
      }

      case FrameTransition.Swirl: {
        const filter = pixi.stage.filters?.[0]
        if (filter) {
          (filter as unknown as TwistFilter).angle = -(1 - Math.abs(percent - 0.5) / 0.5) * 15
        }
        break
      }
    }
  }
}
