import React from 'react'
import { Loader } from '..'

interface Props {
  message?: string
}

const PromptLoader = function (props: Props) {
  const { message } = props 
  
  return <>
    <div className='prompt-loader'>
      <Loader />
    </div>
    { message && <div className='prompt-message -loader'>
      { message }
    </div> }
  </>
}

export default PromptLoader
