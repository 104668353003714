import React, { useEffect, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAccessToken } from "@megapolis/react-auth";
import Prompt from "../components/Prompt";
import Modal from "../modal";
import AppRedirect from "./AppRedirect";
import Navigation from "../components/Navigation";
import Layout from "../components/Layout";
import { setPrompt } from "../reducers/layout";
import { AppRoutes } from "../types";
import { setToken } from "../reducers/user";

interface Props {
  routes: AppRoutes;
}

const AppRouting = function (props: Props) {
  const { routes } = props;

  console.log(routes)
  const token = useAccessToken();
  const dispatch = useDispatch();

  useLayoutEffect(
    function () {
      dispatch(setToken(token));
    },
    [token, dispatch]
  );

  /** FIXME: workaround to prevent app update on first prompt call */
  useEffect(
    function () {
      dispatch(setPrompt());
    },
    [dispatch]
  );
  /*****************************************************************/

  if (!token) {
    return null;
  }

  return (
    // @ts-ignore
    <Router>
      <>
        {/* @ts-ignore */}
        <Prompt />
        <div className="app">
          {/* @ts-ignore */}
          <Modal routes={routes} />
          {/* @ts-ignore */}
          <AppRedirect />
          {/* @ts-ignore */}
          <Navigation routes={routes} />
          {/* @ts-ignore */}
          <Layout>
            {/* @ts-ignore */}
            <Switch>
              {routes.pages.map(
                ({
                  url,
                  path,
                  routes,
                  redirect,
                  props,
                  component: Component,
                }) => (
                  // @ts-ignore
                  <Route path={url} key={url}>
                    {/* @ts-ignore */}
                    <Switch>
                      {/* @ts-ignore */}
                      <Route path={path}>
                        {/* @ts-ignore */}
                        <Switch>
                          {routes?.map(
                            ({ path, props, component: Component }, key) => (
                              //@ts-ignore
                              <Route key={key} path={path}>
                                {/* @ts-ignore */}
                                <Component {...props} />
                              </Route>
                            )
                          )}
                          {/* @ts-ignore */}
                          <Component {...props} />
                        </Switch>
                      </Route>
                      {
                        // @ts-ignore
                        redirect && <Redirect to={redirect} />
                      }
                    </Switch>
                  </Route>
                )
              )}

              {
                // @ts-ignore
                routes.redirect && <Redirect to={routes.redirect} />
              }
            </Switch>
          </Layout>
        </div>
      </>
    </Router>
  );
};

export default AppRouting;
