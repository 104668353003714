import {
  deleteDatumItem,
  loadDatum,
  updateDatumItem,
} from "ui-kit/reducers/datum";
import { loadAppendix, loadDetails, setDetails } from "ui-kit/reducers/details";
import { loadSummary, setPrompt, setRedirect } from ".";
import * as contentApi from "services/content";
import { createPathURL } from "ui-kit/helpers";
import { Page } from "consts";
import { IContent } from "models/IContent";
import { Dispatch } from "react";
import { ReduxState, RouteMatch } from "ui-kit/types";

export const loadContentData = function (batch?: number) {
  return loadDatum(Page.Messages, contentApi.fetchContents, batch);
};

export const loadContentDetails = function (id: number) {
  return loadDetails(id, Page.Messages, contentApi.fetchContent);
};

export const loadContentSummary = function () {
  return loadSummary(Page.Messages, contentApi.fetchSummary);
};

export const loadContentBoards = function (id: number) {
  return loadAppendix(
    id,
    "boards",
    Page.Messages,
    contentApi.fetchContentBoards
  );
};

export const loadContentLog = function (id: number) {
  return loadAppendix(id, "log", Page.Messages, contentApi.fetchContentLog);
};

export const saveContent = function (data: IContent, match: RouteMatch) {
  return async function (dispatch: Dispatch<any>, getState: () => ReduxState) {
    if (!data) {
      return;
    }

    dispatch(
      setPrompt({
        message: "Сохранение сообщения...",
        loader: true,
      })
    );

    const token = getState().user.token;
    if (!token) {
      return;
    }

    try {
      const res: IContent = await contentApi.saveContent(token, data);
      dispatch(setPrompt());
      dispatch(loadContentSummary());

      if (res.id) {
        dispatch(loadContentData());
        dispatch(setDetails(Page.Messages, res));
      }

      if (match && res.id) {
        dispatch(
          setRedirect(
            createPathURL(`/:page/:mode/:id/:tab?`, {
              ...match?.params,
              id: res.id,
            }),
            true
          )
        );
      } else {
        dispatch(setRedirect(`/${Page.Messages}`, true));
      }
    } catch (e) {
      dispatch(
        setPrompt({
          message: e?.error || "При сохранении сообщения произошла ошибка.",
        })
      );
    }
  };
};

export const arhiveContent = function (id: number, match: RouteMatch) {
  return setPrompt({
    message: "Отправить сообщение в архив?",
    buttons: [
      {
        label: "Архивировать",
        default: true,
        danger: true,
        action: async function (
          dispatch: Dispatch<any>,
          getState: () => ReduxState
        ) {
          dispatch(
            setPrompt({
              message: "Архивация сообщения...",
              loader: true,
            })
          );

          const token = getState().user.token;
          if (!token) {
            return;
          }
          try {
            await contentApi.archiveContent(token, id);
            dispatch(setPrompt());
            dispatch(loadContentSummary());
            dispatch(deleteDatumItem(Page.Messages, id, match));
          } catch (e) {
            dispatch(
              setPrompt({
                message:
                  e?.error || "При архивации сообщения произошла ошибка.",
              })
            );
          }
        },
      },
    ],
  });
};

export const extractContent = function (id: number, match: RouteMatch) {
  return setPrompt({
    message: "Разархивировать сообщение?",
    buttons: [
      {
        label: "Разархивировать",
        default: true,
        action: async function (
          dispatch: Dispatch<any>,
          getState: () => ReduxState
        ) {
          dispatch(
            setPrompt({
              message: "Разархивация сообщения...",
              loader: true,
            })
          );

          const token = getState().user.token;
          if (!token) {
            return;
          }
          try {
            await contentApi.extractContent(token, id);
            dispatch(setPrompt());
            dispatch(loadContentSummary());
            dispatch(deleteDatumItem(Page.Messages, id, match));
          } catch (e) {
            dispatch(
              setPrompt({
                message:
                  e?.error || "При разархивации сообщения произошла ошибка.",
              })
            );
          }
        },
      },
    ],
  });
};

export const assignBoards = function (contentId: number, ids: number[]) {
  return async function (dispatch: Dispatch<any>, getState: () => ReduxState) {
    const token = getState().user.token;
    if (!token) {
      return;
    }
    try {
      await contentApi.assignBoards(token, contentId, ids);
      dispatch(loadContentSummary());
      dispatch(loadContentBoards(contentId));
      dispatch(setRedirect(`/${Page.Messages}/list/${contentId}/boards`, true));
      const item = (getState().datum[Page.Messages].items || []).find(
        (x) => x.id === contentId
      );
      item &&
        dispatch(
          updateDatumItem(Page.Messages, {
            ...item,
            bindedBoardsCount: ids.length,
          })
        );
    } catch (e) {
      dispatch(
        setPrompt({
          message: e?.error || "При назначении табло произошла ошибка.",
        })
      );
    }
  };
};

export const publishContent = function (contentId: number) {
  return setPrompt({
    message: "Вы уверены, что хотите опубликовать сообщение?",
    buttons: [
      {
        label: "Опубликовать",
        fill: true,
        default: true,
        action: async function (
          dispatch: Dispatch<any>,
          getState: () => ReduxState
        ) {
          const token = getState().user.token;
          if (!token) {
            return;
          }
          try {
            await contentApi.publishContent(token, contentId, true);
            dispatch(loadContentSummary());
            dispatch(loadContentDetails(contentId));
            const item = (getState().datum[Page.Messages].items || []).find(
              (x) => x.id === contentId
            );
            item &&
              dispatch(
                updateDatumItem(Page.Messages, { ...item, isPublished: true })
              );
          } catch (e) {
            dispatch(
              setPrompt({
                message:
                  e?.error || "При публикации сообщения произошла ошибка.",
              })
            );
          }
        },
      },
    ],
  });
};

export const unPublishContent = function (contentId: number) {
  return setPrompt({
    message: "Вы уверены, что хотите снять сообщение с публикации?",
    buttons: [
      {
        label: "Снять с публикации",
        danger: true,
        action: async function (
          dispatch: Dispatch<any>,
          getState: () => ReduxState
        ) {
          const token = getState().user.token;
          if (!token) {
            return;
          }
          try {
            await contentApi.publishContent(token, contentId, false);
            dispatch(loadContentSummary());
            dispatch(loadContentDetails(contentId));
            const item = (getState().datum[Page.Messages].items || []).find(
              (x) => x.id === contentId
            );
            item &&
              dispatch(
                updateDatumItem(Page.Messages, { ...item, isPublished: false })
              );
          } catch (e) {
            dispatch(
              setPrompt({
                message:
                  e?.error ||
                  "При снятии сообщения с публикации произошла ошибка.",
              })
            );
          }
        },
      },
    ],
  });
};
