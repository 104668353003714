import React from 'react'
import MenuLink from './MenuLink'
import { AppRoutes } from '../../types'
import './menu.scss'

const Menu = function (props: { routes: AppRoutes }) {
  return <div className='menu'>
    { props.routes.pages.map(page => (
      <MenuLink 
        key={ page.url } 
        href={ page.url } 
        icon={ page.icon }
        title={ page.title }
      />
    )) }
  </div>
}

export default Menu
