import React, { useMemo } from 'react'
import classes from 'classnames'
import './icon.scss'

export interface Props {
  type: React.FunctionComponent,
  fill?: boolean
  color?: string
  size?: number
  className?: string
  onClick?: (e: any) => void
  onMouseDown?: (e: any) => void
}

const Icon = function (props: Props) {
  const { type: Component, fill, color, size, className, ...eventHandlers } = props

  const style = useMemo(function () {
    return color || size
      ? {
        ...(color ? { fill: color, stroke: color } : undefined),
        ...(size ? { minWidth: size, maxWidth: size, minHeight: size, maxHeight: size } : undefined)
      }
      : undefined
  }, [ color, size ])

  return <span
    className={ classes('icon', className, fill && '-fill') }
    style={ style }
    { ...eventHandlers }
  >
    <Component />
  </span>
}

export default Icon
