import React, { useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Button, Icons, List } from "ui-kit";
import Tags from "components/Tags/Tags";
import { setFilter } from "ui-kit/reducers/settings";
import { setModal } from "ui-kit/reducers/layout";
import { AppIcons, tagsColor } from "consts";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { ITag } from "models/ITag";

const TagsFilter = function (props: { name: string; label?: string }) {
  const { name, label } = props;
  const match: RouteMatch = useRouteMatch();
  const { page } = match?.params || { page: "" };
  const dispatch = useDispatch();
  const tags: ITag[] | undefined = useSelector(
    ({ settings }: ReduxState) => settings.filters[page]?.[name],
    shallowEqual
  );

  const handleShowTags = function () {
    dispatch(
      setModal({
        value: tags,
        onSubmit: (t: ITag[]) => dispatch(setFilter(page, name, t)),
        component: Tags,
      })
    );
  };

  const handleDelete = useCallback(
    function (id: number) {
      dispatch(
        setFilter(
          page,
          name,
          (tags || []).filter((x) => x.id !== id)
        )
      );
    },
    [page, name, dispatch, tags]
  );

  const tagsList = useMemo(
    function () {
      return tags
        ? tags.map((tag) => ({
            label: tag.value,
            value: tag.id,
            icon: tag.isGeo ? AppIcons.Geozone : Icons.Tag,
            iconColor: tagsColor[tag.color],
            onRemove: () => handleDelete(tag.id),
          }))
        : [];
    },
    [tags, handleDelete]
  );

  return (
    <>
      {label && <span className="list-label">{label}</span>}
      {tagsList.length ? <List list={tagsList} /> : null}
      {/* @ts-ignore */}
      <Button small clear accent onClick={handleShowTags}>
        Добавить теги
      </Button>
    </>
  );
};

export default TagsFilter;
