import { API_URL } from 'consts'
import { ITag } from 'models/ITag'

/**
 * Получает список тегов
 * @param {string } token
 */
export const fetchTags = async function (token: string) {
  const response = await fetch(`${ API_URL }/Tag`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return (await response.json()).filter((x: any) => x.geo === undefined) as ITag[]
  } else {
    throw await response.json()
  }
}

/**
 * Сохраняет/создаёт тег
 * @param { string } token
 * @param { ITag } data
 */
export const saveTag = async function (token: string, data: ITag) {
  const url = data.id ? `${ API_URL }/Tag/${data.id}` : `${ API_URL }/Tag`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ token }`
    },
    body: JSON.stringify(data)
  })

  if (response.status === 200) {
    return await response.json() as ITag
  } else {
    throw await response.json()
  }
}

/**
 * Удаляет тег
 * @param { string } token
 * @param { number } id
 */
export const deleteTag = async function (token: string, id: number) {
  const response = await fetch(`${ API_URL }/Tag/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return true
  } else {
    throw await response.json()
  }
}
