import { loadDatum } from 'ui-kit/reducers/datum'
import { loadAppendix, loadDetails } from 'ui-kit/reducers/details'
import { loadSummary } from '.'
import { Page } from 'consts'
import * as boardsApi from 'services/boards'

export const loadBoardData = function (batch?: number) {
  return loadDatum(Page.Infoboards, boardsApi.fetchBoards, batch)
}

export const loadBoardDetails = function (id: number) {
  return loadDetails(id, Page.Infoboards, boardsApi.fetchBoard)
}

export const loadBoardContent = function (id: number) {
  return loadAppendix(id, 'content', Page.Infoboards, boardsApi.fetchBoardContent)
}

export const loadBoardSummary = function () {
  return loadSummary(Page.Infoboards, boardsApi.fetchSummary)
}
