import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LayoutArea, Section } from 'ui-kit'
import Filter from 'ui-kit/filter'
import Map from 'ui-kit/map'
import Table from 'ui-kit/table'
import Tiles from 'ui-kit/tiles'
import {
  InfoboardsCard,
  InfoboardsHeader,
  useMapData,
  useTableData,
  useTilesData
} from 'components/Infoboards/index'
import { useDataLoading } from 'ui-kit/hooks'
import { loadBoardData, loadBoardSummary } from 'actions'
import { filterLists, tableColumns } from 'settings'
import { ReduxState } from 'ui-kit/types'
import { Page } from 'consts'
import { AppLoader } from '../../ui-kit/app'

const Infoboards = function () {
  const dispatch = useDispatch()
  const { mode, id } = useParams()
  const [ boards, total, summary ] = useDataLoading(loadBoardData)
  const hasFilters = useSelector(({ layout }: ReduxState) => layout.showFilters[Page.Infoboards])
  const tableData = useTableData(boards)
  const tilesData = useTilesData(boards)
  const [ mapData, icons ] = useMapData(boards)

  useEffect(function () {
    if (boards === undefined) {
      dispatch(loadBoardSummary())
    }
  }, [ boards, dispatch ])

  return <>
    <LayoutArea size={ 280 } collapsed={ !hasFilters } className='-pale'>
      <Filter list={filterLists[Page.Infoboards]} total={summary && summary.total}/>
    </LayoutArea>
    <LayoutArea>
      <Section header={ InfoboardsHeader } >
        <Map
          hidden={ mode !== 'map' }
          onLoad={ () => dispatch(loadBoardData()) }
          data={ mapData }
          icons={ icons }
        />
        { mode === 'list' && <Table
          items={ tableData }
          total={ total }
          onLoad={ (batch) => dispatch(loadBoardData(batch)) }
          columns={tableColumns[Page.Infoboards]}
        /> }
        { mode === 'tiles' && <Tiles
          items={ tilesData }
          total={ total }
          onLoad={ (batch) => dispatch(loadBoardData(batch)) }
        /> }
      </Section>
    </LayoutArea>
    <LayoutArea size={ 424 }>
      { boards || id ? <InfoboardsCard /> : <AppLoader /> }
    </LayoutArea>
  </>
}

export default Infoboards
