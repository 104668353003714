import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { ReduxState } from "../types";
import { setRedirect } from "../reducers/layout";

const AppRedirect = function () {
  const redirect = useSelector(
    ({ layout }: ReduxState) => layout.redirect,
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(
    function () {
      if (redirect) {
        dispatch(setRedirect());
      }
    },
    [dispatch, redirect]
  );

  return redirect ? (
    // @ts-ignore
    <Redirect to={redirect.path} push={redirect.push} />
  ) : null;
};

export default AppRedirect;
