import React, { useMemo } from 'react'
import { Badge, Popup, Status, Value } from 'ui-kit'
import { getFormattedDate } from 'ui-kit/helpers'
import { logActions, Page, AppIcons } from 'consts'
import { TableData } from 'ui-kit/types'
import { ILog } from 'models/ILog'
import { IBoard } from 'models/IBoard'

const renderBoards = function (boards: IBoard[] | null) {
  if (!boards || boards.length === 0) {
    return null
  }

  if (boards.length <= 2) {
    return boards.map((b, i) =>
      <span key={b.id}>
        <strong>
          <Value value={`№${b.num}`} href={`/${Page.Infoboards}/list/${b.id}`}/>
        </strong>
        {i + 1 !== boards?.length && ', '}
      </span>
    )
  }

  return <Popup
    trigger={(props: any) => <span {...props}>
      <Badge>{boards.length}</Badge> Табло
    </span>}
    triggerProps={({ onClick}: any) => ({ onClick })}
    items={boards.map(b => ({
      label: b.num?.toString() || '',
      icon: AppIcons.BoardType(b.type),
      href: `/${Page.Infoboards}/list/${b.id}`
    }))}
  />
}

export const useTableData = function (
  data: ILog[] | null | undefined
): TableData[] | null | undefined {
  return useMemo(function () {
    return data
      ? data.map(item => {
        return item
          ? {
            id: item.id,
            datum: [
              <span className='table-text'>
                { getFormattedDate(item.timestamp) }
              </span>,
              <span className='table-accent'>
                { item.userDisplayName }
              </span>,
              <span className='table-text'>
                <Status {...logActions[item.action]?.modifier}>
                  {logActions[item.action]?.label}
                </Status>
              </span>,
              <span className='table-text'>
                { renderBoards(item.boards) }
              </span>,
              <span className='table-text'>
                <Value
                  value={item.contentName || `№${item.contentId}`}
                  href={`/${Page.Messages}/list/${item.contentId}`}
                />
              </span>
            ]
          }
          : item
      })
      : data
  }, [ data ])
}
