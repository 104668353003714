import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Map as MapBox } from "mapbox-gl";
import { LayoutArea } from "ui-kit";
import { AppLoader } from "ui-kit/app";
import Section from "ui-kit/section";
import Map from "ui-kit/map";
import Table from "ui-kit/table";
import Tiles from "ui-kit/tiles";
import {
  GeozonesGroups,
  GeozonesHeader,
  useMapData,
  useTableData,
  useTilesData,
} from "components/Geozones/index";
import GeozonesCard from "./GeozonesCard";
import GeozonesForm from "components/Geozones/GeozonesForm";
import { useDataLoading, useFetch } from "ui-kit/hooks";
import { useMapEditing } from "ui-kit/map";
import { loadGeoZoneDetails, loadGeoZonesData, loadGeoGroups } from "actions";
import { API_URL, Page } from "consts";
import { tableColumns } from "settings";
import { Position } from "geojson";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { TMapEditData } from "ui-kit/map";
import { IGeoZone } from "models/IGeoZone";
import { IBoard } from "models/IBoard";

const Geozones = function () {
  const dispatch = useDispatch();
  const match: RouteMatch = useRouteMatch();
  const { id, mode } = match?.params || { id: "", mode: "" };
  const form = useMemo(
    function () {
      if (!match) return null;
      if (match.url.substr(match.url.length - 5).indexOf("/edit") === 0)
        return "edit";
      if (match.url.substr(match.url.length - 7).indexOf("/create") === 0)
        return "create";
      return null;
    },
    [match]
  );

  const hasFilters = useSelector(
    ({ layout }: ReduxState) => layout.showFilters[Page.Geozones]
  );
  const [boards] = useFetch<IBoard[]>(`${API_URL}/Board`);
  const [zones, total] = useDataLoading(loadGeoZonesData);
  const editZoneData = useSelector(
    ({ details }: ReduxState) =>
      details[Page.Geozones].data as IGeoZone | undefined | null,
    shallowEqual
  );
  const tableData = useTableData(zones);
  const tilesData = useTilesData(zones);
  const [mapData, icons] = useMapData(
    zones,
    boards,
    form === "edit" ? +id : undefined
  );
  const [editZones, setEditZones] = useState<undefined | Position[][]>([]);
  const [map, setMap] = useState<MapBox | null>(null);

  useEffect(
    function () {
      if (form === "edit" && id) {
        dispatch(loadGeoZoneDetails(+id));
      }
    },
    [dispatch, id, form]
  );

  useEffect(
    function () {
      if (zones === undefined) {
        dispatch(loadGeoGroups());
      }
    },
    [zones, dispatch]
  );

  useEffect(
    function () {
      if (id && form === "edit" && editZoneData) {
        setEditZones(
          !editZoneData.geo?.shapes?.length
            ? undefined
            : editZoneData.geo?.shapes.map((x) => x.coordinates || [])
        );
      }

      return function () {
        setEditZones(undefined);
      };
    },
    [id, form, editZoneData]
  );

  const editData = useMemo(
    function () {
      return (
        editZones && {
          polygons: {
            data: editZones,
            multiple: true,
          },
        }
      );
    },
    [editZones]
  );

  const handleZonesChange = useCallback(function (data: TMapEditData) {
    setEditZones(data.polygons);
  }, []);

  const mapEditProps = useMapEditing(
    map,
    Boolean(form),
    editData,
    handleZonesChange
  );

  return (
    <>
      <LayoutArea
        size={280}
        collapsed={!hasFilters || Boolean(form)}
        className="-pale"
      >
        <GeozonesGroups />
      </LayoutArea>
      <LayoutArea>
        <Section
          header={GeozonesHeader}
          headerProps={{
            ...mapEditProps,
            editDisabled: !map,
            form,
          }}
        >
          <Map
            data={mapData}
            dataIndex={1}
            icons={icons}
            hidden={mode !== "map" && !form}
            onLoad={() => dispatch(loadGeoZonesData())}
            editMode={Boolean(form)}
            onInit={setMap}
          />
          {mode === "list" && !form && (
            <Table
              total={total}
              items={tableData}
              onLoad={(batch) => dispatch(loadGeoZonesData(batch))}
              columns={tableColumns[Page.Geozones]}
            />
          )}
          {mode === "tiles" && !form && (
            <Tiles
              total={total}
              items={tilesData}
              onLoad={(batch) => dispatch(loadGeoZonesData(batch))}
            />
          )}
        </Section>
      </LayoutArea>
      <LayoutArea size={424}>
        {!form ? (
          zones || id ? (
            <GeozonesCard />
          ) : (
            <AppLoader />
          )
        ) : (
          <GeozonesForm zones={editZones} form={form} />
        )}
      </LayoutArea>
    </>
  );
};

export default Geozones;
