const check = function (object: any, type: string): boolean {
  switch (type) {
    case 'string':
    case 'number':
    case 'function':
    case 'boolean':
      return typeof object === type
    case 'array':
      return Array.isArray(object)
    case 'object':
      return object && !Array.isArray(object) && typeof object === 'object'
    default:
      return false
  }
}

export const matchTypes = function (object: any, ...types: Array<string>): boolean {
  // Any type
  if (types.length === 0) {
    return true
  }

  for (let i = 0; i < types.length; i++) {
    if (check(object, types[i])) {
      return true
    }
  }

  return false
}
