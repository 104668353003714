import { arrify, matchTypes } from './index'
import { TKeys, TSuggestion, TTypes } from '../types'

const findMatchedKey = function (
  obj: any,
  keys: Array<string>,
  types: Array<string>
): any {

  const typesArray = arrify(types)
  /** Check if obj has matched key of matched type */
  for (let i = 0; i < keys.length; i++) {
    if (obj.hasOwnProperty(keys[i]) && matchTypes(obj[keys[i]], ...typesArray)) {
      return obj[keys[i]]
    }
  }

  /** Find first of this type */
  if (typesArray.length > 0) {
    const k = Object.keys(obj)
    for (let i = 0; i < k.length; i++) {
      if (matchTypes(obj[k[i]], ...typesArray)) {
        return obj[k[i]]
      }
    }
  }

  return ''
}

export const getSuggestionData = function (
  item: TSuggestion | null,
  keys: TKeys = {},
  types: TTypes = {}
): [ string, string ] {
  if (!item) {
    return [ '', '' ]
  }

  if (matchTypes(item, 'string', 'number')) {
    return [ String(item), String(item) ]
  }

  if (Array.isArray(item)) {
    return [ '', '' ]
  }

  /** Object */
  const valueKeys = (keys && keys['value']) || 'value'
  const displayKeys = (keys && keys['display']) || 'label'

  const value = findMatchedKey(item, arrify(valueKeys), arrify(types.value))
  const display = findMatchedKey(item, arrify(displayKeys), arrify(types.display))
  return [ value, display ]
}

export const isSuggestionDisabled = function (elem: Element): boolean {
  if (!elem) return false

  return (
    elem.hasAttribute('aria-disabled') ||
    elem.hasAttribute('disabled')
  )
}
