import React from 'react'
import { isSuggestionDisabled } from './index'
import { getStyleProperty } from './index'

/**
 * Get index of selected element
 * @param listRef { Ref }: Suggestions list to perform suggestion search within
 */
export const getFocusedIndex = function (listRef: React.RefObject<HTMLElement>): number {
  if (!listRef.current) return -1

  const items = listRef.current.querySelectorAll('[role=button]')
  for (let i = 0; i < items.length; i++) {
    if (items[i].hasAttribute('aria-current')) return i
  }

  return -1
}

export const moveSuggestionFocus = function (
  direction: number,
  listRef: React.RefObject<HTMLElement>,
  scroll: boolean = false
): HTMLElement | null {
  const items = listRef.current?.querySelectorAll('[role=button]') || []
  const range = [ 0, items.length - 1 ]

  const validate = function (index: number): number {
    if (index < range[0]) {
      return range[1]
    }
    if (index > range[1]) {
      return range[0]
    }
    return index
  }

  let index = validate(getFocusedIndex(listRef) + direction)
  for (let i = 0; i < items.length; i++) {
    if (!isSuggestionDisabled(items[index])) {
      return focusElement(index, listRef, scroll)
    }

    index = validate(index + direction)
  }

  return focusElement(-1, listRef, scroll)
}

export const setSuggestionFocus = function (
  desired: number,
  listRef: React.RefObject<HTMLElement>,
  scroll: boolean = false
): HTMLElement | null {
  if (!listRef.current) return null

  const items = listRef.current.querySelectorAll('[role=button]')
  const range = [ 0, items.length - 1 ]

  /** Drop index to minimum if it's not withing range */
  let index = range[0] > desired || range[1] < desired
    ? range[0]
    : desired

  /** Exclude disabled suggestions */
  while (isSuggestionDisabled(items[index])) {
    index++
  }

  if (index > range[1]) { // All items are disabled
    index = -1
  }

  return focusElement(index, listRef, scroll)
}

/**
 * Adds 'aria-selected' attribute to specific suggestion
 *
 * @param index { ?number }: Index of focused element
 * @param listRef { Ref<HTMLElement> }: Suggestions container
 * @param scroll { boolean }: Should change scroll position of container
 */
export const focusElement = function (
  index: number,
  listRef: React.RefObject<HTMLElement>,
  scroll: boolean = false
): HTMLElement | null {
  if (!listRef.current) return null

  // Ignore elements which are not suggestions (group wrappers)
  const items: NodeListOf<HTMLElement> = listRef.current.querySelectorAll('[role=button]')

  const selected = listRef.current.querySelector('[aria-current]')
  selected && selected.removeAttribute('aria-current')

  for (let i = 0; i < items.length; i++) {
    if (i === index) {
      items[i].setAttribute('aria-current', 'true')
      scroll && scrollToElement(items[i], listRef.current)

      return items[i]
    }

  }

  scroll && (listRef.current.scrollTop = 0)
  return null
}

const scrollToElement = function (element: HTMLElement, listEl: HTMLElement) {
  let positionTop = element.offsetTop
  let parentElement = element.parentElement
  let scrollElement = parentElement

  while (scrollElement && getStyleProperty(scrollElement, 'overflow') !== 'scroll') {
    scrollElement = scrollElement.parentElement
  }

  if (scrollElement) {
    /** Add parent top position to element offsetTop */
    while (parentElement && parentElement !== scrollElement) {
      if (getStyleProperty(parentElement, 'position') !== 'static') {
        positionTop += parentElement.offsetTop
      }
      parentElement = parentElement.parentElement
    }

    const positionBottom = positionTop + element.offsetHeight
    const limitTop = scrollElement.scrollTop
    const limitBottom = limitTop + scrollElement.offsetHeight

    const padding = getStyleProperty(scrollElement, 'padding').split(' ')
    const margin = getStyleProperty(scrollElement, 'margin').split(' ')
    const offsetTop = Number(padding[0].substr(0, padding[0].length - 2))
    const offsetBottom = Number((padding[2] && padding[2].substr(0, padding[2].length - 2)) || offsetTop)
    const marginBottom = Number((margin[2] && margin[2].substr(0, margin[2].length - 2)) || offsetTop)

    if (positionTop - offsetTop < limitTop) {
      scrollElement.scrollTop = positionTop - offsetTop - 1
    }

    if (positionBottom + offsetBottom - marginBottom > limitBottom) {
      scrollElement.scrollTop = (
        positionBottom - scrollElement.offsetHeight + offsetBottom - marginBottom
      )
    }
  }
}
