import React, { useMemo } from "react";
import { List } from "ui-kit";
import { isBitmaskContain } from "ui-kit/helpers";
import { boardTypeList } from "settings";
import { TValue } from "ui-kit/types";

interface Props {
  value: number | null | undefined;
  onChange: (value: number) => void;
}

const MessageFormBoards = function (props: Props) {
  const { value, onChange } = props;

  const boardTypes = useMemo(
    function () {
      return boardTypeList
        .map((x) => x.value)
        .filter((x) => isBitmaskContain(x + 1, value));
    },
    [value]
  ) as TValue[] | undefined;

  const handleChange = function (values: TValue[]) {
    let value = 0;
    (values || []).forEach((x) => {
      value += Math.pow(2, Number(x));
    });

    onChange(value);
  };

  return (
    <div className="message-form-list -boards -cols">
      {/* @ts-ignore */}
      <List
        inline={2}
        label="Тип"
        list={boardTypeList}
        onChange={handleChange}
        value={boardTypes}
      />
    </div>
  );
};

export default MessageFormBoards;
