import React, { useMemo } from "react";
import { IFilterList, ReduxState, RouteParams, TValue } from "../types";
import { setFilter } from "../reducers/settings";
import { List } from "../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const FilterList = function (props: IFilterList) {
  const { name, label, single, list } = props;
  const dispatch = useDispatch();
  const page = (useParams() as RouteParams).page;
  const filters = useSelector(
    ({ settings }: ReduxState) => settings.filters[page],
    shallowEqual
  );
  const summary = useSelector(
    ({ datum }: ReduxState) => datum[page]?.summary,
    shallowEqual
  );

  const items = useMemo(
    function () {
      if (!list) {
        return list;
      }

      return list
        .map((item) => ({
          ...item,
          badge:
            item.total !== undefined
              ? item.total
              : summary
              ? summary?.[name]?.[String(item.value)]
              : "",
        }))
        .filter((item) => !item.hideMissing || item.total);
    },
    [name, list, summary]
  );

  return (
    //@ts-ignore
    <List
      key={name}
      label={label}
      value={single ? filters?.[name]?.[0] : filters?.[name] || []}
      list={items}
      onChange={(value: TValue[] | undefined) =>
        dispatch(setFilter(page, name, value))
      }
    />
  );
};

export default FilterList;
