import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Button } from "ui-kit/controls";
import { Icons, Tabs, Value } from "ui-kit";
import { showFilters } from "ui-kit/reducers/layout";
import { SectionTitle } from "ui-kit/section";
import { FilterSearch } from "ui-kit/filter";
import { createPathURL } from "ui-kit/helpers";
import { Page, AppIcons } from "consts";
import { ReduxState, RouteMatch } from "ui-kit/types";

interface Props {
  onAddPolygon: () => void;
  onDelete: () => void;
  isDrawing: boolean;
  editIndex: number;
  editDisabled: boolean;
  width: number;
  form?: "edit" | "create";
}

const GeozonesHeader = function (props: Props) {
  const {
    onAddPolygon,
    onDelete,
    isDrawing,
    editIndex,
    editDisabled,
    width,
    form,
  } = props;
  const match: RouteMatch = useRouteMatch();
  const hasFilters = useSelector(
    ({ layout }: ReduxState) => layout.showFilters[Page.Geozones]
  );
  const total = useSelector(
    ({ datum }: ReduxState) => datum[Page.Geozones].total
  );
  const dispatch = useDispatch();

  return (
    <>
      <Button
        icon={Icons.FolderOpen}
        onClick={() => dispatch(showFilters(Page.Geozones))}
        active={hasFilters}
        disabled={Boolean(form)}
      />
      <SectionTitle
        label={
          form ? undefined : (
            <span>
              <span>Найдено: </span>
              <Value value={total} />
            </span>
          )
        }
      >
        {form ? "Редактор геозон" : "Геозоны"}
      </SectionTitle>

      {!form && (
        <>
          <Button
            fill
            icon={Icons.Plus}
            href={`${createPathURL(match?.path, {
              ...match?.params,
              form: undefined,
            })}/create`}
            label={width > 750 ? "Добавить геозону" : undefined}
          />

          <FilterSearch />

          <Tabs>
            <Button
              href={createPathURL(match?.path, {
                ...match?.params,
                mode: "map",
              })}
              replace
              clear
              icon={Icons.Map}
            />
            <Button
              href={createPathURL(match?.path, {
                ...match?.params,
                mode: "list",
              })}
              replace
              clear
              icon={Icons.List}
            />
            <Button
              href={createPathURL(match?.path, {
                ...match?.params,
                mode: "tiles",
              })}
              replace
              clear
              icon={Icons.Tiles}
            />
          </Tabs>
        </>
      )}

      {form && (
        <>
          <Tabs>
            <Button
              clear
              icon={AppIcons.ZoneAdd}
              accent
              onClick={onAddPolygon}
              active={isDrawing}
              disabled={editDisabled}
            />
            <Button
              clear
              icon={Icons.Delete}
              danger
              onClick={onDelete}
              disabled={editDisabled || editIndex === -1}
            />
          </Tabs>
        </>
      )}
    </>
  );
};

export default GeozonesHeader;
