import React  from 'react'
import classes from 'classnames'
import { Icon } from '..'
import { Value } from '..'
import { TValue } from '../../types'
import { Icons, Colors } from '../../consts'

export interface IListItem {
  icon?: React.FunctionComponent
  iconColor?: string
  value?: TValue
  label?: string | null | false
  description?: string | null
  selected?: boolean
  badge?: string | number | null | false
  controls?: React.ReactElement
  onClick?: (e: any) => void
  onRemove?: (e: any) => void
  onDelete?: (e: any) => void
  onEdit?: (e: any) => void
  placeholder?: boolean
}

const ListItem = function (props: IListItem) {
  const { label, description, icon, iconColor, selected, badge, placeholder, onClick, onRemove, controls } = props

  return <span
    onClick={onClick}
    className={classes('list-item', selected && '-selected', onClick && '-active' )}
  >
    {icon && <Icon type={icon} color={iconColor} /> }
    <span className={classes('-label', (!label || placeholder) && '-empty')}>
      <Value value={label} />
      {description && <span className='-description'>
        { description }
      </span> }
    </span>
    {badge !== undefined && <span className='-badge'>
      <Value value={ badge === '' ? undefined : badge} />
    </span>}
    { controls || null }
    {onRemove && (
      <Icon
        className='-button -remove'
        type={Icons.Close}
        color={Colors.Red}
        onClick={onRemove}
      />
    )}
  </span>
}

export default ListItem
