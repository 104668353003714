import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'

type TDeps = any[]

const useDidUpdate = function(
  callback: (prevDeps: TDeps) => (((prevDeps: TDeps) => void) | void),
  deps: TDeps
) {
  const [ prevDeps, setPrevDeps ] = useState<TDeps>([])

  useEffect(function () {
    setPrevDeps((p: TDeps) => {
      return deps.findIndex((x, i) => !shallowEqual(p[i], x)) > -1
        ? deps
        : p
    })
  }, [deps])

  useEffect(function () {
    const r = callback(prevDeps)

    return function () {
      if (typeof r === 'function') {
        r(prevDeps)
      }
    }
  }, [prevDeps, callback])
}

export default useDidUpdate
