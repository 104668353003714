import { UserState } from '../types'
import { settingsState } from './settings'

export const userState: UserState = {}

const SET_TOKEN = 'user/SET_TOKEN'

interface SetTokenAction {
  type: typeof SET_TOKEN
  token: string | undefined
}

type UserActions = SetTokenAction

const userReducer = function (state = settingsState, action: UserActions) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token
      }
    default:
      return state
  }
}

export default userReducer;

export const setToken = function (token: string | undefined) {
  return {
    type: SET_TOKEN,
    token
  }
}
