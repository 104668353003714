import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Feature, FeatureCollection, Point } from 'geojson'
import {
  CirclePaint,
  SymbolLayout,
  SymbolPaint,
  EventData,
  GeoJSONSource,
  LngLatLike,
  Map as MapBox,
  MapboxGeoJSONFeature,
  MapMouseEvent,
  Popup
} from 'mapbox-gl'
import MapZoom from './MapZoom'
import {
  MAP_BOUNDS,
  MAP_CENTER,
  MAP_STYLE_URL,
  MAP_TOKEN
} from '../consts'
import { ScrollerOffset } from '../components/Scroller'
import {
  addLineLayers,
  addPointLayers,
  addPolygonLayers,
  getFitBounds
} from './MapView.helpers'
import 'mapbox-gl/dist/mapbox-gl.css'
import './map.scss'

export interface MapViewProps {
  data:
    (FeatureCollection | null | undefined) |
    (FeatureCollection | null | undefined)[]
  dataIndex?: number
  icons?: { [key: string]: string }
  width?: number,
  height?: number,
  selected?: number | number[]
  selectedIndex?: number
  renderPopup?: (f?: Feature) => string
  offset?: ScrollerOffset
  hidden?: boolean
  locked?: boolean
  editMode?: boolean
  onInit?: (map: MapBox) => void
  onClick?: (e: MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined; } & EventData) => void
  hiddenData?: number[]
  centerTo?: Position
  focusTo?:
    (FeatureCollection | null | undefined) |
    (FeatureCollection | null | undefined)[]
  customPaint?: {
    paint?: { circle?: CirclePaint, symbol?: SymbolPaint, selected?: CirclePaint }
    layout?: { symbol?: SymbolLayout }
  }
}

const MapView = function (props: MapViewProps) {
  const {
    data,
    dataIndex,
    width,
    height,
    selected,
    selectedIndex,
    icons,
    renderPopup,
    onClick,
    hidden,
    locked,
    editMode,
    onInit,
    hiddenData,
    customPaint,
    offset
  } = props

  const mapRef = useRef<HTMLDivElement | null>(null)
  const [ zoom, setZoom ] = useState(10)
  const [ map, setMap ] = useState<MapBox | null>(null)
  const popupRef = useRef<null | Popup>(null)

  /** Initialisation */
  useEffect(function () {
    if (mapRef.current) {
      const map = new MapBox({
        container: mapRef.current,
        style: MAP_STYLE_URL,
        accessToken: MAP_TOKEN,
        maxZoom: 16,
        minZoom: 8,
        maxBounds: MAP_BOUNDS,
        center: MAP_CENTER,
        dragRotate: false,
      })

      map.on('load', () => {
        onInit?.(map)
        if (mapRef.current) {
          setMap(map)
        }
      })
    }
  }, [ mapRef, onInit ])

  /** Set offset. E.g to account header/footer height */
  const [ offsetTop, offsetRight, offsetBottom, offsetLeft ] = useMemo(function () {
    return [
      Math.max(0, offset?.top || 0),
      Math.max(0, offset?.right || 0),
      Math.max(0, offset?.bottom || 0),
      Math.max(0, offset?.left || 0)
    ]
  }, [ offset ])

  useEffect(function () {
    map?.setPadding({
      top: 20 + offsetTop,
      bottom: 20 + offsetBottom,
      left: 20 + offsetLeft,
      right: 20 + offsetRight
    })
  }, [ offsetTop, offsetBottom, offsetRight, offsetLeft, map ])

  /** Convert data to Feature collection */
  const mapData = useMemo(function () {
    return (Array.isArray(data) ? data : [ data ])
      .map(data => ({
        data,
        type: (data && data.features[0]?.geometry.type) || null
      }))
  }, [ data ])

  const focusTo = useMemo(function () {
    const data = props.focusTo
    return data
      ? (Array.isArray(data) ? data : [ data ]).map(data => ({
        data,
        type: (data && data.features[0]?.geometry.type) || null
      }))
      : data
  }, [ props.focusTo ])

  /** Lock zoom */
  useEffect(function () {
    if (map) {
      if (locked) {
        map.scrollZoom.disable()
      } else {
        map.scrollZoom.enable()
      }
    }
  }, [ map, locked ])

  /** Mouse enters feature. E.g to show popup */
  const handleMouseEnter = useCallback(function (e: any) {
    const f = e.features?.[0]
    if (map && f) {
      map.getCanvas().style.cursor = 'pointer'

      if (renderPopup) {
        const coordinates = (f.geometry as Point).coordinates
        const popup = new Popup({
          closeButton: false,
          className: 'map-popup'
        })
          .setLngLat(coordinates as LngLatLike)
          .setHTML(renderPopup(f))
          .addTo(map)

        setTimeout(() => popup.addClassName('-visible'), 1)
        popupRef.current = popup
      }
    }
  }, [ map, renderPopup ])

  /** Mouse leaves feature */
  const handleMouseLeave = useCallback(function () {
    if (map) {
      map.getCanvas().style.cursor = ''

      if (popupRef.current) {
        const popup = popupRef.current
        popup?.removeClassName('-visible')
        setTimeout(() => popup?.remove(), 200)
      }
    }
  }, [ popupRef, map ])

  /** Bind basic map events */
  useEffect(function () {
    if (map) {
      map.on('zoomend', () => {
        setZoom(map.getZoom())
        map.resize()
      })
    }
  }, [ map ])

  /** Bind data layers mouse events */
  useEffect(function () {
    if (map) {
      if (!editMode) {
        onClick && map.on('click', `layer${ dataIndex || 0 }`, onClick)
        map.on('mouseenter', `layer${ dataIndex || 0 }`, handleMouseEnter)
        map.on('mouseleave', `layer${ dataIndex || 0 }`, handleMouseLeave)
      }
    }

    return function () {
      if (map) {
        map.off('mouseenter', `layer${ dataIndex || 0 }`, handleMouseEnter)
        map.off('mouseleave', `layer${ dataIndex || 0 }`, handleMouseLeave)
        onClick && map.off('click', `layer${ dataIndex || 0 }`, onClick)
      }
    }
  }, [ map, dataIndex, handleMouseEnter, handleMouseLeave, onClick, editMode ])

  /** Load icons images */
  useEffect(function () {
    if (map && icons) {
      Object.keys(icons).forEach(k => {
        const el = new Image()
        el.src = icons[k]
        el.addEventListener('load', function () {
          if (map.hasImage(k)) map.removeImage(k)
          map.addImage(k, el)
        })
      })
    }
  }, [ map, icons ])

  /** Render data layers */
  useEffect(function () {
    if (!map) return

    mapData.forEach((json, index) => {
      if (json.data && !hiddenData?.includes(index)) {
        map.addSource(`data${ index }`, {
          type: 'geojson',
          data: json.data
        })

        if (json.type === 'Point' || json.type === 'MultiPoint') {
          addPointLayers(map, index, customPaint, editMode)
        } else if (json.type === 'Polygon' || json.type === 'MultiPolygon') {
          addPolygonLayers(map, index, editMode)
        } else if (json.type === 'LineString' || json.type === 'MultiLineString') {
          addLineLayers(map, index, editMode)
        }
      }
    })

    mapData.forEach((json, index) => {
      if (json.data) {
        map.addSource(`selected${ index }`, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        })

        if (json.type === 'Point' || json.type === 'MultiPoint') {
          addPointLayers(map, index, customPaint, editMode, true)
        } else if (json.type === 'Polygon' || json.type === 'MultiPolygon') {
          addPolygonLayers(map, index, editMode, true)
        } else if (json.type === 'LineString' || json.type === 'MultiLineString') {
          addLineLayers(map, index, editMode, true)
        }
      }
    })

    return function () {
      if (map) {
        mapData.forEach((json, index) => {
          if (map.getSource(`data${ index }`)) {
            map.getLayer(`layer${ index }`) && map.removeLayer(`layer${ index }`)
            map.getLayer(`layer${ index }_line`) && map.removeLayer(`layer${ index }_line`)
            map.getLayer(`layer${ index }_icons`) && map.removeLayer(`layer${ index }_icons`)
            map.removeSource(`data${ index }`)
          }

          if (map.getSource(`selected${ index }`)) {
            map.getLayer(`selected_layer${ index }`) && map.removeLayer(`selected_layer${ index }`)
            map.getLayer(`selected_layer${ index }_line`) && map.removeLayer(`selected_layer${ index }_line`)
            map.getLayer(`selected_layer${ index }_icons`) && map.removeLayer(`selected_layer${ index }_icons`)
            map.getLayer(`selected_layer${ index }_area`) && map.removeLayer(`selected_layer${ index }_area`)
            map.removeSource(`selected${ index }`)
          }
        })
      }
    }
  }, [ map, mapData, editMode, selectedIndex, hiddenData, customPaint ])

  /** Set map boundaries */
  useEffect(function () {
    if (map) {
      let fitBounds = null
      if (focusTo) {
        fitBounds = getFitBounds(focusTo)
      } else {
        // Не меняем границы, пока что-то из данных загружается
        if (mapData.some(l => l.data === undefined)) {
          return
        }

        fitBounds = getFitBounds(mapData)
      }

      if (fitBounds) {
        map.fitBounds(fitBounds, { animate: false })
      }
    }
  }, [ map, mapData, focusTo, offsetTop, offsetBottom ])

  /** Render selected feature */
  useEffect(function () {
    const index = selectedIndex === undefined
      ? dataIndex || 0
      : selectedIndex
    if (map && selected && mapData && mapData[index]) {
      const selectedIds: number[] = Array.isArray(selected) ? selected : [selected]
      const data = mapData[index].data
      const source = map.getSource(`selected${index}`) as GeoJSONSource
      if (data && source) {
        source.setData({
          type: 'FeatureCollection',
          features: data.features.filter(x => x.id && selectedIds.includes(+x.id))
        })
      }
    }
  }, [ map, mapData, dataIndex, selectedIndex, selected ])

  /** Center map based on selected feature */
  useEffect(function () {
    const index = selectedIndex === undefined
      ? dataIndex || 0
      : selectedIndex
    if (hidden && mapData[index]?.data && map) {
      if (typeof selected === 'number') {
        const selectedFeature: Feature | undefined = (mapData[index].data as FeatureCollection)
          .features.find(x => x.id === selected)
        if (selectedFeature) {
          const selectedBounds = getFitBounds([{
            type: mapData[index].type,
            data: {
              type: 'FeatureCollection',
              features: [selectedFeature]
            } as FeatureCollection
          }])
          const bounds = map.getBounds().toArray()
          if (selectedBounds) {
            if (
              bounds[0][0] > selectedBounds[0][0] ||
              bounds[1][0] < selectedBounds[1][0] ||
              bounds[0][1] > selectedBounds[0][1] ||
              bounds[1][1] < selectedBounds[1][1]
            ) {
              if (
                bounds[1][0] - bounds[0][0] < selectedBounds[1][0] - selectedBounds[0][0] ||
                bounds[1][1] - bounds[0][1] < selectedBounds[1][1] - selectedBounds[0][1]
              ) {
                map.fitBounds(selectedBounds, { animate: false })
              } else {
                map.setCenter([
                  (selectedBounds[1][0] + selectedBounds[0][0]) / 2,
                  (selectedBounds[1][1] + selectedBounds[0][1]) / 2,
                ], { animate: false })
              }
            }
          }
        }
      }
    }
  }, [ mapData, dataIndex, hidden, selected, selectedIndex, map ])

  useEffect(function () {
    map?.resize()
  }, [ map, width, height ])

  return <>
    <div className='map-view' ref={ mapRef }/>
    <MapZoom
      zoom={ zoom }
      onZoom={ v => {
        map?.setZoom(v)
        map?.resize()
      } }
      disabled={ !map }
    />
  </>
}

export default MapView
