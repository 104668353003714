import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import format from 'date-fns/format'
import classes from 'classnames'
import { Icon } from '../components'
import { Colors, Icons } from '../consts'
import Popup from '../components/Popup'
import Calendar, { isWithTime }  from '../controls/DatePicker/Calendar'
import { getLocalDate, getISODate } from '../helpers'
import { setFilter } from '../reducers/settings'
import { IFilterDate, ReduxState, RouteParams } from '../types'
import 'react-day-picker/lib/style.css'
import '../controls/DatePicker/calendar.scss'
import './filter.scss'

const FilterDate = function (props: IFilterDate) {
  const { label, name } = props
  const dispatch = useDispatch()
  const page = (useParams() as RouteParams).page
  const [ from, to ] = useSelector(({ settings }: ReduxState) =>
    settings.filters[page]?.[name] || [],
    shallowEqual
  )

  const value = useMemo(function () {
    return [
      from ? from.toString() : undefined,
      to ? to.toString() : undefined
    ]
  }, [ from, to ])

  const handleDispatchValue = useCallback(function ([from, to]: (string | undefined)[]) {
    if (value[0] !== from || value[1] !== to) {
      if (
        (!from || format(new Date(from), 'HH:mm') === '00:00') &&
        (!to || format(new Date(to), 'HH:mm') === '23:59')
      ) {
        setShowTime(false)
      }
      dispatch(setFilter(page, name, (from || to) ? [from, to] : undefined))
    }
  }, [ dispatch, value, name, page ])

  const [ inputs, setInputs ] = useState([ '', '' ])
  const [ dateValues, setDateValues ] = useState<(Date | undefined)[]>([ undefined, undefined ])
  const [ focused, setFocused ] = useState(-1)
  const [ isOpened, setOpened ] = useState(false)
  const [ showTime, setShowTime ] = useState(false)
  const blockRef = useRef<HTMLDivElement | null>(null)
  const timerRef = useRef(0)
  const inputRefs = [
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null)
  ]
  const sizesRefs = [
    useRef<HTMLSpanElement | null>(null),
    useRef<HTMLSpanElement | null>(null)
  ]

  useEffect(function () {
    return function () {
      clearTimeout(timerRef.current)
      if (isOpened) {
        timerRef.current = window.setTimeout(() => {
          let updateValues: (string | undefined)[] = []
          setDateValues(dateValues => {
            updateValues = dateValues.map(x => getISODate(x))
            return dateValues
          })
          handleDispatchValue(updateValues)
        }, 100)
      }
    }
  }, [ isOpened, handleDispatchValue ])

  useEffect(function () {
    const dates = value.map(x => getLocalDate(x))
    setShowTime(isWithTime(dates[0], dates[1]))
    setInputs(dates.map(x => x ? format(x, `dd.MM.yyyy HH:mm`) : ''))
    setDateValues(dates)
  }, [ value ])

  useEffect(function () {
    if (!showTime) {
      setDateValues(values => [
        values[0] ? new Date(values[0].setHours(0,0,0,0)) : undefined,
        values[1] ? new Date(values[1].setHours(23,59,59,999)) : undefined,
      ])
    }
  }, [ showTime ])

  const handleUpdateDates = function (date: Date | number | undefined) {
    let update = [...dateValues]
    update[focused] = date ? new Date(date) : undefined
    if (update[0] && update[1] && update[0] > update[1]) {
      update[1] = undefined
    }
    setInputs(update.map(x => x
      ? format(x, 'dd.MM.yyyy HH:mm')
      : ''
    ))
    setDateValues(update)

    if (date && !showTime) {
      setTimeout(() => {
        inputRefs[focused === 0 ? 1 : 0].current?.focus()
        inputRefs[focused === 0 ? 1 : 0].current?.click()
      }, 1)
    }
  }

  const handleInputChange = useCallback(function (e: any) {
    const reDate = /^(\d|([012]\d?)|3[01])((\/|-|\.)((\d|(0\d?)|(10|11|12))((\/|-|\.)(\d{1,4})?)?)?)?$/
    const reTime = /^(\d|([01]\d)|(2[0123]))(:|(:\d)|(:[012345]\d))?$/
    const val = e.currentTarget.value
    const [ date, time ] = val.split(' ')

    if (!val || showTime ? (reDate.test(date) && (!time || reTime.test(time))) : reDate.test(val)) {
      const localDate = getInputDate(date, time, focused)
      setInputs(inputs => inputs.map((v, i) => i === focused ? val : v))
      setDateValues(dates => dates.map((x, i) => i === focused
        ? localDate
        : x
      ))
    }

    !isOpened && setOpened(true)
  }, [ focused, isOpened, showTime ])

  return <>
    <span className='list-label'>
      {label}
    </span>
    <div
      className={classes('filter-date', showTime && '-time')}
      ref={ blockRef }
    >
      <div className='filter-date-controls'>
        <Icon
          type={ Icons.Calendar }
          color={ Colors.Blue }
          onClick={() => inputRefs[0].current?.focus()}
        />
        {inputs.map((v, i) => {
          const value = v.substr(0, showTime ? 16 : 10)
          return <span className='-input' key={i}>
            <span
              className='-size'
              key={ `size${ i }` }
              ref={ el => {
                sizesRefs[i].current = el
              } }
            >
              { value || placeholders[i] }
            </span>

            <input
              value={ value }
              ref={ el => {
                inputRefs[i].current = el
              } }
              onChange={ e => handleInputChange(e) }
              onBlur={ () => {
                setFocused(-1)
                setInputs(inputs => inputs.map((x, i) => {
                  const val = dateValues[i]
                  return val ? format(val, `dd.MM.yyyy HH:mm`) : x
                }))
              } }
              placeholder={ placeholders[i] }
              onFocusCapture={ () => {
                setFocused(i)
                setOpened(true)
              } }
              onMouseDown={ () => focused === i && setOpened(!isOpened) }
              onKeyPress={ (e) => {
                if (e.key === 'Enter') {
                  handleUpdateDates(dateValues[i]);
                  setOpened(false)
                }
              }}
              onKeyDown={ (e) => e.key === 'Escape' && setOpened(false) }
            />
          </span>
        })}
      </div>

      <Popup isOpened={isOpened} onToggle={setOpened}>
        <Calendar
          startDate={dateValues[0]}
          endDate={dateValues[1]}
          onChange={handleUpdateDates}
          from={focused === 1 ? dateValues[0] : undefined}
          endOfDay={focused === 1}
          displayMonth={dateValues[focused] || dateValues[0] || dateValues[1]}
          activeDate={focused === 1 ? 'end' : 'start'}
          withTime={v => setShowTime(v)}
        />
      </Popup>
    </div>
  </>
}

export default FilterDate


const placeholders = [ 'От', 'До' ]

const getInputDate = function (date: string, time: string | undefined, focused: number) {
  const bits = date.replace(/[/-]/g, '.')
    .split('.')
    .map((x: string) => x === '' ? undefined : x)
  bits[2] = bits[2]?.substr(0, 4)

  if (bits[0]) {
    let defaultDate = new Date()
    defaultDate.setFullYear(
      bits[2] && (bits[2].length === 2 || bits[2].length === 4)
        ? Number(`20${bits[2]}`.substr(-4))
        : defaultDate.getFullYear(),
      bits[1] ? Number(bits[1]) - 1 : defaultDate.getMonth(),
      bits[0] ? Number(bits[0]) : defaultDate.getDay()
    )

    if (!time) {
      if (focused === 0) {
        defaultDate.setHours(0,0,0,0)
      }
      if (focused === 1) {
        defaultDate.setHours(23,59,59,999)
      }
    } else {
      defaultDate.setHours(+time.split(':')[0], +time.split(':')[1] || 0, 0, 0)
    }

    return defaultDate || undefined
  } else {
    return undefined
  }
}
