import React, { useMemo, useState } from 'react'
import classes from 'classnames'
import { Value } from '../components'
import { Textbox } from '../controls'
import { Icons } from '../consts'
import './datum.scss'

interface DatumProps {
  label?: any
  children?: any
  items?: any[] | undefined | null
  renderer?: (item: any, index: number) => React.ReactElement[]
  classNames?: (string | null)[] | ((row: number) => (string | null)[])
  sizes?: (number | string)[]
  rowProps?: (item: any, index: number) => any
  simple?: boolean,
  header?: string[]
  filter?: (query: string, item: any) => boolean
  placeholder?: string
}

const Datum = function (props: DatumProps) {
  const {
    label,
    children,
    items,
    renderer,
    sizes,
    rowProps,
    simple,
    classNames,
    header,
    filter,
    placeholder
  } = props

  const [query, setQuery] = useState('')

  const filtered = useMemo(function () {
    if (!filter || !query) {
      return items
    }

    return items
      ? items.filter(x => filter(query, x))
      : items
  }, [ items, filter, query ])

  return <dl className={ classes('datum', simple && '-simple') }>
    { label && <dt className='datum-label'>{ label }</dt> }

    { filter && <dd className='-search'>
      <Textbox
        icon={ Icons.Search }
        small
        value={query}
        onChange={e => setQuery(e.currentTarget.value)}
        placeholder='Поиск'
        disabled={!items}
      />
    </dd> }

    { header && <dd className='-header'>
      { header.map((label, i) => (
        <span style={ { maxWidth: sizes?.[i] || 'unset' } } key={ label }>
          { label }
        </span>
      )) }
    </dd> }

    { children ?
      <>
        {Array.isArray(children)
          ? children.map((row, i) => <dd key={i} { ...(rowProps ? rowProps(row, i) : {}) }>
            { Array.isArray(row)
              ? row.map((cell, j) => (
                <span
                  className={ classes('datum-cell', (
                    typeof classNames === 'function' ? classNames(i)[j] : classNames?.[j]
                  )) }
                  key={ j }
                  style={ { maxWidth: sizes?.[j] || 'unset' } }
                >
                 { cell }
                </span>
              ))
              : <span
                className={ classes('datum-cell', (
                  typeof classNames === 'function' ? classNames(0)[0] : classNames?.[0]
                )) }
                style={ { maxWidth: sizes?.[0] || 'unset' } }
              >
                { children }
              </span>
            }
          </dd>)
          : <dd { ...(rowProps ? rowProps(null, 0) : {}) }>
            <span
              className={ classes('datum-cell', (
                typeof classNames === 'function' ? classNames(0)[0] : classNames?.[0]
              )) }
              style={ { maxWidth: sizes?.[0] || 'unset' } }
            >
              { children }
            </span>
          </dd>}
      </>
      : children !== undefined
        ? <dd className='-empty'>
          <span className='datum-cell -label'>
            <Value value={children} />
          </span>
        </dd>
        : null
    }

    { renderer !== undefined && <>
      {filtered
        ? filtered.length > 0
          ? filtered.map((row: any[], i) => (
            <dd key={ i } { ...(rowProps ? rowProps(row, i) : {}) }>
              { renderer(row, i).map((cell, j) => (
                <span
                  className={ classes('datum-cell', (
                    typeof classNames === 'function' ? classNames(i)[j] : classNames?.[j]
                  )) }
                  key={ j }
                  style={ { maxWidth: sizes?.[j] || 'unset' } }
                >
                  { cell }
                </span>
              )) }
            </dd>
          ))
          : <dd className='-empty'>
            <span className='datum-cell -label'>
              { placeholder || 'Ничего не найдено'}
            </span>
          </dd>
        : <dd className='-empty'>
          <span className='datum-cell -label'>
            <Value value={ filtered }/>
          </span>
        </dd>
      }
    </>}
  </dl>
}

export default Datum
