import React from 'react'
import classes from 'classnames'
import './tabs.scss'

interface TabsProps {
  children: any
  wide?: boolean
  blur?: boolean
}

const Tabs = function (props: TabsProps) {
  const { wide, blur, children } = props

  return <div className={classes('tabs', wide && '-wide', blur && '-blur')}>
    {children}
  </div>
}

export default Tabs
