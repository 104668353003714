import { arrify, isEmpty, matchTypes } from './index'
import { TItem, TKeys, TOptions } from './filterArray'

const flatItem = function (
  item: any,
  values: Array<string>,
  { exclude, narrow }: TOptions
): Array<TItem> {
  if (isEmpty(item)) {
    return []
  }

  if (matchTypes(item, 'string', 'number')) {
    return [ item ]
  }

  if (matchTypes(item, 'array')) {
    return flatArray(item, values, { exclude, narrow }) || []
  }

  if (matchTypes(item, 'object')) {
    const keys = Object.keys(item)

    const obj = { ...item }
    let flatten: Array<TItem> = []
    let add = false

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]

      if (
        Array.isArray(item[key]) &&
        (narrow.length > 0 ? narrow.indexOf(key) > -1 : exclude.indexOf(key) === -1)
      ) {
        flatten = [
          ...flatten,
          ...(flatArray(item[key], values, { exclude, narrow }) || [])
        ]

        delete obj[key]
      } else {
        add = add || (values.length === 0 || values.indexOf(key) > -1)
      }
    }

    if (add) {
      flatten = [ obj, ...flatten ]
    }

    return flatten
  }

  return []
}

export const flatArray = function (
  list: Array<any>,
  values: TKeys | string = [],
  { exclude, narrow }: TOptions = { exclude: [], narrow: [] }
): Array<any> | null {
  if (!Array.isArray(list)) return null

  let flatten: Array<any> = []
  for (let i = 0; i < list.length; i++) {
    flatten = [
      ...flatten,
      ...flatItem(
        list[i],
        arrify(values),
        { exclude: arrify(exclude), narrow: arrify(narrow) }
      )
    ]
  }

  return flatten
}
