import React, { useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Badge, Value, Tabs, Space, SectionTitle, Icons } from "ui-kit";
import { Button } from "ui-kit/controls";
import { MapPreview } from "ui-kit/map";
import { useMapData } from "components/Infoboards";
import { createPathURL } from "ui-kit/helpers";
import {
  setRedirect,
  arhiveContent,
  extractContent,
  publishContent,
  unPublishContent,
} from "actions";
import { Page, statusColors, statuses } from "consts";
import { Feature } from "geojson";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { StyleFunction } from "mapbox-gl";

const boardsMapPaint = {
  paint: {
    selected: {
      "circle-radius": 14,
      "circle-stroke-width": 2,
      "circle-stroke-color": "rgba(87,132,255, 0.8)",
      "circle-color": "rgba(87,132,255,0.8)",
    },
    circle: {
      "circle-radius": 1,
      "circle-color": { type: "identity", property: "color" } as StyleFunction,
    },
    symbol: {
      "text-color": "#FFFFFF",
      "icon-opacity": 1,
    },
  },
  layout: {
    symbol: {
      "icon-image": { type: "identity", property: "icon" } as StyleFunction,
      "text-field": ["get", "label"] as StyleFunction,
      "icon-size": 0.33,
      "icon-allow-overlap": true,
    },
  },
};

const MessagesCardHeader = function (props: { selected?: number }) {
  const match: RouteMatch = useRouteMatch();
  const dispatch = useDispatch();
  const data = useSelector(
    ({ details }: ReduxState) => details[Page.Messages].data,
    shallowEqual
  );
  const boards = useSelector(
    ({ details }: ReduxState) => details[Page.Messages].boards,
    shallowEqual
  );
  const [geoJSON, icons] = useMapData(boards);

  const handleMapClick = useCallback(
    function (e: any) {
      dispatch(
        setRedirect(`/${Page.Infoboards}/list/${e.features[0]?.id || ""}`, true)
      );
    },
    [dispatch]
  );

  const handleRenderPopup = useCallback(function (feature?: Feature) {
    if (!feature || !feature.properties) {
      return "";
    }

    const { num, status, address } = feature.properties;

    return `<div class="map-popup-board">
      <div class="-title">ТОИ №${num}</div>
      <div class="-status" style="color: ${statusColors[status]}">
        ${statuses[status]}
      </div>
      <div class="-address">${address}</div>
    </div>`;
  }, []);

  const handlePublish = useCallback(
    function () {
      if (data) {
        dispatch(
          data.isPublished ? unPublishContent(data.id) : publishContent(data.id)
        );
      }
    },
    [data, dispatch]
  );

  return (
    <>
      <Button icon={Icons.Edit} href={`${match?.url}/edit`} disabled={!data} />
      <Button
        icon={Icons.Archive}
        onClick={() =>
          data?.isRemoved
            ? dispatch(extractContent(Number(match?.params?.id || 0), match))
            : dispatch(arhiveContent(Number(match?.params?.id || 0), match))
        }
        disabled={!data}
      />
      <Space />
      <Button
        fill={!data?.isPublished}
        icon={Icons.Share}
        danger={data?.isPublished}
        label={
          !data
            ? undefined
            : data.isPublished
            ? "Снять с публикации"
            : "Опубликовать"
        }
        disabled={!data}
        onClick={handlePublish}
      />
      <hr />
      <SectionTitle>
        <Value value={data ? data.name : data} />
      </SectionTitle>
      <hr />
      <Tabs wide>
        <Button
          large
          clear
          exact
          replace
          href={createPathURL(match?.path, { ...match?.params, tab: "" })}
        >
          Основное
        </Button>
        <Button
          large
          clear
          exact
          replace
          href={createPathURL(match?.path, { ...match?.params, tab: "boards" })}
        >
          <Badge disabled={!data || data.bindedBoardsCount === 0}>
            {data && data.bindedBoardsCount}
          </Badge>
          Табло
        </Button>
        <Button
          large
          clear
          exact
          replace
          href={createPathURL(match?.path, { ...match?.params, tab: "log" })}
        >
          История
        </Button>
      </Tabs>
      {match?.params?.tab === "boards" && <hr />}
      <MapPreview
        locked
        hidden={match?.params?.tab !== "boards"}
        data={geoJSON}
        icons={icons}
        onClick={handleMapClick}
        renderPopup={handleRenderPopup}
        selected={props.selected}
        customPaint={boardsMapPaint}
      />
    </>
  );
};

export default MessagesCardHeader;
