import { useEffect } from 'react'
import { GeoJSONSource, Map } from 'mapbox-gl'
import { Position, FeatureCollection } from 'geojson'

export const useLayersInit = function (
  map: Map | null, isActive: boolean, prefix: string
) {
  useEffect(function () {
    if (map && isActive) {
      setTimeout(() => {
        map.addSource(`${prefix}_nodes`, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        })

        map.addSource(`${prefix}_lines`, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        })

        map.addSource(`${prefix}_polygons`, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        })

        map.addLayer({
          id: `${prefix}_polygons_layer`,
          source: `${prefix}_polygons`,
          type: 'fill',
          paint: {
            'fill-color': { type: 'identity', property: 'fill' },
            'fill-opacity': { type: 'identity', property: 'opacity' }
          }
        })

        map.addLayer({
          id: `${prefix}_lines_layer`,
          source: `${prefix}_lines`,
          type: 'line',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': { type: 'identity', property: 'fill' },
            'line-width': { type: 'identity', property: 'stroke-width'},
          }
        })

        if (prefix === 'data') {
          map.addLayer({
            id: `data_lines_helper`,
            source: `data_lines`,
            type: 'line',
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-width': 7,
              'line-opacity': 0
            }
          })
        }

        map.addLayer({
          id: `${prefix}_nodes_layer`,
          source: `${prefix}_nodes`,
          type: 'circle',
          paint: {
            'circle-radius': { type: 'identity', property: 'size'},
            'circle-color': { type: 'identity', property: 'fill' },
            'circle-stroke-width': { type: 'identity', property: 'stroke-width' },
            'circle-stroke-color': { type: 'identity', property: 'stroke'},
          }
        })

        if (prefix === 'data') {
          map.addLayer({
            id: `data_nodes_helper`,
            source: `data_nodes`,
            type: 'circle',
            paint: {
              'circle-radius': 6,
              'circle-opacity': 0
            }
          })
        }
      }, 1)
    }

    return function () {
      if (map) {
        if (prefix === 'data') {
          map.getLayer(`data_nodes_helper`) && map.removeLayer(`data_nodes_helper`)
          map.getLayer(`data_lines_layer`) && map.removeLayer(`data_lines_helper`)
        }
        map.getLayer(`${prefix}_nodes_layer`) && map.removeLayer(`${prefix}_nodes_layer`)
        map.getLayer(`${prefix}_lines_layer`) && map.removeLayer(`${prefix}_lines_layer`)
        map.getLayer(`${prefix}_polygons_layer`) && map.removeLayer(`${prefix}_polygons_layer`)
        map.getSource(`${prefix}_nodes`) && map.removeSource(`${prefix}_nodes`)
        map.getSource(`${prefix}_polygons`) && map.removeSource(`${prefix}_polygons`)
        map.getSource(`${prefix}_lines`) && map.removeSource(`${prefix}_lines`)
      }
    }
  }, [ map, isActive, prefix ])
}


const getPolygonGeoJSON = function (selected: number, data?: null | Position[][]) {
  return {
    type: 'FeatureCollection',
    features: (data || [[]]).map((f, i) => {
      return {
        type: 'Feature',
        id: i,
        geometry: {
          type: 'Polygon',
          coordinates: [[...f]],
        },
        properties: {
          fill: '#5784FF',
          opacity: selected === i ? 0.6 : 0.3
        }
      }
    })
  } as FeatureCollection
}

const getLineGeoJSON = function (selected: number, data?: null | Position[][]) {
  return {
    type: 'FeatureCollection',
    features: (data || [[]]).map((f, i) => {
      return {
        type: 'Feature',
        id: i,
        geometry: {
          type: 'LineString',
          coordinates: [...f],
        },
        properties: {
          fill: '#5784FF',
          'stroke-width': selected === i ? 2 : 1,
        }
      }
    })
  } as FeatureCollection
}

const getPointsGeoJSON = function (selected: number, data?: null | Position[]) {
  return {
    type: 'FeatureCollection',
    features: (data || []).map((f, i) => ({
      type: 'Feature',
      id: i,
      geometry: {
        type: 'Point',
        coordinates: f,
      },
      properties: {
        size: selected === i ? 4 : 3,
        'stroke-width': selected === i ? 2 : 1,
        stroke: selected === i ? '#FFFFFF' : '#5784FF',
        fill: selected === i ? '#5784FF' : '#FFFFFF'
      }
    }))
  } as FeatureCollection
}

export const renderLayers = function (
  map: Map | null,
  prefix: 'data' | 'draw',
  data: null | undefined | Position[][],
  editIndex: number,
  nodeIndex: number
) {
  if (map && data) {
    (map.getSource(`${prefix}_polygons`) as GeoJSONSource)?.setData(getPolygonGeoJSON(editIndex, data));
    (map.getSource(`${prefix}_lines`) as GeoJSONSource)?.setData(getLineGeoJSON(editIndex, data));
    (map.getSource(`${prefix}_nodes`) as GeoJSONSource)?.setData(getPointsGeoJSON(nodeIndex, data?.[editIndex]));
  }
}

export const findLineIndex = function (point: Position, lines: Position[]) {
  let index = -1
  let min = 2

  for (let i = 1; i < lines.length; i++) {
    const a = lines[i]
    const b = lines[i-1]
    const length = Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2)
    const delta = (
      (Math.pow(point[0] - a[0], 2) + Math.pow(point[1] - a[1], 2)) +
      (Math.pow(point[0] - b[0], 2) + Math.pow(point[1] - b[1], 2))
    ) / length

    if (delta < min) {
      index = i
      min = delta
    }
  }

  return index
}
