import React, { Dispatch } from "react";
import { Textarea } from "ui-kit/controls";
import { FormControl } from "ui-kit/form";
import { setPrompt } from "ui-kit/reducers/layout";
import { BoardFormat, FrameKind } from "consts";
import { IContent } from "models/IContent";
import "./message-form.scss";
import { SvgForm } from "ui-kit/svgForm";
import svg64 from "./svg64";

const defaultValue = 426;

const aspectRatioMap: { [key in BoardFormat]: number } = {
  [BoardFormat.FourThree]: 4 / 3,
  [BoardFormat.TwoThree]: 2 / 3,
  [BoardFormat.FiveOne]: 5 / 1,
  [BoardFormat.OneOne]: 1 / 1,
  [BoardFormat.ThreeHalfOne]: 3.6 / 1,
  [BoardFormat.Unknown]: 4 / 3,
};

const calculateHeight = (format: BoardFormat) => {
  const aspectRatio = aspectRatioMap[format];
  return parseFloat((defaultValue / aspectRatio).toFixed(2));
};

export const getFrameContent = function (
  dispatch: Dispatch<any>,
  kind?: FrameKind,
  initialData?: any,
  format?: BoardFormat
) {
  return new Promise<{ [key: string]: any }>(async function (resolve, reject) {
    switch (kind) {
      case FrameKind.StaticImage:
        try {
          const [base64data, url] = await getLocalImage();
          resolve({ base64data, url });
        } catch (error) {
          reject(error);
        }
        return;

      case FrameKind.Video:
        try {
          const [base64data, url] = await getLocalVideo();
          const video = document.createElement("video");
          video.src = `data:video/mp4;base64,${base64data}`;
          video.addEventListener("loadeddata", function () {
            resolve({
              base64data,
              url,
              playInterval: Math.floor((video.duration || 0) * 1000),
            });
          });
        } catch (error) {
          reject(error);
        }
        return;

      case FrameKind.UrlSource:
        return dispatch?.(
          setPrompt({
            component: MessagesFormUrlForm,
            initialState: initialData,
            onCancel: () => reject(""),
            buttons: [
              {
                label: initialData ? "Сохранить" : "Добавить",
                props: (value: any) => ({
                  disabled: !value?.url || !value?.isChanged,
                }),
                default: true,
                fill: true,
                callback: (value: any) => {
                  value?.url && resolve({ url: value.url });
                },
              },
            ],
          })
        );

      case FrameKind.MessageEditor:
        return dispatch?.(
          setPrompt({
            component: MessageEditorForm,
            initialState: initialData,
            onCancel: () => reject(""),
            format,
            buttons: [
              {
                label: initialData ? "Сохранить" : "Добавить",
                default: true,
                fill: true,
                props: (value: any) => {
                  const { SVGText, SVGTitle } = value;
                  return {
                    disabled: !SVGText?.length || !SVGTitle?.length,
                  };
                },
                callback: (e: any) => {
                  const SVGDomElement =
                    document.getElementById("message-screen");

                  const SVGTitle =
                    document.getElementById("message-title")?.innerHTML;
                  const SVGText =
                    document.getElementById("message-text")?.innerHTML;

                  const serializedSVG =
                    SVGDomElement &&
                    new XMLSerializer().serializeToString(SVGDomElement);

                  const base64dataSVG = serializedSVG && svg64(serializedSVG);

                  const canvas = document.createElement("canvas");
                  canvas.width = defaultValue;
                  canvas.height = calculateHeight(format!);
                  const ctx = canvas.getContext("2d");
                  const img = document.createElement("img");

                  if (base64dataSVG) {
                    img.setAttribute(
                      "src",
                      "data:image/svg+xml;base64," + base64dataSVG
                    );
                    img.onload = () => {
                      ctx!.drawImage(img, 0, 0);
                      const base64dataPng = canvas.toDataURL("image/png");
                      const pngLength = base64dataPng.length - 1;
                      const base64data = base64dataPng.slice(22, pngLength);

                      resolve({
                        base64data,
                        url: "1.png",
                        editorJson: JSON.stringify({ SVGTitle, SVGText }),
                      });
                    };
                  }
                },
              },
            ],
          })
        );

      default:
        reject("");
    }
  });
};

const getLocalImage = async function (): Promise<[string, string]> {
  return new Promise((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    setTimeout(() => input.click(), 1);
    input.addEventListener("change", function (e: any) {
      const file = e.currentTarget.files[0];
      if (file) {
        const fileReader = new FileReader();

        fileReader.onload = function (e) {
          if (e.target?.result) {
            const base64data = e.target.result.toString();
            const image = new Image();
            image.src = base64data;

            image.onerror = function () {
              reject("Файл повреждён или не является изображением");
            };

            image.onload = function () {
              if (!image.width || !image.height) {
                reject("Файл повреждён или не является изображением");
              }

              resolve([
                base64data.substr(base64data.indexOf("base64,") + 7),
                file.name,
              ]);
            };
          }
        };

        fileReader.readAsDataURL(file);
      }
    });
  });
};

const getLocalVideo = async function (): Promise<[string, string]> {
  return new Promise((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "video/mp4";
    input.click();
    input.addEventListener("change", function (e: any) {
      const file = e.currentTarget.files[0];
      if (file) {
        const fileReader = new FileReader();

        fileReader.onload = function (e) {
          if (e.target?.result) {
            const base64data = e.target.result.toString();
            if (base64data.indexOf("base64,") > -1) {
              resolve([
                base64data.substr(base64data.indexOf("base64,") + 7),
                file.name,
              ]);
            }
          }
        };

        fileReader.readAsDataURL(file);
      }
    });
  });
};

const MessagesFormUrlForm = function (props: {
  value: { url?: string };
  onChange: (s: { url: string; isChanged: boolean }) => void;
}) {
  const { value, onChange } = props;

  return (
    <>
      <FormControl
        label="Динамическая ссылка"
        component={Textarea}
        value={value?.url || ""}
        onChange={(e: any) =>
          onChange({ url: e.currentTarget.value, isChanged: true })
        }
        size={40}
        placeholder="Введите URL"
        tabIndex={0}
      />
    </>
  );
};

export const MessageEditorForm = function (props: {
  value: { editorJson?: string };
  format: BoardFormat;
  onChange: (s: { SVGText: string; SVGTitle: string }) => void;
}) {
  const editorJson = props.value.editorJson;
  const { SVGText: text, SVGTitle: title } = editorJson
    ? JSON.parse(editorJson)
    : { SVGText: "", SVGTitle: "" };
  const width = defaultValue;
  const height = calculateHeight(props.format);
  return (
    <div className="popup-wrapper">
      <SvgForm
        title={title}
        text={text}
        format={props.format}
        onChange={props.onChange}
        width={width}
        height={height}
      />
    </div>
  );
};

export const validateTime = function (start: string, stop: string) {
  if (start && stop) {
    const period = [
      start.substr(0, 5),
      stop.substr(0, 5) === "00:00" ? "24:00" : stop.substr(0, 5),
    ].sort();
    if (
      start.substr(0, 5) !== "00:00" &&
      start.substr(0, 5) === stop.substr(0, 5)
    ) {
      return "equal";
    }

    if (start.substr(0, 5) !== period[0]) {
      return "invalid";
    }
  }

  return false;
};

export const validateMessage = function (value: IContent) {
  return {
    name: !value || !value.name,
    schedule:
      value.schedule &&
      value.schedule.some(
        (x) => x.time && validateTime(x.time.start || "", x.time.stop || "")
      ),
    frameSets:
      !value ||
      !value.frameSets ||
      value.frameSets.filter((x) => x.frames?.length).length === 0,
  };
};
