import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { Button, Number } from "ui-kit/controls";
import { Icon, Icons, Image, Popup } from "ui-kit";
import { getFrameContent } from "./MessageForm.helpers";
import { setPrompt } from "actions";
import {
  AppIcons,
  BoardFormat,
  FrameKind,
  FrameTransition,
  frameTransitions,
  Photo,
} from "consts";
import { IFrame } from "models/IFrame";

interface Props {
  value: IFrame | null | undefined;
  onChange: (name: string | string[], value: any) => void;
  onDelete: () => void;
  format: BoardFormat;
}

const SortHandler = SortableHandle(Button);

const MessageFormFrame = function (props: Props) {
  const dispatch = useDispatch();
  const { value, onChange, onDelete, format } = props;
  const [playInterval, setPlayInterval] = useState<string | number>("");
  const [transitionInterval, setTransitionInterval] = useState<string | number>(
    ""
  );
  const { transition } = value || {};

  const handleClickSettings = async function (method: string) {
    switch (method) {
      case "edit":
        try {
          const data = await getFrameContent(
            dispatch,
            value?.kind,
            value,
            format
          );
          onChange(Object.keys(data), Object.values(data));
        } catch (error) {
          error && dispatch(setPrompt({ message: error }));
        }
        return;

      case "delete":
        return onDelete();

      default:
        return;
    }
  };

  useEffect(() => {
    setPlayInterval(
      value?.playInterval === undefined ? 20 : value.playInterval / 1000
    );
    setTransitionInterval(
      value?.transitionInterval === undefined
        ? 1
        : value.transitionInterval / 1000
    );
  }, [value]);

  const settingsList = useMemo(() => {
    return [
      { label: "Редактировать", icon: Icons.Edit, value: "edit" },
      { label: "Удалить", icon: Icons.Close, value: "delete" },
    ];
  }, []);

  const transitionsList = useMemo(
    function () {
      return frameTransitions.map((x) => ({
        ...x,
        icon:
          x.value === (transition || FrameTransition.None)
            ? Icons.Check
            : undefined,
      }));
    },
    [transition]
  );

  const style = useMemo(
    function () {
      switch (format) {
        case BoardFormat.FiveOne:
          return { height: 120, width: 600 };
        case BoardFormat.FourThree:
          return { height: 120, width: 160 };
        case BoardFormat.TwoThree:
          return { height: 120, width: 80 };
        case BoardFormat.ThreeHalfOne:
          return { height: 120, width: 432 };
        default:
          return { height: 120, width: 120 };
      }
    },
    [format]
  );

  const imageProps = useMemo(() => {
    if (value?.kind === FrameKind.StaticImage) {
      // console.log(value?.base64data);
      return {
        src: value?.base64data
          ? `data:image/png;base64,${value.base64data}`
          : value?.base64data,
        type: Photo.Frame,
        id: value?.id,
      };
    } else if (value?.kind === FrameKind.Video) {
      return {
        src: value?.base64data
          ? `data:video/mp4;base64,${value.base64data}`
          : value?.base64data,
        type: Photo.Frame,
        id: value?.id,
        isVideo: true,
      };
    } else if (value?.kind === FrameKind.UrlSource) {
      return {
        src: null,
        className: "message-form-url",
        placeholder: value?.url ? (
          <>
            <Icon type={AppIcons.FrameType(value.kind)} />
            <span>{value?.url}</span>
          </>
        ) : undefined,
      };
    } else if (value?.kind === FrameKind.MessageEditor) {
      return {
        src: "data:image/png;base64," + value.base64data,
        type: Photo.Frame,
        id: value?.id,
      };
    } else {
      return undefined;
    }
  }, [value]);

  return (
    <div className="message-form-image">
      <div className="message-form-aside">
        <Number
          small
          center
          step={0.1}
          min={0}
          max={2}
          toFixed={1}
          value={value?.transition ? transitionInterval : 0}
          onChange={(e) => setTransitionInterval(e.currentTarget.value)}
          onBlur={() =>
            onChange(
              "transitionInterval",
              Math.floor(+transitionInterval * 1000 || 0)
            )
          }
          disabled={!value?.transition}
        />
        <Popup
          items={transitionsList}
          onClick={(transition: number) => onChange("transition", transition)}
          triggerProps={({ isActive, onClick }: any) => ({
            onClick: onClick,
            active: isActive,
            icon: AppIcons.Effect,
            accent: Boolean(transition),
          })}
          trigger={Button}
        />
      </div>

      <Image {...imageProps} style={style} cover />

      <div className="message-form-footer">
        <Number
          small
          center
          step={0.1}
          min={0}
          max={600}
          toFixed={1}
          value={playInterval}
          onChange={(e) => setPlayInterval(e.currentTarget.value)}
          onBlur={() =>
            onChange("playInterval", Math.floor(+playInterval * 1000 || 0))
          }
        />

        <div className="buttons">
          <Popup
            items={settingsList}
            triggerProps={({ isActive, onClick }: any) => ({
              onClick: onClick,
              active: isActive,
              icon: Icons.Settings,
              clear: true,
              small: true,
            })}
            trigger={Button}
            onClick={handleClickSettings}
          />
          {/* @ts-ignore */}
          <SortHandler
            className="-sort-handler"
            clear
            small
            icon={Icons.Menu}
          />
        </div>
      </div>
    </div>
  );
};

export default SortableElement(MessageFormFrame);
