import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Map as MapBox } from "mapbox-gl";
import { useAccessToken } from "@megapolis/react-auth";
import classes from "classnames";
import {
  Badge,
  Colors,
  Datum,
  Icon,
  Icons,
  LayoutArea,
  SectionTitle,
  Space,
  Tabs,
  Value,
} from "ui-kit";
import { Button, Checkbox } from "ui-kit/controls";
import Section from "ui-kit/section";
import Map, { TMapEditData, useMapEditing } from "ui-kit/map";
import MessagesBoardsHeader from "./MessagesBoardsHeader";
import { useMapData } from "components/Geozones";
import { useFetch } from "ui-kit/hooks";
import { createPathURL, isBitmaskContain } from "ui-kit/helpers";
import {
  assignBoards,
  loadContentDetails,
  setPrompt,
  setRedirect,
  loadContentBoards,
} from "actions";
import { API_URL, Page, AppIcons } from "consts";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { IBoard } from "models/IBoard";
import { IGeoZone } from "models/IGeoZone";
import { IFrameSet } from "models/IFrameSet";

const MessageBoards = function (props: any) {
  const dispatch = useDispatch();
  const token = useAccessToken();
  const match: RouteMatch = useRouteMatch();
  const { id } = match?.params || { id: "" };
  const data = useSelector(
    ({ details }: ReduxState) => details[Page.Messages].data,
    shallowEqual
  );
  const boards: IBoard[] | undefined | null = useSelector(
    ({ details }: ReduxState) => details[Page.Messages].boards,
    shallowEqual
  );
  const [allBoards] = useFetch<IBoard[]>(`${API_URL}/Board`);
  const [zones] = useFetch<IGeoZone[]>(`${API_URL}/Tag/geo`);
  const [map, setMap] = useState<MapBox | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [selected, setSelected] = useState<IBoard[] | undefined>(undefined);
  const [mode, setMode] = useState<"boards" | "geozones">("boards");
  const [focused, setFocused] = useState(null);

  const contentFormats = useMemo(
    function () {
      return (data || []).frameSets?.map((x: IFrameSet) => x.format) || [];
    },
    [data]
  );

  const filteredBoards = useMemo(
    function () {
      if (data === null || allBoards === null) return null;
      if (!data || !allBoards) return undefined;

      const typeBoards = allBoards.filter((item) =>
        isBitmaskContain(item.type + 1, data.usage)
      );
      const formats =
        (data || []).frameSets?.map((x: IFrameSet) => x.format) || [];
      if (formats.length === 0) return typeBoards;
      return typeBoards.filter((x) => formats.includes(x.format));
    },
    [allBoards, data]
  );

  const [mapData, icons] = useMapData(zones, filteredBoards);

  useEffect(
    function () {
      if (id) {
        !data && dispatch(loadContentDetails(+id));
        !boards && dispatch(loadContentBoards(+id));
      }
    },
    [dispatch, id, data, boards]
  );

  useEffect(
    function () {
      if (boards) {
        setSelected(boards);
      }
    },
    [boards]
  );

  const handleAddMultipleBoards = useCallback(
    function (ids: number[]) {
      if (filteredBoards && ids && ids.length > 0) {
        setSelected((selected) => {
          const selectedIds = (selected || []).map((x) => x.id);
          if (
            filteredBoards
              .filter((x) => ids.includes(x.id))
              .every((x) => selectedIds.includes(x.id))
          ) {
            return (selected || []).filter((x) => !ids.includes(x.id));
          } else {
            const newBoards = filteredBoards.filter(
              (x) => !selectedIds.includes(x.id) && ids.includes(x.id)
            );
            return [...newBoards, ...(selected || [])];
          }
        });
      }
    },
    [filteredBoards]
  );

  const handleSelectAll = function () {
    filteredBoards && handleAddMultipleBoards(filteredBoards.map((x) => x.id));
  };

  const handleGetZonedBoards = useCallback(
    function (zone: IGeoZone) {
      fetch(`${API_URL}/tag/geo/board/inside`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(zone),
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
        })
        .then(handleAddMultipleBoards)
        .finally(() => {
          setEditMode(false);
        });
    },
    [handleAddMultipleBoards, token]
  );

  const handleSelectZone = useCallback(
    function (data: TMapEditData) {
      if (data.polygons) {
        handleGetZonedBoards({
          geo: {
            shapes: data.polygons.map((coordinates) => ({
              coordinates,
            })),
          },
        } as IGeoZone);
      }
    },
    [handleGetZonedBoards]
  );

  const handleDeleteBoard = function (index: number) {
    setSelected((selected) => [
      ...(selected || []).slice(0, index),
      ...(selected || []).slice(index + 1),
    ]);
  };

  const handleClickBoard = useCallback(
    function (e) {
      const id = e.features[0]?.id;
      if (id && filteredBoards) {
        const item = filteredBoards.find((x) => x.id === id);
        if (item) {
          setSelected((selected) =>
            (selected || []).map((x) => x.id).includes(id)
              ? (selected || []).filter((x) => x.id !== id)
              : [item, ...(selected || [])]
          );
        } else {
          setSelected((selected) =>
            (selected || []).filter((x) => x.id !== id)
          );
        }
      }
    },
    [filteredBoards]
  );

  const handleClickZone = useCallback(
    function (e) {
      const id = e.features[0]?.id;
      if (zones) {
        const zone = zones.find((x) => x.id === id);
        if (zone) {
          handleGetZonedBoards(zone);
        }
      }
    },
    [zones, handleGetZonedBoards]
  );

  const { onAddPolygon, isDrawing } = useMapEditing(
    map,
    editMode,
    undefined,
    handleSelectZone
  );

  const handleFormExit = useCallback(
    function () {
      let url = createPathURL("/:page/:mode/:id/:tab?", {
        ...match?.params,
        tab: "boards",
      });

      if (boards && !shallowEqual(boards, selected)) {
        dispatch(
          setPrompt({
            message: "Выйти без сохранения изменений?",
            buttons: [
              {
                label: "Не сохранять",
                action: setRedirect(url),
              },
            ],
          })
        );
      } else {
        dispatch(setRedirect(url));
      }
    },
    [dispatch, match, boards, selected]
  );

  const handleAssignBoards = function () {
    if (selected) {
      dispatch(
        assignBoards(
          +id,
          selected.map((x) => x.id)
        )
      );
    }
  };

  const mapModeProps = useMemo(
    function () {
      return {
        dataIndex: mode === "boards" ? 0 : 1,
        hiddenData: mode === "boards" ? [1] : undefined,
        onClick: mode === "boards" ? handleClickBoard : handleClickZone,
      };
    },
    [mode, handleClickBoard, handleClickZone]
  );

  return (
    <>
      <LayoutArea>
        <Section
          header={MessagesBoardsHeader}
          headerProps={{
            ...{
              map,
              mode,
              setEditMode,
              setMode,
              onAddPolygon,
              isDrawing,
              boards: filteredBoards,
              setFocused,
              zones,
            },
          }}
        >
          <Map
            data={mapData}
            {...mapModeProps}
            selected={selected ? selected.map((x) => x.id) : undefined}
            selectedIndex={0}
            icons={icons}
            editMode={editMode}
            onInit={setMap}
            focusTo={focused}
          />
        </Section>
      </LayoutArea>
      <LayoutArea size={424}>
        <Section
          header={() => (
            <>
              <Button
                icon={Icons.Save}
                onClick={handleAssignBoards}
                disabled={!data || !boards}
              />
              <Space />
              <Button icon={Icons.Exit} onClick={handleFormExit} />
              <hr />
              <SectionTitle
                label={selected ? `Выбрано: ${selected.length}` : undefined}
              >
                <Value value={data ? data.name : false} />
              </SectionTitle>
              <hr />
              <Tabs wide>
                <Button
                  large
                  clear
                  exact
                  replace
                  href={createPathURL(match?.path, {
                    ...match?.params,
                    ftab: undefined,
                  })}
                >
                  <span>Выбранные табло</span>
                  <Badge disabled={!selected?.length}>
                    {selected?.length}{" "}
                  </Badge>
                </Button>
                <Button
                  large
                  clear
                  exact
                  replace
                  href={createPathURL(match?.path, {
                    ...match?.params,
                    ftab: "all",
                  })}
                >
                  <span>Все табло</span>
                  <Badge disabled={!filteredBoards?.length}>
                    {filteredBoards?.length}
                  </Badge>
                </Button>
              </Tabs>
            </>
          )}
          footer={() => (
            <Button wide onClick={handleSelectAll} disabled={!filteredBoards}>
              {selected &&
              filteredBoards &&
              selected.length >= filteredBoards.length
                ? "Очистить выбранное"
                : "Выбрать все"}
            </Button>
          )}
        >
          {!match?.params?.ftab && (
            <Datum
              sizes={[40, "unset", 24, 24, 32]}
              items={selected}
              placeholder="Нет выбранных табло"
              renderer={(item: IBoard, index) => [
                <Value value={`№${item.num}`} />,
                <Value value={item.address} />,
                <Icon type={AppIcons.BoardType(item.type)} />,
                <Icon
                  type={AppIcons.BoardFormat(item.format)}
                  color={
                    contentFormats.includes(item.format)
                      ? undefined
                      : Colors.Red
                  }
                />,
                <Button
                  small
                  danger
                  clear
                  icon={Icons.Close}
                  onClick={() => handleDeleteBoard(index)}
                />,
              ]}
            />
          )}
          {match?.params?.ftab === "all" && (
            <Datum
              sizes={[40, "unset", 24, 24, 32]}
              items={filteredBoards}
              rowProps={(item) => ({
                className: classes(
                  selected?.map((x) => x.id).includes(item.id) && "-selected"
                ),
              })}
              renderer={(item: IBoard) => [
                <Value value={`№${item.num}`} />,
                <Value value={item.address} />,
                <Icon type={AppIcons.BoardType(item.type)} />,
                <Icon
                  type={AppIcons.BoardFormat(item.format)}
                  color={
                    contentFormats.includes(item.format)
                      ? undefined
                      : Colors.Red
                  }
                />,
                <Checkbox
                  value={selected?.map((x) => x.id).includes(item.id)}
                  onChange={() => handleClickBoard({ features: [item] })}
                />,
              ]}
            />
          )}
        </Section>
      </LayoutArea>
    </>
  );
};

export default MessageBoards;
