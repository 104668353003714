import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import classes from 'classnames'
import '../../components/Popup/popup.scss'

interface Props {
  isOpened: boolean
  className?: string
  style?: React.CSSProperties
  children: any
  fixed?: boolean
}

const InputPopup = forwardRef(function (props: Props, ref: any) {
  const { isOpened, className, fixed } = props
  const popupRef = useRef<HTMLDivElement | null>(null)
  const [ style, setStyle ] = useState(props.style)
  const openedRef = useRef(isOpened)

  const followScroll = useCallback(function () {
    const element = (ref?.current || popupRef.current)
    const parent = element?.parentElement
    if (parent && openedRef.current) {
      const rect = parent.getBoundingClientRect()

      setStyle({
        position: 'fixed',
        maxWidth: rect.width,
        top: rect.top,
        left: rect.left,
        maxHeight: window.innerHeight - rect.top
      })
      window.requestAnimationFrame(followScroll)
    }
  }, [ ref, popupRef ])

  useEffect(
    function () {
      const element = ref?.current || popupRef.current
      openedRef.current = isOpened
      if (element && !fixed) {
        if (isOpened) {
          followScroll()
        }
      }

      return function () {
        setStyle(props.style)
      }
    },
    [ isOpened, popupRef, ref, props.style, followScroll, fixed ]
  )

  return (
    <div
      className={ classes('popup', className, isOpened && '-opened') }
      ref={ ref || popupRef }
      style={ style }
      onMouseDown={e => e.preventDefault()}
    >
      { props.children }
    </div>
  )
})

export default InputPopup
