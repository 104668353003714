import { ReduxState } from '../ui-kit/types'
import { Page } from 'consts'
import { setDatumAppendix } from '../ui-kit/reducers/datum'

export {
  loadBoardSummary, loadBoardData, loadBoardDetails, loadBoardContent
} from './boards'
export {
  loadContentSummary,
  loadContentData,
  loadContentDetails,
  loadContentBoards,
  loadContentLog,
  saveContent,
  arhiveContent,
  extractContent,
  assignBoards,
  publishContent,
  unPublishContent
} from './content'
export {
  loadGeoZonesData,
  loadGeoZoneDetails,
  loadGeoGroups,
  deleteGeozone,
  loadGeoZoneBoards,
  loadGeoZoneContent,
  saveGeoGroup,
  deleteGeoGroup
} from './geozones'
export { loadTags, saveTag, deleteTag } from './tags'
export { showFilters, setRedirect, setPrompt, setModal } from 'ui-kit/reducers/layout'
export { loadLogsData, loadLogDetails } from './logs'

export const loadSummary = function (page: Page, endpoint: Function) {
  return async function (dispatch: any, getState: () => ReduxState) {
    const token = getState().user.token
    if (!token) { return }
    if (getState().datum[page].summary === null) {
      dispatch(setDatumAppendix(page, 'summary', undefined))
    }

    try {
      const data = await endpoint(token)
      dispatch(setDatumAppendix(page, 'summary', data))
    } catch {
      dispatch(setDatumAppendix(page, 'summary', null))
    }
  }
}
