export const filterObject = function (
  query: string, 
  obj: {[key: string]: any}, 
  parser: {
    [key: string]: ((v: any) => string) | boolean
  } 
) {
  const q = query.toLowerCase()  
  const keys = Object.keys(parser)
  
  return keys.some(key => {
    return (typeof parser[key] === 'function'
      //@ts-ignore
      ? parser[key](obj[key]).toLowerCase()
      : obj[key].toString().toLowerCase()
    ).indexOf(q) > -1
  })
}