import React  from 'react'
import { NavLink } from 'react-router-dom'
import { Icon } from '../index'
import './menu.scss'

interface MenuLinkProps {
  href: string
  icon: React.FunctionComponent
  title?: string
}

const MenuLink = function (props: MenuLinkProps) {
  const { href, icon, title } = props

  return <NavLink className='menu-link' to={ href }>
    <Icon type={ icon }/>
    {title && <span className='menu-title'>
      { title }
    </span> }
  </NavLink>
}

export default MenuLink
