import React, { useState, useRef } from 'react'
import classes from 'classnames'
import { Icon } from '../../components'
import { InputPopup, Suggestions } from '..'
import { useSelect } from '../plugins/use-controls'
import { TSelectProps } from '../plugins/use-controls/types'
import { Icons } from '../../consts'
import '../Textbox/textbox.scss'

interface Props extends TSelectProps {
  icon?: React.FunctionComponent
  className?: string
  size?: number
}

const Select = React.forwardRef(function (props: Props, ref: any) {
  const { icon, className, size, ...selectProps } = props
  const { disabled } = selectProps
  const inputRef = useRef<HTMLInputElement | null>(null)
  const listRef = useRef<HTMLElement | null>(null)
  const [options] = useState({
    keys: { value: ['value', 'id'], display: ['label', 'name'] },
  })
  const [inputProps, items, isOpened] = useSelect(
    ref || inputRef,
    listRef,
    selectProps,
    options
  )

  return <label
    className={classes('textbox', className, !size && '-wide')}
    style={{
      maxWidth: size ? size * 8.4 : 'unset',
      minWidth: size ? size * 8.4 : 'unset'
    }}
  >
    <input
      ref={ref || inputRef}
      type='text'
      {...inputProps}
      className={classes('textbox-control popup-control -select', isOpened && '-opened')}
      autoComplete='off'
    />
    {icon && <Icon type={icon} className={classes('popup-icon', isOpened && '-opened')}/>}
    <span
      className={classes(
        'textbox-button -select',
        disabled && '-disabled',
        isOpened && '-opened'
      )}
    >
      <Icon type={Icons.ArrowLeft} />
    </span>
    <InputPopup
      className='-input'
      isOpened={isOpened && items?.length > 0}
      ref={listRef}
    >
      {items?.length > 0 && (
        <Suggestions
          keys={options.keys}
          value={props.value || ''}
          items={items}
        />
      )}
    </InputPopup>
  </label>
})

export default Select
