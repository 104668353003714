import React, { useMemo } from 'react'
import classes from 'classnames'
import './layout.scss'

interface LayoutAreaProps {
  children: any
  size?: string | number
  className?: string
  collapsed?: boolean
}

const LayoutArea = function (props: LayoutAreaProps) {
  const { size, children, className, collapsed } = props

  const style = useMemo(function () {
    if (!size) {
      return
    }

    if (typeof size === 'string' && size.indexOf('%') > -1) {
      const value = +(size.substr(0, size.length - 1))
      return value ? {
        flexBasis: `${ value * 2 }%`
      } : undefined
    } else {
      return {
        maxWidth: size,
        minWidth: size,
        flexGrow: 1
      }
    }
  }, [ size ])

  return <div
    className={classes('layout-area', className, collapsed && '-collapsed')}
    style={ style
      ? {
        ...style,
        maxWidth: collapsed ? 0 : style.maxWidth,
        minWidth: collapsed ? 0 : style.minWidth
      }
      : undefined
    }
  >
    <div className='layout-wrapper' style={{width: (size && style?.maxWidth) || 'auto'}}>
      { children }
    </div>
  </div>
}

export default LayoutArea
