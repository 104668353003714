import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Section } from "ui-kit";
import { AppLoader } from "ui-kit/app";
import { useForm } from "ui-kit/form";
import MessageFormFrameSet from "./MessageFormFrameSet";
import MessageFormHeader from "./MessageFormHeader";
import MessageFormSettings from "./MessageFormSettings";
import { validateMessage } from "./MessageForm.helpers";
import { loadContentDetails, saveContent } from "actions";
import { ReduxState, RouteParams } from "ui-kit/types";
import { BoardFormat, Page } from "consts";
import { IFrameSet } from "models/IFrameSet";
import { IFrame } from "models/IFrame";

const MessageForm = function (props: { edit: boolean }) {
  const { edit } = props;
  const params: RouteParams = useParams();
  const id = params.id && !isNaN(+params.id) ? +params.id : undefined;
  const initialState = useSelector(
    ({ details }: ReduxState) =>
      edit ? details[Page.Messages].data : undefined,
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(
    function () {
      if (edit && id && !initialState && initialState !== null) {
        dispatch(loadContentDetails(id));
      }
    },
    [id, edit, dispatch, initialState]
  );

  const { onSubmit, onExit, onChange, disabled, state } = useForm(
    saveContent,
    validateMessage,
    initialState
  );

  const handleUpdateFrames = function (frames: IFrame[], format: BoardFormat) {
    const index = state?.frameSets?.findIndex(
      (x: IFrameSet) => x.format === format
    );

    if (index > -1) {
      onChange?.("frameSets", [
        ...state.frameSets.slice(0, index),
        { ...state.frameSets[index], frames },
        ...state.frameSets.slice(index + 1),
      ]);
    } else {
      onChange?.("frameSets", [
        ...(state?.frameSets || []),
        { format, frames },
      ]);
    }
  };

  const [frameSet43, framesSet23, frameSet51, frameSet11, frameSet3h1] =
    useMemo(
      function () {
        if (!state?.frameSets) {
          return [null, null, null, null];
        }

        return [
          state.frameSets.find(
            (x: IFrameSet) => x.format === BoardFormat.FourThree
          ),
          state.frameSets.find(
            (x: IFrameSet) => x.format === BoardFormat.TwoThree
          ),
          state.frameSets.find(
            (x: IFrameSet) => x.format === BoardFormat.FiveOne
          ),
          state.frameSets.find(
            (x: IFrameSet) => x.format === BoardFormat.OneOne
          ),
          state.frameSets.find(
            (x: IFrameSet) => x.format === BoardFormat.ThreeHalfOne
          ),
        ];
      },
      [state]
    );

  if (edit && !initialState) {
    return <AppLoader />;
  }

  return (
    <Section
      header={MessageFormHeader}
      headerProps={{ ...{ id, onSubmit, onExit, disabled } }}
    >
      <MessageFormSettings state={state} onChange={onChange} />
      <MessageFormFrameSet
        format={BoardFormat.FourThree}
        frameSet={frameSet43}
        onChange={(frames: IFrame[]) =>
          handleUpdateFrames(frames, BoardFormat.FourThree)
        }
      />
      <MessageFormFrameSet
        format={BoardFormat.TwoThree}
        frameSet={framesSet23}
        onChange={(frames: IFrame[]) =>
          handleUpdateFrames(frames, BoardFormat.TwoThree)
        }
      />
      <MessageFormFrameSet
        format={BoardFormat.FiveOne}
        frameSet={frameSet51}
        onChange={(frames: IFrame[]) =>
          handleUpdateFrames(frames, BoardFormat.FiveOne)
        }
      />
      <MessageFormFrameSet
        format={BoardFormat.OneOne}
        frameSet={frameSet11}
        onChange={(frames: IFrame[]) =>
          handleUpdateFrames(frames, BoardFormat.OneOne)
        }
      />
      <MessageFormFrameSet
        format={BoardFormat.ThreeHalfOne}
        frameSet={frameSet3h1}
        onChange={(frames: IFrame[]) =>
          handleUpdateFrames(frames, BoardFormat.ThreeHalfOne)
        }
      />
    </Section>
  );
};

export default MessageForm;
