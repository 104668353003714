import { useEffect, useMemo, useRef, useState } from "react";
import * as PIXI from "pixi.js";
import isEqual from "lodash/isEqual";
import { shallowEqual, useSelector } from "react-redux";
import { FrameKind, FrameTransition, Photo } from "consts";
import { ReduxState } from "ui-kit/types";
import { IFrame } from "models/IFrame";

export type TPreviewSource = {
  kind: FrameKind;
  source: HTMLElement | null;
  sprite: PIXI.Container;
  effect: FrameTransition | null;
  /** Substracks transitionInverval from all times if effect is 0 */
  time: {
    start: number;
    transition: number;
  };
};

const getSprite = function (
  el: HTMLVideoElement | HTMLImageElement | null,
  style: { width: number; height: number },
  ratio: number
) {
  const container = new PIXI.Container();

  if (el) {
    const sprite = new PIXI.Sprite(PIXI.Texture.from(el));
    const r = (el.offsetWidth || el.width) / (el.offsetHeight || el.height);

    sprite.width = r > ratio ? style.height * r : style.width;
    sprite.height = r > ratio ? style.height : style.width / r;
    sprite.x = (style.width - sprite.width) / 2;
    sprite.y = (style.height - sprite.height) / 2;
    container.width = style.width;
    container.height = style.height;
    container.alpha = 0;
    container.addChild(sprite);
    return container;
  } else {
    const graphics = new PIXI.Graphics();
    graphics.beginFill(0x666666);
    graphics.drawRect(0, 0, style.width, style.height);
    graphics.endFill();
    container.alpha = 0;
    container.addChild(graphics);
    return container;
  }
};

const getTimings = function (frames: IFrame[], index: number) {
  let total = 0;
  for (let i = 0; i < index; i++) {
    total +=
      frames[i].playInterval +
      (frames[i].transition && frames[i].playInterval
        ? frames[i].transitionInterval
        : 0);
  }

  const i = index < frames.length ? index : 0;

  const transition =
    frames[i].transition && frames[i].playInterval
      ? frames[i].transitionInterval
      : 0;

  return {
    start:
      total -
      (frames[0].transition && frames[0].playInterval
        ? frames[0].transitionInterval
        : 0),
    transition,
  };
};

const usePreviewFrames = function (
  frames: IFrame[] | undefined,
  style: { width: number; height: number }
): [TPreviewSource[] | undefined, number] {
  const [sources, setSources] = useState<TPreviewSource[]>([]);
  const photos = useSelector(
    ({ photos }: ReduxState) => photos[Photo.Frame],
    shallowEqual
  );
  const stateDataRef = useRef<(null | undefined | string)[] | undefined>();

  const stateData = useMemo(
    function () {
      const data = frames?.map((f) => photos[f.id]);
      if (!isEqual(stateDataRef.current, data)) {
        stateDataRef.current = data;
      }
      return stateDataRef.current;
    },
    [photos, frames, stateDataRef]
  );

  useEffect(
    function () {
      if (frames?.length) {
        const ratio = style.width / style.height;

        [...frames, null].forEach((frame: IFrame | null, index: number) => {
          const f: IFrame = frames[index] || frames[0];

          switch (f.kind) {
            case FrameKind.StaticImage: {
              const src =
                f.base64data === undefined
                  ? index < frames.length
                    ? stateData?.[index]
                    : stateData?.[0]
                  : `data:image/png;base64,${f.base64data}`;

              if (src) {
                const image = new Image();
                image.src = src;
                image.onload = function () {
                  setSources((sources) => {
                    let res = [...sources];
                    res[index] = {
                      kind: FrameKind.StaticImage,
                      source: image,
                      sprite: getSprite(image, style, ratio),
                      effect: f.transition,
                      time: getTimings(frames, index),
                    };
                    return res;
                  });
                };
              }
              return;
            }

            case FrameKind.MessageEditor: {
              const src =
                f.base64data === undefined
                  ? index < frames.length
                    ? stateData?.[index]
                    : stateData?.[0]
                  : `data:image/png;base64,${f.base64data}`;

              if (src) {
                const image = new Image();
                image.src = src;
                image.onload = function () {
                  setSources((sources) => {
                    let res = [...sources];
                    res[index] = {
                      kind: FrameKind.StaticImage,
                      source: image,
                      sprite: getSprite(image, style, ratio),
                      effect: f.transition,
                      time: getTimings(frames, index),
                    };
                    return res;
                  });
                };
              }
              return;
            }

            case FrameKind.Video: {
              const src =
                f.base64data === undefined
                  ? index < frames.length
                    ? stateData?.[index]
                    : stateData?.[0]
                  : `data:video/mp4;base64,${f.base64data}`;

              if (src) {
                const video = document.createElement("video");
                video.style.position = "absolute";
                video.style.zIndex = "-1";
                video.style.top = "-10000px";
                video.src = src;
                video.muted = true;
                video.addEventListener("loadeddata", function (e) {
                  setSources((sources) => {
                    let res = [...sources];
                    document.body.appendChild(video);
                    res[index] = {
                      kind: FrameKind.Video,
                      source: video,
                      sprite: getSprite(video, style, ratio),
                      effect: f.transition,
                      time: getTimings(frames, index),
                    };
                    document.body.removeChild(video);
                    return res;
                  });
                });
              }
              return;
            }

            default:
              setSources((sources) => {
                let res = [...sources];
                res[index] = {
                  kind: f.kind,
                  source: null,
                  sprite: getSprite(null, style, ratio),
                  effect: f.transition,
                  time: getTimings(frames, index),
                };
                return res;
              });
              return;
          }
        });
      }
    },
    [frames, stateData, style]
  );

  const isLoading = useMemo(
    function () {
      if (frames?.length) {
        if (frames.length + 1 === sources.filter((x) => x).length) {
          return false;
        }
      }

      return true;
    },
    [sources, frames]
  );

  const total = useMemo(
    function () {
      if (sources && !isLoading) {
        return sources[sources.length - 1].time.start - sources[0].time.start;
      }
      return 0;
    },
    [sources, isLoading]
  );

  return [isLoading ? undefined : sources, total];
};

export default usePreviewFrames;
