import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setModal } from "actions";
import { Icons, List } from "ui-kit";
import { Button } from "ui-kit/controls";
import Tags from "components/Tags";
import { AppIcons, tagsColor } from "consts";
import { ITag } from "models/ITag";

interface Props {
  value: ITag[];
  onChange: (value: ITag[]) => void;
}

const MessageFormTags = function (props: Props) {
  const { value, onChange } = props;
  const dispatch = useDispatch();

  const handleShowModal = function () {
    dispatch(
      setModal({
        component: Tags,
        value: value,
        onSubmit: onChange,
      })
    );
  };

  const tagsList = useMemo(
    function () {
      if (!value) {
        return [];
      }

      return value.map((tag, index) => ({
        label: tag.value,
        icon: tag.isGeo ? AppIcons.Geozone : Icons.Tag,
        iconColor: tagsColor[tag.color],
      }));
    },
    [value]
  );

  return (
    <div className="message-form-list -tags">
      {/* @ts-ignore */}
      <List label="Теги" list={tagsList} />
      {/* @ts-ignore */}
      <Button clear accent small onClick={handleShowModal}>
        Управление тегами
      </Button>
    </div>
  );
};

export default MessageFormTags;
