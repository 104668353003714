import React, { useMemo } from "react";
import { Value, Datum } from "ui-kit";
import { IBoard } from "models/IBoard";
import { IGeoZone } from "models/IGeoZone";
import { useMapData as useBoardsData } from "components/Infoboards/Infoboards.helpers";
import { Page, Photo } from "consts";
import { FeatureCollection } from "geojson";
import { ReduxState, TableData, TileData } from "ui-kit/types";
import { useSelector } from "react-redux";

export const useTilesData = function (
  data: IGeoZone[] | null | undefined
): TileData[] | null | undefined {
  const search = useSelector(
    ({ settings }: ReduxState) => settings.filters[Page.Geozones]?.search?.[0]
  );

  return useMemo(
    function () {
      return data
        ? data.map((item) => {
            return item
              ? {
                  id: item.id,
                  //@ts-ignore
                  title: <Value value={item.value} highlight={search} />,
                  images: [
                    {
                      id: item.id,
                      type: Photo.GeoZonePreview,
                    },
                  ],
                  children: (
                    <>
                      {/* @ts-ignore */}
                      <Datum classNames={["", "-right"]}>
                        {[
                          [<Value value={`№${item.id}`} />, <span>Табло</span>],
                        ]}
                      </Datum>
                      <span className="tiles-text">
                        {/* @ts-ignore */}
                        <Value value={item.description} highlight={search} />
                      </span>
                    </>
                  ),
                }
              : item;
          })
        : null;
    },
    [data, search]
  );
};

export const useTableData = function (
  data: IGeoZone[] | null | undefined
): TableData[] | null | undefined {
  const search = useSelector(
    ({ settings }: ReduxState) => settings.filters[Page.Geozones]?.search?.[0]
  );

  return useMemo(
    function () {
      return data
        ? data.map((item) => {
            return item
              ? {
                  id: item.id,
                  datum: [
                    <span className="table-text">№{item.id}</span>,
                    <span className="table-accent">
                      {/* @ts-ignore */}
                      <Value value={item.value} highlight={search} />
                    </span>,
                    <span className="table-text">
                      {/* @ts-ignore */}
                      <Value
                        value={item.description || null}
                        highlight={search}
                      />
                    </span>,
                    <>
                      {/*<Badge disabled={ item.bindedBoardsCount === 0 }>*/}
                      {/*  { item.bindedBoardsCount }*/}
                      {/*</Badge>*/}
                      <span>Табло</span>
                    </>,
                  ],
                }
              : item;
          })
        : data;
    },
    [data, search]
  );
};

export const useMapData = function (
  data: IGeoZone[] | null | undefined,
  boards: IBoard[] | null | undefined,
  editZoneId?: number
): [(FeatureCollection | null | undefined)[], { [key: string]: string }] {
  const [boardsData, icons] = useBoardsData(boards);
  return [
    useMemo(
      function () {
        return [
          boardsData,
          data
            ? {
                type: "FeatureCollection",
                features: (data as IGeoZone[])
                  .filter(
                    (item) => item && (!editZoneId || editZoneId !== item.id)
                  )
                  .map((item) => {
                    return {
                      type: "Feature",
                      id: item.id,
                      geometry: {
                        type: "MultiPolygon",
                        coordinates:
                          item.geo?.shapes?.map(
                            (shape) => [shape.coordinates || []] || []
                          ) || [],
                      },
                      properties: {
                        fill: "#5784FF",
                      },
                    };
                  }),
              }
            : data,
        ];
      },
      [data, boardsData, editZoneId]
    ),
    icons,
  ];
};
