export const getStyleProperty = function (el: any, prop: string): string {
  const defaultView = (el.ownerDocument || document).defaultView
  /** W3C standard way: */
  if (defaultView && defaultView.getComputedStyle) {
    /** sanitize property name to css notation */
    const styleProp = prop.replace(/([A-Z])/g, '-$1').toLowerCase()
    return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp)

  } else if (el.currentStyle) { // IE
    /** sanitize property name to camelCase */
    const styleProp = prop.replace(/-(\w)/g, function (str, letter) {
      return letter.toUpperCase()
    })
      
    const value = el.currentStyle[styleProp]

    /** convert other units to pixels on IE */
    if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
      return (function (value) {
        const oldLeft = el.style.left
        const oldRsLeft = el.runtimeStyle.left
        el.runtimeStyle.left = el.currentStyle.left
        el.style.left = value || 0
        value = el.style.pixelLeft + 'px'
        el.style.left = oldLeft
        el.runtimeStyle.left = oldRsLeft
        return value
      })(value)
    }
    
    return value
  }
  
  return ''
}
