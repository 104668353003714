import React, { useEffect, useMemo, useState } from 'react'
import classes from 'classnames'
import { Icon, Icons, Value } from 'ui-kit'
import { IGeoGroup } from 'models/IGeoGroup'
import 'ui-kit/components/List/list.scss'

interface Props extends IGeoGroup {
  depth: number
  onCheck: (id: number, value: boolean) => void
  onClick: (id: number) => void
  selected?: IGeoGroup
  filters: number[] | undefined
  query: string
}

const GeozonesGroup = function (props: Props) {
  const {
    id,
    name,
    geoGroups,
    tags,
    onCheck,
    onClick,
    selected,
    filters,
    depth,
    query
  } = props
  const [ isExpanded, setExpanded ] = useState(false)

  const [ checkedClass, icon ] = useMemo(function () {
    const finder = function (items: IGeoGroup[] | null): boolean {
      if (!items) return false
      return Boolean(items.find((x: IGeoGroup) => filters?.includes(x.id) || finder(x.geoGroups)))
    }
    return [
      filters?.includes(id)
        ? '-checked'
        : !isExpanded && finder(geoGroups)
          ? '-partial'
          : false,
      Boolean(geoGroups?.length && isExpanded)
        ? Icons.FolderOpen
        : Icons.Folder
    ]
  }, [ filters, id, geoGroups, isExpanded ])

  useEffect(function () {
    if (geoGroups?.find(x => x.id === selected?.id)) {
      setExpanded(true)
    }
  }, [ selected, geoGroups ])

  useEffect(function () {
    const finder = function (item: IGeoGroup): boolean {
      return (
        item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        Boolean(item.geoGroups?.find(finder))
      )
    }

    if (query && geoGroups?.find(finder)) {
      setExpanded(true)
    }
  }, [ query, geoGroups ])

  return <>
    <button
      onClick={() => {
        geoGroups?.length && setExpanded(selected?.id === id ? !isExpanded : true)
        onClick(id)
      }}
      className={classes(
        'list-item -active',
        selected?.id === id && '-selected',
        depth && `-depth${depth}`
      )}
    >
      <Icon type={icon} color='#5784FF' size={20} />
      <span className='-label'>
        <Value value={name} highlight={query} />
      </span>
      { tags && <span className='-badge'>
        <Value value={ tags.length } />
      </span> }
      <span
        className={classes('list-checkbox', checkedClass)}
        onClick={(e: any) => {
          e.stopPropagation()
          onCheck(id, !filters?.includes(id))
        }}
      >
        <Icon type={Icons.Check} />
      </span>
    </button>

    {isExpanded && geoGroups?.map(item => (
      <GeozonesGroup
        {...item}
        key={ item.id }
        selected={ selected }
        filters={ filters }
        depth={ depth + 1 }
        onCheck={ onCheck }
        onClick={ onClick }
        query={ query }
      />
    ))}
  </>
}

export default GeozonesGroup
