import React  from 'react'
import classes from 'classnames'
import { Icon } from '../../components'
import { Icons} from '../../consts'
import './checkbox.scss'

interface Props {
  value: boolean | undefined | number
  onChange?: (value: boolean) => void
  onClick?: (e: any) => void
  onMouseUp?: (e: any) => void
  children?: any
  disabled?: boolean
  style?: React.CSSProperties
  name?: string
}

const Checkbox = function (props: Props) {
  const { value, onChange, disabled, style, children, name } = props

  const handleChange = function () {
    onChange && onChange(!value)
  }

  return (
    <span
      onClick={handleChange}
      style={style}
      className={classes('checkbox', disabled && '-disabled')}
    >
      <input
        disabled={disabled}
        type="checkbox"
        checked={Boolean(value)}
        readOnly
        name={name}
      />
      <span
        className='checkbox-appearance'
      >
        <span className='-box'>
          <Icon type={Icons.Check } />
        </span>
        { children && <span className='-label'>
          {children}
        </span> }
      </span>
    </span>
  )
}

export default Checkbox
