import React from "react";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { Provider } from "react-redux";
import { Authentication } from "@megapolis/react-auth";
import thunk from "redux-thunk";
import AppLoader from "./AppLoader";
import AppRouting from "./AppRouting";
import datumReducer from "../reducers/datum";
import settingsReducer, { settingsState } from "../reducers/settings";
import detailsReducer from "../reducers/details";
import photosReducer from "../reducers/photos";
import layoutReducer, { layoutState } from "../reducers/layout";
import userReducer from "../reducers/user";
import { AUTH_URL } from "../consts";
import { AppRoutes } from "../types";
import "./app.scss";

interface AppProps {
  clientId: string;
  scope: string;
  reducers?: { [key: string]: Function };
  state?: { [key: string]: Object };
  routes: AppRoutes;
}

const App = function (props: AppProps) {
  const { scope, clientId, reducers, state, routes } = props;
  const composeEnhancers =
    process.env.NODE_ENV === "development"
      ? // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  const store = createStore(
    combineReducers({
      datum: datumReducer,
      details: detailsReducer,
      photos: photosReducer,
      layout: layoutReducer,
      settings: settingsReducer,
      user: userReducer,
      ...reducers,
    }),
    {
      ...state,
      layout: { ...layoutState, ...state?.layout },
      settings: { ...settingsState, ...state?.settings },
    } as any,
    composeEnhancers(applyMiddleware(thunk))
  );

  return (
    // @ts-ignore
    <Provider store={store}>
      {/* @ts-ignore */}
      <Authentication
        authority={AUTH_URL}
        client_id={clientId}
        scope={scope}
        loader={<AppLoader />}
        debug={process.env.NODE_ENV === "development"}
      >
        {/* @ts-ignore */}
        <AppRouting routes={routes} />
      </Authentication>
    </Provider>
  );
};

export default App;
