import React, { useEffect, useState } from "react";
import classes from "classnames";
import { Image } from "..";
import { Button } from "../../controls";
import { Icons } from "../../consts";
import { IImage } from "../../types";

interface Props {
  className?: string;
  images?: IImage[] | null;
  placeholder?: string;
}
const ImageSlider = function (props: Props) {
  const { images, className, placeholder } = props;
  const [selected, setSelected] = useState(0);

  useEffect(
    function () {
      setSelected(0);
    },
    [images]
  );

  return (
    <span className={classes("image-slider", className)}>
      {!images || images.length === 0 ? (
        <Image src={null} placeholder={placeholder} className="-selected" />
      ) : (
        images.map((image, index) => (
          <Image
            key={index}
            className={classes(selected === index && "-selected")}
            {...image}
          />
        ))
      )}

      {images && images.length > 1 && (
        <>
          <Button
            small
            className="image-slider-button -prev"
            blur
            icon={Icons.ArrowLeft}
            onClick={() => setSelected(Math.max(0, selected - 1))}
            disabled={selected === 0}
          />
          <Button
            small
            className="image-slider-button -next"
            blur
            icon={Icons.ArrowRight}
            onClick={() =>
              setSelected(Math.min(images.length - 1, selected + 1))
            }
            disabled={selected === images.length - 1}
          />
        </>
      )}
    </span>
  );
};

export default ImageSlider;
