import React, { useCallback, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useEventListener from '@use-it/event-listener'
import { addDays } from 'date-fns'
import { ModalBody, ModalHeader, useModalSize } from 'ui-kit/modal'
import {
  AppDataEmpty,
  AppLoadingError,
  Button,
  Icons,
  Popup,
  SectionTitle
} from 'ui-kit'
import { Calendar } from 'ui-kit/controls'
import { MessagesRow } from 'components/Messages'
import { useTableData } from './InfoboardLog.helpers'
import { AppLoader } from 'ui-kit/app'
import InfoboardLogTimeline from './InfoboardLogTimeline'
import { getFormattedDate, getISODate, getQueryParams } from 'ui-kit/helpers'
import { setModal } from 'actions'
import { useFetch } from 'ui-kit/hooks'
import { API_URL } from 'consts'
import { ReduxState } from 'ui-kit/types'
import { IBoardLog } from 'models/IBoardLog'
import './infoboard-log.scss'
import { IBoard } from 'models/IBoard'

const calculateWidth = function () {
  return Math.min(1200, window.innerWidth * 0.9)
}

const InfoboardLog = function () {
  const dispatch = useDispatch()
  const [ date, setDate ] = useState<Date>(new Date())
  const [ time, setTime ] = useState<[number, number | undefined]>([420, 1260])
  const [ width, setWidth ] = useState(calculateWidth())
  const [ isDateOpened, setDateOpened ] = useState(false)
  const stateModal = useSelector(({ layout }: ReduxState) => layout.modal, shallowEqual)
  const headerRef = useRef<HTMLDivElement | null>(null)
  const offset = useModalSize(headerRef, null)
  const [ data ] = useMemo(function (): [
    IBoard | null | undefined
  ] {
    return [ stateModal?.data ]
  }, [ stateModal ])

  const [ from, to ] = useMemo(function () {
    const startDate = new Date(date)
    const startTime = [ Math.floor(time[0] / 60), time[0] % 60 ]
    if (startTime[0] < 24) {
      startDate.setHours(startTime[0], startTime[1], 0, 0)
    } else {
      startDate.setHours(23, 59, 0, 0)
    }

    const endDate = time[1] ? new Date(date) : startDate
    if (time[1] !== undefined) {
      const endTime = time[1] ? [ Math.floor(time[1] / 60), time[1] % 60 ] : startTime

      if (endTime[0] < 24) {
        endDate.setHours(endTime[0], endTime[1], 0, 0)
      } else {
        endDate.setHours(23, 59, 0, 0)
      }
    }

    return [ getISODate(startDate), getISODate(endDate) ]
  }, [ time, date ])

  const [ logContent, error, onUpdate ] = useFetch<IBoardLog[]>(data
    ? `${API_URL}/Board/${data.id}/publications/summary${getQueryParams({ from, to })}`
    : ''
  )
  const logData = useTableData(logContent, data?.format)

  const handleSetWidth = useCallback(function () {
    setWidth(calculateWidth())
  }, [])

  const handleClose = function () {
    dispatch(setModal())
  }

  useEventListener('resize', handleSetWidth, window)

  return <>
    <ModalHeader ref={headerRef} onClose={handleClose}>
      <SectionTitle>
        История изменения контента табло{data?.num ? ` №${data.num}` : ''}
      </SectionTitle>
      <hr/>
      <div className='infoboard-log-date'>
        <Popup
          isOpened={isDateOpened}
          onToggle={setDateOpened}
          trigger={Button}
          triggerProps={({ isActive, onClick }: any) => ({
            active: isActive,
            onClick,
            icon: Icons.Calendar,
            children: getFormattedDate(getISODate(date), false),
            className: '-selector'
          })}
        >
          <Calendar
            style={{ width: 240 }}
            startDate={date}
            onChange={v => {
              setDate(d => (v as Date | undefined) || d)
              setDateOpened(false)
            }}
          />
        </Popup>
        <Button round icon={Icons.ArrowLeft} onClick={() => setDate(d => addDays(d, -1))} />
        <Button round icon={Icons.ArrowRight} onClick={() => setDate(d => addDays(d, 1))} />
      </div>
      <hr />
      <InfoboardLogTimeline value={time} onChange={setTime} />
    </ModalHeader>
    <ModalBody offset={offset} width={width}>
      <div className='infoboard-log-list'>
        { logData
          ? logData.length
            ? logData.map(item => <MessagesRow key={item.id} { ...item } widths={[250]}/>)
            : <AppDataEmpty />
          : logData === null
            ? <AppLoadingError onClick={onUpdate} error={error} />
            : <AppLoader />}
      </div>
    </ModalBody>
  </>
}

export default InfoboardLog
