import React from 'react'
import classes from 'classnames'
import './loader.scss'

interface Props {
  icon?: boolean
  className?: string
}

const Loader = function (props: Props) {
  return <span className={classes('loader', props.className, props.icon && '-icon')}>
    <span/><span/><span/>
  </span>
}

export default Loader
