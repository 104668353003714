import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Icon, Loader } from '../index'
import { getFormattedDate, getFormattedTime } from '../../helpers'

interface Props {
  value: string | number | null | undefined | false
  href?: string
  download?: boolean
  date?: boolean
  dateTime?: boolean
  time?: boolean
  number?: boolean
  icon?: React.FunctionComponent
  highlight?: string
  onClick?: () => void,
  className?: string
  postfix?: string
}

const Highlighter = function (props: { highlight?: string, value: any }) {
  const { highlight, value } = props

  const [ start, length ] = useMemo(function (){
    if (typeof value !== 'string') return [ -1, 0 ]
    if (!highlight) return [ -1, 0 ]
    const stack = value.toLowerCase()
    const needle = highlight?.toLowerCase() || ''
    const index = stack.indexOf(needle)
    return [ index, needle.length]
  }, [ highlight, value])

  if (start === -1) {
    return value
  }

  return <>
    <span>{value.substr(0, start)}</span>
    <span className='-highlight'>{value.substr(start, length)}</span>
    <span>{value.substr(start + length)}</span>
  </>

}

const renderValue = function (props: Props) {
  const { date, dateTime, time, value, icon, highlight, number, postfix } = props

  let formatted = value

  if ((date || dateTime) && value) {
    formatted = getFormattedDate(value, Boolean(dateTime))
  } else if (time && value) {
    formatted = getFormattedTime(value)
  } else if (number) {
    formatted = (value || 0).toLocaleString()
  }

  if (postfix) {
    formatted = `${formatted} ${postfix}`
  }

  return <>
    {icon && <Icon type={icon} className='-inline' />}
    <Highlighter highlight={highlight} value={formatted} />
  </>
}

const Value = function (props: Props) {
  const { value, href, download, onClick, className } = props

  return <span {...{ onClick, className }}>
    {value === undefined
      ? <Loader />
      : value === null
        ? '—'
        : href
          ? href.indexOf(':') === -1
            ? <Link to={href}>{renderValue(props)}</Link>
            : <a href={href} target='_blank' rel='noopener noreferrer' download={download}>{renderValue(props)}</a>
          : renderValue(props)
    }
  </span>
}

export default Value
