import React from 'react'
import { NavLink } from 'react-router-dom'
import { Image, Loader } from '../components'
import Datum from '../datum'
import ImageSlider from '../components/Image/ImageSlider'
import { IImage } from '../types'
import './tiles.scss'

interface Props {
  children?: any
  id?: number
  title?: string | React.ReactElement
  images?: IImage[]
  imageHeader?: {
    to?: string
    children?: React.ReactElement
  }
}

const Tile = function (props: Props) {
  const { children, id, title, images, imageHeader } = props

  return <span className='tiles-block'>
    { id
      ? <>
        { title && <span className='tiles-title'>
          { title }
        </span> }
        { imageHeader && <span className='tiles-image-header'>
          { imageHeader.to
            ? imageHeader.to.indexOf(':') === -1
              ? <NavLink to={ imageHeader.to }>
                { imageHeader.children }
              </NavLink>
              : <a href={imageHeader.to} target='_blank' rel='noopener noreferrer'>
                {imageHeader.children}
              </a>
            : <span>
              { imageHeader.children }
            </span>
          }
        </span> }
        { images && <ImageSlider images={ images }/> }
        { children }
      </>
      : <>
        <span className='tiles-title'>
          <Loader/>
        </span>
        <Image id={ 0 } style={ { paddingTop: '75%' } }/>
        <Datum>
          <Loader/>
        </Datum>
      </>
    }
  </span>
}

export default Tile
