import { API_URL } from 'consts'
import { ILog, ILogsResult } from 'models/ILog'
import { getQueryParams } from 'ui-kit/helpers'
import { TValue } from 'ui-kit/types'

/**
 * Получает список логов
 * @param { string } token
 * @param { Object } filters - фильтры
 * @param { number } filters.page - номер страницы
 * @param { number } filters.count - количество записей на страницу
 * @param { string } filters.timestamp - выбока по дате
 */
export const fetchLogs = async function (
  token: string, 
  filters?: {[key: string]: TValue | TValue[]}
  ) {
  const { timestamp, boardId, contentId, userId, ...other } = filters || {}
  const params = {
    ...other,
    contentId: Array.isArray(contentId) ? contentId.map((x: any) => x.value) : undefined,
    boardId: Array.isArray(boardId) ? boardId.map((x: any) => x.value) : undefined,
    userId: Array.isArray(userId) ? userId.map((x: any) => x.value) : undefined,
    ...(Array.isArray(timestamp) 
        ? { from: timestamp?.[0] || '', to: timestamp?.[1] || ''}
        : undefined
    )
  }

  const response = await fetch(`${ API_URL }/Content/history${getQueryParams(params)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`
    }
  })

  if (response.status === 200) {
    return await response.json() as ILogsResult
  } else {
    throw Error()
  }
}

/**
 * Получает информацию по логу
 * @param {string} token
 * @param {number } id - идентификатор табло
 */
export const fetchLog= async function (token: string, id: number) {
  const response = await fetch(`${API_URL}/Content/history/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (response.status === 200) {
    return await response.json() as ILog
  } else {
    throw Error()
  }
}