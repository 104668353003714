import { LayoutState, ReduxState } from "../types";
import { IPrompt, PromptButton } from "../components/Prompt/Prompt";
import { Dispatch } from "react";
import { BoardFormat } from "consts";

export const layoutState: LayoutState = {
  redirect: null,
  showFilters: {},
  prompt: null,
  modal: null,
};

const SET_REDIRECT = "layout/SET_REDIRECT";
const SET_PROMPT = "layout/SET_PROMPT";
const SET_MODAL = "layout/SET_MODAL";
const SHOW_FILTERS = "layout/SHOW_FILTERS";

interface SetRedirectAction {
  type: typeof SET_REDIRECT;
  path?: string;
  push?: boolean;
}

interface SetPromptAction {
  type: typeof SET_PROMPT;
  prompt?: {
    message?: string;
    component?: any;
    initialState?: any;
    format?: BoardFormat;
    buttons?: PromptButton[];
  };
}

interface ShowFiltersAction {
  type: typeof SHOW_FILTERS;
  page: string;
  value?: boolean;
}

interface SetModalAction {
  type: typeof SET_MODAL;
  data: { [key in string]: any } | null;
}

type LayoutActions =
  | SetRedirectAction
  | SetPromptAction
  | ShowFiltersAction
  | SetModalAction;

const layoutReducer = function (state = layoutState, action: LayoutActions) {
  switch (action.type) {
    case SET_REDIRECT:
      return {
        ...state,
        redirect: action.path
          ? {
              path: action.path,
              push: action.push,
            }
          : null,
      };

    case SET_PROMPT:
      return {
        ...state,
        prompt: action.prompt,
      };

    case SHOW_FILTERS:
      return {
        ...state,
        showFilters: {
          ...state.showFilters,
          [action.page]:
            action.value === undefined
              ? !state.showFilters[action.page]
              : action.value,
        },
      };

    case SET_MODAL:
      return {
        ...state,
        modal: action.data,
      };

    default:
      return state;
  }
};

export default layoutReducer;

export const setRedirect = function (path?: string, push = false) {
  return {
    type: SET_REDIRECT,
    path,
    push,
  };
};

export const setPrompt = function (prompt?: IPrompt) {
  return function (dispatch: Dispatch<any>, getState: () => ReduxState) {
    const currentPrompt = getState().layout.prompt;

    if (prompt && currentPrompt) {
      dispatch({ type: SET_PROMPT });
      setTimeout(() => dispatch({ type: SET_PROMPT, prompt }), 100);
    } else {
      dispatch({ type: SET_PROMPT, prompt });
    }
  };
};

export const showFilters = function (
  page: string,
  value?: boolean
): ShowFiltersAction {
  return {
    type: SHOW_FILTERS,
    page,
    value,
  };
};

export const setModal = function (data?: { [key in string]: any }) {
  return {
    type: SET_MODAL,
    data: data || null,
  };
};
