import { useEffect, useRef } from 'react'

export const useTouchOnChange = function (
  onChange: (e: Event) => void,
  onBlur: (e: Event) => void,
  element?: HTMLElement | null
): void {
  const handlerChange = useRef<Function | null>(null)
  const handlerBlur = useRef<Function | null>(null)
  const isTouched = useRef(false)

  useEffect(function () {
    handlerChange.current = onChange
  }, [ onChange ])

  useEffect(function () {
    handlerBlur.current = onBlur
  }, [ onBlur ])

  const handleBlur = function (e: Event) {
    if (!isTouched.current) {
      handlerChange.current && handlerChange.current(e)
    } else {
      handlerBlur.current && handlerBlur.current(e)
      isTouched.current = false
    }
  }

  const handleTouchStart = function () {
    isTouched.current = true
  }

  useEffect(function () {
    if (element) {
      element && element.addEventListener('blur', handleBlur)
      document.addEventListener('touchstart', handleTouchStart, { capture: true })
    }


    return function () {
      if (element) {
        element && element.removeEventListener('blur', handleBlur)
        document.removeEventListener('touchstart', handleTouchStart, { capture: true })
      }
    }
  }, [ element ])
}