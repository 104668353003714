import React from 'react'
import { Button } from '../controls'
import { Icons } from '../consts'
import './map.scss'

interface Props {
  zoom: number,
  onZoom: (zoom: number) => void
  disabled?: boolean
}

const MapZoom = function (props: Props) {
  const { zoom, onZoom, disabled } = props 
  
  return <span className='map-zoom'>
    <Button 
      clear 
      icon={Icons.Minus} 
      onClick={() => onZoom(zoom - 1)}
      disabled={disabled}
    />
    <Button 
      clear 
      icon={Icons.Plus}
      onClick={() => onZoom(zoom + 1)}
      disabled={disabled}
    />
  </span>
}

export default MapZoom