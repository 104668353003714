import { createContext } from 'react'

export { default as useForm } from './useForm'
export { default } from './Form'
export { default as FormControl } from './FormControl'
export { default as FormSection } from './FormSection'

export const formContext = createContext<{
  state: {[key: string]: any}
  onChange?: (name: string, e: any) => void
  onInit?: (
    state: {[key: string]: any} | undefined | null,
    validate?: Function | null
  ) => void
  isChanged: boolean,
  errors: {[key: string]: boolean} | null,
  disabled: boolean
}>({
  state: {},
  isChanged: false,
  errors: null,
  disabled: false
})
