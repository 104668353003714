import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import classes from 'classnames'
import { Icon } from '../../components'
import './button.scss'

interface ButtonProps {
  children?: any,
  onClick?: (e: React.MouseEvent) => void,
  className?: string
  icon?: React.FunctionComponent
  label?: string
  active?: boolean
  fill?: boolean
  white?: boolean
  clear?: boolean
  wide?: boolean
  large?: boolean
  small?: boolean
  round?: boolean
  blur?: boolean
  danger?: boolean
  success?: boolean
  accent?: boolean
  strong?: boolean
  disabled?: boolean
  exact?: boolean
  href?: string
  replace?: boolean
}

const Button = React.forwardRef(function (props: ButtonProps, ref: React.Ref<any>) {
  const {
    icon, onClick, active, className, label, children, disabled,
    white, wide, clear, large, small, blur, fill, danger, success, accent, strong, round,
    exact, replace, href
  } = props

  const handleClick = function (e: any) {
    e.currentTarget.blur()
    onClick?.(e)
  }

  const content = useMemo(function () {
    return <>
      { label && <span className='button-label'>
        { label }
      </span> }
      <span className='button-box'>
        { icon && <Icon type={ icon }/> }
        { children && <span className='button-caption'>{ children }</span>}
      </span>
    </>
  }, [ icon, children, label ])

  const classNames = useMemo(function () {
    return classes(
      'button',
      className,
      active && '-active',
      fill && '-fill',
      wide && '-wide',
      white && '-white',
      clear && '-clear',
      large && '-large',
      small && '-small',
      blur && '-blur',
      danger && '-danger',
      success && '-success',
      accent && '-accent',
      strong && '-strong',
      disabled && '-disabled',
      round && '-round'
    )
  }, [
    active, fill, wide, white, clear, large, blur, small,
    danger, success, accent, disabled, strong, round, className
  ])

  return href
    ? <NavLink
      to={ href }
      exact={ exact }
      replace={ replace }
      className={ classNames }
      onClick={ handleClick }
      ref={ref}
    >
      { content }
    </NavLink>
    : <button
      type='button'
      className={ classNames }
      onClick={ handleClick }
      disabled={ disabled }
      ref={ref}
    >
      { content }
    </button>
})

export default Button
