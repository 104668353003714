import { BATCH_SIZE } from "../consts";
import { DatumState, ReduxState, RouteMatch } from "../types";
import { setRedirect } from "./layout";
import { createPathURL } from "../helpers";

const initialState: DatumState = {};

const SET_DATUM = "datum/SET_DATUM";
const SET_DATUM_APPENDIX = "datum/SET_DATUM_APPENDIX";
const ERROR_DATUM = "datum/ERROR_DATUM";
const CLEAR_DATUM = "datum/CLEAR_DATUM";
const UPDATE_DATUM_ITEM = "datum/UPDATE_DATUM_ITEM";
const DELETE_DATUM_ITEM = "datum/DELETE_DATUM_ITEM";

interface SetDatumAction {
  type: typeof SET_DATUM;
  page: string;
  items: (any | null | undefined)[];
  total: number;
  time?: number;
}

interface SetDatumAppendixAction {
  type: typeof SET_DATUM_APPENDIX;
  page: string;
  key: string;
  data: undefined | null | any;
}

interface ErrorDatumAction {
  type: typeof ERROR_DATUM;
  page: string;
}

interface ClearDatumAction {
  type: typeof CLEAR_DATUM;
  page: string;
}

interface DeleteDatumItemAction {
  type: typeof DELETE_DATUM_ITEM;
  page: string;
  id: number;
}

interface UpdateDatumItemAction {
  type: typeof UPDATE_DATUM_ITEM;
  page: string;
  id: number;
  data: any;
}

type DatumActions =
  | SetDatumAction
  | SetDatumAppendixAction
  | ErrorDatumAction
  | ClearDatumAction
  | DeleteDatumItemAction
  | UpdateDatumItemAction;

const datumReducer = function (state = initialState, action: DatumActions) {
  switch (action.type) {
    case SET_DATUM:
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          items: action.items,
          total: action.total,
          time: action.time,
        },
      };

    case ERROR_DATUM:
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          items: null,
          total: null,
          time: undefined,
        },
      };

    case CLEAR_DATUM: {
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          items: undefined,
          total: undefined,
        },
      };
    }

    case DELETE_DATUM_ITEM: {
      const total = state[action.page].total;
      const items = state[action.page].items || null;
      const index = (items || []).findIndex((x) => x.id === action.id);
      if (index === -1) return state;

      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          items: items
            ? items.filter((x) => x.id !== action.id)
            : state[action.page].items,
          total: total ? total - 1 : total,
        },
      };
    }

    case UPDATE_DATUM_ITEM: {
      const items = state[action.page].items || [];
      const index = items.findIndex((x) => x.id === action.data.id);

      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          items: [
            ...(index > -1 ? items.slice(0, index) : []),
            action.data,
            ...items.slice(index + 1),
          ],
          total:
            index > -1
              ? state[action.page].total
              : (state[action.page].total || 0) + 1,
        },
      };
    }

    case SET_DATUM_APPENDIX: {
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          [action.key]: action.data,
        },
      };
    }

    default:
      return state;
  }
};

export default datumReducer;

/**
 * Загрузка данных
 * @param page
 * @param endpoint
 * @param batch
 */
export const loadDatum = function (
  page: string,
  endpoint: Function,
  batch = 1
) {
  return async function (dispatch: any, getState: () => ReduxState) {
    const datum = getState().datum[page];
    const token = getState().user.token;

    if (!endpoint || !token) {
      return;
    }

    const filters = getState().settings.filters[page];
    const sort = getState().settings.sort[page];
    const batchSize = BATCH_SIZE as number;
    const time = Date.now();

    /** Handle preload data */
    if (typeof datum?.total !== "number") {
      /** Set total to null to prevent repeating requests */
      dispatch({
        type: SET_DATUM,
        page,
        time,
      });
    } else {
      /** Set requested batch to null[] to prevent repeating requests */
      dispatch({
        type: SET_DATUM,
        page,
        items: [
          ...[null, ...Array(batch * batchSize - 1)]
            .slice(0, datum.total)
            .map(
              (x, i) =>
                (datum.items && datum.items[i]) ||
                (i < batchSize * (batch - 1) ? undefined : {})
            ),
          ...(datum.items || []).slice(batch * batchSize),
        ],
        total: datum.total,
        time,
      });
    }

    try {
      const data = await endpoint(
        token,
        batch === 1 ? filters : { ...filters, page: batch, count: batchSize },
        sort
      );

      /** Get actual state of datum */
      const datum = getState().datum[page];
      if (datum?.time === time) {
        /** Batch data */
        if (!Array.isArray(data)) {
          /** Append batch */
          if (datum?.items) {
            const ids = data.items.map((x: any) => x.id);
            dispatch({
              type: SET_DATUM,
              page,
              items: [
                /** Set duplicates to undefined to initiate batch request */
                ...(datum.items || [])
                  .slice(0, (batch - 1) * batchSize)
                  .map((x) => (ids.includes(x?.id) ? undefined : x)),
                ...data.items,
                ...(datum.items || []).slice(batch * batchSize),
              ],
              total: data.totalCount,
            });

            /** Set first batch */
          } else {
            dispatch({
              type: SET_DATUM,
              page,
              items: data.items,
              total: data.totalCount,
            });
          }

          /** Full data */
        } else {
          dispatch({ type: SET_DATUM, page, items: data, total: data.length });
        }
      }
    } catch (e) {
      const datum = getState().datum[page];
      if (time === datum.time) {
        if (typeof datum?.total === "number") {
          dispatch({
            type: SET_DATUM,
            page,
            items: [
              ...(datum.items || []).slice(0, (batch - 1) * batchSize),
              ...[
                null,
                ...Array(
                  Math.min(0, datum.total - batch * batchSize) + batchSize - 1
                ),
              ].map(() => null),
              ...(datum.items || []).slice(batch * batchSize),
            ],
            total: datum.total,
          });
        } else {
          dispatch({ type: ERROR_DATUM, page });
        }
      }
    }
  };
};

/** Удаление записи из списка */
export const deleteDatumItem = function (
  page: string,
  id: number,
  match?: RouteMatch
) {
  return function (dispatch: any, getState: () => ReduxState) {
    const items = getState().datum[page]?.items;
    if (items && match) {
      const index = (items || []).findIndex((x) => x?.id === id);
      const nextItem = items[index + 1] || items[index - 1];
      if (index >= 0 && nextItem) {
        dispatch(
          setRedirect(
            createPathURL(match?.path, { ...match?.params, id: nextItem.id })
          )
        );
      } else {
        dispatch(
          setRedirect(
            createPathURL(match?.path, { ...match?.params, id: undefined })
          )
        );
      }
    }

    dispatch({
      type: DELETE_DATUM_ITEM,
      id,
      page,
    });
  };
};

export const updateDatumItem = function (page: string, data: any) {
  return {
    type: UPDATE_DATUM_ITEM,
    data,
    page,
  };
};

export const clearDatum = function (page: string) {
  return {
    type: CLEAR_DATUM,
    page,
  };
};

export const setDatumAppendix = function (
  page: string,
  key: string,
  data: any
) {
  return {
    type: SET_DATUM_APPENDIX,
    page,
    key,
    data,
  };
};
