import {
  deleteDatumItem,
  loadDatum,
  updateDatumItem
} from 'ui-kit/reducers/datum'
import * as tagsApi from 'services/tags'
import { Dispatch } from 'react'
import { ReduxState } from 'ui-kit/types'
import { ITag } from 'models/ITag'
import { setPrompt } from '.'

export const loadTags = function () {
  return loadDatum('tags', tagsApi.fetchTags)
}

export const saveTag = function (data: ITag) {
  return async function (dispatch: Dispatch<any>, getState: () => ReduxState) {
    const token = getState().user.token
    if (!token) { return }
    try {
      const res = await tagsApi.saveTag(token, data)
      dispatch(updateDatumItem('tags', res))
    } catch (e) {
      dispatch(setPrompt({
        message: e.error || 'При сохранении тега произошла ошибка.'
      }))
    }
  }
}

export const deleteTag = function (id: number) {
  return async function (dispatch: Dispatch<any>, getState: () => ReduxState) {
    const token = getState().user.token
    if (!token) { return }
    try {
      await tagsApi.deleteTag(token, id)
      dispatch(deleteDatumItem('tags', id))
    } catch (e) {
      dispatch(setPrompt({
        message: e.error || 'При удалении тега произошла ошибка.'
      }))
    }
  }
}

