import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import classes from 'classnames'
import { Icon, Loader } from '../../components'
import { InputPopup, Suggestions } from '..'
import { Icons } from '../../consts'
import {
  getFocusedIndex,
  moveSuggestionFocus,
  setSuggestionFocus,
  dispatchValue,
  filterArray,
  arrify, getSuggestionData
} from '../plugins/use-controls/helpers'
import {
  TSelectOptions,
  TSelectProps,
  TSuggestion
} from '../plugins/use-controls/types'
import useSuggestions from '../plugins/use-controls/shared/useSuggestions'
import '../Textbox/textbox.scss'

interface Props extends TSelectProps {
  icon?: React.FunctionComponent
  className?: string
  size?: number
  onChange?: (e: ChangeEvent | Event) => void,
  onSelect?: (value: TSuggestion, e: any) => void
  options?: TSelectOptions,
  value?: string
  small?: boolean
  filtered?: boolean
  fixed?: boolean
}

const Suggester = React.forwardRef(function (props: Props, ref: any) {
  const {
    icon,
    className,
    size,
    onKeyDown,
    onMouseDown,
    onSelect,
    onChange,
    value,
    options,
    list,
    small,
    filtered,
    fixed,
    ...inputProps
  } = props

  const [ isOpened, setOpened ] = useState(false)
  const [ isActive, setActive ] = useState(false)
  const [ keys ] = useState({
    search: options?.keys?.search,
    list: options?.keys?.list,
    value: options?.keys?.value ? arrify(options.keys.value) :  ['value', 'id'],
    display: options?.keys?.display ? arrify(options.keys.display) :  ['label', 'name'],
  })
  const [ query, setQuery ] = useState(value)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const listRef = useRef<HTMLElement | null>(null)
  const focusedRef = useRef<HTMLElement | null>(null)
  const timeoutRef = useRef(0)

  useEffect(function () {
    setQuery(value || '')
  }, [ value ])

  const items = useMemo(function () {
    return (filtered
      ? list
      : filterArray(
        query || '',
        list,
        keys.search,
        {
          narrow: keys.list || [],
          exclude: keys.value && (keys.list === keys.value) ? [] : keys.value || []
        }
      )) || []
  }, [ query, list, keys, filtered ])

  useEffect(function () {
    if (items && query) {
      focusedRef.current = setSuggestionFocus(0, listRef)
      isActive && setOpened(true)
    }
  }, [ items, isActive, query ])

  useEffect(function () {
    if (isOpened) {
      setSuggestionFocus(getFocusedIndex(listRef), listRef, true)
    }
  }, [ isOpened, items ])

  const handleSelectSuggestion = function (index: number, e: any) {
    onSelect?.(items[index], e)
    setQuery(getSuggestionData(items[index])[1])
    setTimeout(() => {
      setOpened(false)
      setActive(false)
    }, 1)
  }

  const handleKeyDown = function (e: any) {
    onKeyDown && onKeyDown(e)

    if (
      (e.key === 'ArrowUp' || e.key === 'ArrowDown')
    ) {
      e.preventDefault()
      e.stopPropagation()

      if (!isOpened) {
        /** Select textbox text on opening for user be able to replace value */
        query && list && setOpened(true)
      } else {
        focusedRef.current = moveSuggestionFocus(e.key === 'ArrowUp' ? -1 : 1, listRef, true)
      }
    } else if (e.key === 'Escape') {
      e.preventDefault()
      isOpened && items.length && e.stopPropagation()
      setOpened(false)
    } else if (e.key === 'Enter') {
      if (isOpened && items.length) {
        e.preventDefault()
        e.stopPropagation()
        handleSelectSuggestion(getFocusedIndex(listRef), e)
      } else {
        if (query && list) {
          setOpened(true)
          setActive(true)
        }
      }
    }
  }

  const handleMouseDown = function (e: any) {
    onMouseDown && onMouseDown(e)

    if (!isOpened && query && list) {
      setOpened(true)
      setActive(true)
    } else {
      setOpened(false)
      setActive(false)
    }
  }

  const handleBlur = function (e: any) {
    timeoutRef.current = window.setTimeout(() => {
      setActive(false)
      setOpened(false)
    }, 100)
  }

  const handleFocus = function (e: any) {
    clearTimeout(timeoutRef.current)
    setActive(true)
  }

  const handleChange = function (e: any) {
    onChange?.(e)
    setActive(true)
  }

  useSuggestions(
    ref || inputRef,
    (isOpened && listRef) || { current: null },
    focusedRef,
    handleSelectSuggestion
  )

  return <label
    className={ classes('textbox', className, !size && '-wide', small && '-small') }
    style={{
      maxWidth: size ? size * 8.4 : 'unset',
      minWidth: size ? size * 8.4 : 'unset'
    }}
  >
    <input
      { ...inputProps }
      value={query || ''}
      onChange={handleChange}
      ref={ ref || inputRef }
      type='text'
      className={ classes('textbox-control popup-control', isOpened && '-opened') }
      autoComplete='off'
      onKeyDown={ handleKeyDown }
      onMouseDown={ handleMouseDown }
      onBlur={ handleBlur }
      onFocus={ handleFocus }
    />
    { icon && <Icon
      type={ icon }
      className={ classes('popup-icon', isOpened && '-opened') }
    /> }

    <span
      className={ classes('textbox-button', !value?.trim() && '-disabled') }
      onClick={ () => {
        onChange && dispatchValue('', ref || inputRef, onChange)
      } }
    >
      { list ? <Icon type={ Icons.Close }/> : <Loader icon /> }
    </span>

    <InputPopup
      className={classes('-input', small && '-small')}
      isOpened={ Boolean(query && isOpened) }
      ref={ listRef }
      fixed={ fixed }
    >
      { items && items.length > 0 && (
        <Suggestions
          className={classes(icon && '-iconic')}
          small={small}
          keys={keys}
          value={ value || '' }
          items={ items }
        />
      ) }
    </InputPopup>
  </label>
})

export default Suggester
