import { Colors } from 'ui-kit'
import BezierEasing from 'bezier-easing'

export const easeInOut = BezierEasing(0.4, 0, 0.6, 1)

export { AppIcons } from 'assets/icons'

export enum Page {
  Messages = 'messages',
  Infoboards = 'infoboards',
  Geozones = 'geozones',
  Logs = 'logs'
}

export enum Photo {
  BoardCamera = 'boardCamera',
  Frame = 'frame',
  GeoZonePreview = 'geoZonePreview'
}

export enum BoardType {
  Common,
  Vehicle,
  Truck,
  RoadSign
}

export enum LogAction {
  Create = 0,
  Update = 1,
  Delete = 2,
  Publish = 3,
  Unpublish = 4,
  BoardsUpdate = 5
}

export enum LogBoardAction {
  Unchanged = 0,
  Linked = 1,
  Unlinked = 2
}

export enum BoardFormat {
  OneOne = 0,
  TwoThree = 1,
  FourThree = 2,
  FiveOne = 3,
  ThreeHalfOne = 4,
  Unknown = 100
}

export enum FrameKind {
  StaticImage = 1,
  UrlSource = 2,
  MessageEditor = 3,
  Video = 4
}

export enum FrameTransition {
  None,
  Swirl,
  Blinds,
  Circle,
  Cloud,
  FadeIn,
  Pixelate,
  RadialBlur,
  Ripple,
  Wave,
  Wipe,
  SlideBottom,
  SlideTop,
  SlideRight,
  SlideLeft
}

export const frameTransitions = [
  { label: 'Отсутствует', value: FrameTransition.None },
  { label: 'Водоворот', value: FrameTransition.Swirl },
  { label: 'Жалюзи', value: FrameTransition.Blinds },
  { label: 'Круги', value: FrameTransition.Circle, disabled: true },
  { label: 'Облака', value: FrameTransition.Cloud, disabled: true },
  { label: 'Затухание', value: FrameTransition.FadeIn },
  { label: 'Пикселизация', value: FrameTransition.Pixelate },
  { label: 'Размытие', value: FrameTransition.RadialBlur },
]

export const statusColors = [ Colors.Green, Colors.Red ]
export const tagsColor = [
  '#64DD17', '#AA00FF', '#6200EA', '#00B8D4', '#FF9800', 'rgb(51,51,51)'
]
export const statuses = ['Работает', 'Не работает']
export const types = ['Стационарное табло', 'Мобильное табло', 'Прицеп']
export const formats = ['1:1', '2:3', '4:3', '1:5', '3.6:1', '~']
export const API_URL = window.config?.REACT_APP_API_URL || process.env.REACT_APP_API_URL
export const IMAGE_API_URL = window.config?.REACT_APP_IMAGE_API_URL || process.env.REACT_APP_IMAGE_API_URL
export const logActions = {
  [LogAction.Create]: {
    label: 'Создание сообщения',
    modifier: { success: true }
  },
  [LogAction.Update]: {
    label: 'Редактирование сообщения',
    modifier: { accent: true, soft: true }
  },
  [LogAction.Delete]: {
    label: 'Удаление сообщения',
    modifier: { danger: true }
  },
  [LogAction.Publish]: {
    label: 'Публикация',
    modifier: { success: true, soft: true }
  },
  [LogAction.Unpublish]: {
    label: 'Снятие с публикации',
    modifier: {  danger: true, soft: true }
  },
  [LogAction.BoardsUpdate]: {
    label: 'Изменение списка табло',
    modifier: { accent: true, soft: true }
  }
}

export const logBoardActions = {
  [LogBoardAction.Unchanged]: undefined,
  [LogBoardAction.Linked]: {className: '-success'},
  [LogBoardAction.Unlinked]: {className: '-danger'}
}

export enum Cities {
  Moscow = 'moscow',
  Saratov = 'saratov'
}
