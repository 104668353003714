import React from 'react'
import { AppLoader } from '../app'
import Section, { SectionTitle } from '../section'
import FilterFooter from './FilterFooter'
import { Value } from '../components'
import FilterDate from './FilterDate'
import FilterSuggester from './FilterSuggester'
import FilterGroup from './FilterGroup'
import { FilterType } from '../consts'
import { IFilter } from '../types'
import FilterList from './FilterList'

interface Props {
  list?: IFilter[] | null
  total?: number | null
}

const Filter = function (props: Props) {
  const { list, total } = props

  return <Section
    header={ () => <SectionTitle
      label={<span>
        <span>Всего объектов: </span>
        <Value value={total} />
      </span>}
    >
      Фильтр
    </SectionTitle> }
    footer={ FilterFooter }
  >
    <div className='filter'>
      {list
        ? list.map((filter: IFilter, index: number) => {
          switch (filter.type) {
            case FilterType.Date:
              return <FilterDate key={ filter.name } {...filter } />
            case FilterType.Suggestions:
              return <FilterSuggester key={ filter.name } { ...filter }/>
            case FilterType.Component: {
              const Component = filter.component
              return Component
                ? <Component
                  name={ filter.name }
                  label={ filter.label }
                  key={ filter.name }
                />
                : null
            }
            case FilterType.Group:
              return <FilterGroup {...filter } key={index} />
            default:
              return <FilterList {...filter } key={filter.name} />
          }
        })
        : <AppLoader />
      }
    </div>
  </Section>
}

export default Filter
