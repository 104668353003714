import React, { useCallback, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  AppMessage,
  Button,
  Datum,
  Value,
  Section,
  SectionTitle,
  Tabs,
  Icons,
  Icon,
} from "ui-kit";
import { MapPreview } from "ui-kit/map";
import { useMapData } from "components/Infoboards";
import { createPathURL, filterObject } from "ui-kit/helpers";
import { getDatesRange } from "components/Messages/Messages.helpers";
import {
  loadGeoZoneDetails,
  deleteGeozone,
  loadGeoZoneBoards,
  loadGeoZoneContent,
} from "actions";
import { Page, AppIcons } from "consts";
import { FeatureCollection } from "geojson";
import { ReduxState, RouteMatch } from "ui-kit/types";
import { IGeoZone } from "models/IGeoZone";
import { IBoard } from "models/IBoard";
import { IContent } from "models/IContent";

const GeozonesCard = function () {
  const dispatch = useDispatch();
  const match: RouteMatch = useRouteMatch();
  const { id, page } = match?.params || { id: "", page: "" };
  const data = useSelector(
    ({ details }: ReduxState) =>
      details[Page.Geozones].data as IGeoZone | undefined | null,
    shallowEqual
  );
  const boards = useSelector(
    ({ details }: ReduxState) =>
      details[Page.Geozones].boards as IBoard[] | undefined | null,
    shallowEqual
  );
  const content = useSelector(
    ({ details }: ReduxState) =>
      details[Page.Geozones].content as IContent[] | undefined | null,
    shallowEqual
  );

  useEffect(
    function () {
      if (id) {
        dispatch(loadGeoZoneDetails(+id));
        dispatch(loadGeoZoneBoards(+id));
        dispatch(loadGeoZoneContent(+id));
      }
    },
    [dispatch, id, page]
  );

  const [geoJSON, icons] = useMapData(boards);
  const mapData = useMemo(
    function () {
      return [
        geoJSON,
        data
          ? ({
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  id: data.id,
                  geometry: {
                    type: "MultiPolygon",
                    coordinates:
                      data.geo?.shapes?.map(
                        (shape) => [shape.coordinates || []] || []
                      ) || [],
                  },
                  properties: {
                    fill: "#5784FF",
                  },
                },
              ],
            } as FeatureCollection)
          : data,
      ];
    },
    [data, geoJSON]
  );

  const filterContent = useCallback(function (query: string, item: IContent) {
    return filterObject(query, item, {
      name: true,
    });
  }, []);

  const filterBoards = useCallback(function (query: string, item: IBoard) {
    return filterObject(query, item, {
      num: true,
      address: true,
    });
  }, []);

  if (!id) {
    return (
      <Section>
        <AppMessage>Выберите геозону</AppMessage>
      </Section>
    );
  }

  return (
    <Section
      header={() => (
        <>
          <Button icon={Icons.Edit} href={`${match?.url}/edit`} />
          <Button
            danger
            icon={Icons.Delete}
            onClick={() =>
              dispatch(deleteGeozone(Number(match?.params?.id || 0), match))
            }
          />
          <hr />
          <SectionTitle>
            <Value value={data && data.value} />
          </SectionTitle>
          <hr />
          <Tabs wide>
            <Button
              large
              clear
              exact
              replace
              href={createPathURL(match?.path, {
                ...match?.params,
                tab: "",
              })}
            >
              Основное
            </Button>
            <Button
              large
              clear
              exact
              replace
              href={createPathURL(match?.path, {
                ...match?.params,
                tab: "content",
              })}
            >
              Контент
            </Button>
          </Tabs>
        </>
      )}
    >
      {match?.params?.tab === "content" && (
        <>
          <Datum
            label={"Сообщения"}
            classNames={["", "-right", ""]}
            sizes={["unset", "unset", 22]}
            filter={filterContent}
            items={
              content
                ? content.filter((x) => x.isActive && !x.isRemoved)
                : content
            }
            renderer={(item: IContent) => [
              <Value
                value={item.name}
                href={`/${Page.Messages}/list/${item.id}`}
              />,
              <Value value={getDatesRange(item.schedule)} />,
              <Icon type={Icons.Calendar} />,
            ]}
          />
        </>
      )}

      <MapPreview
        data={mapData}
        icons={icons}
        dataIndex={1}
        hidden={Boolean(match?.params?.tab)}
      />
      {!match?.params?.tab && (
        <>
          <Datum label="Описание" rowProps={() => ({ className: "-empty" })}>
            <Value value={data ? data.description || null : data} />
          </Datum>
          <Datum
            label="Табло геозоны"
            sizes={[60, "unset", 24, 24]}
            classNames={["-strong", null, null, null]}
            items={boards}
            filter={filterBoards}
            renderer={(board: IBoard) => [
              <Value
                href={`/${Page.Infoboards}/list/${board.id}`}
                value={`№${board.num}`}
              />,
              <Value value={board.address} />,
              <Icon type={AppIcons.BoardType(board.type)} />,
              <Icon type={AppIcons.BoardFormat(board.format)} />,
            ]}
          />
        </>
      )}
    </Section>
  );
};

export default GeozonesCard;
