import React, { useMemo } from 'react'
import { FeatureCollection } from 'geojson'
import { AppDataEmpty, AppLoader, AppLoadingError } from '../app'
import { ScrollerOffset } from '../components/Scroller'

interface Props {
  data:
    (FeatureCollection | null | undefined) |
    (FeatureCollection | null | undefined)[]
  onLoad?: () => void,
  offset?: ScrollerOffset
}

const MapDataHandler = function (props: Props) {
  const { data, onLoad, offset } = props

  const status = useMemo(function (){
    if (data === undefined) {
      return null
    }

    const mapData = Array.isArray(data) ? data : [data]
    return mapData.some(x => x === null)
      ? 'error'
      : mapData.some(x => x === undefined)
        ? 'loading'
        : mapData.filter(x => x && x.features.length > 0).length === 0
          ? 'empty'
          : null
  }, [data])

  if (!status) {
    return null
  }

  return <>
    { status === 'loading' && <AppLoader offset={offset}/> }
    { status === 'error' && <AppLoadingError onClick={ onLoad } offset={offset}/> }
    { status === 'empty' && <AppDataEmpty offset={offset}/> }
  </>
}

export default MapDataHandler
