import React, { useMemo } from "react";
import { IFilterGroup, ReduxState, RouteParams } from "../types";
import { List } from "../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setFilter } from "../reducers/settings";

const FilterGroup = function (props: IFilterGroup) {
  const { label, list } = props;
  const dispatch = useDispatch();
  const page = (useParams() as RouteParams).page;
  const filters = useSelector(
    ({ settings }: ReduxState) => settings.filters[page],
    shallowEqual
  );
  const summary = useSelector(
    ({ datum }: ReduxState) => datum[page]?.summary,
    shallowEqual
  );
  const values = list.map((x) => x.value).filter((x) => filters?.[x]?.[0]);

  const items = useMemo(
    function () {
      if (!list) {
        return list;
      }

      return list.map((item) => ({
        ...item,
        badge:
          item.total !== undefined
            ? item.total
            : summary
            ? summary?.[item.value]
            : "",
      }));
    },
    [list, summary]
  );

  return (
    <>
      {/* @ts-ignore */}
      <List
        label={label}
        value={values}
        list={items}
        onChange={(next: string[]) => {
          const removed = values.filter((x) => !next || !next.includes(x));
          const added = next?.filter((x) => !values.includes(x)) || [];
          removed.forEach((key) => dispatch(setFilter(page, key, undefined)));
          added.forEach((key) => dispatch(setFilter(page, key, [true])));
        }}
      />
    </>
  );
};

export default FilterGroup;
