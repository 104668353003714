import { useEffect, useRef, useState } from 'react'
import { ReduxState } from '../types'
import { shallowEqual, useSelector } from 'react-redux'

const useModalData = function<T>(selector: (state: ReduxState) => any) {
  const stateData = useSelector(selector, shallowEqual)
  const [ data, setData ] = useState<T | undefined>(undefined)
  const timerRef = useRef(0)

  useEffect(function () {
    if (stateData) {
      clearTimeout(timerRef.current)
      setData(stateData)
    } else {
      timerRef.current = window.setTimeout(function () {
        setData(stateData)
      }, 200)
    }
  }, [ stateData ])

  return data
}

export default useModalData
