import React from 'react'
import classes from 'classnames'
import './badge.scss'

interface Props {
  children: any
  disabled?: boolean
}

const Badge = function (props: Props) {
  const { disabled, children } = props
  return <span className={classes('badge', disabled && '-disabled')}>
    { children } 
  </span>
}

export default Badge