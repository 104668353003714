import { useCallback, useEffect, useRef, useState } from 'react'
import useEventListener from '@use-it/event-listener'
import isEqual from 'lodash/isEqual'
import { Position } from 'geojson'
import { Map } from 'mapbox-gl'
import {
  findLineIndex,
  renderLayers,
  useLayersInit
} from './MapDraw.helpers'
import { TMapEditData } from './index'

const useMapEditing = function (
  map: Map | null,
  editMode: boolean | undefined,
  editData?: {
    polygons?: { data: Position[][], multiple?: boolean }
    points?: { data: Position[], multiple?: boolean }
    lines?: { data: Position[][], multiple?: boolean }
    /* TODO:
        Add data for point, line.
        For now it's just creating multiple polygons
    */
  },
  onChange?: (data: TMapEditData) => void
) {
  const [ isDrawing, setDrawing ] = useState(false)
  const [ polygonsData, setPolygonsData ] = useState<undefined | Position[][]>(editData?.polygons?.data)
  const [ editIndex, setEditIndex ] = useState(-1)
  const [ nodeIndex, setNodeIndex ] = useState(-1)
  const [ mapElement, setMapElement ] = useState<null | HTMLCanvasElement>(null)
  const drawDataRef = useRef<null | Position[]>(null)
  const lockRef = useRef<boolean | 'node' | 'line'>(false)
  const keyPressedRef = useRef<number[]>([])

  const handleNodeDelete = function (nodeIndex: number, editIndex: number) {
    if (nodeIndex >= 0 && editIndex >= 0) {
      setPolygonsData(polygons => {
        const data = polygons?.[editIndex] || []
        if (data.length <= 4) { return polygons }

        return [
          ...(polygons || []).slice(0, editIndex),
          nodeIndex === 0 || nodeIndex === data.length - 1
            ? [ ...data.slice(1, data.length - 1), data[1] ]
            : [ ...data.slice(0, nodeIndex), ...data.slice(nodeIndex + 1) ],
          ...(polygons || []).slice(editIndex + 1),
        ]
      })
    }
  }

  useEffect(function () {
    if (!editMode) {
      setDrawing(false)
    }

    return function () {
      setPolygonsData(undefined)
      setEditIndex(-1)
    }
  }, [ editMode ])

  useEffect(function () {
    mapElement && (mapElement.style.cursor = 'default')
  }, [ mapElement ])

  /** Init drawing source data */
  useEffect(function () {
    setPolygonsData(editData?.polygons?.data)
  }, [ editData ])

  /** Handle onChange method */
  useEffect(function () {
    if (polygonsData) {
      onChange?.({ polygons: polygonsData })
    }
  }, [ polygonsData, onChange ])

  /** Create sources and layers for editing polygons */
  useLayersInit(map, Boolean(editMode), 'data')

  /** Update editing polygons */
  useEffect(function () {
    editMode && setTimeout(() => renderLayers(map, 'data', polygonsData, editIndex, nodeIndex), 1)
  }, [ map, editMode, polygonsData, editIndex, nodeIndex])

  const handleNodeMouseMove = useCallback(() => {
    if (mapElement) {
      lockRef.current = 'node'
      mapElement.style.cursor = 'pointer'
    }
  }, [mapElement, lockRef])

  const handleNodeMouseLeave = useCallback(() => {
    if (mapElement) {
      lockRef.current = false
      mapElement.style.cursor = 'default'
    }
  }, [mapElement, lockRef])

  const handleNodeMouseDown = useCallback(function (e: any) {
    if (map && !e.originalEvent.altKey) {
      const nodeIndex = e.features?.[0]?.id
      let data = [...(polygonsData || [[]])]

      const handleMouseMove = function (e: any) {
        const { lng, lat } = e.lngLat as { lng: number, lat: number }

        data = [
          ...data.slice(0, editIndex),
          data[editIndex].map((c, i) => {
            return (
              (i === 0 && (nodeIndex === data[editIndex].length - 1)) ||
              ((i === data[editIndex].length - 1) && nodeIndex === 0) ||
              i === nodeIndex
            )
              ? [lng, lat]
              : c
          }),
          ...data.slice(editIndex + 1)
        ] as Position[][]

        renderLayers(map, 'data', data, editIndex, nodeIndex)
      }

      if (nodeIndex !== undefined) {
        lockRef.current = true
        setNodeIndex(nodeIndex)
        map.dragPan.disable()
        map.on('mousemove', handleMouseMove)
      }

      map.once('mouseup', () => {
        lockRef.current = false
        map.off('mousemove', handleMouseMove)
        map.dragPan.enable()
        setPolygonsData(p => isEqual(p, data) ? p : data)
      })
    }
  }, [ lockRef, map, editIndex, polygonsData ])

  const handleNodeClick = useCallback(function (e) {
    if (e.originalEvent.altKey) {
      const nodeIndex = e.features?.[0]?.id
      handleNodeDelete(nodeIndex, editIndex)
      setNodeIndex(-1)
    }
  }, [ editIndex ])

  const handleLineMouseMove = useCallback(function () {
    if (mapElement && editIndex >= 0 && !lockRef.current) {
      mapElement.style.cursor = 'crosshair'
      lockRef.current = 'line'
    }
  }, [mapElement, editIndex, lockRef])

  const handleLineMouseLeave = useCallback(function () {
    if (mapElement) {
      mapElement.style.cursor = 'default'
      lockRef.current = false
    }
  }, [mapElement])

  const handleLineMouseDown = useCallback(function (e: any) {
    if (map && editIndex >= 0 && lockRef.current === 'line') {
      const { lng, lat } = e.lngLat as { lng: number, lat: number }
      let data = [...(polygonsData || [])]
      let index = -1

      const handleMouseMove = function (e: any) {
        const { lng, lat } = e.lngLat as { lng: number, lat: number }

        data = [
          ...data.slice(0, editIndex),
          data[editIndex].map((c, i) => {
            return i === index
              ? [lng, lat]
              : c
          }),
          ...data.slice(editIndex + 1)
        ] as Position[][]

        renderLayers(map, 'data', data, editIndex, index)
      }

      if (e.features?.[0]) {
        const indexData = data[editIndex] || []
        index  = findLineIndex([lng, lat], indexData)

        if (index > -1) {
          data[editIndex] = [
            ...indexData.slice(0, index),
            [lng, lat],
            ...indexData.slice(index)
          ]

          renderLayers(map, 'data', data, editIndex, index)
        }

        lockRef.current = true
        map.dragPan.disable()
        map.on('mousemove', handleMouseMove)
      }

      map.once('mouseup', () => {
        lockRef.current = false
        map.off('mousemove', handleMouseMove)
        map.dragPan.enable()
        setPolygonsData(p => isEqual(p, data) ? p : data)
        setNodeIndex(index)
        if (mapElement) {
          mapElement.style.cursor = 'pointer'
        }
      })
    }
  }, [map, mapElement, lockRef, editIndex, polygonsData])

  const handlePolygonMouseMove = useCallback(() => {
    if (mapElement && !lockRef.current) {
      mapElement.style.cursor = 'grab'
    }
  }, [mapElement, lockRef])

  const handlePolygonMouseLeave = useCallback(() => {
    if (mapElement && !lockRef.current) {
      mapElement.style.cursor = 'default'
    }
  }, [mapElement, lockRef])

  const handlePolygonMouseDown = useCallback(function (e: any) {
    if (map && mapElement) {
      if (!lockRef.current) {
        const { lng, lat } = e.lngLat as { lng: number, lat: number }
        const index = e.features?.[0]?.id
        let data = [...(polygonsData || [[]])]
        let original: Position[] = []
        const start: Position = [ lng, lat]

        const handleMouseMove = function (e: any) {
          const { lng, lat } = e.lngLat as { lng: number, lat: number }
          const delta = [lng - start[0], lat - start[1]]

          data = [
            ...data.slice(0, index),
            original.map((c) => [ c[0] + delta[0], c[1] + delta[1] ]),
            ...data.slice(index + 1)
          ] as Position[][]

          renderLayers(map, 'data', data, index, -1)
        }

        if (index !== undefined) {
          lockRef.current = true
          setEditIndex(index)
          setNodeIndex(-1)
          original = data[index]
          map.dragPan.disable()
          map.on('mousemove', handleMouseMove)
          mapElement.style.cursor = 'grabbing'
        }

        map.once('mouseup', () => {
          lockRef.current = false
          map.off('mousemove', handleMouseMove)
          map.dragPan.enable()
          mapElement.style.cursor = 'grab'
          setPolygonsData(p => isEqual(p, data) ? p : data)
        })
      }
    }
  }, [ map, mapElement, lockRef, polygonsData ])

  const handleDeselect = useCallback(function (e: any) {
    if (!lockRef.current) {
      setEditIndex(-1)
      setNodeIndex(-1)
    }
  }, [lockRef])

  /** Bind events to select existing polygons */
  useEffect(function () {
    if (map && !isDrawing) {
      map.on('click', 'data_nodes_helper', handleNodeClick)
      map.on('mousemove', 'data_nodes_helper', handleNodeMouseMove)
      map.on('mouseleave', 'data_nodes_helper', handleNodeMouseLeave)
      map.on('mousedown', 'data_nodes_helper', handleNodeMouseDown)
      map.on('mousemove', 'data_lines_helper', handleLineMouseMove)
      map.on('mouseleave', 'data_lines_helper', handleLineMouseLeave)
      map.on('mousedown', 'data_lines_helper', handleLineMouseDown)
      map.on('mousemove', 'data_polygons_layer', handlePolygonMouseMove)
      map.on('mouseleave', 'data_polygons_layer', handlePolygonMouseLeave)
      map.on('mousedown', 'data_polygons_layer', handlePolygonMouseDown)
      map.on('mousedown', handleDeselect)
      map.once('mousemove', (e: any) => {
        setMapElement(e.originalEvent.target)
      })
    }

    return function () {
      if (map) {
        map.off('click', 'data_nodes_helper', handleNodeClick)
        map.off('mousemove', 'data_nodes_helper', handleNodeMouseMove)
        map.off('mouseleave', 'data_nodes_helper', handleNodeMouseLeave)
        map.off('mousedown', 'data_nodes_helper', handleNodeMouseDown)
        map.off('mousemove', 'data_lines_helper', handleLineMouseMove)
        map.off('mouseleave', 'data_lines_helper', handleLineMouseLeave)
        map.off('mousedown', 'data_lines_helper', handleLineMouseDown)
        map.off('mousemove', 'data_polygons_layer', handlePolygonMouseMove)
        map.off('mouseleave', 'data_polygons_layer', handlePolygonMouseLeave)
        map.off('mousedown', 'data_polygons_layer', handlePolygonMouseDown)
        map.off('mousedown', handleDeselect)
      }
    }
  }, [
    map, isDrawing, handleDeselect, handleNodeClick,
    handlePolygonMouseDown, handlePolygonMouseMove, handlePolygonMouseLeave,
    handleLineMouseDown, handleLineMouseMove, handleLineMouseLeave,
    handleNodeMouseDown, handleNodeMouseMove, handleNodeMouseLeave
  ])

  const onAddPolygon = function () {
    setDrawing(true)
    setEditIndex(-1)
    drawDataRef.current = []
  }

  const onDelete = useCallback(function () {
    if (editIndex >= 0) {
      setPolygonsData(polygons => ([
        ...(polygons || []).slice(0, editIndex),
        ...(polygons || []).slice(editIndex + 1)
      ]))
      setEditIndex(-1)
    }
  }, [editIndex])

  const handleMapKeyDown = useCallback(function (e: any) {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (nodeIndex >= 0) {
        handleNodeDelete(nodeIndex, editIndex)
        setNodeIndex(-1)
      } else if (editIndex >= 0) {
        onDelete()
      }
    }
  }, [nodeIndex, editIndex, onDelete])

  useEventListener('keydown', handleMapKeyDown, !isDrawing ? mapElement : null)

  /** Init drawing */
  useLayersInit(map, isDrawing, 'draw')

  const handleDrawNodeMouseMove = useCallback(function (e: any) {
    const data = drawDataRef.current || []
    if (e.features?.[1]?.id !== undefined) {
      if (mapElement) {
        if ((e.features[1].id === 0 && data.length >= 3 )|| e.features[1].id === data.length - 1) {
          mapElement.style.cursor = 'pointer'
        } else {
          mapElement.style.cursor = 'not-allowed'
        }
      }

      renderLayers(map, 'draw', [[...data, data[e.features[1].id]]], 0, data.length)
      lockRef.current = true
    }
  }, [drawDataRef, map, mapElement])

  const handleDrawNodeMouseLeave = useCallback(function () {
    lockRef.current = false
    if (mapElement) {
      mapElement.style.cursor = 'crosshair'
    }
  }, [mapElement])

  /** Add new node */
  const handleDrawNodeClick = useCallback(function (e: any) {
    const data = drawDataRef.current || []
    const { lng, lat } = e.lngLat as { lng: number, lat: number }
    if (e.features?.[1]?.id === undefined) {
      drawDataRef.current = [
        ...data,
        [ lng, lat ]
      ];
      renderLayers(map, 'draw', [drawDataRef.current], 0, -1)
    } else {
      if (e.features[1].id === 0 && data.length >= 3) {
        let editIndex = -1
        setDrawing(false)
        setPolygonsData(polygons => {
          editIndex = polygons?.length || 0
          return [
            ...(polygons || []),
            [...data, data[0]]
          ]
        })
        setEditIndex(editIndex)
      } else if (e.features[1].id === data.length - 1) {
        drawDataRef.current = data.slice(0, data.length - 1)
        renderLayers(map, 'draw', [drawDataRef.current], 0, -1)
      }
    }
  }, [ map, drawDataRef])

  const handleDrawMapMouseMove = useCallback(function (e: any) {
    if (map && drawDataRef.current && mapElement && !lockRef.current) {
      const { lng, lat } = e.lngLat as { lng: number, lat: number }
      const data = drawDataRef.current || []
      mapElement.style.cursor = 'crosshair'
      renderLayers(map, 'draw', [[...data, [lng, lat]]], 0, data.length)
    }
  }, [ map, drawDataRef, mapElement, lockRef ])

  const handleDrawMapKeyDown = useCallback(function (e: any) {
    if (!keyPressedRef.current.includes(e.keyCode)) {
      keyPressedRef.current.push(e.keyCode)
      if (e.keyCode === 32) {
        if (mapElement && drawDataRef.current) {
          mapElement.style.cursor = 'grab'
          renderLayers(map, 'draw', [drawDataRef.current], 0, drawDataRef.current.length)
        }
      }

      if (e.keyCode === 27) {
        drawDataRef.current = []
        renderLayers(map, 'draw', [], 0, 0)
      }
    }
  }, [ map, keyPressedRef, mapElement, drawDataRef ])

  const handleDrawMapKeyUp = useCallback(function (e: any) {
    keyPressedRef.current = keyPressedRef.current.filter(x => x !== e.keyCode)
  }, [ keyPressedRef ])

  useEventListener('keydown', handleDrawMapKeyDown, isDrawing ? mapElement : null)
  useEventListener('keyup', handleDrawMapKeyUp, isDrawing ? mapElement : null)

  /** Bind events to add drawing polygon node */
  useEffect(function () {
    if (map && isDrawing) {
      map.on('mousemove', handleDrawMapMouseMove)
      map.on('mousemove', 'draw_nodes_layer', handleDrawNodeMouseMove)
      map.on('mouseleave', 'draw_nodes_layer', handleDrawNodeMouseLeave)
      map.on('click', 'draw_nodes_layer', handleDrawNodeClick)
    }

    return function () {
      if (map) {
        map.off('mousemove', handleDrawMapMouseMove)
        map.off('mousemove', 'draw_nodes_layer', handleDrawNodeMouseMove)
        map.off('mouseleave', 'draw_nodes_layer', handleDrawNodeMouseLeave)
        map.off('click', 'draw_nodes_layer', handleDrawNodeClick)
      }
    }
  }, [
    map, isDrawing, handleDrawMapMouseMove,
    handleDrawNodeMouseMove, handleDrawNodeMouseLeave, handleDrawNodeClick
  ])

  useEffect(function () {
    if (!isDrawing) {
      keyPressedRef.current = []
      lockRef.current = false
      if (mapElement) {
        mapElement.style.cursor = 'default'
      }
    }
  }, [ isDrawing, mapElement ])

  return { onAddPolygon, onDelete, editIndex, isDrawing }
}

export default useMapEditing
