import React from 'react'
import { Textbox } from 'ui-kit/controls'
import { Button, Icon, Icons, Popup } from 'ui-kit'
import { tagsColor } from 'consts'
import { ITag } from 'models/ITag'
import './tags.scss'

interface Props {
  value: ITag
  onChange: (value: ITag) => void
}

const TagsEditForm = function (props: Props) {
  const { value, onChange } = props

  return <div className='tags-edit'>
    <Textbox
      value={value?.value || ''}
      onChange={e => onChange({...value, value: e.currentTarget.value})}
      size={40}
      tabIndex={0}
      placeholder='Введите название'
    />

    <Popup
      alignRight
      trigger={(props: any) => (
        <Button { ...props }>
          <Icon type={Icons.Tag} color={tagsColor[value?.color]} />
        </Button>
      )}
      triggerProps={({ onClick, isActive }: any) => ({
        onClick, active: isActive
      })}
    >
      <div className='tags-colors'>
        { tagsColor.map((color, index) => (
          <Button
            key={color}
            clear
            onClick={() => onChange({...value, color: index })}
            active={value?.color === index}
          >
            <Icon type={Icons.Tag} color={color} />
          </Button>
        ))}
      </div>
    </Popup>
  </div>
}

export default TagsEditForm
