import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'
import classes from 'classnames'
import Scroller from '../Scroller'
import { Icon } from '../index'
import { useOutsideClick } from '../../controls'
import { useAnimationFrame } from '../../hooks'
import './popup.scss'

interface Props {
  className?: string
  style?: React.CSSProperties
  items?: {
    icon?: React.FunctionComponent
    danger?: boolean
    label: string
    onClick?: (e?: any) => void
    disabled?: boolean
    value?: any
    href?: string
  }[]
  children?: any
  onClick?: (value: any) => void
  onToggle?: (value: boolean) => void
  trigger?: any
  triggerProps?: Function
  isOpened?: boolean
  alignRight?: boolean
}

const Popup = (props: Props) => {
  const {
    className,
    items,
    onClick,
    trigger: Trigger,
    children,
    onToggle,
    alignRight,
    style
  } = props
  const [ popupStyle, setPopupStyle ] = useState<undefined | React.CSSProperties>(style)
  const [ listStyle, setListStyle ] = useState<undefined | React.CSSProperties>(undefined)
  const [ isOpened, setOpened ] = useState(props.isOpened || false)
  const [ isVisible, setVisibility ] = useState(false)
  const listRef = useRef<HTMLUListElement | null>(null)
  const blockRef = useRef<HTMLDivElement | null>(null)
  const popupRef = useRef<HTMLDivElement | null>(null)
  const triggerRef = useRef<HTMLElement | null>(null)
  const positionedRef = useRef(false)
  useOutsideClick(isOpened, setOpened, popupRef, triggerRef)

  useEffect(function () {
    setOpened(props.isOpened || false)
  }, [ props.isOpened ])

  useEffect(function () {
    onToggle?.(isOpened)
    setVisibility(isOpened)

    if (isOpened && popupRef.current) {
      triggerRef.current = popupRef.current?.previousElementSibling as HTMLElement
      const rect = triggerRef.current?.getBoundingClientRect()
      if (rect) {
        const anchorEl = document.createElement('span')
        anchorEl.style.left = `${rect.left}px`
        anchorEl.style.top = `${rect.top}px`
        anchorEl.style.position = 'fixed'
        triggerRef.current?.parentElement?.insertBefore(anchorEl, triggerRef.current)
        const anchorRect = anchorEl.getBoundingClientRect()
        positionedRef.current = !(anchorRect?.left === rect.left && anchorRect.top === rect.top)
      }
    }

    return function () {
      triggerRef.current = null
    }
  }, [ isOpened, onToggle, popupRef ])

  const followScroll = useCallback(function () {
    if (triggerRef.current && (listRef.current || blockRef.current)) {
      const rect = triggerRef.current.getBoundingClientRect()
      const innerWidth = Math.max(listRef.current?.offsetWidth || 0, blockRef.current?.offsetWidth || 0)
      const isAlignRight = alignRight || innerWidth > window.innerWidth - rect.left
      let maxHeight = window.innerHeight - rect.bottom - 4
      let isTopAligned = false
      const listHeight = (listRef.current?.offsetHeight || 0) + (blockRef.current?.offsetHeight || 0)
      if ((listHeight > maxHeight) && (rect.top - 4 > maxHeight)) {
        isTopAligned = true
        maxHeight = rect.top - 4
      }

      const popupStyle = !positionedRef.current
        ? {
          position: 'fixed',
          top: isTopAligned ? 'auto' : rect.bottom + 2,
          bottom: isTopAligned ? window.innerHeight - rect.top + 2 : 'auto',
          maxHeight,
          left: isAlignRight ? 'auto' : rect.left,
          right: isAlignRight ? window.innerWidth - rect.right : 'auto'
        }
        : {
            ...style,
            position: 'absolute',
            top: 'auto',
            marginTop: 2,
            left: 'auto',
            marginLeft: alignRight
              ? (triggerRef.current?.offsetWidth || 0) -
              (popupRef.current?.offsetWidth || 0)
              : 0
        }

      const listStyle = {
        height: listHeight,
        width: innerWidth,
        maxHeight: maxHeight || 'unset'
      }

      setPopupStyle(style => shallowEqual(popupStyle, style) ? style : popupStyle as React.CSSProperties)
      setListStyle(style => shallowEqual(listStyle, style) ? style : listStyle as React.CSSProperties)
    }
  }, [ popupRef, listRef, blockRef, alignRight, style ])

  useAnimationFrame(isOpened ? followScroll : null)

  return <>
    { props.trigger && (
      <Trigger
        { ...props.triggerProps?.({
          onClick: () => setOpened(!isOpened),
          isActive: isOpened
        }) }
      />
    )}
    { isOpened && <div
      className={ classes('popup', className, isVisible && '-opened') }
      ref={ popupRef }
      style={ popupStyle }
      onMouseDown={ e => e.preventDefault() }
    >
      <Scroller style={ listStyle } scrollTop={1} offset={{ bottom: 2}}>
        { items && <ul className="popup-menu" ref={ listRef } style={{ maxHeight: 300 }} >
          { items.map(function (item, index) {
            return (
              <li
                key={ index }
                role="button"
                aria-disabled={ item.disabled ? true : undefined }
                className={ classes(item.danger && '-danger') }
                onClick={ (e: any) => {
                  onClick?.(item.value)
                  item.onClick?.(e)
                  setOpened(false)
                } }
              >
                { item.href
                  ? <Link to={ item.href }>
                    { item.icon && <Icon type={ item.icon }/> }
                    { item.label }
                  </Link>
                  : <>
                    { item.icon && <Icon type={ item.icon }/> }
                    { item.label }
                  </>
                }
              </li>
            )
          }) }
        </ul> }
        { <div ref={ blockRef }>
          { children }
        </div> }
      </Scroller>
    </div> }
  </>
}

export default Popup
