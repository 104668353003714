import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import useEventListener from "@use-it/event-listener";
import classes from "classnames";
import ListItem, { IListItem } from "./ListItem";
import { TValue } from "../../types";
import { toggleArray } from "../../helpers";
import "./list.scss";

export interface IList {
  label?: string;
  search?: React.ReactElement;
  list: IListItem[] | null | undefined;
  onChange?: (value: any) => void;
  value?: TValue | TValue[];
  inline?: number;
}

const List = function (props: IList) {
  const { label, list, onChange, value, search, inline } = props;
  const [colsModifier, setColsModifier] = useState("");
  const listRef = useRef<HTMLDivElement | null>(null);

  const handleSetWidth = useCallback(
    function () {
      if (!inline) {
        setColsModifier("");
        return;
      }

      if (listRef.current) {
        const width = listRef.current?.offsetWidth;
        const cols = Math.min(5, Math.floor(width / inline));

        if (cols > 1) {
          setColsModifier(`-cols${cols}`);
        } else {
          setColsModifier("");
        }
      }
    },
    [listRef, inline]
  );

  useEventListener("resize", handleSetWidth, window);

  useLayoutEffect(
    function () {
      handleSetWidth();
    },
    [handleSetWidth]
  );

  return (
    <>
      {label && <span className="list-label">{label}</span>}
      {search && <span className="list-search">{search}</span>}
      <div
        ref={listRef}
        className={classes("list-items", inline && "-inline", colsModifier)}
      >
        {list ? (
          list.length > 0 ? (
            list.map((item, index) => {
              return item.label ? (
                //@ts-ignore
                <ListItem
                  {...item}
                  selected={
                    value !== undefined &&
                    (Array.isArray(value)
                      ? value.includes(item.value)
                      : value === item.value)
                  }
                  key={index}
                  onClick={
                    onChange
                      ? () => {
                          const response = Array.isArray(value)
                            ? toggleArray(value, item.value)
                            : value === item.value
                            ? undefined
                            : [item.value];

                          onChange(response?.length ? response : undefined);
                        }
                      : undefined
                  }
                />
              ) : null;
            })
          ) : (
            //@ts-ignore
            <ListItem label={null} />
          )
        ) : (
          //@ts-ignore
          <ListItem label={list} />
        )}
      </div>
    </>
  );
};

export default List;
