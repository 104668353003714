import { useCallback, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { formContext } from ".";
import { setPrompt, setRedirect } from "../reducers/layout";
import { useRouteMatch } from "react-router-dom";
import { createPathURL } from "../helpers";

const useForm = function (
  endpointAction?: Function,
  validate?: Function | null,
  initialState?: { [key: string]: any } | null
): {
  onSubmit: () => void;
  onExit: (url?: string, action?: any) => void;
  disabled: boolean;
  state: { [key: string]: any };
  onChange:
    | undefined
    | ((name: string, value: any | ((v: any) => any)) => void);
} {
  const { state, onInit, disabled, onChange, isChanged } =
    useContext(formContext);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  useEffect(
    function () {
      onInit?.(initialState, validate);
    },
    [onInit, initialState, validate]
  );

  const onExit = useCallback(
    function (url?: string, action?: any) {
      const redirectTo = url
        ? url
        : createPathURL(match?.path, { ...match?.params, modal: undefined });

      if (isChanged) {
        dispatch(
          setPrompt({
            message: "Данные не будут сохранены. Продолжить?",
            buttons: [
              {
                label: "Продолжить",
                danger: true,
                default: true,
                action: action || setRedirect(redirectTo, true),
              },
            ],
          })
        );
      } else {
        dispatch(action || setRedirect(redirectTo, true));
      }
    },
    [isChanged, dispatch, match]
  );

  const onSubmit = useCallback(
    function () {
      endpointAction && dispatch(endpointAction(state, match));
    },
    [endpointAction, dispatch, state, match]
  );

  return {
    onSubmit,
    onExit,
    disabled: disabled || !isChanged,
    state,
    onChange,
  };
};

export default useForm;
