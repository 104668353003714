import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classes from "classnames";
import useEventListener from "@use-it/event-listener";
import PromptLoader from "./PromptLoader";
import { Button } from "../../controls";
import { setPrompt } from "../../reducers/layout";
import { ReduxState } from "../../types";
import "./prompt.scss";
import { BoardFormat } from "consts";

export interface PromptButton {
  label?: string;
  danger?: boolean;
  success?: boolean;
  accent?: boolean;
  fill?: boolean;
  default?: boolean;
  action?: any;
  props?: (state: any) => any;
  callback?: (state: any, e: any) => any;
  icon?: React.FunctionComponent;
}

export interface IPrompt {
  message?: string;
  component?: any;
  initialState?: any;
  onCancel?: (state: any, e: any) => any;
  buttons?: PromptButton[];
  loader?: boolean;
  format?: BoardFormat;
}

const Prompt = function () {
  const prompt: IPrompt | null = useSelector(
    ({ layout }: ReduxState) => layout.prompt,
    shallowEqual
  );
  const [statePrompt, setStatePrompt] = useState(prompt);
  const [dataState, setDataState] = useState(prompt?.initialState || {});
  const dispatch = useDispatch();
  const focusRef = useRef<HTMLElement | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef(0);

  useEffect(
    function () {
      setTimeout(() => {
        if (componentRef.current) {
          const focusEl: HTMLInputElement | null =
            componentRef.current?.querySelector('input[tabindex="0"]');
          focusEl?.focus();
        }
      }, 100);
    },
    [componentRef, statePrompt]
  );

  useEffect(
    function () {
      clearTimeout(timerRef.current);
      if (prompt) {
        setDataState(prompt.initialState || {});
        setStatePrompt(prompt);
      } else {
        timerRef.current = window.setTimeout(() => {
          setDataState({});
          setStatePrompt(prompt);
        }, 200);
      }

      return function () {
        clearTimeout(timerRef.current);
      };
    },
    [prompt]
  );

  const handleClose = function (e: any) {
    dispatch(setPrompt());
    statePrompt?.onCancel?.(dataState, e);
  };

  const handleButtonClick = function (e: any, button: PromptButton) {
    if (!e.isPropagationStopped?.()) {
      dispatch(setPrompt());

      if (button.action) {
        if (Array.isArray(button.action)) {
          button.action.forEach((action) => dispatch(action));
        } else {
          dispatch(button.action);
        }
      }

      if (button.callback) {
        button.callback?.(dataState, e);
      }
    }
  };

  const handleKeyPress = function (e: any) {
    if (e.key === "Escape") {
      dispatch(setPrompt());
    } else if (e.key === "Enter") {
      const button = statePrompt?.buttons?.find((b) => b.default);
      if (button) {
        handleButtonClick(e, button);
      }
    }
  };

  useEventListener(
    "keydown",
    handleKeyPress,
    prompt && statePrompt ? window : null
  );

  const Component = useMemo(
    function () {
      return statePrompt?.component || null;
    },
    [statePrompt]
  );

  if (!statePrompt && !prompt) {
    return null;
  }

  return (
    <div className={classes("prompt", prompt && statePrompt && "-visible")}>
      <div className="prompt-window">
        {statePrompt?.loader ? (
          <PromptLoader message={statePrompt?.message} />
        ) : (
          <>
            {statePrompt?.message && (
              <div className="prompt-message">{statePrompt?.message}</div>
            )}
            {statePrompt?.component && (
              <div className="prompt-component" ref={componentRef}>
                <Component
                  value={dataState}
                  format={prompt?.format}
                  onChange={setDataState}
                />
              </div>
            )}
            <div className="prompt-footer">
              {statePrompt?.buttons?.map((button, index) => (
                <Button
                  ref={button.default ? focusRef : undefined}
                  key={index}
                  fill={button.fill}
                  danger={button.danger}
                  success={button.success}
                  accent={button.accent}
                  strong={button.default}
                  children={button.label}
                  icon={button.icon}
                  {...(button.props ? button.props(dataState) : {})}
                  onClick={(e: any) => handleButtonClick(e, button)}
                />
              ))}
              <Button onClick={handleClose}>Отмена</Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Prompt;
