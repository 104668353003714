import { format, utcToZonedTime } from 'date-fns-tz'
import formatISO from 'date-fns/formatISO'
import { TIME_ZONE } from '../consts'

const hasTimeZone = function (date: number | string) {
  if (typeof date !== 'string') {
    return false
  }

  return (
    date.substr(-1) === 'Z' ||
    date.substr(-6).substr(0, 1) === '+' ||
    date.substr(-6).substr(0, 1) === '-'
  )
}

export const getFormattedDate = function (date: number | string, withTime = true) {
  if (!date) {
    return date
  }

  const timeFormat = withTime
    ? 'dd.MM.yyyy HH:mm'
    : 'dd.MM.yyyy'

  return hasTimeZone(date)
    ? format(utcToZonedTime(new Date(date), TIME_ZONE), timeFormat, { timeZone: TIME_ZONE })
    : format(new Date(date), timeFormat)
}

export const getFormattedTime = function (date: number | string) {
  return hasTimeZone(date)
    ? format(utcToZonedTime(new Date(date), TIME_ZONE), 'HH:mm:ss', { timeZone: TIME_ZONE })
    : format(new Date(date), 'HH:mm:ss')
}

export const getFormattedPeriod = function (from: string | null, to: string | null) {
  if (!from && !to) {
    return null
  }

  const startDate = from ? format(
    utcToZonedTime(new Date(from), TIME_ZONE),
    'dd.MM.yyyy HH:mm',
    { timeZone: TIME_ZONE }
  ) : ''

  const endDate = to ? format(
    utcToZonedTime(new Date(to), TIME_ZONE),
    'dd.MM.yyyy HH:mm',
    { timeZone: TIME_ZONE }
  ) : ''

  return [startDate, endDate].map(x => x || '...').join(' — ')
}

export const getLocalTimeZone = function () {
  const timeOffset = (new Date()).getTimezoneOffset()
  if (timeOffset === 0) {
    return 'Z'
  }
  const sign = timeOffset <= 0 ? '+' : '-'
  const hours = `0${ Math.floor(Math.abs(timeOffset) / 60) }`.substr(-2)
  const minutes = `0${ Math.abs(timeOffset) % 60 }`.substr(-2)

  return `${ sign }${ hours }:${ minutes }`
}

const clearTimeZone = function (dateISO: string) {
  return dateISO.substr(0, 19)
}

export const getLocalDate = function (
  dateISO: string | Date | number | undefined | null
): Date | undefined {
  if (!dateISO) {
    return undefined
  }

  return typeof dateISO === 'string'
    ? utcToZonedTime(new Date(dateISO), TIME_ZONE)
    : new Date(dateISO)
}

export const getISODate = function (date: Date | number | undefined): string {
  if (!date) {
    return ''
  }

  const dateISO = formatISO(date)
  return `${ clearTimeZone(dateISO) }${ TIME_ZONE }`
}
